/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CollaboratorFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import * as fakerStatic from 'faker';
import {Collaborator} from "@services/src";
import {type} from "os";


export class CollaboratorFactory {
    static factoryArray(size: number): Collaborator[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(CollaboratorFactory.factory());
        }
        return array;
    }

    static factory(): Collaborator {

        return {
            id: fakerStatic.random.number(4),
            firstName: fakerStatic.name.firstName(),
            lastName: fakerStatic.name.lastName(),
            email: fakerStatic.internet.email(),

            mobile: fakerStatic.phone.phoneNumber(),

        };
    }
}

