/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       GuestFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import * as fakerStatic from 'faker';
import {Guest, GuestType} from "@services/src/models";

export class GuestFactory {
    static factoryArray(size: number, type: GuestType = null): Guest[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(GuestFactory.factory(type));
        }
        return array;
    }

    static factory(type: GuestType = null): Guest {
        const typeArray = [GuestType.Client, GuestType.Collaborator];
        if (type == null)
            type = typeArray[fakerStatic.random.number(typeArray.length - 1)];
        return {
            id: fakerStatic.random.number(4),
            type: type,
            firstName: fakerStatic.name.firstName(),
            lastName: fakerStatic.name.lastName(),
            email: fakerStatic.internet.email(),
            fiscalCode: fakerStatic.random.alphaNumeric(16),
            mobile: fakerStatic.phone.phoneNumber(),
            photoPath: null,
        };
    }
}

