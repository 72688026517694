/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HostReservationFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import {HostReservation, ReservationStateEnum} from "@services/src/models";
import {HouseFactory} from "./HouseFactory";
import {ReservationFactory} from "./ReservationFactory";
import {RegistryFactory} from "./RegistryFactory";

export class HostReservationFactory {
    static factoryArray(size: number, type: ReservationStateEnum = null): HostReservation[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(HostReservationFactory.factory(type));
        }
        return array;
    }

    static factory(type: ReservationStateEnum = null): HostReservation {
        return {
            id: ReservationFactory.factory(type).id,
            startAt: ReservationFactory.factory(type).startAt,
            endAt: ReservationFactory.factory(type).endAt,
            currentState: ReservationFactory.factory(type).currentState,
            registry: RegistryFactory.factory(),
            house: HouseFactory.factory(),
        };
    }
}

