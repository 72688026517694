/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReservationAccommodationJoin,
    ReservationAccommodationJoinFromJSON,
    ReservationAccommodationJoinToJSON,
} from '../models';

export interface ApiReservationAccommodationJoinsIdDeleteRequest {
    id: number;
}

export interface ApiReservationAccommodationJoinsIdGetRequest {
    id: string;
}

export interface ApiReservationAccommodationJoinsIdPutRequest {
    id: number;
    reservationAccommodationJoin: ReservationAccommodationJoin;
}

export interface ApiReservationAccommodationJoinsPostRequest {
    reservationAccommodationJoin: ReservationAccommodationJoin;
}

/**
 * 
 */
export class ReservationAccommodationJoinsApi extends runtime.BaseAPI {

    /**
     */
    async apiReservationAccommodationJoinsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ReservationAccommodationJoin>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/reservation_accommodation_joins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReservationAccommodationJoinFromJSON));
    }

    /**
     */
    async apiReservationAccommodationJoinsGet(initOverrides?: RequestInit): Promise<Array<ReservationAccommodationJoin>> {
        const response = await this.apiReservationAccommodationJoinsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReservationAccommodationJoinsIdDeleteRaw(requestParameters: ApiReservationAccommodationJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReservationAccommodationJoinsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/reservation_accommodation_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiReservationAccommodationJoinsIdDelete(requestParameters: ApiReservationAccommodationJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiReservationAccommodationJoinsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiReservationAccommodationJoinsIdGetRaw(requestParameters: ApiReservationAccommodationJoinsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReservationAccommodationJoin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReservationAccommodationJoinsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/reservation_accommodation_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationAccommodationJoinFromJSON(jsonValue));
    }

    /**
     */
    async apiReservationAccommodationJoinsIdGet(requestParameters: ApiReservationAccommodationJoinsIdGetRequest, initOverrides?: RequestInit): Promise<ReservationAccommodationJoin> {
        const response = await this.apiReservationAccommodationJoinsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiReservationAccommodationJoinsIdPutRaw(requestParameters: ApiReservationAccommodationJoinsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiReservationAccommodationJoinsIdPut.');
        }

        if (requestParameters.reservationAccommodationJoin === null || requestParameters.reservationAccommodationJoin === undefined) {
            throw new runtime.RequiredError('reservationAccommodationJoin','Required parameter requestParameters.reservationAccommodationJoin was null or undefined when calling apiReservationAccommodationJoinsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/reservation_accommodation_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationAccommodationJoinToJSON(requestParameters.reservationAccommodationJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiReservationAccommodationJoinsIdPut(requestParameters: ApiReservationAccommodationJoinsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiReservationAccommodationJoinsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiReservationAccommodationJoinsPostRaw(requestParameters: ApiReservationAccommodationJoinsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reservationAccommodationJoin === null || requestParameters.reservationAccommodationJoin === undefined) {
            throw new runtime.RequiredError('reservationAccommodationJoin','Required parameter requestParameters.reservationAccommodationJoin was null or undefined when calling apiReservationAccommodationJoinsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/reservation_accommodation_joins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationAccommodationJoinToJSON(requestParameters.reservationAccommodationJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiReservationAccommodationJoinsPost(requestParameters: ApiReservationAccommodationJoinsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiReservationAccommodationJoinsPostRaw(requestParameters, initOverrides);
    }

}
