/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ResourceSelectionBlockStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import Colors from "../../config/Colors";

const styles = StyleSheet.create({
    enabledButton: {
        paddingTop:10,
        paddingBottom:10,
    },
    backgroundEnabled: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        backgroundColor: Colors.buttonEnabledBackground,
    },
    backgroundDisabled: {
        backgroundColor: Colors.buttonDisabledBackground,
    },
    text: {
        textAlign:'center',
        color: 'white',
        padding: 10,
    },
    avatarPlaceholderAdd: {
        width: 30,
        height: 30,
        position: 'absolute',
        left: 60,
    },
    addButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.primaryDark,
    },
    addButtonContainer: {
        padding: 10,
        backgroundColor: Colors.primaryColor,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    addButtonImage: {
        width: 8,
        height: 8,
        alignSelf: 'center',
    },
    addButtonText: {
        marginHorizontal: 20,
        color: 'white',
        fontSize: 10,
    },
    serviceButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        borderColor: Colors.primaryDark,
        borderWidth: 0.5,
    },
    serviceButtonUntouchable: {
        flex: 1,
        flexDirection: 'column',
        borderColor: Colors.primaryDark,
        borderWidth: 0.5,
    },
    serviceNotSelectedButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        borderColor: Colors.greyBorder,
        borderWidth: 0.5,
    },
    serviceNotSelectedButtonUntouchable: {
        flex: 1,
        flexDirection: 'column',
        borderColor: Colors.greyBorder,
        borderWidth: 0.5,
    },
    serviceTextContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 5,
    },
    serviceButtonContainer: {
        padding: 10,
    },
    serviceButtonImage: {
        width: 25,
        height: 25,
        alignSelf: 'flex-end',
        resizeMode: 'contain',
    },
    serviceButtonText: {
        marginHorizontal: 5,
    },
    inputBlockButtonContainer: {
        flexDirection: 'row',
        display: 'flex',
    },
    inputBlockButtonInputContainer: {
        flex: 3,
        flexDirection: 'column',
    },
    inputBlockButtonButtonContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
});

export default styles;