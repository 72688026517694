/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ClickableBlock.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {GestureResponderEvent, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import {AvenirHeavyText, AvenirLightGreyText, AvenirLightPrimaryText, AvenirMediumPrimaryText} from "./StyledText";
import blockStyle from "@styles/ClickableBlockStyle";
import {PlusWhiteImage} from "@components/Images/PlusWhiteImage";
import {ListColorImage} from "@components/Images/ListColorImage";
import {RightArrowColorImage} from "@components/Images/RightArrowColorImage";
import {SettingsImage} from "@components/Images/SettingsImage";
import {PlusImage} from "@components/Images/PlusImage";

interface ClickableBlockProps {
    onPress: (event: GestureResponderEvent) => void,
    style?: StyleProp<ViewStyle>,
    title: string,
}

export function AddClickableBlock(props: ClickableBlockProps) {
    return (
        <View style={props.style}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[blockStyle.addButtonTouchable]}>
                <View style={[blockStyle.addButtonContainer]}>
                    <PlusWhiteImage style={[blockStyle.addButtonImage]}/>
                </View>
                <AvenirHeavyText style={blockStyle.addButtonText}>{props.title}</AvenirHeavyText>
            </TouchableOpacity>
        </View>
    );
}

export function ListClickableBlock(props: ClickableBlockProps) {
    return (
        <View style={props.style}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[blockStyle.listButtonTouchable]}>
                <View style={[blockStyle.listButtonContainer]}>
                    <ListColorImage style={[blockStyle.listButtonImage]}/>
                </View>
                <AvenirLightPrimaryText style={blockStyle.listButtonText}>{props.title}</AvenirLightPrimaryText>
                <View style={{flex: 1}}>
                    <RightArrowColorImage style={[blockStyle.listButtonImage, {marginRight: 5,}]}/>
                </View>
            </TouchableOpacity>
        </View>
    );
}

export function FiltersClickableBlock(props: ClickableBlockProps) {
    return (
        <View style={props.style}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[blockStyle.filtersButtonTouchable]}>
                <AvenirMediumPrimaryText style={blockStyle.filtersButtonText}>{props.title}</AvenirMediumPrimaryText>
                <View style={{flex: 1}}>
                    <SettingsImage style={[blockStyle.filtersButtonImage, {marginRight: 5,}]}/>
                </View>
            </TouchableOpacity>
        </View>
    );
}

export function AddRowClickableBlock(props: ClickableBlockProps) {
    return (
        <View style={props.style}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[blockStyle.addRowButtonTouchable]}>
                <View style={{flex: 1}}>
                    <PlusImage style={[blockStyle.addRowButtonImage, {marginRight: 5,}]}/>
                </View>
                <AvenirLightGreyText style={blockStyle.addRowButtonText}>{props.title}</AvenirLightGreyText>
            </TouchableOpacity>
        </View>
    );
}