/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       EventReadRow.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, TouchableOpacity, View} from 'react-native';
import {
    AvenirBookText,
    AvenirEventPriorityText,
    AvenirLightPrimaryText,
    AvenirLightText,
    AvenirMediumPrimaryText
} from "../StyledText";
import eventReadRowStyle from "@styles/host/rows/EventReadRowStyle";
import {Event} from '@services/src';
import {NavigationProps} from '@helpers/NavigationHelper';
import {DateHelper} from "@helpers/DateHelper";
import Colors from "../../config/Colors";
import {EventHelper} from "@helpers/EventHelper";

export interface EventReadRowProps {
    event: Event,
    read: boolean,
    context: Component<NavigationProps, any>,
    onPress: (event: GestureResponderEvent) => void,
}

export function EventReadRow(props: EventReadRowProps) {
    return (
        <View style={[eventReadRowStyle.flex, eventReadRowStyle.container]}>
            <View style={[eventReadRowStyle.dotView, {flexDirection: 'row', width: 10}]}>
                {!props.event.read &&
                <AvenirLightPrimaryText>o</AvenirLightPrimaryText>
                }
            </View>
            <TouchableOpacity onPress={props.onPress} style={eventReadRowStyle.flex}>
                <View
                    style={[eventReadRowStyle.bigContainer, eventReadRowStyle.textContainer, {flexDirection: 'row',}]}>

                    <View style={{flex: 8,}}>

                        <View style={[eventReadRowStyle.rowWithCenteredContent]}>
                            <AvenirEventPriorityText style={[eventReadRowStyle.flex]} read={props.event.read}
                                                     priority={props.event.priority}>
                                {EventHelper.trimmedLongDescription(props.event)}
                            </AvenirEventPriorityText>
                        </View>

                        <View style={[eventReadRowStyle.rowWithCenteredContent]}>
                            <View style={{flexDirection: 'row', flex: 1,}}>
                                <View style={eventReadRowStyle.dateContainer}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[eventReadRowStyle.flex, {textAlign: "left",}]}>
                                        {DateHelper.formatDate(props.event.datetime)}
                                    </AvenirBookText>
                                </View>
                                <View style={eventReadRowStyle.timeContainer}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[eventReadRowStyle.flex, eventReadRowStyle.timeText, {textAlign: "left",}]}>
                                        {DateHelper.formatTime(props.event.datetime)}
                                    </AvenirBookText>
                                </View>
                            </View>
                            {/*<View style={{flexDirection: 'row', flex: 0.2,}}>*/}
                            {/*    <DetailArrowColorImage style={[eventReadRowStyle.dateArrow]}/>*/}
                            {/*</View>*/}
                            {/*<View style={{flexDirection: 'row', flex: 1,}}>*/}
                            {/*    <View style={eventReadRowStyle.dateContainer}>*/}
                            {/*        <AvenirBookText numberOfLines={1}*/}
                            {/*                        style={[eventReadRowStyle.flex, {textAlign: "left",}]}>*/}
                            {/*            {DateHelper.formatDate(props.event.endAt)}*/}
                            {/*        </AvenirBookText>*/}
                            {/*    </View>*/}
                            {/*    <View style={eventReadRowStyle.timeContainer}>*/}
                            {/*        <AvenirBookText numberOfLines={1}*/}
                            {/*                        style={[eventReadRowStyle.flex, eventReadRowStyle.timeText, {textAlign: "left",}]}>*/}
                            {/*            {DateHelper.formatTime(props.event.endAt)}*/}
                            {/*        </AvenirBookText>*/}
                            {/*    </View>*/}
                            {/*</View>*/}
                        </View>

                        <View style={[eventReadRowStyle.rowWithCenteredContent]}>
                            <AvenirLightText numberOfLines={1}
                                             style={[eventReadRowStyle.flex, {
                                                 textAlign: "left",
                                                 color: Colors.eventNameColor,
                                             }]}>
                                {EventHelper.rowDetailString(props.event)}
                            </AvenirLightText>

                        </View>

                        <View
                            style={{flexDirection: 'row', alignItems: 'center', alignContent: 'center', marginTop: 5,}}>
                            {/*<View style={{flexDirection: 'row', flex: 0.1,}}>*/}
                            {/*    {props.event.registry.type == GuestType.Collaborator &&*/}
                            {/*    <CollaboratorIconColorImage style={[eventReadRowStyle.guestIcon]}/>*/}
                            {/*    }*/}
                            {/*</View>*/}
                            <View style={{flexDirection: 'row', flex: 1,}}>
                                <AvenirMediumPrimaryText numberOfLines={1}
                                                         style={{
                                                             flex: 1,
                                                             textAlign: "left",
                                                             color: Colors.eventResourceColor,
                                                         }}>
                                    {/*{props.event.resource != null ? props.event.resource.name : ''}*/}
                                </AvenirMediumPrimaryText>
                            </View>
                        </View>
                    </View>

                    {/*DETAIL ARROW*/}
                    {/*<View style={[eventReadRowStyle.detailArrowContainer]}>*/}
                    {/*    <DetailArrowImage style={[eventReadRowStyle.detailArrow]}/>*/}
                    {/*</View>*/}

                </View>
                <View style={{height: 1, backgroundColor: Colors.eventRowLine, marginTop: 10,}}>

                </View>
            </TouchableOpacity>
        </View>
    );
}