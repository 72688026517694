/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DebugButtonStyle.ts
 * @author     Christian Ascone
 * @date       9/26/19 3:40 PM
 */

import {StyleSheet} from "react-native";
import Colors from "../config/Colors";

const styles = StyleSheet.create({
    enabledButton: {
        position: 'absolute',
        top: 0,
        width: 40,
        height: 100,
        opacity: 0.3,
        alignContent: 'center',
        alignItems: 'center',
    },
    backgroundEnabled: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        backgroundColor: Colors.buttonEnabledBackground,
    },
    text: {
        textAlign: 'center',
        textAlignVertical: 'center',
        color: 'white',
        padding: 10,
    },
});

export default styles;