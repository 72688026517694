/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewCollaborationAccommodationsScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, LogBox, ScrollView, View,} from 'react-native';
import newCollaborationScreenStyle from "@styles/host/collaborations/NewCollaborationAccommodationsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {getCollaborationBeanFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {DebugButton} from "@components/DebugButton";
import {CollaborationBean} from "../../../models/beans/CollaborationBean";
import {i18n} from "../../../i18n/i18n";
import {AlertHelper} from "@helpers/AlertHelper";
import {AccommodationSelection} from "../../../models/beans/AccommodationSelection";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {House} from "@services/src/models/index";
import {HouseExpandableRow} from "@components/rows/HouseExpandableRow";
import {ApiHostHousesByFiltersPostRequest, CardsApi, HostCardsApi, HostHousesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {
    HouseForAccommodationsSelection,
    HouseForAccommodationsSelectionBean
} from "../../../models/interfaces/HouseForAccommodationsSelectionBean";
import {HouseCompleteFactory} from "../../../models/factory/HouseCompleteFactory";
import {NoDataLabel} from "@components/NoDataLabel";
import {
    NavigationProps,
    openCardBarcodeScanner,
    openCollaborationCardBarcodeScanner,
    openNewCollaborationDoneRoute
} from "@helpers/NavigationHelper";
import {CheckBox} from "react-native-elements";
import {Card} from "@services/src/models";
import {CardSelection, CardSelectionBean} from "../../../models/beans/CardSelection";
import {CardSelectionBlock} from "@components/CardSelectionBlock";
import {AvenirBookPrimaryText} from "@components/StyledText";
import {RoundedButton} from "@components/RoundedButton";



interface State extends LoaderState {
    collaborationBean: CollaborationBean,
    cards: CardSelectionBean[],
    checked: boolean,
}

export default class NewCollaborationPermissionsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.new_collaboration_permissions.title');

        let collaborationBean = getCollaborationBeanFromNavigationProps(this) || new CollaborationBean();
        if (!collaborationBean.houses) collaborationBean.houses = [];
        this.state = {
            loading: false,
            collaborationBean: collaborationBean,
            cards: [],
            checked: false,
        };

    }


    getAllCards(id = null){
        let self = this;
        new CardsApi(this).apiHostCardsStatusGet().then(resp => {
            let cards = resp || [];
            Logger.log(self.constructor.name, `Found houses for host.`);
            self.setState({cards: CardSelection.fromCardList(resp.data, false, this.state.cards), showNoData: true,}, ()=>{
                if (id != null)
                    this.selectScannedCard(id);
            });
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get houses list");
            self.setState({showNoData: true,});
        });
    }

    componentDidMount() {
        this.getAllCards();
    }

    /**
     * Update selected accommodation
     * @param {number} key
     * @param {number} accommodationIndex
     */
    updateSelectedCard(key: number) {
        let cards = this.state.cards;
        let card = cards[key];
        card.selected = !card.selected;
        cards[key] = card;
        this.setState({cards: cards});
    }

    updateSelectedScannedCard(key: number) {
        let cards = this.state.cards;
        let card = cards[key];
        card.selected = true;
        cards[key] = card;
        this.setState({cards: cards});
    }


    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={[newCollaborationScreenStyle.container, globalScreenStyle.containerContainer]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                {!this.state.checked && <View style={[newCollaborationScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <View style={newCollaborationScreenStyle.scrollContainerWithBottomButton}>
                        <AvenirBookPrimaryText>{i18n.t('card.collaboration.description')}</AvenirBookPrimaryText>
                        <RoundedButton     title={i18n.t('screens.new_card.scan')}
                                           style={{marginVertical: 10}}
                                           isQr={true}
                                           onPress={()=>this.props.navigation.navigate( "CollaborationCardBarcodeScanner", {onModalDismiss: (data) =>
                                               this.handleScan(data)}
                                           )}/>
                        <ScrollView
                            style={newCollaborationScreenStyle.container}
                            contentContainerStyle={newCollaborationScreenStyle.contentContainer}>
                            {this.state.cards.map((card, key) => (
                                <View
                                    key={key}
                                    >
                                    <CardSelectionBlock active={card.selected}
                                                        onPress={()=>this.updateSelectedCard(key)}
                                                        style={[newCollaborationScreenStyle.cardContainer]}
                                                        card={card.card}/>

                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.cards} show={this.state.showNoData}/>
                    </View>
                </View>}
                <View style={newCollaborationScreenStyle.checkBox}>

                </View>
                <ConfirmButtonBottom active={dataComplete(this) || this.state.checked}
                                     onPress={() => openNextPage(this)}
                                     title={i18n.t('buttons.new_collaboration_accommodations')}/>
            </KeyboardAvoidingView>
        );
    }

    private handleScan(data){
        if (data)
            new HostCardsApi(this).apiHostCardsExistsCodePost({code: data }).then(resp =>{
                if (resp.id == null){
                    console.log("La carta non esiste...");
                    /*new HostCardsApi(this).apiHostCardsPost({card:{code: data, codeEncrypted: BfreeX1.encode(data)}}).then(resp=>{
                        console.log(resp);
                        this.setState({passepartout: {codeToShow: resp.code, codeToSave: resp.codeEncrypted, selectedCardId: resp.id}});
                    }).catch(error => {
                        Logger.warn(self.constructor.name, "Error " + error);
                        this.setState({showNoData: true,});
                    });*/
                    this.props.navigation.navigate('CollaborationsNewCard', {code: data, onDismiss: (id) => {this.scanHappened(id)}});
                }
                else {
                    console.log("La carta esiste");
                    this.selectScannedCard(resp.id);
                }


            }).catch(error => {
                Logger.warn(self.constructor.name, "Error " + error);
                this.setState({showNoData: true,});
            });
        else
            AlertHelper.showSimpleAlert(i18n.t('screens.new_card.errors.unknown_code_title'), i18n.t('screens.new_card.errors.unknown_code'));

    }
    selectScannedCard(id){
        for (let i = 0; i < this.state.cards.length; i++){
            if (this.state.cards[i].card.id == id)
                this.updateSelectedScannedCard(i);
        }
    }

    async scanHappened(id){
            await this.getAllCards(id);
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let bean = context.state.collaborationBean;
    let houses = HouseCompleteFactory.factoryArray(3);
    let housesWithSelectionBeans = HouseForAccommodationsSelection.fromHouseList(houses.filter(house => house.accommodationsCount > 0));
    housesWithSelectionBeans.forEach(house => {
        house.accommodationsSelectionBeans = AccommodationSelection.fromAccommodationList(house.accommodations);
    });
    bean.houses = housesWithSelectionBeans;
    context.setState({collaborationBean: bean});
}

/**
 * Checks if at least one accommodation for every house has been selected
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    let selectedCards = context.state.cards.filter(card => card.selected);
    return selectedCards != null && context.state.cards.length > 0;
}

/**
 * Open the next page for collaboration creation
 * @param {React.Component<any, State>} context
 */
function openNextPage(context: Component<any, State>) {
   /* if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.collaboration_accommodations_data_incomplete'));
        return;
    }*/
    let selectedCards = [];
    if (context.state.cards != null && context.state.cards != [])
        context.state.cards.forEach((card) => {
                if (card.selected)
                    selectedCards.push(card);
            }
        );
    let collaborationBean = context.state.collaborationBean;
    openNewCollaborationDoneRoute(context, collaborationBean, selectedCards);
}