/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       StatusDotViewStyle.ts
 * @author     Christian Ascone
 * @date       9/25/19 11:09 AM
 */

import {StyleSheet} from "react-native";
import Colors from "../config/Colors";

const styles = StyleSheet.create({
    dot: {
        width: 5,
        height: 5,
        borderRadius: 20,
        marginRight: 5,
        alignSelf: "center",
        overflow: 'hidden',
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
    },
    online: {
        backgroundColor: Colors.statusOnline,
    },
    offline: {
        backgroundColor: Colors.statusOffline,
    },
    active: {
        backgroundColor: Colors.serviceStatusActive,
    },
    expiring: {
        backgroundColor: Colors.serviceStatusExpiring,
    },
    expired: {
        backgroundColor: Colors.serviceStatusExpired,
    },
    statusText: {
        fontSize: 12,
        marginRight: 8,
        textAlign: 'center',
        alignSelf: 'center',
        textAlignVertical: 'center',
    },
    activeText: {
        fontSize: 12,
        marginRight: 8,
        textAlign: 'center',
        alignSelf: 'center',
    },
});

export default styles;