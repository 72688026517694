/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DrawerTabNavigator.tsx
 * @author     Christian Ascone
 * @date       3/25/20 5:54 PM
 */

import React, {Component} from 'react';
import {createDrawerNavigator, createStackNavigator, StackNavigatorConfig} from 'react-navigation';
import {Animated, Dimensions, Easing, Image, Platform, TouchableOpacity, View} from 'react-native';
import TabBarIcon from '@components/TabBarIcon';
import DashboardReservationsScreen from '@screens/host/DashboardReservationsScreen';
import DashboardHousesScreen from '@screens/host/DashboardHousesScreen';
import DashboardCreditsScreen from '@screens/host/DashboardCreditsScreen';
import NewHouseScreen from '@screens/host/house/NewHouseScreen';
import NewHouseDoneScreen from '@screens/host/house/NewHouseDoneScreen';
import HouseDetailScreen from '@screens/host/house/HouseDetailScreen';
import HouseDeleteRecap from '@screens/host/house/HouseDeleteRecap';
import DoorDeleteRecap from '@screens/host/doors/DoorDeleteRecap';
import AccommodationDetailScreen from '@screens/host/accommodations/AccommodationDetailScreen';
import AccommodationDeleteRecap from '@screens/host/accommodations/AccommodationDeleteRecap';
import DoorsScreen from '@screens/host/doors/DoorsScreen';
import AccommodationsScreen from '@screens/host/accommodations/AccommodationsScreen';
import NewDoorScreen from '@screens/host/doors/NewDoorScreen';
import NewAccommodationScreen from '@screens/host/accommodations/NewAccommodationScreen';
import DoorDetailScreen from '@screens/host/doors/DoorDetailScreen';
import {LeftArrowImage} from "@components/Images/LeftArrowImage";
import LinksScreen from '@screens/host/LinksScreen';
import SettingsScreen from '@screens/host/SettingsScreen';
import SidebarMenu from './SidebarMenu';
import headerStyle from "@styles/HeaderStyle";
import {getHeaderProfileImage, getHeaderTitle} from "@helpers/NavigationDataHelper";
import {LogoBFreeSmallImage} from "@components/Images/LogoBFreeSmallImage";
import {AvenirHeavyTitleText} from "@components/StyledText";
import globalScreenStyle from "@styles/GlobalStyle";
import YourHousesScreen from "@screens/host/YourHousesScreen";
import ProfileScreen from "@screens/host/user/ProfileScreen";
import {imageUri} from "../../models/ImageUri";
import {NavigationProps} from "@helpers/NavigationHelper";
import IntroReservationScreen from "@screens/host/reservations/IntroReservationScreen";
import IntroReservationNewUserScreen from "@screens/host/reservations/IntroReservationNewUserScreen";
import IntroCollaborationScreen from "@screens/host/collaborations/IntroCollaborationScreen";
import IntroCollaborationNewUserScreen from "@screens/host/collaborations/IntroCollaborationNewUserScreen"
import NewReservationGuestScreen from "@screens/host/reservations/NewReservationGuestScreen";
import NewReservationPeriodScreen from "@screens/host/reservations/NewReservationPeriodScreen";
import NewReservationHousesScreen from "@screens/host/reservations/NewReservationHousesScreen";
import NewReservationAccommodationsScreen from "@screens/host/reservations/NewReservationAccommodationsScreen";
import NewReservationDoneScreen from "@screens/host/reservations/NewReservationDoneScreen";
import ReservationsScreen from "@screens/host/reservations/ReservationsScreen";
import ReservationDetailScreen from "@screens/host/reservations/ReservationDetailScreen";
import CollaborationsScreen from "@screens/host/collaborations/CollaborationsScreen";
import CollaborationDetailScreen from "@screens/host/collaborations/CollaborationDetailScreen";

import ReservationEditAccommodationsScreen from "@screens/host/reservations/ReservationEditAccommodationsScreen";
import SupportReportScreen from "@screens/host/support/SupportReportScreen";
import GetCreditsScreen from "@screens/host/credits/GetCreditsScreen";
import DoorActionsScreen from "@screens/host/doors/DoorActionsScreen";
import DoorPlanScreen from "@screens/host/doors/DoorPlanScreen";
import BarcodeScannerScreen from "@screens/BarcodeScannerScreen";
import CardBarcodeScannerScreen from "@screens/CardBarcodeScannerScreen";
import DashboardEventsScreen from "@screens/host/DashboardEventsScreen";
import PhoneVerificationScreen from "@screens/host/user/PhoneVerificationScreen";
import NewCollaborationCollaboratorScreen from "@screens/host/collaborations/NewCollaborationCollaboratorScreen";
import NewCollaborationPeriodScreen from "@screens/host/collaborations/NewCollaborationPeriodScreen";
import NewCollaborationHousesScreen from "@screens/host/collaborations/NewCollaborationHousesScreen";
import NewCollaborationAccommodationsScreen from "@screens/host/collaborations/NewCollaborationAccommodationsScreen";
import NewCollaborationDoneScreen from "@screens/host/collaborations/NewCollaborationDoneScreen";
import NewCollaborationPermissionsScreen from "@screens/host/collaborations/NewCollaborationPermissionsScreen";
import CardsScreen from "@screens/host/cards/CardsScreen";
import NewCardScreen from "@screens/host/cards/NewCardScreen";
import ProfilePassepartoutScreen from "@screens/host/user/ProfilePassepartoutScreen";
import CollaborationCardBarcodeScannerScreen from "@screens/CollaborationCardBarcodeScannerScreen";

const config: StackNavigatorConfig = Platform.select({
    default: {
        headerMode: 'float',
        headerLayoutPreset: 'center',
        transitionConfig : () => ({
            transitionSpec: {
                duration: 0,
                timing: Animated.timing,
                easing: Easing.step0,
            },
        }),
    },
    web: {
        headerMode: 'float',
        headerLayoutPreset: 'center',
        transitionConfig : () => ({
            transitionSpec: {
                duration: 0,
                timing: Animated.timing,
                easing: Easing.step0,
            },
        }),
    },

});

//Navigation Drawer Structure for all screen
class NavigationDrawerStructure extends Component<NavigationProps, any> {
    //Top Navigation Header with Donute Button
    toggleDrawer = () => {
        //Props to open/close the drawer
        this.props.navigation.toggleDrawer();
    };

    render() {
        return (
            <View style={[globalScreenStyle.globalMargins, {flexDirection: 'row'}]}>
                <TouchableOpacity onPress={this.toggleDrawer.bind(this)}>
                    {/*Donute Button Image */}
                    <Image
                        source={imageUri.menu.uri}
                        style={{width: 25, height: 20}}
                    />
                </TouchableOpacity>
            </View>
        );
    }
}

//Navigation Drawer Structure for all screen
class NavigationUserProfileStructure extends Component<NavigationProps, any> {
    //Top Navigation Header with Donute Button
    goToProfile = () => {
        //Props to open/close the drawer
    };

    render() {
        let uri = getHeaderProfileImage(this.props);
        let visible = uri != null && uri.trim() !== '';
        if (visible)
            return (
                <View style={[globalScreenStyle.globalMargins, {flexDirection: 'row'}]}>
                    <TouchableOpacity onPress={this.goToProfile.bind(this)}>
                        <Image
                            source={{uri: uri}}
                            style={{width: 30, height: 30, borderRadius: 15,}}
                        />
                    </TouchableOpacity>
                </View>
            );
        else
            return null;
    }
}

//Navigation Drawer Structure for all screen
class NavigationHeaderTitle extends Component<NavigationProps, any> {
    render() {
        let title = getHeaderTitle(this.props);
        let visible = title != null && title.trim() !== '';
        if (visible)
            return (
                <View style={headerStyle.headerTitleContainer}>
                    <LogoBFreeSmallImage style={headerStyle.headerTitleImage}/>
                    <AvenirHeavyTitleText>{title}</AvenirHeavyTitleText>
                </View>
            );
        else
            return null;
    }
}

//Navigation Drawer Structure for all screen
class NavigationHeaderLeftBack extends Component<NavigationProps, any> {
    /**
     * Go to previous page
     */
    goBack = () => {
        //Props to open/close the drawer
        this.props.navigation.pop();
    };

    render() {
        return (
            <View style={globalScreenStyle.globalMargins}>
                <TouchableOpacity onPress={this.goBack.bind(this)}>
                    <LeftArrowImage style={headerStyle.headerLeftBackImage}/>
                </TouchableOpacity>
            </View>
        );
    }
}

/**
 * Gets options for Drawer Navigation header
 * @param navigation
 * @returns object
 */
function headerDrawerNavigationOptions(navigation) {
    return {
        headerLeft: <NavigationDrawerStructure navigation={navigation}/>,
        headerRight: <NavigationUserProfileStructure navigation={navigation}/>,
        headerTitle: <NavigationHeaderTitle navigation={navigation}/>,
        headerStyle: headerStyle.header,
    }
}

/**
 * Gets options for Navigation header
 * @param navigation
 * @returns {{headerLeft: *, headerTitle: *, headerStyle: *}}
 */
function headerNavigationOptions(navigation) {
    return {
        headerLeft: <NavigationHeaderLeftBack navigation={navigation}/>,
        headerTitle: <NavigationHeaderTitle navigation={navigation}/>,
        headerStyle: headerStyle.header,
    }
}

const HomeStack = createStackNavigator(
    {
        Home: {
            screen: DashboardEventsScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        DashboardHouses: {
            screen: DashboardHousesScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        YourHouses: {
            screen: YourHousesScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        DashboardCredits: {
            screen: DashboardCreditsScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        DashboardEvents: {
            screen: DashboardEventsScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        DashboardReservations: {
            screen: DashboardReservationsScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        NewHouse: {
            screen: NewHouseScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewHouseDone: {
            screen: NewHouseDoneScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        IntroReservation: {
            screen: IntroReservationScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        IntroReservationNewUser: {
            screen: IntroReservationNewUserScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        IntroCollaboration: {
            screen: IntroCollaborationScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        IntroCollaborationNewUser: {
            screen: IntroCollaborationNewUserScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewReservationGuest: {
            screen: NewReservationGuestScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewCollaborationCollaborator: {
            screen: NewCollaborationCollaboratorScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewReservationPeriod: {
            screen: NewReservationPeriodScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewCollaborationPeriod: {
            screen: NewCollaborationPeriodScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewReservationHouses: {
            screen: NewReservationHousesScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewCollaborationHouses: {
            screen: NewCollaborationHousesScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewReservationAccommodations: {
            screen: NewReservationAccommodationsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewCollaborationAccommodations: {
            screen: NewCollaborationAccommodationsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewCollaborationPermissions: {
            screen: NewCollaborationPermissionsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        CollaborationCardBarcodeScanner: {
            screen: CollaborationCardBarcodeScannerScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        CollaborationsNewCard: {
            screen: NewCardScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewReservationDone: {
            screen: NewReservationDoneScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewCollaborationDone: {
            screen: NewCollaborationDoneScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        ReservationDetail: {
            screen: ReservationDetailScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        ReservationEditAccommodations: {
            screen: ReservationEditAccommodationsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        GetCredits: {
            screen: GetCreditsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    config
);

const HousesStack = createStackNavigator(
    {
        Home: {
            screen: YourHousesScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        NewHouse: {
            screen: NewHouseScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewHouseDone: {
            screen: NewHouseDoneScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        HouseDetail: {
            screen: HouseDetailScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        Doors: {
            screen: DoorsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewDoor: {
            screen: NewDoorScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        DoorDetail: {
            screen: DoorDetailScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        DoorBarcodeScanner: {
            screen: BarcodeScannerScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        DoorActions: {
            screen: DoorActionsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        DoorPlan: {
            screen: DoorPlanScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        Accommodations: {
            screen: AccommodationsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewAccommodation: {
            screen: NewAccommodationScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        AccommodationDetail: {
            screen: AccommodationDetailScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        HouseDelete: {
            screen: HouseDeleteRecap,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        ResourceDelete: {
            screen: DoorDeleteRecap,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        AccommodationDelete: {
            screen: AccommodationDeleteRecap,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    config
);

const ReservationsStack = createStackNavigator(
    {
        Home: {
            screen: ReservationsScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        ReservationDetail: {
            screen: ReservationDetailScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        }
    },
    config
);

const CollaborationsStack = createStackNavigator(
    {
        Home: {
            screen: CollaborationsScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        CollaborationDetail: {
            screen: CollaborationDetailScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        }
    },
    config
);
const DoorsStack = createStackNavigator(
    {
        Home: {
            screen: DoorsScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        NewDoor: {
            screen: NewDoorScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        DoorDetail: {
            screen: DoorDetailScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        DoorBarcodeScanner: {
            screen: BarcodeScannerScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        DoorActions: {
            screen: DoorActionsScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        DoorPlan: {
            screen: DoorPlanScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    config
);

const CardsStack = createStackNavigator(
    {
        Home: {
            screen: CardsScreen,
            navigationOptions: ({navigation})=>headerDrawerNavigationOptions(navigation),
        },
        NewCard: {
            screen: NewCardScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        CardBarcodeScanner: {
            screen: CardBarcodeScannerScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    config,
);


const AccountStack = createStackNavigator(
    {
        Home: {
            screen: ProfileScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
        PhoneVerificationFromProfile: {
            screen: PhoneVerificationScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        ProfilePassepartout: {
            screen: ProfilePassepartoutScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        CardBarcodeScanner: {
            screen: CardBarcodeScannerScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        NewCard: {
            screen: NewCardScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        ProfilePassepartout2: {
            screen: ProfilePassepartoutScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    config
);

const GetCreditsStack = createStackNavigator(
    {
        Home: {
            screen: GetCreditsScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
    },
    config
);

const SupportStack = createStackNavigator(
    {
        Home: {
            screen: SupportReportScreen,
            navigationOptions: ({navigation}) => headerDrawerNavigationOptions(navigation),
        },
    },
    config
);

HomeStack.navigationOptions = {
    tabBarLabel: 'Home',
    tabBarIcon: ({focused}) => (
        <TabBarIcon
            focused={focused}
            name={
                Platform.OS === 'ios'
                    ? `ios-information-circle${focused ? '' : '-outline'}`
                    : 'md-information-circle'
            }
        />
    ),
};

//HomeStack.path = '';

const LinksStack = createStackNavigator(
    {
        Links: {
            screen: LinksScreen,
            navigationOptions: ({navigation}) => ({
                headerLeft: <NavigationDrawerStructure navigation={navigation}/>,
            }),
        },
    },
    config
);

LinksStack.navigationOptions = {
    tabBarLabel: 'Links',
    tabBarIcon: ({focused}) => (
        <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-link' : 'md-link'}/>
    ),
};

// LinksStack.path = '';

const SettingsStack = createStackNavigator(
    {
        Settings: {
            screen: SettingsScreen,
            navigationOptions: ({navigation}) => ({
                headerLeft: <NavigationDrawerStructure navigation={navigation}/>,
            }),
        },
    },
    config
);

SettingsStack.navigationOptions = {
    tabBarLabel: 'Settings',
    tabBarIcon: ({focused}) => (
        <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-options' : 'md-options'}/>
    ),
};

// SettingsStack.path = '';



//Drawer Navigator Which will provide the structure of our App
const drawerNavigatorExample = createDrawerNavigator(
    {
        //Drawer Optons and indexing
        DashboardScreen: {
            screen: HomeStack,
            navigationOptions: {
                drawerLabel: 'Dashboard',
            },
        },
        HousesScreen: {
            screen: HousesStack,
            navigationOptions: {
                drawerLabel: 'Houses',
            },
        },
        ReservationsScreen: {
            screen: ReservationsStack,
            navigationOptions: {
                drawerLabel: 'Reservations',
            },
        },
        CollaborationsScreen: {
            screen: CollaborationsStack,
            navigationOptions: {
                drawerLabel: 'Collaborations',
            },
        },
        CardsScreen: {
            screen: CardsStack,
            navigationOptions: {
                drawerLabel: 'Cards',
            },
        },
        DoorsScreen: {
            screen: DoorsStack,
            navigationOptions: {
                drawerLabel: 'Doors',
            },
        },
        ProfileScreen: {
            screen: AccountStack,
            navigationOptions: {
                drawerLabel: 'Account',
            },
        },
        GetCreditsScreen: {
            screen: GetCreditsStack,
            navigationOptions: {
                drawerLabel: 'Credits',
            },
        },
        SupportReportScreen: {
            screen: SupportStack,
            navigationOptions: {
                drawerLabel: 'Support',
            },
        },
        NavScreen2: {
            screen: LinksStack,
            navigationOptions: {
                drawerLabel: 'Links',
            },
        },
        NavScreen3: {
            screen: SettingsStack,
            navigationOptions: {
                drawerLabel: 'Settings',
            },
        },
    },
    {
        //For the Custom sidebar menu we have to provide our CustomSidebarMenu
        contentComponent: SidebarMenu,
        //Sidebar width
        drawerWidth: Dimensions.get('window').width - 130,
        ...Platform.select({
            web:{
                drawerWidth: Dimensions.get('window').width*40 / 100,
            }
        }),

    }
);

export default drawerNavigatorExample;
