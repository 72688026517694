/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       StatusDotView.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import statusDotStyles from "@styles/StatusDotViewStyle";
import {i18n} from "@i18n/i18n";
import {AvenirLightGreyText} from "./StyledText";
import {ResourceStateEnum} from "@services/src/models";

interface StatusDotProps {
    enabled: boolean,
    style: StyleProp<ViewStyle>,
}

interface ActivationProps {
    status: ResourceStateEnum,
    style: StyleProp<ViewStyle>,
}

/**
 * A custom view with a dot for online/offline status and text
 * @param {StatusDotProps} props
 * @returns {any}
 * @constructor
 */
export function StatusConnectionDotView(props: StatusDotProps) {
    if (props.enabled)
        return (
            <View style={[statusDotStyles.container, props.style]}>
                <View style={[statusDotStyles.dot, statusDotStyles.online]}/>
                <AvenirLightGreyText numberOfLines={1}
                                     style={statusDotStyles.statusText}>{i18n.t('common.online')}</AvenirLightGreyText>
            </View>
        );
    else
        return (
            <View style={[statusDotStyles.container, props.style]}>
                <View style={[statusDotStyles.dot, statusDotStyles.offline]}/>
                <AvenirLightGreyText numberOfLines={1}
                                     style={statusDotStyles.statusText}>{i18n.t('common.offline')}</AvenirLightGreyText>
            </View>
        );
}

/**
 * A custom view with a dot for active/inactive status and text
 * @param {ActivationProps} props
 * @returns {any}
 * @constructor
 */
export function StatusActivationDotView(props: ActivationProps) {
    switch (props.status) {
        case ResourceStateEnum.Active:
            return (
                <View style={[statusDotStyles.container, props.style]}>
                    <View style={[statusDotStyles.dot, statusDotStyles.active]}/>
                    <AvenirLightGreyText numberOfLines={1}
                                         style={statusDotStyles.statusText}>{i18n.t('common.active')}</AvenirLightGreyText>
                </View>
            );
        case ResourceStateEnum.Expiring:
            return (
                <View style={[statusDotStyles.container, props.style]}>
                    <View style={[statusDotStyles.dot, statusDotStyles.expiring]}/>
                    <AvenirLightGreyText numberOfLines={1}
                                         style={statusDotStyles.statusText}>{i18n.t('common.expiring')}</AvenirLightGreyText>
                </View>
            );
        case ResourceStateEnum.Expired:
            return (
                <View style={[statusDotStyles.container, props.style]}>
                    <View style={[statusDotStyles.dot, statusDotStyles.expired]}/>
                    <AvenirLightGreyText numberOfLines={1}
                                         style={statusDotStyles.statusText}>{i18n.t('common.expired')}</AvenirLightGreyText>
                </View>
            );
        default:
            return (
                <View style={[statusDotStyles.container, props.style]}>
                </View>
            );
    }
}