/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewReservationHousesScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import newReservationHousesScreenStyle from "@styles/host/reservations/NewReservationHousesScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {getReservationBeanFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {HouseFactory} from "../../../models/factory/HouseFactory";
import {DebugButton} from "@components/DebugButton";
import {ApiHostHousesByFiltersPostRequest, HostHousesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {NoDataLabel} from "@components/NoDataLabel";
import {HouseSelectionRow} from "@components/rows/HouseSelectionRow";
import {ReservationBean} from "../../../models/beans/ReservationBean";
import {i18n} from "../../../i18n/i18n";
import {HouseSelection, HouseSelectionBean} from "../../../models/beans/HouseSelection";
import accommodationsScreenStyle from "@styles/host/accommodations/AccommodationScreenStyle";
import {AvenirHeavyPrimaryText} from "@components/StyledText";
import {DoubleConfirmButtonBottom} from "@components/DoubleConfirmButton";
import {AlertHelper} from "@helpers/AlertHelper";
import {
    NavigationProps,
    openNewReservationAccommodationsRoute,
    openNewReservationDoneRoute
} from "@helpers/NavigationHelper";
import {HouseForAccommodationsSelection} from "../../../models/interfaces/HouseForAccommodationsSelectionBean";
import {AccommodationSelection} from "../../../models/beans/AccommodationSelection";

interface State extends LoaderState {
    reservationBean: ReservationBean,
    housesSelectionBeans: Array<HouseSelectionBean>,
}

/**
 * Enum for type of selection.
 * It can be ALL accommodations for selected houses
 * or a SINGLE choice
 */
enum ReservationHouseSelectionEnum {
    SINGLE,
    ALL,
}

export default class NewReservationHousesScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.new_reservation_houses.title');

        this.state = {
            loading: false,
            reservationBean: getReservationBeanFromNavigationProps(this) || new ReservationBean(),
            housesSelectionBeans: [],
        };
    }

    componentDidMount() {
        let self = this;

        new HostHousesApi(this).apiHostHousesGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} houses for host.`);
            self.setState({housesSelectionBeans: HouseSelection.fromHouseList(resp.data), showNoData: true,});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get houses list");
            self.setState({showNoData: true,});
        });
    }

    updateSelectedHouse(key) {
        let houses = this.state.housesSelectionBeans;
        let houseSelectionBean = houses[key];
        if (houseSelectionBean.house.accommodationsCount <= 0) {
            AlertHelper.showSimpleAlert(i18n.t('alerts.attention'), i18n.t('errors.reservation_houses_has_no_accommodations'));
            return;
        }
        houseSelectionBean.selected = !houseSelectionBean.selected;
        this.setState({housesSelectionBeans: houses});
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={[newReservationHousesScreenStyle.container, globalScreenStyle.containerContainer]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[newReservationHousesScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <View style={newReservationHousesScreenStyle.scrollContainerWithBottomButton}>
                        <ScrollView
                            style={newReservationHousesScreenStyle.container}
                            contentContainerStyle={newReservationHousesScreenStyle.contentContainer}>
                            <View style={accommodationsScreenStyle.welcomeContainer}>
                                <AvenirHeavyPrimaryText>
                                    {i18n.t('screens.new_reservation_houses.subtitle')}
                                </AvenirHeavyPrimaryText>
                            </View>
                            {this.state.housesSelectionBeans.map((houseBean, key) => (
                                <View
                                    key={key}
                                    style={[newReservationHousesScreenStyle.houseContainer]}>
                                    <HouseSelectionRow accommodations={houseBean.house.accommodationsCount || 0}
                                                       free={houseBean.house.accommodationsCount || 0}
                                                       house_id={houseBean.house.id}
                                                       house_name={houseBean.house.name}
                                                       public_count={houseBean.house.publicResourcesCount}
                                                       residential_count={houseBean.house.residentialResourcesCount}
                                                       house={houseBean.house}
                                                       onPress={() => this.updateSelectedHouse(key)}
                                                       selected={houseBean.selected}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.housesSelectionBeans} show={this.state.showNoData}/>
                    </View>
                </View>
                <DoubleConfirmButtonBottom active={dataComplete(this)}
                                           onPressFirst={() => openNextPage(this, ReservationHouseSelectionEnum.SINGLE)}
                                           onPressSecond={() => openNextPage(this, ReservationHouseSelectionEnum.ALL)}
                                           firstTitle={i18n.t('buttons.new_reservation_houses_first')}
                                           secondTitle={i18n.t('buttons.new_reservation_houses_second')}/>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({housesSelectionBeans: HouseSelection.fromHouseList(HouseFactory.factoryArray(3))});
}

/**
 * Checks if at least one house has been selected
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    let selectedHouses = context.state.housesSelectionBeans.filter(bean => bean.selected);
    return selectedHouses != null && selectedHouses.length > 0;
}

/**
 * Open the next page for reservation creation
 * @param {React.Component<any, State>} context
 * @param {ReservationHouseSelectionEnum} selectionType
 */
function openNextPage(context: Component<any, State>, selectionType: ReservationHouseSelectionEnum) {
    if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.reservation_data_incomplete'));
        return;
    }
    let reservationBean = context.state.reservationBean;
    reservationBean.houses = HouseForAccommodationsSelection.fromHouseList(context.state.housesSelectionBeans.filter(bean => bean.selected).map(bean => bean.house));
    switch (selectionType) {
        case ReservationHouseSelectionEnum.ALL:
            downloadAccommodationsForAllSelection(context, reservationBean);
            break;
        case ReservationHouseSelectionEnum.SINGLE:
            openNewReservationAccommodationsRoute(context, reservationBean);
            break;
    }
}

/**
 * Download accommodations for selected
 * @param {React.Component<any, State>} context
 * @param {ReservationBean} reservationBean
 */
function downloadAccommodationsForAllSelection(context: Component<any, State>, reservationBean: ReservationBean) {
    let filters: ApiHostHousesByFiltersPostRequest = {
        houseFilterInput: {
            ids: reservationBean.houses.map(house => house.id)
        }
    };

    new HostHousesApi(context).apiHostHousesByFiltersPost(filters).then(resp => {
        let houses = resp.data || [];
        let housesWithSelectionBeans = HouseForAccommodationsSelection.fromHouseList(houses.filter(house => house.accommodationsCount > 0));
        housesWithSelectionBeans.forEach(house => {
            house.accommodationsSelectionBeans = AccommodationSelection.fromAccommodationList(house.accommodations, true);
        });
        reservationBean.houses = housesWithSelectionBeans;
        Logger.log(context.constructor.name, `Found ${houses.length} houses for host.`);
        openNewReservationDoneRoute(context, reservationBean);
    }).catch(error => {
        Logger.warn(context.constructor.name, "Cannot get accommodations data");
        AlertHelper.showSimpleErrorAlert();
    });
}