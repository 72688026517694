/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       MainNavigator.web.js
 * @author     Christian Ascone
 * @date       9/13/19 11:01 AM
 */

import {createBrowserApp} from '@react-navigation/web';

import MainTabNavigator from './MainTabNavigator';

const mainTabNavigator = MainTabNavigator;
mainTabNavigator.path = '';

export default createBrowserApp(mainTabNavigator, {history: 'hash'});
