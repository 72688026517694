/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationDetailScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 11:00 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import reservationDetailScreenStyle from "@styles/host/reservations/ReservationDetailScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {goBack, NavigationProps} from "@helpers/NavigationHelper";
import {ConfirmButton, ConfirmGreenButton} from "@components/ConfirmButton";
import {
    getGenericDismissCallbackFromNavigationProps,
    getReservationFromNavigationProps,
    getUserFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {DebugButton} from "@components/DebugButton";
import {HouseCompleteFactory} from "../../../models/factory/HouseCompleteFactory";
import {HostGuestsApi, HostReservationsApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {
    HostReservationAccommodationJoin,
    HostReservationDetail,
    House,
    ReservationStateEnum,
    User,
    VerificationCodeType
} from "@services/src/models/index";
import {RegistryFactory} from "../../../models/factory/RegistryFactory";
import {
    ReservationAccommodationBlock,
    ReservationPeriodBlock,
    ReservationUserDataBlock
} from "@components/details/ReservationDetailsContainerBlock";
import {ReservationFactory} from "../../../models/factory/ReservationFactory";
import {AccommodationFactory} from "../../../models/factory/AccommodationFactory";
import {UserFactory} from "../../../models/factory/UserFactory";
import {ReservationStateText} from "@components/ReservationStateText";
import {AvenirBookUpperInlineText} from "@components/StyledText";
import {AlertHelper} from "@helpers/AlertHelper";
import {DeleteButton} from "@components/DeleteButton";
import {AccommodationSelectionBean} from "../../../models/beans/AccommodationSelection";

interface State extends LoaderState {
    reservation: HostReservationDetail,
    originalReservation: HostReservationDetail,
    onDismiss: () => void,
    enableResendCodes: boolean,
    user: User,
}

export default class ReservationDetailScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.reservation_detail.title');

        this.state = {
            loading: false,
            reservation: {},
            originalReservation: {},
            onDismiss: getGenericDismissCallbackFromNavigationProps(this),
            enableResendCodes: true,
            user: getUserFromNavigationProps(this),
        };

    }

    componentDidMount() {

           this.onRefresh();
    }

    onRefresh(){
        new HostReservationsApi(this).apiHostReservationsIdGet({id: getReservationFromNavigationProps(this).id + ''}).then(resp => {
            Logger.log(self.constructor.name, 'Ok');

            this.setState({
                reservation: {...resp},
                originalReservation: {...resp},
            });

        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + JSON.stringify(error));
        });
        console.log('Reservation: ' + this.state.reservation);
        console.log('User: ' + this.state.user);
        console.log('Reservation User: ' + this.state.reservation.user);
    }

    /**
     * Checks if guest user is verified
     * @returns {boolean}
     */
    guestIsVerified() {
        let user = this.state.reservation.user;
        if (user == null) return false;
        return user.emailVerifiedAt != null && user.phoneVerifiedAt != null;
    }

    resendEmailCode() {
        let type = VerificationCodeType.Email;
        if (this.state.reservation.user.emailVerifiedAt != null) return;
        let self = this;

        AlertHelper.showYesNoAlert(i18n.t('alerts.attention'), i18n.t('screens.reservation_detail.alert_resend_message'), function (confirmed) {
            if (confirmed) {
                self.sendVerificationCode(type);
            }
        });
    }

    resendPhoneCode() {
        let type = VerificationCodeType.Phone;
        if (this.state.reservation.user.phoneVerifiedAt != null) return;
        let self = this;

        AlertHelper.showYesNoAlert(i18n.t('alerts.attention'), i18n.t('screens.reservation_detail.alert_resend_message'), function (confirmed) {
            if (confirmed) {
                self.sendVerificationCode(type);
            }
        });
    }

    /**
     * Update selected
     * @param {House} house
     * @param {Array<AccommodationSelectionBean>} accommodations
     */
    updateSelectedAccommodations(house: House, accommodations: Array<AccommodationSelectionBean>) {
        // TODO
        console.log(house);
        console.log(accommodations);
    }

    onDateChange(date, type){
        let reservation = this.state.reservation;
        if (type == 'from') {
            let time = reservation.startAt.toTimeString();
            date = date.toDateString();
            let datetime = date+ ' ' + time;

            reservation.startAt = new Date(datetime);
        }
        if (type == 'to'){
            let time = reservation.endAt.toTimeString();
            date = date.toDateString();
            let datetime = date+ ' ' + time;

            reservation.endAt = new Date(datetime);
        }
        console.log(reservation.endAt, reservation.startAt);
        this.setState({reservation: reservation});
    }

    dataDidChange(){

        let reservationChanged = this.state.reservation.startAt === this.state.originalReservation.startAt && this.state.reservation.endAt === this.state.originalReservation.endAt;
        console.log('DataChanged: ',reservationChanged);
        return !(reservationChanged);
    }

    onTimeChange(time, type){
        let reservation = this.state.reservation;
        if (type == 'from') {
            let date = reservation.startAt.toDateString();
            time = time.toTimeString();
            let datetime = date+ ' ' + time;

            reservation.startAt = new Date(datetime);
        }
        if (type == 'to'){
            let date = reservation.endAt.toDateString();
            time = time.toTimeString();
            let datetime = date+ ' ' + time;

            reservation.endAt = new Date(datetime);
        }
        console.log(reservation.endAt, reservation.startAt);
        this.setState({reservation: reservation});
    }

    updateReservation(context){
        let reservation = context.state.reservation;
        reservation.user = {};
        new HostReservationsApi(context).apiHostReservationsUpdatePost({hostReservationDetail: reservation}).then((resp)=>{
            console.log('save pressed');
            context.onRefresh();
        }).catch((error)=>{
            console.log('error in save');
            console.log(error);
        })
    }

    /**
     * Builds the bottom button
     * @returns {any}
     */
    buildBottomButton() {
        if (this.dataDidChange() && this.state.reservation.currentState != ReservationStateEnum.Checkedout && this.state.reservation.currentState != ReservationStateEnum.Canceled ){
            console.log("DATA DID CHANGE")
            return (<ConfirmButton active={this.state.enableResendCodes} onPress={() => this.updateReservation(this)}
                                   title={i18n.t('buttons.signup')}
                                   style={{marginBottom: 20}}/>);
        }


        if (!this.guestIsVerified())
            return (<ConfirmButton active={false} onPress={null}
                                   title={i18n.t('screens.reservation_detail.wait_button')}
                                   style={{marginBottom: 20}}/>);
        else if (this.guestIsVerified() && this.state.reservation.currentState == ReservationStateEnum.Pending)
            return (<ConfirmGreenButton active={true} onPress={() => updateState(this, ReservationStateEnum.Confirmed)}
                                        title={i18n.t('screens.reservation_detail.confirm_button')}
                                        style={{marginBottom: 20}}/>);
        else if (this.guestIsVerified() && this.state.reservation.currentState == ReservationStateEnum.Confirmed)
            return (<ConfirmButton active={true} onPress={() => updateState(this, ReservationStateEnum.Checkedin)}
                                   title={i18n.t('screens.reservation_detail.checkin_button')}
                                   style={{marginBottom: 20}}/>);
        else if (this.state.reservation.currentState == ReservationStateEnum.Checkedin)
            return (<ConfirmButton active={this.state.enableResendCodes} onPress={() => resendCodes(this)}
                                   title={i18n.t('screens.reservation_detail.resend_codes_button')}
                                   style={{marginBottom: 20}}/>);
    }

    render() {
        if (this.state.reservation == null || this.state.user == null)
            return null;
        return (
            <KeyboardAvoidingView behavior="padding" style={reservationDetailScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View
                    style={[reservationDetailScreenStyle.container, globalScreenStyle.globalMargins]}
                >
                    <View style={{
                        flexDirection: 'row',
                        marginTop: 5,
                        alignItems: 'flex-end',
                        alignContent: 'flex-end',
                        justifyContent: 'flex-end'
                    }}>
                        <View style={{flex: 0.1, alignSelf: 'flex-end'}}>
                            
                            <DeleteButton onPress={() => deleteReservation(this)}/>

                        </View>
                    </View>
                    <View
                        style={[reservationDetailScreenStyle.getStartedContainer, reservationDetailScreenStyle.container]}>
                        <View style={reservationDetailScreenStyle.installationContainer}>
                            <ScrollView
                                style={[reservationDetailScreenStyle.container, reservationDetailScreenStyle.scroll]}
                                contentContainerStyle={reservationDetailScreenStyle.contentContainer}
                                refreshControl={<RefreshControl onRefresh={() => this.onRefresh()} refreshing={false}/>}>

                                <View>
                                    {/* TODO: Enable editing only for pending state */}
                                    <ReservationPeriodBlock from_datetime={this.state.reservation.startAt}
                                                            to_datetime={this.state.reservation.endAt}
                                                            style={{marginVertical: 5,}}
                                                            onDateChange={(date, type) => this.onDateChange(date, type)}
                                                            onTimeChange={(date, type)=> this.onTimeChange(date, type)}/>

                                    <ReservationAccommodationBlock
                                        hostReservationAccommodationJoins={this.state.reservation.reservationAccommodationJoins}
                                        style={{marginVertical: 5,}} context={this}
                                        onPressEnabled={false}
                                        reservation={this.state.reservation}
                                        unsent={this.state.reservation.unsent}
                                        resendCodes={ () => resendCodesToDevices(this)}
                                        onChange={(house, accommodations) => this.updateSelectedAccommodations(house, accommodations)}/>
                                    <ReservationUserDataBlock registry={this.state.reservation.registry}
                                                              user={this.state.reservation.user}
                                                              style={{marginVertical: 5,}}
                                                              emailStatePress={() => this.resendEmailCode()}
                                                              phoneStatePress={() => this.resendPhoneCode()}/>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                    <View style={{marginVertical: 5, flexDirection: 'row',}}>
                        <AvenirBookUpperInlineText>
                            {i18n.t('screens.reservation_detail.reservation_state')}
                        </AvenirBookUpperInlineText>
                        <ReservationStateText state={this.state.reservation.currentState}/>
                    </View>
                    {this.buildBottomButton()}
                </View>
            </KeyboardAvoidingView>
        );
    }

    /**
     * Resend verification code to user with given type
     * @param type
     */
    private sendVerificationCode(type) {
        let self = this;
        new HostGuestsApi(this).apiHostGuestsSendVerificationTypePost({
            type: type,
            guest: self.state.reservation.guest,
        }).then(resp => {
            Logger.log(self.constructor.name, 'Ok');

        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + error);
        });
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let reservation = context.state.reservation;
    let fakeReservation = ReservationFactory.factory();
    reservation.registry = RegistryFactory.factory();
    reservation.user = UserFactory.factory(true, true);
    reservation.startAt = fakeReservation.startAt;
    reservation.endAt = fakeReservation.endAt;
    reservation.currentState = fakeReservation.currentState;

    let joins = [];
    let accommodations = AccommodationFactory.factoryArray(10);
    let houses = HouseCompleteFactory.factoryArray(accommodations.length / 2);
    accommodations.forEach((accommodation, index) => {
        let houseIndex = Math.trunc(index / 2);
        let join: HostReservationAccommodationJoin = {
            house: houses[houseIndex],
            accommodation: accommodation,
        };
        joins.push(join);
    });
    reservation.reservationAccommodationJoins = joins;
    context.setState({reservation: reservation});
}

/**
 * Update component state with reservation data
 * @param {React.Component<NavigationProps, State>} context
 * @param {HostReservationDetail} reservationDetail
 */
function updateReservationState(context: Component<NavigationProps, State>, reservationDetail: HostReservationDetail) {
    let reservation = reservationDetail;
    console.log(reservation);
    console.log(reservation.unsent.length > 0 ? '=> Non Pronta: ' + reservation.unsent : '=> Pronta');
    context.setState({
        reservation: reservation,
    });
}

 function resendCodesToDevices(context: Component<NavigationProps, State>){

    new HostReservationsApi(context).apiHostReservationsIdDevicecodesResendPost({id: context.state.reservation.id}).then(resp => {
        context.onRefresh();
    }).catch(error => {
                Logger.warn(context.constructor.name, error);
                AlertHelper.showSimpleErrorAlert();
    });


}

/**h
 * Delete a reservation
 * @param {React.Component<NavigationProps, State>} context
 */
function deleteReservation(context: Component<NavigationProps, State>) {
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed) {
            new HostReservationsApi(context).apiHostReservationsIdDelete({id: context.state.reservation.id}).then(resp => {
                Logger.log(context.constructor.name, "Ok");
                if (context.state.onDismiss != null)
                    context.state.onDismiss();
                goBack(context);
            }).catch(error => {
                Logger.warn(context.constructor.name, error);
                AlertHelper.showSimpleErrorAlert();
            });
        }
    });
}

/**
 * Resend access codes to users
 * @param {React.Component<any, State>} context
 */
function resendCodes(context: Component<any, State>) {
    new HostReservationsApi(context).apiHostReservationsIdCodesResendPost({id: context.state.reservation.id}).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        context.setState({enableResendCodes: false});
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    });
}

/**
 * Update reservation state
 * @param context
 * @param newState
 */
function updateState(context: Component<any, State>, newState: ReservationStateEnum) {
    let reservation = context.state.reservation;

    new HostReservationsApi(context).apiHostReservationsIdStatePut({
        id: reservation.id,
        state: newState
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        reservation.currentState = newState;
        context.setState({reservation: reservation});
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    });
}