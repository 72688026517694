/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ImagesManager.ts
 * @author     Christian Ascone
 * @date       9/30/19 3:46 PM
 */

import * as ImagePicker from "expo-image-picker";

export class ImagesManager {
    /**
     * Launch the image selection and returns selection
     * @returns {Promise<ImagePickerResult>}
     */
    static async chooseImage() {
        return await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.5,
            base64: true,
        });
    }
}