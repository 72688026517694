/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseRow.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

/*
 * Copybottom (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseRow.tsx
 * @author     Christian Ascone
 * @date       9/19/19 7:45 PM
 */

import React, {Component} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {i18n} from "@i18n/i18n";
import {
    AvenirBookGreyLabelText,
    AvenirHeavyAvailableText,
    AvenirHeavyNotAvailableText,
    AvenirLightPrimaryText,
    AvenirMediumPrimaryText
} from "../StyledText";
import {RightArrowColorImage} from "@components/Images/RightArrowColorImage";
import houseRowStyles from "@styles/host/rows/HouseRowStyle";
import {NavigationProps, openAccommodationsList, openDoorsList, openHouseDetail} from "@helpers/NavigationHelper";
import {AvatarPlaceholderImage} from "@components/Images/AvatarPlaceholderImage";
import {GatePublicColorImage} from "@components/Images/GatePublicColorImage";
import {GateResidentialColorImage} from "@components/Images/GateResidentialColorImage";
import {House} from "@services/src/models";

export interface HouseRowProps {
    house_id: number,
    house_name: string,
    accommodations: number,
    free: number,
    residential_count: number,
    public_count: number,
    context: Component<NavigationProps, any>,
    house: House,
}

export function HouseRow(props: HouseRowProps) {
    let prefix = 'rows.house.';
    return (
        <View style={[houseRowStyles.flex, houseRowStyles.container]}>
            <TouchableOpacity onPress={() => openHouseDetail(props.context, props.house)}>
                <View style={[houseRowStyles.bigContainer, houseRowStyles.textContainer, {flexDirection: 'row',}]}>
                    <View style={{flex: 1, marginHorizontal: 10,}}>
                        <AvatarPlaceholderImage style={[houseRowStyles.houseImage]}
                                                imageUri={props.house.photoPath}/>
                    </View>
                    <View style={{marginLeft: 5, flex: 7,}}>
                        <AvenirLightPrimaryText style={{marginLeft: 1,}}>
                            {props.house_name}
                        </AvenirLightPrimaryText>
                        <View style={{
                            flexDirection: 'row',
                            marginLeft: 1,
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                        }}>
                            <AvenirMediumPrimaryText
                                numberOfLines={1}
                                style={[houseRowStyles.flex, houseRowStyles.leftText]}>{i18n.t(prefix + 'free_accommodations', {accommodations: props.accommodations})}</AvenirMediumPrimaryText>
                            {props.free > 0 &&
                            <AvenirHeavyAvailableText style={{flex: 1, marginLeft: 5,}}>
                                {i18n.t(prefix + 'free', {free_number: props.free})}
                            </AvenirHeavyAvailableText>
                            }
                            {props.free == null || props.free == 0 &&
                            <AvenirHeavyNotAvailableText style={{flex: 1, marginLeft: 5,}}>
                                {i18n.t(prefix + 'free', {free_number: props.free})}
                            </AvenirHeavyNotAvailableText>
                            }
                        </View>
                        <View style={{flexDirection: 'row', alignItems: 'baseline',}}>
                            <GateResidentialColorImage style={houseRowStyles.gateResidential}/>
                            <AvenirBookGreyLabelText
                                style={houseRowStyles.gateResidentialText}>{props.residential_count}</AvenirBookGreyLabelText>
                            <GatePublicColorImage style={houseRowStyles.gatePublic}/>
                            <AvenirBookGreyLabelText
                                style={houseRowStyles.gatePublicText}>{props.public_count}</AvenirBookGreyLabelText>
                        </View>
                    </View>
                    <View style={[houseRowStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[houseRowStyles.detailArrow]}/>
                    </View>
                </View>
            </TouchableOpacity>

            <View style={[houseRowStyles.bottomContainer, {
                flexDirection: 'row',
                alignItems: 'flex-end',
                alignContent: 'flex-end'
            }]}>
                <TouchableOpacity style={[houseRowStyles.textContainer, {flex: 5}]}
                                  onPress={() => openAccommodationsList(props.context, props.house)}>
                    <AvenirMediumPrimaryText
                        style={[houseRowStyles.bottomText]}>{i18n.t(prefix + 'accommodations')}</AvenirMediumPrimaryText>
                </TouchableOpacity>
                <TouchableOpacity style={[houseRowStyles.textContainer, {flex: 3}]}
                                  onPress={() => openDoorsList(props.context, props.house)}>
                    <AvenirMediumPrimaryText
                        style={[houseRowStyles.bottomText]}>{i18n.t(prefix + 'doors')}</AvenirMediumPrimaryText>
                </TouchableOpacity>
            </View>
        </View>
    );
}