/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CheckTextButtonStyle.ts
 * @author     Christian Ascone
 * @date       10/30/19 1:13 PM
 */

import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    enabledButton: {
        paddingTop: 10,
        paddingBottom: 10,
        flexDirection: 'row',
    },
    backgroundEnabled: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        backgroundColor: 'white',
    },
    backgroundDisabled: {
        backgroundColor: 'white',
    },
    text: {
        textAlign: 'center',
        padding: 10,
    },
    reservationStateText: {
        textAlign: 'center',
        padding: 10,
    },
    collaborationStateText: {
        textAlign: 'center',
        padding: 10,
    },
    checkImageContainer: {
        flex: 1,
        alignSelf: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
    },
    checkImage: {
        width: 15,
        height: 15,
        marginRight: 5,
        resizeMode: 'contain',
    },
});

export default styles;