/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       PlatformHelper.ts
 * @author     Christian Ascone
 * @date       2/27/20 12:45 PM
 */

import {Platform} from "react-native";

export class PlatformHelper {
    private static WEB = 'web';
    private static IOS = 'ios';
    private static ANDROID = 'android';

    /**
     * Checks if current platform is web
     * @returns {boolean}
     */
    public static isWeb(): boolean {
        return Platform.OS === PlatformHelper.WEB;
    }

    /**
     * Checks if current platform is iOs
     * @returns {boolean}
     */
    public static isIos(): boolean {
        return Platform.OS === PlatformHelper.IOS;
    }

    /**
     * Checks if current platform is Android
     * @returns {boolean}
     */
    public static isAndroid(): boolean {
        return Platform.OS === PlatformHelper.ANDROID;
    }
}