/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewReservationGuestScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import newReservationGuestScreenStyle from "@styles/host/reservations/NewReservationGuestScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {NavigationProps, openNewReservationPeriodRoute} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {getGuestFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import {Guest, GuestType} from "@services/src/models/index";
import {DebugButton} from "@components/DebugButton";
import {GuestFactory} from "../../../models/factory/GuestFactory";
import {HostGuestsApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";

interface State extends LoaderState {
    id: number,
    first_name: string,
    last_name: string,
    mail: string,
    mobile: string,
    selectedType: GuestType,
    originalMobile: string,
    originalFirst_name: string,
    originalLast_name: string,
}

export default class NewReservationGuestScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_reservation_guest.title');
        let guest = getGuestFromNavigationProps(this) || {};
        this.state = {
            id: guest.id,
            loading: false,
            first_name: guest.firstName || '',
            last_name: guest.lastName || '',
            mail: guest.email || '',
            mobile: guest.mobile || '',
            originalMobile: guest.mobile || '',
            originalLast_name: guest.lastName || '',
            originalFirst_name: guest.firstName || '',
            selectedType: guest.type || GuestType.Client,
        };

    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newReservationGuestScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <View>
                    {/* TODO: Wizard */}
                </View>
                <Loader state={this.state}/>
                <ScrollView
                    style={[newReservationGuestScreenStyle.scrollContainer, globalScreenStyle.globalMargins]}
                    contentContainerStyle={newReservationGuestScreenStyle.contentContainer}>
                    <View
                        style={[newReservationGuestScreenStyle.getStartedContainer, newReservationGuestScreenStyle.scrollContainer]}>
                        <View
                            style={newReservationGuestScreenStyle.inputContainer}>

                            {/* HIDDEN Selector*/}
                            {/*<View style={newDoorScreenStyle.serviceSelectionContainer}>*/}
                            {/*<GuestClientSelectionBlock*/}
                            {/*onPress={() => this.setState({selectedType: GuestType.Client})}*/}
                            {/*style={[newDoorScreenStyle.serviceBlock, newDoorScreenStyle.clientBlock]}*/}
                            {/*active={clientSelected(this)}/>*/}
                            {/*<GuestCollaboratorSelectionBlock*/}
                            {/*onPress={() => this.setState({selectedType: GuestType.Collaborator})}*/}
                            {/*style={[newDoorScreenStyle.serviceBlock, newDoorScreenStyle.collaboratorBlock]}*/}
                            {/*active={collaboratorSelected(this)}/>*/}
                            {/*</View>*/}

                            <TextInputBlock label={i18n.t('screens.new_reservation_guest.first_name')}
                                            onChangeText={(text) => this.setState({first_name: text})}
                                            text={this.state.first_name}/>
                            <TextInputBlock label={i18n.t('screens.new_reservation_guest.last_name')}
                                            onChangeText={(text) => this.setState({last_name: text})}
                                            text={this.state.last_name}/>
                            <TextInputBlock label={i18n.t('screens.new_reservation_guest.mail')}
                                            keyboardType='email-address'
                                            onChangeText={(text) => this.setState({mail: text})}
                                            text={this.state.mail} editable={!this.state.id || this.state.id == 0}/>
                            <TextInputBlock label={i18n.t('screens.new_reservation_guest.mobile')}
                                            keyboardType={'phone-pad'}
                                            onChangeText={(text) => this.setState({mobile: text})}
                                            text={this.state.mobile} editable={/*!this.state.id || this.state.id == 0*/true}/>
                        </View>
                    </View>
                </ScrollView>
                <ConfirmButtonBottom active={dataComplete(this)} onPress={() => openNextPage(this)}
                                     title={this.dataDidChange() ? i18n.t('buttons.new_reservation_guest_to_save') : i18n.t('buttons.new_reservation_guest')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    } 

    dataDidChange(){
        let mobileChanged = this.state.mobile != this.state.originalMobile;
        let nameChanged = this.state.first_name != this.state.originalFirst_name ;
        let surnameChanged = this.state.last_name != this.state.originalLast_name;
        console.log('OriginalFN: ', this.state.originalFirst_name);
        console.log('NewFN: ', this.state.originalFirst_name);
        console.log('OriginalLN: ', this.state.originalLast_name);
        console.log('NewLN: ', this.state.originalLast_name);
        return (mobileChanged || nameChanged || surnameChanged);
    }
}

/**
 * Checks if collaborator type is selected
 * @param {React.Component<any, State>} context
 */
function collaboratorSelected(context: Component<any, State>): boolean {
    return context.state.selectedType == GuestType.Collaborator;
}

/**
 * Checks if client type is selected
 * @param {React.Component<any, State>} context
 */
function clientSelected(context: Component<any, State>): boolean {
    return context.state.selectedType == GuestType.Client;
}

/**
 * Checks if data is valid
 * @param {string} data
 * @returns {boolean}
 */
function dataIsValid(data: string) {
    return data != null && data != '';
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return dataIsValid(context.state.first_name) && dataIsValid(context.state.last_name) && dataIsValid(context.state.mail) && dataIsValid(context.state.mobile);
}

function debugUpdateData(context: Component<any, State>) {
    let fakeGuest = GuestFactory.factory();
    context.setState({
        first_name: fakeGuest.firstName,
        last_name: fakeGuest.lastName,
        selectedType: fakeGuest.type,
        mail: fakeGuest.email,
        mobile: fakeGuest.mobile,
    });

}

/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.reservation_data_incomplete'));
        return;
    }
    let guest: Guest = {
        type: context.state.selectedType,
        firstName: context.state.first_name,
        lastName: context.state.last_name,
        email: context.state.mail,
        mobile: context.state.mobile,
    };
    if (context.state.id && context.state.id != 0) {
        updateGuest(context, guest);
    } else {
        createGuest(context, guest);
    }
}

/**
 * Updates the existing Guest
 * @param {React.Component<any, State>} context
 * @param {Guest} guest
 */
function updateGuest(context: React.Component<any, State>, guest: Guest) {
    new HostGuestsApi(context).apiHostGuestsIdPut({
        id: context.state.id,
        guest: guest
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        guest.id = context.state.id;
        openNewReservationPeriodRoute(context, guest);
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    })
}

/**
 * Creates a new Guest
 * @param {React.Component<any, State>} context
 * @param {Guest} guest
 */
function createGuest(context: React.Component<any, State>, guest: Guest) {
    new HostGuestsApi(context).apiHostGuestsPost({
        guest: guest
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        Logger.log(context.constructor.name, resp);
        openNewReservationPeriodRoute(context, resp);
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    })
}