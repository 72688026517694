/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HostReservationHouseInput
 */
export interface HostReservationHouseInput {
    /**
     * 
     * @type {number}
     * @memberof HostReservationHouseInput
     */
    id?: number;
    /**
     * List of accommodation ids
     * @type {Array<number>}
     * @memberof HostReservationHouseInput
     */
    accommodations?: Array<number>;
}

export function HostReservationHouseInputFromJSON(json: any): HostReservationHouseInput {
    return HostReservationHouseInputFromJSONTyped(json, false);
}

export function HostReservationHouseInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostReservationHouseInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'accommodations': !exists(json, 'accommodations') ? undefined : json['accommodations'],
    };
}

export function HostReservationHouseInputToJSON(value?: HostReservationHouseInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'accommodations': value.accommodations,
    };
}

