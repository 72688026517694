/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ResourceFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */


import * as fakerStatic from 'faker';
import {Resource, ResourceStateEnum, ResourceType} from "@services/src/models";

export class ResourceFactory {
    static factoryArray(size: number, type: ResourceType = null): Resource[] {
        let array = [];
        for(let i = 0; i < size; i++){
            array.push(ResourceFactory.factory(type));
        }
        return array;
    }

    static factory(type: ResourceType = null): Resource {
        const typeArray = [ResourceType.Public, ResourceType.Residential];
        if(type == null)
            type = typeArray[fakerStatic.random.number(typeArray.length - 1)];
        return {
            id: fakerStatic.random.number(4),
            type: type,
            name: fakerStatic.lorem.words(3),
            serial: fakerStatic.random.uuid(),
            description: fakerStatic.lorem.paragraph(1).substr(0, 255),
            latitude: +fakerStatic.address.latitude(),
            longitude: +fakerStatic.address.longitude(),
            currentState: ResourceStateEnum.Active,
            topic: '',
        };
    }
}

