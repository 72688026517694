/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BoughtCredit,
    BoughtCreditFromJSON,
    BoughtCreditToJSON,
    HostBoughtCredit,
    HostBoughtCreditFromJSON,
    HostBoughtCreditToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
} from '../models';

export interface ApiHostBoughtCreditsIdDeleteRequest {
    id: number;
}

export interface ApiHostBoughtCreditsIdGetRequest {
    id: string;
}

export interface ApiHostBoughtCreditsIdPutRequest {
    id: number;
    boughtCredit: BoughtCredit;
}

export interface ApiHostBoughtCreditsPostRequest {
    boughtCredit: BoughtCredit;
}

export interface ApiHostBoughtCreditsTrialBuyDeviceIdPostRequest {
    deviceId: string;
}

/**
 * 
 */
export class HostBoughtCreditsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostBoughtCreditsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BoughtCredit>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/bought_credits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoughtCreditFromJSON));
    }

    /**
     */
    async apiHostBoughtCreditsGet(initOverrides?: RequestInit): Promise<Array<BoughtCredit>> {
        const response = await this.apiHostBoughtCreditsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostBoughtCreditsIdDeleteRaw(requestParameters: ApiHostBoughtCreditsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostBoughtCreditsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/bought_credits/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostBoughtCreditsIdDelete(requestParameters: ApiHostBoughtCreditsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostBoughtCreditsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostBoughtCreditsIdGetRaw(requestParameters: ApiHostBoughtCreditsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BoughtCredit>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostBoughtCreditsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/bought_credits/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BoughtCreditFromJSON(jsonValue));
    }

    /**
     */
    async apiHostBoughtCreditsIdGet(requestParameters: ApiHostBoughtCreditsIdGetRequest, initOverrides?: RequestInit): Promise<BoughtCredit> {
        const response = await this.apiHostBoughtCreditsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostBoughtCreditsIdPutRaw(requestParameters: ApiHostBoughtCreditsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostBoughtCreditsIdPut.');
        }

        if (requestParameters.boughtCredit === null || requestParameters.boughtCredit === undefined) {
            throw new runtime.RequiredError('boughtCredit','Required parameter requestParameters.boughtCredit was null or undefined when calling apiHostBoughtCreditsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/bought_credits/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BoughtCreditToJSON(requestParameters.boughtCredit),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostBoughtCreditsIdPut(requestParameters: ApiHostBoughtCreditsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostBoughtCreditsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostBoughtCreditsPostRaw(requestParameters: ApiHostBoughtCreditsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostBoughtCredit>> {
        if (requestParameters.boughtCredit === null || requestParameters.boughtCredit === undefined) {
            throw new runtime.RequiredError('boughtCredit','Required parameter requestParameters.boughtCredit was null or undefined when calling apiHostBoughtCreditsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/bought_credits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BoughtCreditToJSON(requestParameters.boughtCredit),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostBoughtCreditFromJSON(jsonValue));
    }

    /**
     */
    async apiHostBoughtCreditsPost(requestParameters: ApiHostBoughtCreditsPostRequest, initOverrides?: RequestInit): Promise<HostBoughtCredit> {
        const response = await this.apiHostBoughtCreditsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostBoughtCreditsTrialBuyDeviceIdPostRaw(requestParameters: ApiHostBoughtCreditsTrialBuyDeviceIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostBoughtCredit>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling apiHostBoughtCreditsTrialBuyDeviceIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/bought_credits/trial/buy/{device_id}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostBoughtCreditFromJSON(jsonValue));
    }

    /**
     */
    async apiHostBoughtCreditsTrialBuyDeviceIdPost(requestParameters: ApiHostBoughtCreditsTrialBuyDeviceIdPostRequest, initOverrides?: RequestInit): Promise<HostBoughtCredit> {
        const response = await this.apiHostBoughtCreditsTrialBuyDeviceIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostBoughtCreditsTrialEnabledGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/bought_credits/trial/enabled`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     */
    async apiHostBoughtCreditsTrialEnabledGet(initOverrides?: RequestInit): Promise<InlineResponse200> {
        const response = await this.apiHostBoughtCreditsTrialEnabledGetRaw(initOverrides);
        return await response.value();
    }

}
