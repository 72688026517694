/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FilterHelper.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import {CollaborationFilterInput, ReservationFilterInput, ResourceFilterInput} from "@services/src/models";

export class FilterHelper {
    static APPLIED = 1;
    static NOT_APPLIED = 0;

    /**
     * Count the number of applied filters for Resources
     * @param {ResourceFilterInput} filters
     * @returns {Promise}
     */
    public static countDoorsFilters(filters: ResourceFilterInput) {
        let houseFilter = filters.houseIds != null && filters.houseIds.length > 0 ? this.APPLIED : this.NOT_APPLIED;
        let onlineFilter = filters.online != null ? this.APPLIED : this.NOT_APPLIED;
        let stateActiveFilter = filters.resourceStateActive != null && filters.resourceStateActive ? this.APPLIED : this.NOT_APPLIED;
        let stateExpiredFilter = filters.resourceStateExpired != null && filters.resourceStateExpired ? this.APPLIED : this.NOT_APPLIED;
        let stateExpiringFilter = filters.resourceStateExpiring != null && filters.resourceStateExpiring ? this.APPLIED : this.NOT_APPLIED;
        let typeFilter = filters.type != null ? this.APPLIED : this.NOT_APPLIED;

        return houseFilter + onlineFilter + stateActiveFilter + stateExpiredFilter + stateExpiringFilter + typeFilter;
    }

    /**
     * Count the number of applied filters for Reservations
     * @param {ReservationFilterInput} filters
     * @returns {Promise}
     */
    public static countReservationsFilters(filters: ReservationFilterInput) {
        let houseFilter = filters.houseIds != null && filters.houseIds.length > 0 ? this.APPLIED : this.NOT_APPLIED;
        let stateCanceledFilter = filters.reservationStateCanceled != null && filters.reservationStateCanceled ? this.APPLIED : this.NOT_APPLIED;
        let statePendingFilter = filters.reservationStatePending != null && filters.reservationStatePending ? this.APPLIED : this.NOT_APPLIED;
        let stateCheckedinFilter = filters.reservationStateCheckedin != null && filters.reservationStateCheckedin ? this.APPLIED : this.NOT_APPLIED;
        let stateConfirmedFilter = filters.reservationStateConfirmed != null && filters.reservationStateConfirmed ? this.APPLIED : this.NOT_APPLIED;
        let stateCheckedoutFilter = filters.reservationStateCheckedout != null && filters.reservationStateCheckedout ? this.APPLIED : this.NOT_APPLIED;
        let mailFilter = filters.registryEmail != null && filters.registryEmail.trim().length > 0 ? this.APPLIED : this.NOT_APPLIED;
        let typeFilter = filters.registryType != null ? this.APPLIED : this.NOT_APPLIED;

        return houseFilter + stateCanceledFilter + statePendingFilter + stateCheckedinFilter + stateCheckedoutFilter + stateConfirmedFilter + mailFilter + typeFilter;
    }

    /**
     * Count the number of applied filters for Reservations
     * @param {ReservationFilterInput} filters
     * @returns {Promise}
     */
    public static countCollaborationsFilters(filters: CollaborationFilterInput) {
        let houseFilter = filters.houseIds != null && filters.houseIds.length > 0 ? this.APPLIED : this.NOT_APPLIED;
        let mailFilter = filters.registryEmail != null && filters.registryEmail.trim().length > 0 ? this.APPLIED : this.NOT_APPLIED;
        let typeFilter = filters.registryType != null ? this.APPLIED : this.NOT_APPLIED;
        let stateCanceledFilter = filters.collaborationStateCanceled != null && filters.collaborationStateCanceled ? this.APPLIED : this.NOT_APPLIED;
        let statePendingFilter = filters.collaborationStatePending != null && filters.collaborationStatePending ? this.APPLIED : this.NOT_APPLIED;
        let stateOngoingFilter = filters.collaborationStateOngoing != null && filters.collaborationStateOngoing ? this.APPLIED : this.NOT_APPLIED;
        let stateConfirmedFilter = filters.collaborationStateConfirmed != null && filters.collaborationStateConfirmed ? this.APPLIED : this.NOT_APPLIED;
        let stateExpiredFilter = filters.collaborationStateExpired != null && filters.collaborationStateExpired ? this.APPLIED : this.NOT_APPLIED;

        return houseFilter + mailFilter + typeFilter + stateCanceledFilter + stateConfirmedFilter + stateExpiredFilter + stateOngoingFilter + statePendingFilter;
    }
}