/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ImageUri.ts
 * @author     Christian Ascone
 * @date       12/27/19 12:52 PM
 */

/**
 * List of uri for images
 */
export const imageUri = {
    logo_bfree_credit: {
        uri: require('../assets/images/Logo-icon-credit.png')
    },
    logo_bfree_small: {
        uri: require('../assets/images/Logo-icon-credit_no_border.png')
    },
    menu: {
        uri: require('../assets/images/menu.png')
    },
    left_arrow: {
        uri: require('../assets/images/left_arrow.png')
    },
    gear: {
        uri: require('../assets/images/gear.png')
    },
    gear_color: {
        uri: require('../assets/images/gear_color.png')
    },
    qr_code: {
        uri: require('../assets/images/qr_code_scanner_purple.png')
    }
};