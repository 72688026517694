/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventPriority {
    High = 'HIGH',
    Medium = 'MEDIUM',
    Low = 'LOW'
}

export function EventPriorityFromJSON(json: any): EventPriority {
    return EventPriorityFromJSONTyped(json, false);
}

export function EventPriorityFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventPriority {
    return json as EventPriority;
}

export function EventPriorityToJSON(value?: EventPriority | null): any {
    return value as any;
}

