/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       TextInputBlock.tsx
 * @author     Christian Ascone
 * @date       10/31/19 4:27 PM
 */

import React from 'react';

import {KeyboardTypeOptions, StyleProp, TextInput, View, ViewStyle} from 'react-native';
import TextStyle from '@styles/TextStyle';
import {AvenirBookUpperLabelText} from "./StyledText";
import * as Device from 'expo-device';

interface TextInputBlockProps {
    label: string,
    keyboardType?: KeyboardTypeOptions,
    style?: StyleProp<ViewStyle>,
    secureTextEntry?: boolean,
    placeholder?: string,
    onChangeText?: (text: string) => void,
    text?: string,
    editable?: boolean,
    maxLength?: number,
    autoCapitalize?: "none" | "sentences" | "words" | "characters",
}

export function TextInputBlock(props: TextInputBlockProps) {
    return (
        <View>
            <AvenirBookUpperLabelText>
                {props.label}
            </AvenirBookUpperLabelText>
            <TextInput
                keyboardType={props.keyboardType}
                style={[TextStyle.separator, props.editable == false ? TextStyle.upperLabel : null, props.style]}
                secureTextEntry={props.secureTextEntry}
                placeholder={props.placeholder || props.label}
                onChangeText={props.onChangeText}
                maxLength={props.maxLength || null}
                value={props.text}
                caretHidden={Device.brand == 'xiaomi'}
                editable={props.editable == null ? true : props.editable}
                autoCapitalize={props.autoCapitalize || 'none'}
            />
        </View>
    );
}

export function PasswordInputBlock(props) {
    return (
        <TextInputBlock {...props} secureTextEntry={true} autoCapitalize={'none'}/>
    );
}

export function NumberInputBlock(props) {
    return (
        <TextInputBlock {...props} keyboardType={'number-pad'}/>
    );
}