/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       UserFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */


import * as fakerStatic from 'faker';
import {User} from "@services/src/models";

export class UserFactory {
    static factoryArray(size: number, emailVerified = false, phoneVerified = false): User[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(UserFactory.factory(emailVerified, phoneVerified));
        }
        return array;
    }

    static factory(emailVerified = false, phoneVerified = false): User {
        return {
            id: fakerStatic.random.number(4),
            name: fakerStatic.name.firstName(),
            email: fakerStatic.internet.email(),
            phone: fakerStatic.phone.phoneNumber(),
            emailVerifiedAt: emailVerified ? fakerStatic.date.past() : null,
            phoneVerifiedAt: phoneVerified ? fakerStatic.date.past() : null,
        };
    }
}

