/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HostDeviceDetail,
    HostDeviceDetailFromJSON,
    HostDeviceDetailToJSON,
} from '../models';

export interface ApiHostDevicesIdGetRequest {
    id: string;
}

/**
 * 
 */
export class HostDevicesApi extends runtime.BaseAPI {

    /**
     */
    async apiHostDevicesIdGetRaw(requestParameters: ApiHostDevicesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostDeviceDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostDevicesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/devices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostDeviceDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHostDevicesIdGet(requestParameters: ApiHostDevicesIdGetRequest, initOverrides?: RequestInit): Promise<HostDeviceDetail> {
        const response = await this.apiHostDevicesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
