/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Event,
    EventFromJSON,
    EventToJSON,
    EventType,
    EventTypeFromJSON,
    EventTypeToJSON,
} from '../models';

export interface ApiEventsGetRequest {
    count: number;
    type?: EventType;
}

export interface ApiEventsIdReadPostRequest {
    id: string;
    read: boolean;
}

/**
 * 
 */
export class EventsApi extends runtime.BaseAPI {

    /**
     */
    async apiEventsGetRaw(requestParameters: ApiEventsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Event>>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling apiEventsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventFromJSON));
    }

    /**
     */
    async apiEventsGet(requestParameters: ApiEventsGetRequest, initOverrides?: RequestInit): Promise<Array<Event>> {
        const response = await this.apiEventsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiEventsIdReadPostRaw(requestParameters: ApiEventsIdReadPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiEventsIdReadPost.');
        }

        if (requestParameters.read === null || requestParameters.read === undefined) {
            throw new runtime.RequiredError('read','Required parameter requestParameters.read was null or undefined when calling apiEventsIdReadPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.read !== undefined) {
            queryParameters['read'] = requestParameters.read;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/events/{id}/read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiEventsIdReadPost(requestParameters: ApiEventsIdReadPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiEventsIdReadPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiEventsUnreadCountGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/events/unread/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiEventsUnreadCountGet(initOverrides?: RequestInit): Promise<void> {
        await this.apiEventsUnreadCountGetRaw(initOverrides);
    }

}
