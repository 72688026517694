/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HostReservationDetail,
    HostReservationDetailFromJSON,
    HostReservationDetailToJSON,
    HostReservationInput,
    HostReservationInputFromJSON,
    HostReservationInputToJSON,
    HostReservationList,
    HostReservationListFromJSON,
    HostReservationListToJSON,
    Reservation,
    ReservationFromJSON,
    ReservationToJSON,
    ReservationFilterInput,
    ReservationFilterInputFromJSON,
    ReservationFilterInputToJSON,
    ReservationStateEnum,
    ReservationStateEnumFromJSON,
    ReservationStateEnumToJSON,
} from '../models';

export interface ApiHostReservationsByFiltersPostRequest {
    reservationFilterInput: ReservationFilterInput;
}

export interface ApiHostReservationsIdCodesResendPostRequest {
    id: number;
}

export interface ApiHostReservationsIdDeleteRequest {
    id: number;
}

export interface ApiHostReservationsIdDevicecodesResendPostRequest {
    id: number;
}

export interface ApiHostReservationsIdGetRequest {
    id: string;
}

export interface ApiHostReservationsIdStatePutRequest {
    id: number;
    state: ReservationStateEnum;
}

export interface ApiHostReservationsPostRequest {
    hostReservationInput: HostReservationInput;
}

export interface ApiHostReservationsUpdatePostRequest {
    hostReservationDetail: HostReservationDetail;
}

/**
 * 
 */
export class HostReservationsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostReservationsByFiltersPostRaw(requestParameters: ApiHostReservationsByFiltersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostReservationList>> {
        if (requestParameters.reservationFilterInput === null || requestParameters.reservationFilterInput === undefined) {
            throw new runtime.RequiredError('reservationFilterInput','Required parameter requestParameters.reservationFilterInput was null or undefined when calling apiHostReservationsByFiltersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations/byFilters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReservationFilterInputToJSON(requestParameters.reservationFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostReservationListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostReservationsByFiltersPost(requestParameters: ApiHostReservationsByFiltersPostRequest, initOverrides?: RequestInit): Promise<HostReservationList> {
        const response = await this.apiHostReservationsByFiltersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostReservationsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostReservationList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostReservationListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostReservationsGet(initOverrides?: RequestInit): Promise<HostReservationList> {
        const response = await this.apiHostReservationsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostReservationsIdCodesResendPostRaw(requestParameters: ApiHostReservationsIdCodesResendPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Reservation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostReservationsIdCodesResendPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations/{id}/codes/resend`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationFromJSON(jsonValue));
    }

    /**
     */
    async apiHostReservationsIdCodesResendPost(requestParameters: ApiHostReservationsIdCodesResendPostRequest, initOverrides?: RequestInit): Promise<Reservation> {
        const response = await this.apiHostReservationsIdCodesResendPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostReservationsIdDeleteRaw(requestParameters: ApiHostReservationsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostReservationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostReservationsIdDelete(requestParameters: ApiHostReservationsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostReservationsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostReservationsIdDevicecodesResendPostRaw(requestParameters: ApiHostReservationsIdDevicecodesResendPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostReservationsIdDevicecodesResendPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations/{id}/devicecodes/resend`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiHostReservationsIdDevicecodesResendPost(requestParameters: ApiHostReservationsIdDevicecodesResendPostRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiHostReservationsIdDevicecodesResendPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostReservationsIdGetRaw(requestParameters: ApiHostReservationsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostReservationDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostReservationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostReservationDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHostReservationsIdGet(requestParameters: ApiHostReservationsIdGetRequest, initOverrides?: RequestInit): Promise<HostReservationDetail> {
        const response = await this.apiHostReservationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostReservationsIdStatePutRaw(requestParameters: ApiHostReservationsIdStatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Reservation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostReservationsIdStatePut.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling apiHostReservationsIdStatePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations/{id}/{state}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"state"}}`, encodeURIComponent(String(requestParameters.state))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReservationFromJSON(jsonValue));
    }

    /**
     */
    async apiHostReservationsIdStatePut(requestParameters: ApiHostReservationsIdStatePutRequest, initOverrides?: RequestInit): Promise<Reservation> {
        const response = await this.apiHostReservationsIdStatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostReservationsPostRaw(requestParameters: ApiHostReservationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hostReservationInput === null || requestParameters.hostReservationInput === undefined) {
            throw new runtime.RequiredError('hostReservationInput','Required parameter requestParameters.hostReservationInput was null or undefined when calling apiHostReservationsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostReservationInputToJSON(requestParameters.hostReservationInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostReservationsPost(requestParameters: ApiHostReservationsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostReservationsPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostReservationsUpdatePostRaw(requestParameters: ApiHostReservationsUpdatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hostReservationDetail === null || requestParameters.hostReservationDetail === undefined) {
            throw new runtime.RequiredError('hostReservationDetail','Required parameter requestParameters.hostReservationDetail was null or undefined when calling apiHostReservationsUpdatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/reservations/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostReservationDetailToJSON(requestParameters.hostReservationDetail),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostReservationsUpdatePost(requestParameters: ApiHostReservationsUpdatePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostReservationsUpdatePostRaw(requestParameters, initOverrides);
    }

}
