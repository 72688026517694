/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CreditFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 3:16 PM
 */


import * as fakerStatic from 'faker';
import {Credit, CreditType} from "@services/src/models";

export class CreditFactory {
    static factoryArray(size: number): Credit[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(CreditFactory.factory());
        }
        return array;
    }

    static factory(): Credit {
        return {
            id: fakerStatic.random.number(4),
            type: CreditType.Credit,
            name: fakerStatic.random.uuid(),
            description: fakerStatic.random.uuid(),
            amount: fakerStatic.random.number({min: 1, max: 10}),
            price: +fakerStatic.commerce.price(500, 50000),
        };
    }
}

