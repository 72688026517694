/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationDetailScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 11:00 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import accommodationDetailScreenStyle from "@styles/host/accommodations/AccommodationDetailScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {goBack, NavigationProps, openAccommodationDelete, openResourceDelete} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {
    getAccommodationFromNavigationProps,
    getHouseFromNavigationProps,
    setHeaderTitle
} from "@helpers/NavigationDataHelper";
import {HostAccommodationsApi, HostResourcesApi} from "@services/src/apis/index";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {HostAccommodation, House, ResourceType} from "@services/src/models/index";
import {HouseFactory} from "../../../models/factory/HouseFactory";
import {AddDoorBlock} from "@components/AddDoorBlock";
import {ResourceFactory} from "../../../models/factory/ResourceFactory";
import {ResourceSelection, ResourceSelectionBean} from "../../../models/beans/ResourceSelection";
import {ArrayHelper} from "@helpers/ArrayHelper";
import {DebugButton} from "@components/DebugButton";
import {AccommodationFactory} from "../../../models/factory/AccommodationFactory";
import {DeleteButton} from "@components/DeleteButton";
import {HostAccommodationFactory} from "../../../models/factory/HostAccommodationFactory";

interface State extends LoaderState {
    house: House,
    accommodation: HostAccommodation,
    accommodation_name: string,
    accommodation_description: string,
    residentialDoorSelectionBeans: Array<ResourceSelectionBean>,
    publicDoorSelectionBeans: Array<ResourceSelectionBean>,
}

export default class AccommodationDetailScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        let accommodation = getAccommodationFromNavigationProps(this) || {};
        this.state = {
            house: getHouseFromNavigationProps(this) || {},
            accommodation: accommodation,
            loading: false,
            accommodation_name: accommodation.name || '',
            accommodation_description: accommodation.description || '',
            residentialDoorSelectionBeans: [],
            publicDoorSelectionBeans: [],
        };

        if (this.state.house != null)
            setHeaderTitle(this.props, this.state.house.name);

        // TODO: Get doors list
    }

    componentDidMount() {
        this.downloadDoors();
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={accommodationDetailScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View
                    style={[accommodationDetailScreenStyle.scrollContainer, globalScreenStyle.globalMargins, accommodationDetailScreenStyle.scrollOuter]}>
                    <View style={{marginTop: 10, flexDirection: 'row',}}>
                        <View style={{flex: 5,}}>
                            <TextInputBlock label={i18n.t('screens.accommodation_detail.accommodation_name')}
                                            onChangeText={(text) => this.setState({accommodation_name: text})}
                                            text={this.state.accommodation_name}/>
                        </View>
                        <View style={accommodationDetailScreenStyle.deleteButtonContainer}>
                            <DeleteButton onPress={() => deleteAccommodation(this)} style={{}}/>
                        </View>
                    </View>
                    <View
                        style={[accommodationDetailScreenStyle.getStartedContainer, accommodationDetailScreenStyle.scrollContainer]}>

                        <AddDoorBlock style={accommodationDetailScreenStyle.doorSelection}
                                      doorsSelection={this.state.residentialDoorSelectionBeans}
                                      type={ResourceType.Residential}
                                      onPress={(key) => updateSelection(this, key, ResourceType.Residential)}
                                      showNoData={this.state.showNoData}/>

                        <AddDoorBlock style={[accommodationDetailScreenStyle.doorSelection, {marginVertical: 10,}]}
                                      doorsSelection={this.state.publicDoorSelectionBeans}
                                      type={ResourceType.Public}
                                      onPress={(key) => updateSelection(this, key, ResourceType.Public)}
                                      showNoData={this.state.showNoData}/>

                    </View>
                    <ConfirmButtonBottom active={dataChanged(this)} onPress={() => openNextPage(this)}
                                         title={i18n.t('buttons.new_accommodation')}
                                         style={{marginHorizontal: 0, marginBottom: 0,}}/>
                </View>
            </KeyboardAvoidingView>
        );
    }

    private downloadDoors() {
        let self = this;
        new HostResourcesApi(this).apiHostResourcesByHouseIdGet({id: this.state.house.id}).then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} doors for host.`);
            let residentialDoorsList = ResourceSelection.fromResourceList(resp.data.filter(door => door.type == ResourceType.Residential));
            let publicDoorList = ResourceSelection.fromResourceList(resp.data.filter(door => door.type == ResourceType.Public));
            self.setState({
                residentialDoorSelectionBeans: residentialDoorsList,
                publicDoorSelectionBeans: publicDoorList
            });
            self.downloadAccommodation();
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get doors list");
        });
    }

    private downloadAccommodation() {
        let self = this;
        new HostAccommodationsApi(this).apiHostAccommodationsIdGet({id: this.state.accommodation.id + ''}).then(resp => {
            Logger.log(self.constructor.name, `Found accommodation for host.`);
            let residentialDoorSelectionBeans = self.state.residentialDoorSelectionBeans;
            let publicDoorSelectionBeans = self.state.publicDoorSelectionBeans;

            let houseDoorsId = resp.data.accommodationResourceJoins.map(join => join.resourceId);
            residentialDoorSelectionBeans.forEach(value => value.selected = houseDoorsId.includes(value.resource.id));
            publicDoorSelectionBeans.forEach(value => value.selected = houseDoorsId.includes(value.resource.id));

            self.setState({
                accommodation: resp.data,
                residentialDoorSelectionBeans: residentialDoorSelectionBeans,
                publicDoorSelectionBeans: publicDoorSelectionBeans,
                showNoData: true,
            });
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get accommodation data");
        });
    }
}

/**
 * Update door selection state
 * @param {React.Component<any, State>} context
 * @param key
 * @param {ResourceType} type
 */
function updateSelection(context: Component<any, State>, key, type: ResourceType) {
    switch (type) {
        case ResourceType.Residential:
            let residentialArray = context.state.residentialDoorSelectionBeans;
            residentialArray[key].selected = !residentialArray[key].selected;
            context.setState({residentialDoorSelectionBeans: residentialArray});
            break;
        case ResourceType.Public:
            let publicArray = context.state.publicDoorSelectionBeans;
            publicArray[key].selected = !publicArray[key].selected;
            context.setState({publicDoorSelectionBeans: publicArray});
            break;
    }
}

/**
 * Checks if house name is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function accommodationNameValid(context: Component<any, State>) {
    return context.state.accommodation_name != null && context.state.accommodation_name != '';
}

/**
 * Checks if at least one residential door has been selected
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function atLeastOneResidentialSelected(context: Component<any, State>) {
    let selected = ArrayHelper.filterResourceSelectionBean(context.state.residentialDoorSelectionBeans);
    return selected.length > 0;
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return accommodationNameValid(context) && atLeastOneResidentialSelected(context);
}

function debugUpdateData(context: Component<any, State>) {
    let accommodation = AccommodationFactory.factory();
    context.setState({
        house: HouseFactory.factory(),
        accommodation: HostAccommodationFactory.factory(),
        accommodation_name: accommodation.name,
        accommodation_description: accommodation.description,
        residentialDoorSelectionBeans: ResourceSelection.fromResourceList(ResourceFactory.factoryArray(5)),
        publicDoorSelectionBeans: ResourceSelection.fromResourceList(ResourceFactory.factoryArray(5)),
    });

}

function deleteAccommodation(context: Component<any, State>) {
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed) {
            new HostAccommodationsApi(context).apiHostAccommodationsIdCandeleteGet({id: context.state.accommodation.id}).then(resp => {
                    openAccommodationDelete(context, resp, context.state.accommodation);
            }).catch(error => {
                AlertHelper.showSimpleErrorAlert();
            });
        }
    });
}

/**
 * Checks if data changed
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataChanged(context: Component<any, State>) {
    let state = context.state;
    if (state.house == null)
        return false;
    if (state.accommodation == null)
        return false;
    let accommodationNameChanged = state.accommodation_name.trim() != state.accommodation.name;
    let accommodationDescriptionChanged = state.accommodation_description.trim() != state.accommodation.description;

    let residentialDoorIds = ArrayHelper.filterResourceSelectionBean(context.state.residentialDoorSelectionBeans).map((value) => value.resource.id);
    let publicDoorIds = ArrayHelper.filterResourceSelectionBean(context.state.publicDoorSelectionBeans).filter(value => value.selected).map((value) => value.resource.id);
    let totalIds = residentialDoorIds.concat(publicDoorIds).sort();
    let accommodationIds = context.state.accommodation.accommodationResourceJoins != null ? context.state.accommodation.accommodationResourceJoins.map(join => join.resourceId).sort() : [];
    let different = false;
    totalIds.forEach(id => {
        if (!accommodationIds.includes(id))
            different = true;
    });

    return (accommodationNameChanged || accommodationDescriptionChanged || (different || totalIds.length != accommodationIds.length)) && (dataComplete(context));
}

/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    if (!dataChanged(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.accommodation_data_unchanged'));
        return;
    }
    if (!atLeastOneResidentialSelected(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.at_least_one_residential'));
        return;
    }
    let residentialDoorIds = ArrayHelper.filterResourceSelectionBean(context.state.residentialDoorSelectionBeans).map((value) => value.resource.id);
    let publicDoorIds = ArrayHelper.filterResourceSelectionBean(context.state.publicDoorSelectionBeans).filter(value => value.selected).map((value) => value.resource.id);


    let accommodationCreateInput = {
        name: context.state.accommodation_name,
        resourceIds: [].concat(residentialDoorIds, publicDoorIds),
    };
    new HostAccommodationsApi(context).apiHostAccommodationsIdPut({
        id: context.state.accommodation.id,
        accommodationCreateInput: accommodationCreateInput
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp);
        goBack(context);
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    })
}