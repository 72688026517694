/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewHouseDoneScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import newHouseDoneScreenStyle from "@styles/host/house/NewHouseDoneScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openDashboardHouses, openNewDoor} from "@helpers/NavigationHelper";
import {ConfirmButton} from "@components/ConfirmButton";
import {AvatarImageButton} from "@components/AvatarImageButton";
import {
    getHouseFromNavigationProps,
    getUserFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {House} from "@services/src/models/index";
import {HouseDoneBackgroundLeftImage} from "@components/Images/HouseDoneBackgroundLeftImage";
import {HouseDoneBackgroundRightImage} from "@components/Images/HouseDoneBackgroundRightImage";
import {AvenirHeavyPrimaryText, AvenirLightPrimaryText} from "@components/StyledText";
import {HomeImage} from "@components/Images/HomeImage";
import {PlusColorImage} from "@components/Images/PlusColorImage";
import {DeviceImage} from "@components/Images/DeviceImage";

interface State extends LoaderState {
    house: House,
    latitude: number,
    longitude: number,
    user_photo_uri: string,
    markers: Array<any>,
}

export default class NewHouseDoneScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_house_done.title');
        let houseFromNavigationProps = getHouseFromNavigationProps(this);
        this.state = {
            loading: false,
            house: houseFromNavigationProps,
            latitude: null,
            longitude: null,
            user_photo_uri: houseFromNavigationProps != null ? houseFromNavigationProps.photoPath : null,
            markers: [],
        };

    }


    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newHouseDoneScreenStyle.container}>
                <Loader state={this.state}/>
                <View
                    style={[newHouseDoneScreenStyle.container, globalScreenStyle.globalMargins]}
                >
                    {/*contentContainerStyle={newHouseDoneScreenStyle.contentContainer}*/}
                    <View
                        style={[newHouseDoneScreenStyle.getStartedContainer, newHouseDoneScreenStyle.container]}>
                        <View style={{flex: 2}}>
                            <AvenirHeavyPrimaryText style={newHouseDoneScreenStyle.title}>
                                {i18n.t('screens.new_house_done.its_done_1')}
                            </AvenirHeavyPrimaryText>
                            <AvenirHeavyPrimaryText style={newHouseDoneScreenStyle.title}>
                                {i18n.t('screens.new_house_done.its_done_2')}
                            </AvenirHeavyPrimaryText>
                        </View>
                        <View style={newHouseDoneScreenStyle.welcomeContainer}>
                            <View style={{flex: 1}}>
                                <HouseDoneBackgroundLeftImage style={{width: 90, height: 90, alignSelf: 'flex-start'}}/>
                            </View>
                            <AvatarImageButton style={{flex: 1}}
                                               imageUri={this.state.user_photo_uri}/>
                            <View style={{flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start'}}>
                                <HouseDoneBackgroundRightImage style={{width: 90, height: 90, alignSelf: 'flex-start'}}/>
                            </View>
                        </View>
                        <View style={newHouseDoneScreenStyle.subtitleContainer}>
                            <AvenirHeavyPrimaryText style={newHouseDoneScreenStyle.subtitle}>
                                {this.state.house.name}
                            </AvenirHeavyPrimaryText>
                            <AvenirHeavyPrimaryText style={newHouseDoneScreenStyle.subtitle}>
                                {i18n.t('screens.new_house_done.is_one_of_your_houses')}
                            </AvenirHeavyPrimaryText>
                        </View>
                        <View style={newHouseDoneScreenStyle.installationContainer}>
                            <HomeImage style={newHouseDoneScreenStyle.installationImage}/>
                            <PlusColorImage style={newHouseDoneScreenStyle.installationPlusImage}/>
                            <DeviceImage style={newHouseDoneScreenStyle.installationImage}/>
                            <AvenirLightPrimaryText style={newHouseDoneScreenStyle.installationText}>
                                {i18n.t('screens.new_house_done.you_can_install_keypad')}
                            </AvenirLightPrimaryText>
                        </View>
                    </View>
                    <ConfirmButton active={true} onPress={() => openNextPage(this)}
                                   title={i18n.t('buttons.connect_first_keypad')}
                                   style={{marginBottom: 20}}/>
                </View>
            </KeyboardAvoidingView>
        );
    }
}


/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    openNewDoor(context, context.state.house);
}
