/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       RecapContainerBlock.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import recapContainerBlockStyle from "@styles/host/RecapContainerBlockStyle";
import {
    AvenirBookGreyLabelText,
    AvenirBookPrimaryHalfOpacityText,
    AvenirBookPrimaryText,
    AvenirHeavyPrimaryText
} from "./StyledText";
import {i18n} from "@i18n/i18n";
import {GuestIconImage} from "@components/Images/services/GuestIconImage";
import {Collaborator, Guest} from "@services/src/models";
import {BfreeRecapImage} from "@components/Images/BfreeRecapImage";
import {HouseForAccommodationsSelectionBean} from "../models/interfaces/HouseForAccommodationsSelectionBean";
import {PeriodImage} from "@components/Images/PeriodImage";
import {DateHelper} from "@helpers/DateHelper";
import {CollaboratorIconImage} from "@components/Images/services/CollaboratorIconImage";

interface RecapContainerBlockProps {
    title: string,
    image: any,
    children?: any,
    style?: StyleProp<ViewStyle>,
}

export function RecapContainerBlock(props: RecapContainerBlockProps) {
    return (
        <View style={[recapContainerBlockStyle.flex, recapContainerBlockStyle.container, props.style]}>
            <View
                style={[recapContainerBlockStyle.flex, recapContainerBlockStyle.flexWrap, recapContainerBlockStyle.background, recapContainerBlockStyle.header]}>
                {props.image}
                <View style={recapContainerBlockStyle.leftBorderView}/>
            </View>
            <View style={[recapContainerBlockStyle.flexBig, recapContainerBlockStyle.background]}>
                <View style={recapContainerBlockStyle.textContainer}>
                    <AvenirBookGreyLabelText style={[recapContainerBlockStyle.titleText]}>
                        {props.title}
                    </AvenirBookGreyLabelText>
                </View>
                {props.children}
            </View>
        </View>
    );
}

interface UserDataRecapBlockProps {
    guest: Guest,
    style?: StyleProp<ViewStyle>,
}

interface CollaboratorDataRecapBlockProps {
    collaborator: Collaborator,
    style?: StyleProp<ViewStyle>,
}

export function UserDataRecapBlock(props: UserDataRecapBlockProps) {
    let hasGuest = props.guest != null;
    return (
        <RecapContainerBlock title={i18n.t('common.user_data')} style={props.style}
                             image={<GuestIconImage style={[recapContainerBlockStyle.headImage]}/>}>
            <View style={[recapContainerBlockStyle.flex, {flexDirection: 'column'}]}>
                <AvenirHeavyPrimaryText>
                    {hasGuest ? props.guest.firstName : ''} {hasGuest ? props.guest.lastName : ''}
                </AvenirHeavyPrimaryText>
                <AvenirHeavyPrimaryText>
                    {hasGuest ? props.guest.email : ''}
                </AvenirHeavyPrimaryText>
            </View>
        </RecapContainerBlock>
    );
}

export function CollaboratorDataRecapBlock(props: CollaboratorDataRecapBlockProps) {
    let hasCollaborator = props.collaborator != null;
    return (
        <RecapContainerBlock title={i18n.t('common.user_data')} style={props.style}
                             image={<CollaboratorIconImage style={[recapContainerBlockStyle.headImage]}/>}>
            <View style={[recapContainerBlockStyle.flex, {flexDirection: 'column'}]}>
                <AvenirHeavyPrimaryText>
                    {hasCollaborator ? props.collaborator.firstName : ''} {hasCollaborator ? props.collaborator.lastName : ''}
                </AvenirHeavyPrimaryText>
                <AvenirHeavyPrimaryText>
                    {hasCollaborator ? props.collaborator.email : ''}
                </AvenirHeavyPrimaryText>
            </View>
        </RecapContainerBlock>
    );
}

interface AccommodationRecapBlockProps {
    houses: Array<HouseForAccommodationsSelectionBean>,
    style?: StyleProp<ViewStyle>,
}

export function AccommodationRecapBlock(props: AccommodationRecapBlockProps) {
    let housesData = [];
    props.houses.forEach(house => {
        let selectedCount = house.accommodationsSelectionBeans.filter(bean => bean.selected).length;
        let selectedAll = selectedCount == house.accommodationsSelectionBeans.length;
        housesData.push({
            name: house.name,
            accommodationsSelectionBeans: house.accommodationsSelectionBeans,
            selectedAll: selectedAll,
            selectedCount: selectedCount,
        })
    });

    return (
        <RecapContainerBlock title={i18n.t('common.accommodation')} style={props.style}
                             image={<BfreeRecapImage style={[recapContainerBlockStyle.headImage]}/>}>
            <View style={[recapContainerBlockStyle.flex, {flexDirection: 'column'}]}>
                {housesData.map((houseData, key) => (
                    <View key={key}>
                        <AvenirHeavyPrimaryText>
                            {houseData.name}
                        </AvenirHeavyPrimaryText>
                        <AvenirBookPrimaryText>
                            {houseData.selectedAll ? i18n.t('rows.recap.all_accommodations') : i18n.t('rows.recap.selected_accommodations', {accommodations: houseData.selectedCount})}
                        </AvenirBookPrimaryText>

                    </View>
                ))}
            </View>
        </RecapContainerBlock>
    );
}

interface PeriodRecapBlockProps {
    from_date: Date,
    from_time: Date,
    to_date: Date,
    to_time: Date,
    style?: StyleProp<ViewStyle>,
}

export function PeriodRecapBlock(props: PeriodRecapBlockProps) {
    return (
        <RecapContainerBlock title={i18n.t('common.period')} style={props.style}
                             image={<PeriodImage style={[recapContainerBlockStyle.headImage]}/>}>
            <View style={[recapContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                <View style={{flex: 1}}>
                    <AvenirBookPrimaryHalfOpacityText>
                        {i18n.t('rows.recap.from_date')}
                    </AvenirBookPrimaryHalfOpacityText>
                </View>
                <View style={{flex: 2}}>
                    <AvenirHeavyPrimaryText>
                        {DateHelper.formatDate(props.from_date)}
                    </AvenirHeavyPrimaryText>
                </View>
                <View style={{flex: 2}}>
                    <AvenirBookPrimaryText>
                        {DateHelper.formatTime(props.from_time)}
                    </AvenirBookPrimaryText>
                </View>
            </View>
            <View style={[recapContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                <View style={{flex: 1}}>
                    <AvenirBookPrimaryHalfOpacityText>
                        {i18n.t('rows.recap.to_date')}
                    </AvenirBookPrimaryHalfOpacityText>
                </View>
                <View style={{flex: 2}}>
                    <AvenirHeavyPrimaryText>
                        {DateHelper.formatDate(props.to_date)}
                    </AvenirHeavyPrimaryText>
                </View>
                <View style={{flex: 2}}>
                    <AvenirBookPrimaryText>
                        {DateHelper.formatTime(props.to_time)}
                    </AvenirBookPrimaryText>
                </View>
            </View>
        </RecapContainerBlock>
    );
}