/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ClickableBlockStyle.ts
 * @author     Christian Ascone
 * @date       10/11/19 3:12 PM
 */

import {Platform, StyleSheet} from "react-native";
import Colors from "../config/Colors";

const styles = StyleSheet.create({
    enabledButton: {
        paddingTop:10,
        paddingBottom:10,
    },
    backgroundEnabled: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        backgroundColor: Colors.buttonEnabledBackground,
    },
    backgroundDisabled: {
        backgroundColor: Colors.buttonDisabledBackground,
    },
    text: {
        textAlign:'center',
        color: 'white',
        padding: 10,
    },
    avatarPlaceholderAdd: {
        width: 30,
        height: 30,
        position: 'absolute',
        left: 60,
    },
    addButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.primaryDark,
    },
    addButtonContainer: {
        padding: 10,
        backgroundColor: Colors.primaryColor,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    addButtonImage: {
        width: 8,
        height: 8,
        alignSelf: 'center',
    },
    addButtonText: {
        marginHorizontal: 20,
        color: 'white',
        fontSize: 10,
    },
    listButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.clickableBlockBackground,
        borderColor: Colors.primaryDark,
        borderWidth: 0.5,
    },
    listButtonContainer: {
        padding: 10,
        backgroundColor: Colors.clickableBlockBackground,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        borderRightColor: Colors.primaryDark,
        borderRightWidth: 0.5,
    },
    listButtonImage: {
        width: 8,
        height: 8,
        alignSelf: 'flex-end',
    },
    listButtonText: {
        marginHorizontal: 20,
        color: 'white',
        fontSize: 10,
    },
    inputBlockButtonContainer: {
        flexDirection: 'row',
        display: 'flex',
    },
    inputBlockButtonInputContainer: {
        flex: 3,
        flexDirection: 'column',
    },
    inputBlockButtonButtonContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    filtersButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: Colors.background,
        borderColor: Colors.primaryDark,
        borderWidth: 0.5,
        paddingTop: 15,
        paddingBottom: 15,
    },
    filtersButtonImage: {
        width: 12,
        height: 12,
        alignSelf: 'flex-end',
    },
    filtersButtonText: {
        marginHorizontal: 10,
        color: 'white',
        fontSize: 12,
    },
    addRowButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
        borderColor: Colors.addContactBackground,
        borderWidth: 0.5,
        ...Platform.select({
            ios: {
                paddingTop: 20,
                paddingBottom: 20,
            },
            android: {
                paddingTop: 20,
                paddingBottom: 20,
            },
            web: {
                paddingTop: 15,
                paddingBottom: 15,
            },
        }),
        paddingLeft: 1,
    },
    addRowButtonImage: {
        width: 12,
        height: 12,
        alignSelf: 'flex-end',
    },
    addRowButtonText: {
        marginHorizontal: 10,
        color: 'white',
        fontSize: 12,
    },
});

export default styles;