/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DashboardInfoBlock.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React from 'react';
import {View} from 'react-native';
import {i18n} from "@i18n/i18n";
import {AvenirHeavyPrimaryText, AvenirMediumPrimaryText} from "./StyledText";
import {RightArrowColorImage} from "@components/Images/RightArrowColorImage";
import dashboardStyles from "@styles/host/DashboardInfoBlockStyle";
import {
    DashboardCreditsInfoBlockProps,
    DashboardHousesInfoBlockProps,
    DashboardReservationsInfoBlockProps
} from "./properties/DashboardInfoBlockProps";

export function DashboardReservationsInfoBlock(props: DashboardReservationsInfoBlockProps) {
    let prefix = 'screens.dashboard.blocks.reservations.';
    return (
        <View style={[dashboardStyles.flex, dashboardStyles.container]}>
            <View style={[dashboardStyles.bigContainer, dashboardStyles.textContainer, {flexDirection: 'column',}]}>
                <View style={[dashboardStyles.flex]}/>
                <AvenirMediumPrimaryText
                    style={[dashboardStyles.flex, dashboardStyles.bigNumber]}>{props.reservations}</AvenirMediumPrimaryText>
                <AvenirMediumPrimaryText
                    style={[dashboardStyles.flex, dashboardStyles.leftText]}>{i18n.t(prefix + 'reservations')}</AvenirMediumPrimaryText>
                <View style={[dashboardStyles.flex]}/>
            </View>
            <View style={[dashboardStyles.rightContainer, {flexDirection: 'column',}]}>
                <View style={[dashboardStyles.textContainer]}>
                    <AvenirHeavyPrimaryText
                        style={[dashboardStyles.rightNumber]}>{props.checked_in}</AvenirHeavyPrimaryText>
                    <AvenirMediumPrimaryText
                        style={[dashboardStyles.rightText]}>{i18n.t(prefix + 'checked_in')}</AvenirMediumPrimaryText>
                    <View style={[dashboardStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[dashboardStyles.detailArrow]}/>
                    </View>
                </View>
                <View style={[dashboardStyles.textContainer]}>
                    <AvenirHeavyPrimaryText
                        style={[dashboardStyles.rightNumber]}>{props.confirmed}</AvenirHeavyPrimaryText>
                    <AvenirMediumPrimaryText
                        style={[dashboardStyles.rightText]}>{i18n.t(prefix + 'confirmed')}</AvenirMediumPrimaryText>
                    <View style={[dashboardStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[dashboardStyles.detailArrow]}/>
                    </View>
                </View>
                <View style={[dashboardStyles.textContainer]}>
                    <AvenirHeavyPrimaryText
                        style={[dashboardStyles.rightNumber]}>{props.pending}</AvenirHeavyPrimaryText>
                    <AvenirMediumPrimaryText
                        style={[dashboardStyles.rightText]}>{i18n.t(prefix + 'pending')}</AvenirMediumPrimaryText>
                    <View style={[dashboardStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[dashboardStyles.detailArrow]}/>
                    </View>
                </View>
            </View>
        </View>
    );
}

export function DashboardHousesInfoBlock(props: DashboardHousesInfoBlockProps) {
    let prefix = 'screens.dashboard.blocks.houses.';
    return (
        <View style={[dashboardStyles.flex, dashboardStyles.container]}>
            <View style={[dashboardStyles.bigContainer, dashboardStyles.textContainer, {flexDirection: 'column',}]}>
                <View style={[dashboardStyles.flex]}/>
                <AvenirMediumPrimaryText
                    style={[dashboardStyles.flex, dashboardStyles.bigNumber]}>{props.doors}</AvenirMediumPrimaryText>
                <AvenirMediumPrimaryText
                    style={[dashboardStyles.flex, dashboardStyles.leftText]}>B&Free</AvenirMediumPrimaryText>
                <View style={[dashboardStyles.flex]}/>
            </View>
            <View style={[dashboardStyles.rightContainer, {flexDirection: 'column',}]}>
                <View style={[dashboardStyles.textContainer]}>
                    <AvenirHeavyPrimaryText
                        style={[dashboardStyles.rightNumber]}>{props.active_doors}</AvenirHeavyPrimaryText>
                    <AvenirMediumPrimaryText
                        style={[dashboardStyles.rightText]}>{i18n.t(prefix + 'active_doors')}</AvenirMediumPrimaryText>
                    <View style={[dashboardStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[dashboardStyles.detailArrow]}/>
                    </View>
                </View>
                <View style={[dashboardStyles.textContainer]}>
                    <AvenirHeavyPrimaryText
                        style={[dashboardStyles.rightNumber]}>{props.inactive_doors}</AvenirHeavyPrimaryText>
                    <AvenirMediumPrimaryText
                        style={[dashboardStyles.rightText]}>{i18n.t(prefix + 'inactive_doors')}</AvenirMediumPrimaryText>
                    <View style={[dashboardStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[dashboardStyles.detailArrow]}/>
                    </View>
                </View>
            </View>
        </View>
    );
}

export function DashboardCreditsInfoBlock(props: DashboardCreditsInfoBlockProps) {
    let prefix = 'screens.dashboard.blocks.credits.';
    return (
        <View style={[dashboardStyles.flex, dashboardStyles.container]}>
            <View style={[dashboardStyles.bigContainer, dashboardStyles.textContainer, {flexDirection: 'column',}]}>
                <View style={[dashboardStyles.flex]}/>
                <AvenirMediumPrimaryText
                    style={[dashboardStyles.flex, dashboardStyles.bigNumber]}>{props.credits}</AvenirMediumPrimaryText>
                <AvenirMediumPrimaryText
                    style={[dashboardStyles.flex, dashboardStyles.leftText]}>{i18n.t(prefix + 'remaining_credits')}</AvenirMediumPrimaryText>
                <View style={[dashboardStyles.flex]}/>
            </View>
            <View style={[dashboardStyles.rightContainer, {flexDirection: 'column',}]}>
                <View style={[dashboardStyles.textContainer]}>
                    <AvenirHeavyPrimaryText
                        style={[dashboardStyles.rightNumber]}>{props.active_devices}</AvenirHeavyPrimaryText>
                    <AvenirMediumPrimaryText
                        style={[dashboardStyles.rightText]}>{i18n.t(prefix + 'active_devices')}</AvenirMediumPrimaryText>
                    <View style={[dashboardStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[dashboardStyles.detailArrow]}/>
                    </View>
                </View>
                <View style={[dashboardStyles.textContainer]}>
                    <AvenirHeavyPrimaryText
                        style={[dashboardStyles.rightNumber]}>{props.current_monthly_consumption}</AvenirHeavyPrimaryText>
                    <AvenirMediumPrimaryText
                        style={[dashboardStyles.rightText]}>{i18n.t(prefix + 'current_monthly_consumption')}</AvenirMediumPrimaryText>
                    <View style={[dashboardStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[dashboardStyles.detailArrow]}/>
                    </View>
                </View>
                <View style={[dashboardStyles.textContainer]}>
                    <AvenirHeavyPrimaryText
                        style={[dashboardStyles.rightNumber]}>{props.remaining_months}</AvenirHeavyPrimaryText>
                    <AvenirMediumPrimaryText
                        style={[dashboardStyles.rightText]}>{i18n.t(prefix + 'remaining_months')}</AvenirMediumPrimaryText>
                    <View style={[dashboardStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[dashboardStyles.detailArrow]}/>
                    </View>
                </View>
            </View>
        </View>
    );
}