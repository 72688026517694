/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AddressFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import * as fakerStatic from 'faker';
import {Address, AddressProviderType} from "@services/src/models";

export class AddressFactory {
    static factoryArray(size: number): Address[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(AddressFactory.factory());
        }
        return array;
    }

    static factory(): Address {
        return {
            id: fakerStatic.random.number(5),
            fullAddress: fakerStatic.address.streetAddress(true),
            latitude: +fakerStatic.address.latitude(),
            longitude: +fakerStatic.address.longitude(),
            placeId: fakerStatic.random.alphaNumeric(27),
            placeProvider: AddressProviderType.GoogleMaps,
            street: fakerStatic.address.streetName(),
            number: fakerStatic.random.number(100) + '',
            city: fakerStatic.address.city(),
            locality: fakerStatic.address.county(),
            zip: fakerStatic.address.zipCode(),
        };
    }
}

