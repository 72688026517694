/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DeleteButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import buttonStyle from "@styles/ButtonStyle";
import {DeleteImage} from "@components/Images/DeleteImage";

export function DeleteButton(props) {
    return (
        <View style={[{flexDirection: 'row'}, props.style]}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[buttonStyle.deleteButtonTouchable]}>
                <DeleteImage style={buttonStyle.deleteButtonImage}/>
            </TouchableOpacity>
        </View>
    );
}