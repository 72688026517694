/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Card,
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
    CollaborationStateEnum,
    CollaborationStateEnumFromJSON,
    CollaborationStateEnumFromJSONTyped,
    CollaborationStateEnumToJSON,
    Collaborator,
    CollaboratorFromJSON,
    CollaboratorFromJSONTyped,
    CollaboratorToJSON,
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    HostCollaborationAccommodationJoin,
    HostCollaborationAccommodationJoinFromJSON,
    HostCollaborationAccommodationJoinFromJSONTyped,
    HostCollaborationAccommodationJoinToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostCollaborationDetail
 */
export interface HostCollaborationDetail {
    /**
     * 
     * @type {Collaborator}
     * @memberof HostCollaborationDetail
     */
    collaborator?: Collaborator;
    /**
     * 
     * @type {number}
     * @memberof HostCollaborationDetail
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof HostCollaborationDetail
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostCollaborationDetail
     */
    endAt?: Date;
    /**
     * 
     * @type {Array<HostCollaborationAccommodationJoin>}
     * @memberof HostCollaborationDetail
     */
    collaborationAccommodationJoins?: Array<HostCollaborationAccommodationJoin>;
    /**
     * 
     * @type {Array<Device>}
     * @memberof HostCollaborationDetail
     */
    unsent?: Array<Device>;
    /**
     * 
     * @type {User}
     * @memberof HostCollaborationDetail
     */
    user?: User;
    /**
     * 
     * @type {CollaborationStateEnum}
     * @memberof HostCollaborationDetail
     */
    currentState?: CollaborationStateEnum;
    /**
     * 
     * @type {Array<Card>}
     * @memberof HostCollaborationDetail
     */
    cards?: Array<Card>;
}

export function HostCollaborationDetailFromJSON(json: any): HostCollaborationDetail {
    return HostCollaborationDetailFromJSONTyped(json, false);
}

export function HostCollaborationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostCollaborationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'collaborator': !exists(json, 'collaborator') ? undefined : CollaboratorFromJSON(json['collaborator']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startAt': !exists(json, 'start_at') ? undefined : (new Date(json['start_at'])),
        'endAt': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'collaborationAccommodationJoins': !exists(json, 'collaborationAccommodationJoins') ? undefined : ((json['collaborationAccommodationJoins'] as Array<any>).map(HostCollaborationAccommodationJoinFromJSON)),
        'unsent': !exists(json, 'unsent') ? undefined : ((json['unsent'] as Array<any>).map(DeviceFromJSON)),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'currentState': !exists(json, 'current_state') ? undefined : CollaborationStateEnumFromJSON(json['current_state']),
        'cards': !exists(json, 'cards') ? undefined : ((json['cards'] as Array<any>).map(CardFromJSON)),
    };
}

export function HostCollaborationDetailToJSON(value?: HostCollaborationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'collaborator': CollaboratorToJSON(value.collaborator),
        'id': value.id,
        'start_at': value.startAt === undefined ? undefined : (value.startAt.toISOString()),
        'end_at': value.endAt === undefined ? undefined : (value.endAt.toISOString()),
        'collaborationAccommodationJoins': value.collaborationAccommodationJoins === undefined ? undefined : ((value.collaborationAccommodationJoins as Array<any>).map(HostCollaborationAccommodationJoinToJSON)),
        'unsent': value.unsent === undefined ? undefined : ((value.unsent as Array<any>).map(DeviceToJSON)),
        'user': UserToJSON(value.user),
        'current_state': CollaborationStateEnumToJSON(value.currentState),
        'cards': value.cards === undefined ? undefined : ((value.cards as Array<any>).map(CardToJSON)),
    };
}

