/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationRowStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import Colors from "../../../config/Colors";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
    },
    bigContainer: {
        flex: 1,
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    },
    bigNumber: {
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: 30,
    },
    leftText: {
        flex: 2,
        alignSelf: 'flex-start',
    },
    bottomNumber: {
        textAlign: 'right',
        flex: 1,
        marginLeft: 5,
        marginRight: 5,
    },
    bottomText: {
        flex: 7,
        textAlign: 'center',
    },
    detailArrowContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    detailArrow: {
        alignSelf: 'flex-end',
        width: 8,
        height: 8,
    },
    bottomContainer: {
        flex: 2,
    },
    flex: {
        flex: 1,
    },
    textContainer: {
        flex: 1,
        margin: 2,
        padding: 12,
        flexDirection: 'row',
        backgroundColor: Colors.background,
    },
    accommodationImage: {
        width: 45,
        height: 45,
        alignSelf: 'center',
        resizeMode: 'contain',
    },
    gateResidential: {
        marginRight: 10,
        height: 13,
        width: 0.76 * 15,
        opacity: 0.5,
        resizeMode: 'contain',
    },
    gateResidentialText: {
        fontSize: 12,
        marginRight: 8,
        textAlign: 'center',
        alignSelf: 'center',
        textAlignVertical: 'center',
    },
    gatePublic: {
        marginRight: 10,
        height: 13,
        width: 1.44 * 15,
        opacity: 0.5,
        resizeMode: 'contain',
    },
    gatePublicText: {
        fontSize: 12,
        marginRight: 8,
        textAlign: 'center',
        alignSelf: 'center',
    },
});

export default styles;