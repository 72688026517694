/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CollaborationFiltersScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component, useRef, createRef} from 'react';
import {ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {dismissModal, goBack, NavigationProps, openCardBarcodeScanner} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {
    getPassepartoutDismissCallbackFromNavigationProps,
    getPassepartoutFromNavigationProps
} from "@helpers/NavigationDataHelper";
import {PassepartoutInput} from "@services/src/models/index";
import {AvenirBookServiceGreyText, AvenirBookText, AvenirHeavyPrimaryText} from "@components/StyledText";
import {Logger} from "@helpers/Logger";
import {TextInputBlock} from "@components/TextInputBlock";
import screenStyles from "@styles/host/user/ProfilePassepartoutScreenStyle";
import {CardsApi, HostCardsApi} from "@services/src";
import {CardSelectionBlock} from "@components/CardSelectionBlock";
import {CardSelectionBean} from "../../../models/beans/CardSelection";
import cardsScreenStyle from "@styles/host/cards/CardScreenStyle";
import {RoundedButton} from "@components/RoundedButton";
import {BfreeX1} from "@helpers/BfreeX1";
import { Icon } from 'react-native-elements';
import {AlertHelper} from "@helpers/AlertHelper";
import newDoorScreenStyle from "@styles/host/doors/NewDoorScreenStyle";

interface State extends LoaderState {
    onDismiss: (result: PassepartoutInput) => void
    originalPassepartout: PassepartoutInput
    passepartout: PassepartoutInput,
    cards: CardSelectionBean[],
    usingCard: boolean
}

interface PassepartoutNavigationProps extends NavigationProps{
    devices_code: string,
    devices_code_to_save: string,
    card_id: number,
}

export default class ProfilePassepartoutScreen extends Component<PassepartoutNavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;
    didCodeChange: boolean;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            onDismiss: getPassepartoutDismissCallbackFromNavigationProps(this) || null,
            originalPassepartout: getPassepartoutFromNavigationProps(this) || null,
            passepartout: getPassepartoutFromNavigationProps(this) || null,
            cards: [],
            usingCard: !!this.props.card_id,
        };
        this.didCodeChange=false;
    }

    componentDidMount(): void {
        this.getAllCards();
    }


    render() {
        return (<View style={[screenStyles.container, globalScreenStyle.globalMargins, {display: 'flex', flex: 1,}]}>
                <ScrollView style={[newDoorScreenStyle.scrollContainer]} contentContainerStyle={[{marginBottom: 30, alignItems: 'center'}]}
                >
                    <View style={[{flex: 1}]}>
                        <View style={{marginVertical: 30}}>
                            <AvenirHeavyPrimaryText>{i18n.t('screens.passepartout.title_1')}</AvenirHeavyPrimaryText>
                            <AvenirBookServiceGreyText>{i18n.t('screens.passepartout.subtitle_1')}</AvenirBookServiceGreyText>
                        </View>
                        <View>
                            <TextInputBlock label={i18n.t('screens.passepartout.placeholder')} autoCapitalize={"characters"} text={this.state.passepartout.selectedCardId == null ? this.state.passepartout.codeToShow : ''} onChangeText={text => {
                                let pass = this.state.passepartout;
                                let replacedtext = text.replace(/[^0-9]/g, '');
                                if (replacedtext == null)
                                    replacedtext = '';
                                pass.codeToShow = replacedtext;
                                pass.selectedCardId = null;
                                this.setState({usingCard: false, passepartout: pass});
                                this.textCodeChosen();
                            }}/>

                        </View>
                        <View style={{flex: 1  }}>
                            <AvenirHeavyPrimaryText>{i18n.t('screens.passepartout.title_2')}</AvenirHeavyPrimaryText>
                            <AvenirBookServiceGreyText>{i18n.t('screens.passepartout.subtitle_2')}</AvenirBookServiceGreyText>
                            <RoundedButton     title={i18n.t('screens.new_card.scan')}
                                               style={{marginVertical: 10}}
                                               isQr={true}
                                               onPress={() => openCardBarcodeScanner(this, "ProfilePassepartout",(data) =>
                                                   this.handleScan(data)
                                               )}/>
                            <View style={{alignItems: 'center', justifyContent: 'space-around', marginBottom: 20, flex: 1, marginLeft: '5%'}}>
                                {this.state.cards.length > 0 ? this.state.cards.map((card, key) => (
                                    !card.card.state &&
                                    <CardSelectionBlock
                                        active={card.selected}
                                        card={card.card}
                                        onPress={()=>{this.updateSelectedCard(key)}}
                                        style={ {width: '90%', margin: 5,}}
                                    />


                                )) : <View style={{ marginTop: '50%'}}><AvenirBookText> {i18n.t('screens.passepartout.no_cards')} </AvenirBookText></View>}
                            </View>


                        </View>

                    </View>
                </ScrollView>
                <Loader state={this.state}/>
                <ConfirmButtonBottom active={true} onPress={() => save(this)}
                                     title={i18n.t('buttons.passepartout')}
                                     style={{marginHorizontal: 30, width: '100%', alignSelf: 'center', }}/>
            </View>
        );
    }
    private handleScan(data){
        if (data)
        new HostCardsApi(this).apiHostCardsExistsCodePost({code: data }).then(resp =>{
            if (resp.id == null){
                console.log("La carta non esiste...");
                   /*new HostCardsApi(this).apiHostCardsPost({card:{code: data, codeEncrypted: BfreeX1.encode(data)}}).then(resp=>{
                       console.log(resp);
                       this.setState({passepartout: {codeToShow: resp.code, codeToSave: resp.codeEncrypted, selectedCardId: resp.id}});
                   }).catch(error => {
                       Logger.warn(self.constructor.name, "Error " + error);
                       this.setState({showNoData: true,});
                   });*/
                this.props.navigation.navigate('NewCard', {code: data, onDismiss: (id) => {this.scanHappened(id)}});
                }
            else {
                console.log("La carta esiste");
                this.selectScannedCard(resp.id);
                }


        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            this.setState({showNoData: true,});
        });
        else
            AlertHelper.showSimpleAlert(i18n.t('screens.new_card.errors.unknown_code_title'), i18n.t('screens.new_card.errors.unknown_code'));

}



    private codeChanged(){
        return !(this.state.originalPassepartout == this.state.passepartout);
    }



    private async getAllCards() {
        let self = this;
        await new CardsApi(this).apiHostCardsStatusGet().then(async (resp) => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} cards for host.`);
            let cards = [];
            for (let i = 0; i<resp.data.length; i++){
                if (self.state.originalPassepartout.selectedCardId == resp.data[i].id)
                    cards.push({card: resp.data[i], selected: true});
                else
                    cards.push({card: resp.data[i], selected: false});
            }
          await this.setState({cards: cards});

        return 0;

        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }

    async scanHappened(id){
        await this.getAllCards();
        await this.selectScannedCard(id)
    }

    textCodeChosen(){
        let cards = this.state.cards;
        cards.forEach(card => {
            card.selected = false;
        })
        let pass = this.state.passepartout;

        pass.selectedCardId = null;
        this.setState({cards: cards, passepartout: pass, usingCard: false});
    }


    selectScannedCard(id: number) {
        let cards = this.state.cards;
        console.log(cards);
        let selectedCard = null;
        let selectedCardKey = null;
        cards.forEach((card, key) => {
            console.log('\n\n\nCARTA::::' + JSON.stringify(card) + '\n\n\n')
            card.selected = false;
            console.log('CARD.CARD: ',card.card);
            let carta = card.card
            if (carta.id == id){
                card.selected = true;
                selectedCard = card;
                selectedCardKey = key;
            }
        });
        let temp = cards[0];
        cards[0] = cards[selectedCardKey];
        cards[selectedCardKey] = temp;

        this.setState({cards: cards, usingCard: true, passepartout: {codeToShow: selectedCard.card.code, codeToSave: BfreeX1.decode(selectedCard.card.code), selectedCardId: selectedCard.card.id}});
    }


    updateSelectedCard(key: number) {
        let cards = this.state.cards;
        let pass = this.state.passepartout;
        if (cards[key].selected == true) {
            cards[key].selected = false;
            pass.codeToShow = '';
            pass.codeToSave = '';
            pass.selectedCardId = null;
        } else {
            cards.forEach(card => {
                card.selected = false;
            })
            let card = cards[key];
            card.selected = !card.selected;
            cards[key] = card;
            let carta = card;
            pass.selectedCardId = carta.card.id;
            pass.codeToShow = carta.card.code;
        }
        this.setState({
            passepartout: pass,
            cards: cards,
            usingCard: true
        });
    }
}

function compareCodes(originalPassepartout: PassepartoutInput, passepartout: PassepartoutInput) : boolean {
    return originalPassepartout.codeToShow != passepartout.codeToShow || originalPassepartout.selectedCardId != passepartout.selectedCardId || originalPassepartout.codeToSave != passepartout.codeToSave;
}

function save(context: Component<NavigationProps, State>){
    let selectedCard = null;
    let didCodeChange = compareCodes(context.state.originalPassepartout, context.state.passepartout);
    context.state.cards.forEach(card =>{
        if (card.selected == true){
            selectedCard = card;
        }
    })
    let pass = context.state.passepartout;
    if (selectedCard != null)
        pass.selectedCardId = selectedCard.card.id;
    if (!didCodeChange)
        pass = context.state.originalPassepartout;
    context.setState({passepartout : pass});
    context.state.onDismiss(context.state.passepartout.codeToShow ? context.state.passepartout : context.state.originalPassepartout);
    goBack(context);
}