/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DebugButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 10:26 AM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, TouchableOpacity, View} from 'react-native';
import debugButtonStyle from "@styles/DebugButtonStyle";
import {AvenirMediumText} from "./StyledText";
import {environment} from "../environments/environment";

interface DebugButtonProps {
    onPress: (event: GestureResponderEvent) => void,
}

export class DebugButton extends Component<DebugButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        if (!environment.debugRoom)
            return null;
        return (
            <View>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[debugButtonStyle.enabledButton, debugButtonStyle.backgroundEnabled]}>
                    <AvenirMediumText style={debugButtonStyle.text}>D E B U G</AvenirMediumText>
                </TouchableOpacity>
            </View>
        );
    }
}