/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CollaborationStateEnum,
    CollaborationStateEnumFromJSON,
    CollaborationStateEnumFromJSONTyped,
    CollaborationStateEnumToJSON,
    Collaborator,
    CollaboratorFromJSON,
    CollaboratorFromJSONTyped,
    CollaboratorToJSON,
    House,
    HouseFromJSON,
    HouseFromJSONTyped,
    HouseToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostCollaboration
 */
export interface HostCollaboration {
    /**
     * 
     * @type {Collaborator}
     * @memberof HostCollaboration
     */
    collaborator?: Collaborator;
    /**
     * 
     * @type {number}
     * @memberof HostCollaboration
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof HostCollaboration
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostCollaboration
     */
    endAt?: Date;
    /**
     * 
     * @type {House}
     * @memberof HostCollaboration
     */
    house?: House;
    /**
     * 
     * @type {CollaborationStateEnum}
     * @memberof HostCollaboration
     */
    currentState?: CollaborationStateEnum;
    /**
     * 
     * @type {User}
     * @memberof HostCollaboration
     */
    user?: User;
}

export function HostCollaborationFromJSON(json: any): HostCollaboration {
    return HostCollaborationFromJSONTyped(json, false);
}

export function HostCollaborationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostCollaboration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'collaborator': !exists(json, 'collaborator') ? undefined : CollaboratorFromJSON(json['collaborator']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startAt': !exists(json, 'start_at') ? undefined : (new Date(json['start_at'])),
        'endAt': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'house': !exists(json, 'house') ? undefined : HouseFromJSON(json['house']),
        'currentState': !exists(json, 'current_state') ? undefined : CollaborationStateEnumFromJSON(json['current_state']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function HostCollaborationToJSON(value?: HostCollaboration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'collaborator': CollaboratorToJSON(value.collaborator),
        'id': value.id,
        'start_at': value.startAt === undefined ? undefined : (value.startAt.toISOString()),
        'end_at': value.endAt === undefined ? undefined : (value.endAt.toISOString()),
        'house': HouseToJSON(value.house),
        'current_state': CollaborationStateEnumToJSON(value.currentState),
        'user': UserToJSON(value.user),
    };
}

