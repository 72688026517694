/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       TextAreaBlock.tsx
 * @author     Christian Ascone
 * @date       11/20/19 1:13 PM
 */

import React from 'react';
import {KeyboardTypeOptions, StyleProp, TextInput, TextStyle, View, ViewStyle} from 'react-native';
import style from '@styles/TextStyle';
import {AvenirBookUpperLabelText, AvenirLightGreyText} from "./StyledText";
import supportReportScreenStyle from "@styles/host/support/SupportReportScreenStyle";
import {i18n} from "@i18n/i18n";

interface TextAreaBlockProps {
    label: string,
    keyboardType?: KeyboardTypeOptions,
    style?: StyleProp<ViewStyle>,
    textStyle?: StyleProp<TextStyle>,
    secureTextEntry?: boolean,
    placeholder?: string,
    onChangeText?: (text: string) => void,
    text?: string,
}

export function TextAreaBlock(props: TextAreaBlockProps) {
    return (
        <View>
            <View style={props.textStyle}>
                <AvenirLightGreyText>
                    {props.label}
                </AvenirLightGreyText>
            </View>
            <TextInput
                keyboardType={props.keyboardType}
                style={[style.textArea, props.style]}
                secureTextEntry={props.secureTextEntry}
                placeholder={props.placeholder || props.label}
                onChangeText={props.onChangeText}
                maxLength = {255}
                multiline
                numberOfLines={4}
                value={props.text}
            />
        </View>
    );
}