/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoubleConfirmButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 10:26 AM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import ButtonStyle from "@styles/ButtonStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {AvenirHeavyText, AvenirMediumText} from "./StyledText";

interface DoubleConfirmButtonProps {
    active: boolean,
    style?: StyleProp<ViewStyle>,
    onPressFirst: (event: GestureResponderEvent) => void,
    onPressSecond: (event: GestureResponderEvent) => void,
    firstTitle: string,
    secondTitle: string,
}

export class DoubleConfirmButton extends Component<DoubleConfirmButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <View style={[this.props.style, {flexDirection: 'row',}]}>
                <TouchableOpacity onPress={this.props.onPressFirst}
                                  style={[ButtonStyle.enabledButton, (this.props.active ? ButtonStyle.doubleButtonBackgroundEnabled : ButtonStyle.doubleButtonBackgroundDisabled), {
                                      flex: 7,
                                      marginRight: 2,
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                  }]}>
                    <AvenirHeavyText style={ButtonStyle.doubleButtonText}>{this.props.firstTitle}</AvenirHeavyText>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.props.onPressSecond}
                                  style={[ButtonStyle.enabledButton, (this.props.active ? ButtonStyle.doubleButtonSecondaryBackgroundEnabled : ButtonStyle.doubleButtonSecondaryBackgroundDisabled), {
                                      flex: 5,
                                      marginLeft: 2,
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                  }]}>
                    <AvenirMediumText
                        style={[ButtonStyle.doubleButtonText, (this.props.active ? ButtonStyle.doubleButtonSecondaryTextEnabled : ButtonStyle.doubleButtonSecondaryTextDisabled)]}>{this.props.secondTitle}</AvenirMediumText>
                </TouchableOpacity>
            </View>
        );
    }
}

export class DoubleConfirmButtonBottom extends Component<DoubleConfirmButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <DoubleConfirmButton active={this.props.active} onPressFirst={this.props.onPressFirst}
                                 onPressSecond={this.props.onPressSecond}
                                 firstTitle={this.props.firstTitle}
                                 secondTitle={this.props.secondTitle}
                                 style={[globalScreenStyle.globalMargins, {marginBottom: 20}, this.props.style,]}/>
        );
    }
}