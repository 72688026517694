/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserTokenOs {
    Android = 'ANDROID',
    Ios = 'IOS'
}

export function UserTokenOsFromJSON(json: any): UserTokenOs {
    return UserTokenOsFromJSONTyped(json, false);
}

export function UserTokenOsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTokenOs {
    return json as UserTokenOs;
}

export function UserTokenOsToJSON(value?: UserTokenOs | null): any {
    return value as any;
}

