/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GuestType,
    GuestTypeFromJSON,
    GuestTypeFromJSONTyped,
    GuestTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Guest
 */
export interface Guest {
    /**
     * 
     * @type {number}
     * @memberof Guest
     */
    id?: number;
    /**
     * 
     * @type {GuestType}
     * @memberof Guest
     */
    type?: GuestType;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    fiscalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof Guest
     */
    photoPath?: string;
    /**
     * 
     * @type {number}
     * @memberof Guest
     */
    addressId?: number;
    /**
     * 
     * @type {number}
     * @memberof Guest
     */
    userId?: number;
}

export function GuestFromJSON(json: any): Guest {
    return GuestFromJSONTyped(json, false);
}

export function GuestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Guest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : GuestTypeFromJSON(json['type']),
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fiscalCode': !exists(json, 'fiscal_code') ? undefined : json['fiscal_code'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'photoPath': !exists(json, 'photo_path') ? undefined : json['photo_path'],
        'addressId': !exists(json, 'address_id') ? undefined : json['address_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function GuestToJSON(value?: Guest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': GuestTypeToJSON(value.type),
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'fiscal_code': value.fiscalCode,
        'mobile': value.mobile,
        'photo_path': value.photoPath,
        'address_id': value.addressId,
        'user_id': value.userId,
    };
}

