/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewAccommodationScreen.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import newAccommodationScreenStyle from "@styles/host/accommodations/NewAccommodationScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {goBack, NavigationProps, openNewDoor} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {getHouseFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {HostAccommodationsApi, HostResourcesApi} from "@services/src/apis/index";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {AccommodationCreateInput, House, ResourceType} from "@services/src/models/index";
import {AvenirMediumPrimaryHalfOpacityText} from "@components/StyledText";
import {HouseFactory} from "../../../models/factory/HouseFactory";
import {AddDoorBlock} from "@components/AddDoorBlock";
import {ResourceFactory} from "../../../models/factory/ResourceFactory";
import {ResourceSelection, ResourceSelectionBean} from "../../../models/beans/ResourceSelection";
import {ArrayHelper} from "@helpers/ArrayHelper";
import {DebugButton} from "@components/DebugButton";
import {AddButton} from "@components/AddButton";

interface State extends LoaderState {
    house: House,
    accommodation_name: string,
    accommodation_description: string,
    residentialDoorSelectionBeans: Array<ResourceSelectionBean>,
    publicDoorSelectionBeans: Array<ResourceSelectionBean>,
}

export default class NewAccommodationScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_accommodation.title');
        this.state = {
            house: getHouseFromNavigationProps(this) || {},
            loading: false,
            accommodation_name: '',
            accommodation_description: '',
            residentialDoorSelectionBeans: [],
            publicDoorSelectionBeans: [],
        };

    }

    componentDidMount() {
        let self = this;

        new HostResourcesApi(this).apiHostResourcesByHouseIdGet({id: this.state.house.id}).then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} doors for host.`);
            let residentialDoorsList = ResourceSelection.fromResourceList(resp.data.filter(door => door.type == ResourceType.Residential));
            let publicDoorList = ResourceSelection.fromResourceList(resp.data.filter(door => door.type == ResourceType.Public));
            self.setState({
                residentialDoorSelectionBeans: residentialDoorsList,
                publicDoorSelectionBeans: publicDoorList,
                showNoData: true,
            });
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get doors list");
        });
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newAccommodationScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <View>
                    <AvenirMediumPrimaryHalfOpacityText style={{textAlign: 'center'}}>
                        in {this.state.house.name}
                    </AvenirMediumPrimaryHalfOpacityText>
                </View>
                <Loader state={this.state}/>
                <View
                    style={[newAccommodationScreenStyle.scrollContainer, globalScreenStyle.globalMargins]}>
                    <View style={{marginTop: 10,}}>
                        <TextInputBlock label={i18n.t('screens.new_accommodation.accommodation_name')}
                                        onChangeText={(text) => this.setState({accommodation_name: text})}
                                        text={this.state.accommodation_name}/>
                    </View>
                    <View
                        style={[newAccommodationScreenStyle.getStartedContainer, newAccommodationScreenStyle.scrollContainer]}>

                        <AddDoorBlock style={newAccommodationScreenStyle.doorSelection}
                                      doorsSelection={this.state.residentialDoorSelectionBeans}
                                      type={ResourceType.Residential}
                                      onPress={(key) => updateSelection(this, key, ResourceType.Residential)}
                                      showNoData={this.state.showNoData}/>

                        <AddDoorBlock style={[newAccommodationScreenStyle.doorSelection, {marginVertical: 10,}]}
                                      doorsSelection={this.state.publicDoorSelectionBeans}
                                      type={ResourceType.Public}
                                      onPress={(key) => updateSelection(this, key, ResourceType.Public)}
                                      showNoData={this.state.showNoData}/>

                    </View>
                </View>
                <View
                    style={[globalScreenStyle.globalMargins, newAccommodationScreenStyle.addButton]}>
                    <AddButton title={i18n.t('screens.new_accommodation.add_button')}
                               onPress={() => openNewDoor(this, this.state.house)}/>
                </View>
                <ConfirmButtonBottom active={dataComplete(this)} onPress={() => openNextPage(this)}
                                     title={i18n.t('buttons.new_accommodation')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Update door selection state
 * @param {React.Component<any, State>} context
 * @param key
 * @param {ResourceType} type
 */
function updateSelection(context: Component<any, State>, key, type: ResourceType) {
    switch (type) {
        case ResourceType.Residential:
            let residentialArray = context.state.residentialDoorSelectionBeans;
            residentialArray[key].selected = !residentialArray[key].selected;
            context.setState({residentialDoorSelectionBeans: residentialArray});
            break;
        case ResourceType.Public:
            let publicArray = context.state.publicDoorSelectionBeans;
            publicArray[key].selected = !publicArray[key].selected;
            context.setState({publicDoorSelectionBeans: publicArray});
            break;
    }
}

/**
 * Checks if house name is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function accommodationNameValid(context: Component<any, State>) {
    return context.state.accommodation_name != null && context.state.accommodation_name != '';
}

/**
 * Checks if at least one residential door has been selected
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function atLeastOneResidentialSelected(context: Component<any, State>) {
    let selected = ArrayHelper.filterResourceSelectionBean(context.state.residentialDoorSelectionBeans);
    return selected.length > 0;
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return accommodationNameValid(context) && atLeastOneResidentialSelected(context);
}

function debugUpdateData(context: Component<any, State>) {
    context.setState({
        house: HouseFactory.factory(),
        residentialDoorSelectionBeans: [ResourceSelection.fromResource(ResourceFactory.factory()), ResourceSelection.fromResource(ResourceFactory.factory()), ResourceSelection.fromResource(ResourceFactory.factory())],
        publicDoorSelectionBeans: [ResourceSelection.fromResource(ResourceFactory.factory()), ResourceSelection.fromResource(ResourceFactory.factory()), ResourceSelection.fromResource(ResourceFactory.factory())],
    });

}

/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    if (!accommodationNameValid(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.accommodation_data_incomplete'));
        return;
    }
    if (!atLeastOneResidentialSelected(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.at_least_one_residential'));
        return;
    }
    let residentialDoorIds = ArrayHelper.filterResourceSelectionBean(context.state.residentialDoorSelectionBeans).map((value) => value.resource.id);
    let publicDoorIds = ArrayHelper.filterResourceSelectionBean(context.state.publicDoorSelectionBeans).filter(value => value.selected).map((value) => value.resource.id);


    let accommodationCreateInput: AccommodationCreateInput = {
        name: context.state.accommodation_name,
        houseId: context.state.house.id,
        resourceIds: [].concat(residentialDoorIds, publicDoorIds),
    };
    new HostAccommodationsApi(context).apiHostAccommodationsPost({
        accommodationCreateInput: accommodationCreateInput
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp);
        goBack(context);
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    })
}