/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    House,
    HouseFromJSON,
    HouseFromJSONTyped,
    HouseToJSON,
    ResourceStateEnum,
    ResourceStateEnumFromJSON,
    ResourceStateEnumFromJSONTyped,
    ResourceStateEnumToJSON,
    ResourceType,
    ResourceTypeFromJSON,
    ResourceTypeFromJSONTyped,
    ResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostResource
 */
export interface HostResource {
    /**
     * 
     * @type {number}
     * @memberof HostResource
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HostResource
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HostResource
     */
    serial?: string;
    /**
     * 
     * @type {string}
     * @memberof HostResource
     */
    description?: string;
    /**
     * 
     * @type {ResourceType}
     * @memberof HostResource
     */
    type?: ResourceType;
    /**
     * 
     * @type {ResourceStateEnum}
     * @memberof HostResource
     */
    currentState?: ResourceStateEnum;
    /**
     * 
     * @type {string}
     * @memberof HostResource
     */
    currentPlan?: string;
    /**
     * 
     * @type {number}
     * @memberof HostResource
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof HostResource
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof HostResource
     */
    topic?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HostResource
     */
    hasBackup?: boolean;
    /**
     * 
     * @type {House}
     * @memberof HostResource
     */
    house?: House;
    /**
     * 
     * @type {boolean}
     * @memberof HostResource
     */
    online?: boolean;
    /**
     * 
     * @type {Array<Device>}
     * @memberof HostResource
     */
    devices?: Array<Device>;
    /**
     * 
     * @type {boolean}
     * @memberof HostResource
     */
    trialAlreadyClaimed?: boolean;
}

export function HostResourceFromJSON(json: any): HostResource {
    return HostResourceFromJSONTyped(json, false);
}

export function HostResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostResource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : ResourceTypeFromJSON(json['type']),
        'currentState': !exists(json, 'current_state') ? undefined : ResourceStateEnumFromJSON(json['current_state']),
        'currentPlan': !exists(json, 'current_plan') ? undefined : json['current_plan'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'hasBackup': !exists(json, 'has_backup') ? undefined : json['has_backup'],
        'house': !exists(json, 'house') ? undefined : HouseFromJSON(json['house']),
        'online': !exists(json, 'online') ? undefined : json['online'],
        'devices': !exists(json, 'devices') ? undefined : ((json['devices'] as Array<any>).map(DeviceFromJSON)),
        'trialAlreadyClaimed': !exists(json, 'trial_already_claimed') ? undefined : json['trial_already_claimed'],
    };
}

export function HostResourceToJSON(value?: HostResource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'serial': value.serial,
        'description': value.description,
        'type': ResourceTypeToJSON(value.type),
        'current_state': ResourceStateEnumToJSON(value.currentState),
        'current_plan': value.currentPlan,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'topic': value.topic,
        'has_backup': value.hasBackup,
        'house': HouseToJSON(value.house),
        'online': value.online,
        'devices': value.devices === undefined ? undefined : ((value.devices as Array<any>).map(DeviceToJSON)),
        'trial_already_claimed': value.trialAlreadyClaimed,
    };
}

