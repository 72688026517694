/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FilterImageButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import ButtonStyle from "@styles/ButtonStyle";
import {CheckImage} from "@components/Images/CheckImage";
import FilterImageButtonProps from "./properties/FilterImageButtonProps";

export function FilterImageButton(props: FilterImageButtonProps) {
    let uri = props.selected ? props.selectedImageUri : props.notSelectedImageUri;
    return (
        <View style={props.style}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[ButtonStyle.filterImageContainer, props.selected ? ButtonStyle.filterImageContainerBackround : null]}>
                <Image style={ButtonStyle.filterImage} source={
                    uri
                }/>
                {props.selected &&
                <CheckImage style={ButtonStyle.filterCheck}/>
                }
            </TouchableOpacity>
        </View>
    );
}