/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FirstNewDoorScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 3:10 PM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../i18n/i18n";
import newDoorScreenStyle from "@styles/host/doors/NewDoorScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {
    goBack,
    NavigationProps,
    openDashboardHouses,
    openDoorBarcodeScanner, openFirstDoorBarcodeScanner, openFirstNewAccommodationScreen,
    openYourHouses
} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {getHouseFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {HostResourcesApi, UsersApi} from "@services/src/apis";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {Device, DeviceType, HostResourceInput, House, ResourceType, ResponseStatus} from "@services/src/models";
import {AvenirBookUpperLabelText, AvenirMediumPrimaryHalfOpacityText} from "@components/StyledText";
import {PublicSelectionBlock, ResidentialSelectionBlock} from "@components/ResourceSelectionBlock";
import {HouseFactory} from "../../models/factory/HouseFactory";
import {TextAreaBlock} from "@components/TextAreaBlock";
import {GearButton} from "@components/ImageButton";
import {ReadonlyTextBlock} from "@components/ReadonlyTextBlock";
import {DeviceFactory} from "../../models/factory/DeviceFactory";
import {DebugButton} from "@components/DebugButton";
import {SwitchBlock} from "@components/SwitchBlock";
import {RoundedButton} from "@components/RoundedButton";
import {PlatformHelper} from "@helpers/PlatformHelper";
import {BfreeX1} from "@helpers/BfreeX1";


interface State extends LoaderState {
    house: House,
    door_name: string,
    door_serial: string,
    door_description: string,
    selectedType: ResourceType,
    advanced_settings_enabled: boolean,
    aux_unlock_text_enabled: boolean,
    device: Device,
}

export default class FirstNewDoorScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_door.title');
        this.state = {
            house: getHouseFromNavigationProps(this) || {},
            loading: false,
            door_name: '',
            door_serial: '',
            door_description: '',
            selectedType: ResourceType.Residential,
            advanced_settings_enabled: false,
            aux_unlock_text_enabled: false,
            device: {
                type: DeviceType.Standard,
                installedOn: new Date(),
            },
        };

    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newDoorScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <View>
                    <AvenirMediumPrimaryHalfOpacityText style={{textAlign: 'center'}}>
                        {this.state.house.name}
                    </AvenirMediumPrimaryHalfOpacityText>
                </View>
                <Loader state={this.state}/>
                <ScrollView
                    style={[newDoorScreenStyle.scrollContainer, globalScreenStyle.globalMargins]}
                    contentContainerStyle={newDoorScreenStyle.contentContainer}>
                    <View
                        style={[newDoorScreenStyle.getStartedContainer, newDoorScreenStyle.scrollContainer]}>
                        <View
                            style={newDoorScreenStyle.inputContainer}>

                            <AvenirBookUpperLabelText>
                                {i18n.t('screens.new_door.type')}:
                            </AvenirBookUpperLabelText>
                            <View style={newDoorScreenStyle.serviceSelectionContainer}>
                                <ResidentialSelectionBlock
                                    onPress={() => this.setState({selectedType: ResourceType.Residential})}
                                    style={[newDoorScreenStyle.serviceBlock, newDoorScreenStyle.residentialBlock]}
                                    active={residentialSelected(this)}/>
                                <PublicSelectionBlock onPress={() => this.setState({selectedType: ResourceType.Public})}
                                                      style={[newDoorScreenStyle.serviceBlock, newDoorScreenStyle.publicBlock]}
                                                      active={publicSelected(this)}/>
                            </View>

                            <TextInputBlock label={i18n.t('screens.new_door.door_name')}
                                            onChangeText={(text) => this.setState({door_name: text})}
                                            text={this.state.door_name}/>
                            <TextAreaBlock label={i18n.t('screens.new_door.door_description')} placeholder=' '
                                           onChangeText={(text) => this.setState({door_description: text})}
                                           text={this.state.door_description}/>

                            {/* TODO: Add Credit plan */}
                            {!PlatformHelper.isWeb() &&
                            <RoundedButton title={i18n.t('screens.new_door.scan_imei')} style={{marginBottom: 10,}} isQr={true}
                                           onPress={() => openFirstDoorBarcodeScanner(this, {}, (type, data, enc_code) => (this.state.device.imei = data) && (this.state.device.serial = enc_code) && this.setState({
                                               device: this.state.device,
                                               door_serial: enc_code,
                                           }))}/>
                            }
                            <TextInputBlock label={i18n.t('screens.new_door.device_id')}
                                            onChangeText={(text) => this.setState({door_serial: text})}
                                            text={this.state.door_serial}
                                            editable={false}/>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flex: 0.5}}/>
                                <GearButton active={this.state.advanced_settings_enabled}
                                            onPress={() => toggleAdvancedSettings(this)}
                                            title={i18n.t('screens.new_door.advanced_settings')} style={{flex: 1}}/>
                            </View>
                            {this.state.advanced_settings_enabled &&
                            <View style={{marginTop: 30,}}>
                                <TextInputBlock label={i18n.t('screens.new_door.door_serial')}
                                                onChangeText={(text) => (this.state.device.serial = text) && this.setState({device: this.state.device})}
                                                text={this.state.device.serial}/>
                                <TextInputBlock label={i18n.t('screens.new_door.device_imei')}
                                                editable={true}
                                                onChangeText={(text) => (this.state.device.imei = text) && this.setState({device: this.state.device})}
                                                text={this.state.device.imei}/>

                                <TextInputBlock label={i18n.t('screens.new_door.device_part_number')}
                                                onChangeText={(text) => (this.state.device.partNumber = text) && this.setState({device: this.state.device})}
                                                text={this.state.device.partNumber}/>
                                <SwitchBlock label={i18n.t('screens.new_door.device_aux_unlock_text_enabled')}
                                             value={this.state.aux_unlock_text_enabled}
                                             onValueChange={(bool) => this.setState({aux_unlock_text_enabled: bool})}/>
                                {this.state.aux_unlock_text_enabled &&
                                <TextInputBlock label={i18n.t('screens.new_door.device_aux_unlock_text')}
                                                onChangeText={(text) => (this.state.device.auxUnlockText = text) && this.setState({device: this.state.device})}
                                                text={this.state.device.auxUnlockText}/>}
                                {this.state.aux_unlock_text_enabled &&
                                <TextInputBlock label={i18n.t('screens.new_door.device_aux_unlock_time')}
                                                onChangeText={(text) => (this.state.device.auxUnlockTime = text) && this.setState({device: this.state.device})}
                                                text={this.state.device.auxUnlockTime}/>}
                                <ReadonlyTextBlock label={i18n.t('screens.new_door.device_firmware_version')}
                                                   onChangeText={(text) => (this.state.device.firmwareVersion = text) && this.setState({device: this.state.device})}
                                                   text={this.state.device.firmwareVersion}/>
                                <ReadonlyTextBlock label={i18n.t('screens.new_door.device_time_server')}
                                                   onChangeText={(text) => (this.state.device.timeServer = text) && this.setState({device: this.state.device})}
                                                   text={this.state.device.timeServer}/>
                                <ReadonlyTextBlock label={i18n.t('screens.new_door.device_time_zone')}
                                                   onChangeText={(text) => (this.state.device.timeZone = text) && this.setState({device: this.state.device})}
                                                   text={this.state.device.timeZone}/>
                                <ReadonlyTextBlock label={i18n.t('screens.new_door.device_unlock_time')}
                                                   onChangeText={(text) => (this.state.device.unlockTime = text) && this.setState({device: this.state.device})}
                                                   text={this.state.device.unlockTime}/>
                                <ReadonlyTextBlock label={i18n.t('screens.new_door.device_alert_text')}
                                                   onChangeText={(text) => (this.state.device.alertText = text) && this.setState({device: this.state.device})}
                                                   text={this.state.device.alertText}/>
                            </View>
                            }
                        </View>
                    </View>
                </ScrollView>
                <ConfirmButtonBottom active={dataComplete(this)} onPress={() => openNextPage(this)}
                                     title={i18n.t('buttons.new_door')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Updates device in state
 * @param {React.Component<any, State>} context
 * @param {Device} device
 */
function updateDevice(context: Component<any, State>, device: Device) {
    context.setState({device: device});
}

/**
 * Toggle advanced settings
 * @param {React.Component<any, State>} context
 */
function toggleAdvancedSettings(context: Component<any, State>) {
    context.setState({advanced_settings_enabled: !context.state.advanced_settings_enabled});
}

/**
 * Checks if residential type is selected
 * @param {React.Component<any, State>} context
 */
function residentialSelected(context: Component<any, State>): boolean {
    return context.state.selectedType == ResourceType.Residential;
}

/**
 * Checks if public type is selected
 * @param {React.Component<any, State>} context
 */
function publicSelected(context: Component<any, State>): boolean {
    return context.state.selectedType == ResourceType.Public;
}

/**
 * Checks if house name is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function doorNameValid(context: Component<any, State>) {
    return context.state.door_name != null && context.state.door_name != '';
}

/**
 * Checks if house serial is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function doorSerialValid(context: Component<any, State>) {
    return context.state.door_serial != null && context.state.door_serial != '';
}

/**
 * Checks if aux unlock is disabled or it is enabled and
 * aux unlock text is set
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function doorAuxOk(context: Component<any, State>) {
    return !context.state.aux_unlock_text_enabled || (context.state.device.auxUnlockText != null && context.state.device.auxUnlockText != '');
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return doorSerialValid(context) && doorNameValid(context) && doorAuxOk(context);
}

function debugUpdateData(context: Component<any, State>) {
    let fakeDevice = DeviceFactory.factory();
    context.setState({
        house: HouseFactory.factory(),
        device: fakeDevice,
    });

}

/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.door_data_incomplete'));
        return;
    }
    console.log(context.state.house);
    let resource: HostResourceInput = {
        name: context.state.door_name,
        serial: context.state.door_serial,
        description: context.state.door_description,
        type: context.state.selectedType,
        houseId: context.state.house.id,
        device: context.state.device,
    };
    if (context.state.device != null && context.state.device.imei != null && context.state.device.imei.trim() != '') {
        resource.device = context.state.device;
    }

    new HostResourcesApi(context).apiHostResourcesPost({
        hostResourceInput: resource
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp.data);
        if (resp.status != ResponseStatus.Ok)
                AlertHelper.showSimpleErrorAlert();
        openFirstNewAccommodationScreen(context, context.state.house);

    }).catch(async error => {

        var body = await error.json();
        console.log(body);
        Logger.warn(context.constructor.name, body);
        AlertHelper.showErrorAlertWithServerMessage(body.error);
    });
}