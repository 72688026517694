/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ImageButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 10:26 AM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, Image, ImageProps, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import imageButtonStyle from "@styles/ImageButtonStyle";
import {AvenirLightText} from "./StyledText";
import {imageUri} from "../models/ImageUri";

interface ImageButtonProps {
    active: boolean,
    style: StyleProp<ViewStyle>,
    onPress: (event: GestureResponderEvent) => void,
    title: string,
}

interface SpecificImageButtonProps extends ImageButtonProps {
    image: ImageProps,
}

export function GearButton(props: ImageButtonProps) {
    if (props.active)
        return (
            <ImageButton active={props.active} style={props.style} onPress={props.onPress} title={props.title}
                         image={imageUri.gear.uri}/>
        );
    else
        return (
            <ImageButton active={props.active} style={props.style} onPress={props.onPress} title={props.title}
                         image={imageUri.gear_color.uri}/>
        );
}

/**
 * A component with an image and a text.
 * It can be active or not, changing the style according to this
 */
export class ImageButton extends Component<SpecificImageButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        let source = this.props.image;
        return (
            <View style={this.props.style}>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[imageButtonStyle.enabledButton, (this.props.active ? imageButtonStyle.backgroundEnabled : imageButtonStyle.backgroundDisabled)]}>
                    <Image
                        style={{width: 15, height: 15, marginHorizontal: 10, resizeMode: 'contain'}}
                        source={
                            source
                        }
                    />
                    <AvenirLightText
                        style={[imageButtonStyle.text, (this.props.active ? imageButtonStyle.textActive : imageButtonStyle.textInactive)]}>{this.props.title}</AvenirLightText>
                </TouchableOpacity>
            </View>
        );
    }
}