/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DashboardInfoBlockStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import Colors from "../../config/Colors";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
    },
    bigContainer: {
        flex: 1,
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    bigNumber: {
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: 30,
    },
    leftText: {
        textAlign: 'center',
        alignSelf: 'center',
    },
    rightNumber: {
        textAlign: 'right',
        flex: 1,
        marginLeft: 5,
        marginRight: 5,
    },
    rightText: {
        flex: 7,
    },
    detailArrowContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    detailArrow: {
        alignSelf: 'center',
        width: 8,
        height: 8,
    },
    rightContainer: {
        flex: 2,
    },
    flex: {
        flex: 1,
    },
    textContainer: {
        flex: 1,
        margin: 1.5,
        padding: 8,
        flexDirection: 'row',
        backgroundColor: Colors.background,
    },
});

export default styles;