/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorSelectionRow.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React from 'react';
import {GestureResponderEvent, TouchableOpacity, View} from 'react-native';
import {AvenirMediumPrimaryText} from "../StyledText";
import {Resource, ResourceStateEnum} from "@services/src/models";
import {StatusActivationDotView, StatusConnectionDotView} from "../StatusDotView";
import doorDetailStyle from "@styles/host/rows/DoorSelectionRowStyle";
import {CheckImage} from "@components/Images/CheckImage";
import {CheckEmptyImage} from "@components/Images/CheckEmptyImage";

export interface DoorSelectionRowProps {
    door: Resource,
    online: boolean,
    activationStatus: ResourceStateEnum,
    selected: boolean,
    onPress: (event: GestureResponderEvent) => void,
}

export function DoorSelectionRow(props: DoorSelectionRowProps) {
    return (
        <TouchableOpacity style={doorDetailStyle.serviceSelectionContainer} onPress={props.onPress}>
            <View
                style={[doorDetailStyle.bigContainer, doorDetailStyle.textContainer, {flexDirection: 'row',}]}>
                <View style={{
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                    marginRight: 5,
                }}>
                    {props.selected &&
                    <CheckImage style={[doorDetailStyle.doorImage,]}/>
                    }
                    {!props.selected &&
                    <CheckEmptyImage style={[doorDetailStyle.doorImage,]}/>
                    }
                </View>
                <View style={{flex: 7,}}>
                    <View style={{
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center'
                    }}>
                        <AvenirMediumPrimaryText numberOfLines={1}
                                                 style={{
                                                     flex: 1,
                                                     textAlign: "left",
                                                     marginHorizontal: 10,
                                                 }}>
                            {props.door.name}
                        </AvenirMediumPrimaryText>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'center',
                        marginTop: 5,
                    }}>
                        <StatusConnectionDotView enabled={props.online} style={doorDetailStyle.flex}/>
                        <StatusActivationDotView status={props.activationStatus}
                                                 style={doorDetailStyle.flex}/>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
}