/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeyValueDto
 */
export interface KeyValueDto {
    /**
     * 
     * @type {number}
     * @memberof KeyValueDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof KeyValueDto
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof KeyValueDto
     */
    value?: string;
}

export function KeyValueDtoFromJSON(json: any): KeyValueDto {
    return KeyValueDtoFromJSONTyped(json, false);
}

export function KeyValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function KeyValueDtoToJSON(value?: KeyValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'value': value.value,
    };
}

