/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       App.js
 * @author     Christian Ascone
 * @date       3/2/20 5:56 PM
 */

import AppLoading from 'expo-app-loading';
import {Asset} from 'expo-asset';
import * as Font from 'expo-font';
import React, {Component} from 'react'
import {AppState, Platform, StatusBar, StyleSheet, View} from 'react-native';
import {Ionicons} from '@expo/vector-icons';
import * as StorageHelper from './app/helpers/StorageHelper';
import {BaseAPI} from "./app/services/src/runtime";
import {Logger} from "./app/helpers/Logger";

import MainNavigator from './app/navigation/main/MainNavigator';
import {sentrySetup} from "./app/config/SentryConfig";
import {PlatformHelper} from "./app/helpers/PlatformHelper";
import NeedToUpdateNavigator from "./app/navigation/update/NeedToUpdateNavigator.js";
import {AppApi} from "./app/services/src/apis/AppApi";
import app from "./package.json"
import MaintenanceScreen from "@screens/MaintenanceScreen";

export default class App extends Component {
    state = {
        isLoadingComplete: false,
        loading: false,
        version: null,
        apiVersion: null,
        appState: AppState.currentState,
        isVersionAvailable: false,
    };
    props = {
        skipLoadingScreen: false
    };

    constructor(props) {
        super(props);
        this.props = props;
        Logger.log(this.constructor.name, props);
        Logger.log(this.constructor.name, "Platform: " + Platform.OS);

        this.setup();
    }

    componentDidMount() {
        AppState.addEventListener('change', nextState =>{
            this.checkVersion();
            this.setState({appState: nextState});
        });
        console.log('Component Mounted');
        this.checkVersion();

    }

    checkVersion(){
        console.log('Starting call');
        new AppApi(this).apiAppVersionGet().then(resp => {
            console.log("Version: ", resp);
            let version = app.version;
            let split_version = version.split('.');
            version = split_version[1];
            let apiVersion = resp['front'];
            split_version = apiVersion.split('.');
            apiVersion = split_version[1];
            console.log(apiVersion);
            this.setState({
                version: version,
                apiVersion: apiVersion,
                isVersionAvailable: true,
            })
        }).catch(e => {
            console.log("THERE WAS AN ERROR");
            console.log(e.toString());
            this.setState({
                isVersionAvailable: true,
            })
        });
    }

    async setup() {
        if (!PlatformHelper.isWeb()) {
            sentrySetup();
        }
        let token = await StorageHelper.readAccessToken();
        if(token != null)
            BaseAPI.setToken(token);
        await Asset.loadAsync([
            require('./app/assets/images/add.png'),
            require('./app/assets/images/avatar_placeholder.png'),
            require('./app/assets/images/bfree_recap.png'),
            require('./app/assets/images/check.png'),
            require('./app/assets/images/check_empty.png'),
            require('./app/assets/images/close.png'),
            require('./app/assets/images/close_color.png'),
            require('./app/assets/images/collaborator_icon.png'),
            require('./app/assets/images/collaborator_icon_color.png'),
            require('./app/assets/images/delete.png'),
            require('./app/assets/images/detail_arrow.png'),
            require('./app/assets/images/detail_arrow_color.png'),
            require('./app/assets/images/device.png'),
            require('./app/assets/images/gear.png'),
            require('./app/assets/images/gear_color.png'),
            require('./app/assets/images/guest_icon.png'),
            require('./app/assets/images/guest_icon_color.png'),
            require('./app/assets/images/home.png'),
            require('./app/assets/images/house_success_left.png'),
            require('./app/assets/images/house_success_right.png'),
            require('./app/assets/images/left_arrow.png'),
            require('./app/assets/images/list_color.png'),
            require('./app/assets/images/Logo-B-Free_0001_Livello-1-copia.png'),
            require('./app/assets/images/Logo-B-Free_0002_Livello-1.png'),
            require('./app/assets/images/Logo-icon-credit.png'),
            require('./app/assets/images/Logo-icon-credit_no_border.png'),
            require('./app/assets/images/mail_verification.png'),
            require('./app/assets/images/menu.png'),
            require('./app/assets/images/period.png'),
            require('./app/assets/images/phone_verification.png'),
            require('./app/assets/images/plus.png'),
            require('./app/assets/images/plus_color.png'),
            require('./app/assets/images/plus_white.png'),
            require('./app/assets/images/public.png'),
            require('./app/assets/images/public_color.png'),
            require('./app/assets/images/public_color_add.png'),
            require('./app/assets/images/reservation_guest.png'),
            require('./app/assets/images/reservation_title_image.png'),
            require('./app/assets/images/residential.png'),
            require('./app/assets/images/residential_color.png'),
            require('./app/assets/images/residential_color_add.png'),
            require('./app/assets/images/right_arrow_color.png'),
            require('./app/assets/images/robot-dev.png'),
            require('./app/assets/images/robot-prod.png'),
            require('./app/assets/images/settings.png'),
            require('./app/assets/images/switch_off.png'),
            require('./app/assets/images/switch_on.png'),
            require('./app/assets/images/triangle_selected.png'),
            require('./app/assets/images/user_not_found.png'),
        ]);
        await Font.loadAsync({
            // This is the font that we are using for our tab bar
            ...Ionicons.font,
            // We include SpaceMono because we use it in DashboardReservationsScreen.js. Feel free to
            // remove this if you are not using it in your app
        });
        if (!PlatformHelper.isWeb()) {
            await Font.loadAsync({
                // This is the font that we are using for our tab bar
                ...Ionicons.font,
                // We include SpaceMono because we use it in DashboardReservationsScreen.js. Feel free to
                // remove this if you are not using it in your app
                'space-mono': require('./app/assets/fonts/SpaceMono-Regular.ttf'),
                'avenir-book': require('./app/assets/fonts/AvenirLTStd-Book.otf'),
                'avenir-heavy': require('./app/assets/fonts/AvenirLTStd-Heavy.otf'),
                'avenir-light': require('./app/assets/fonts/AvenirLTStd-Light.otf'),
                'avenir-medium': require('./app/assets/fonts/AvenirLTStd-Medium.otf'),
            });
        }

        this.setState({isLoadingComplete: true});
    }

    render() {
        if (!this.state.isLoadingComplete || this.props.skipLoadingScreen || !this.state.isVersionAvailable) {
            return (
                <AppLoading
                />
            );
        } else {
            if (this.state.version && this.state.apiVersion)
                if (this.state.version >= this.state.apiVersion || PlatformHelper.isWeb()){
                    return (
                        <View style={styles.container}>
                            {PlatformHelper.isIos() && <StatusBar barStyle="default"/>}
                            <MainNavigator/>
                        </View>
                    );
                } else {
                    return (
                        <View style={styles.container}>
                        {PlatformHelper.isIos() && <StatusBar barStyle="default"/>}
                        <NeedToUpdateNavigator/>
                    </View>
                    );
                }
            else
                return (
                    <MaintenanceScreen/>
                );
        }
    }
}

const
    styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: '#fff',
        },
    });
