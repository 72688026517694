/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GuestType,
    GuestTypeFromJSON,
    GuestTypeFromJSONTyped,
    GuestTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CollaborationFilterInput
 */
export interface CollaborationFilterInput {
    /**
     * 
     * @type {Array<number>}
     * @memberof CollaborationFilterInput
     */
    houseIds?: Array<number>;
    /**
     * 
     * @type {GuestType}
     * @memberof CollaborationFilterInput
     */
    registryType?: GuestType;
    /**
     * 
     * @type {Date}
     * @memberof CollaborationFilterInput
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CollaborationFilterInput
     */
    toDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CollaborationFilterInput
     */
    registryEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollaborationFilterInput
     */
    collaborationStateOngoing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CollaborationFilterInput
     */
    collaborationStatePending?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CollaborationFilterInput
     */
    collaborationStateConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CollaborationFilterInput
     */
    collaborationStateCanceled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CollaborationFilterInput
     */
    collaborationStateExpired?: boolean;
}

export function CollaborationFilterInputFromJSON(json: any): CollaborationFilterInput {
    return CollaborationFilterInputFromJSONTyped(json, false);
}

export function CollaborationFilterInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollaborationFilterInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'houseIds': !exists(json, 'house_ids') ? undefined : json['house_ids'],
        'registryType': !exists(json, 'registry_type') ? undefined : GuestTypeFromJSON(json['registry_type']),
        'fromDate': !exists(json, 'from_date') ? undefined : (new Date(json['from_date'])),
        'toDate': !exists(json, 'to_date') ? undefined : (new Date(json['to_date'])),
        'registryEmail': !exists(json, 'registry_email') ? undefined : json['registry_email'],
        'collaborationStateOngoing': !exists(json, 'collaboration_state_ongoing') ? undefined : json['collaboration_state_ongoing'],
        'collaborationStatePending': !exists(json, 'collaboration_state_pending') ? undefined : json['collaboration_state_pending'],
        'collaborationStateConfirmed': !exists(json, 'collaboration_state_confirmed') ? undefined : json['collaboration_state_confirmed'],
        'collaborationStateCanceled': !exists(json, 'collaboration_state_canceled') ? undefined : json['collaboration_state_canceled'],
        'collaborationStateExpired': !exists(json, 'collaboration_state_expired') ? undefined : json['collaboration_state_expired'],
    };
}

export function CollaborationFilterInputToJSON(value?: CollaborationFilterInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'house_ids': value.houseIds,
        'registry_type': GuestTypeToJSON(value.registryType),
        'from_date': value.fromDate === undefined ? undefined : (value.fromDate.toISOString().substr(0,10)),
        'to_date': value.toDate === undefined ? undefined : (value.toDate.toISOString().substr(0,10)),
        'registry_email': value.registryEmail,
        'collaboration_state_ongoing': value.collaborationStateOngoing,
        'collaboration_state_pending': value.collaborationStatePending,
        'collaboration_state_confirmed': value.collaborationStateConfirmed,
        'collaboration_state_canceled': value.collaborationStateCanceled,
        'collaboration_state_expired': value.collaborationStateExpired,
    };
}

