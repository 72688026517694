/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Collaboration,
    CollaborationFromJSON,
    CollaborationToJSON,
    CollaborationFilterInput,
    CollaborationFilterInputFromJSON,
    CollaborationFilterInputToJSON,
    CollaborationStateEnum,
    CollaborationStateEnumFromJSON,
    CollaborationStateEnumToJSON,
    HostCollaborationDetail,
    HostCollaborationDetailFromJSON,
    HostCollaborationDetailToJSON,
    HostCollaborationInput,
    HostCollaborationInputFromJSON,
    HostCollaborationInputToJSON,
    HostCollaborationList,
    HostCollaborationListFromJSON,
    HostCollaborationListToJSON,
} from '../models';

export interface ApiHostCollaborationsByFiltersPostRequest {
    collaborationFilterInput: CollaborationFilterInput;
}

export interface ApiHostCollaborationsIdCodesResendPostRequest {
    id: number;
}

export interface ApiHostCollaborationsIdDeleteRequest {
    id: number;
}

export interface ApiHostCollaborationsIdDevicecodesResendPostRequest {
    id: number;
}

export interface ApiHostCollaborationsIdGetRequest {
    id: string;
}

export interface ApiHostCollaborationsIdStatePutRequest {
    id: number;
    state: CollaborationStateEnum;
}

export interface ApiHostCollaborationsPostRequest {
    hostCollaborationInput: HostCollaborationInput;
}

export interface ApiHostCollaborationsUpdatePostRequest {
    hostCollaborationDetail: HostCollaborationDetail;
}

/**
 * 
 */
export class HostCollaborationsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostCollaborationsByFiltersPostRaw(requestParameters: ApiHostCollaborationsByFiltersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostCollaborationList>> {
        if (requestParameters.collaborationFilterInput === null || requestParameters.collaborationFilterInput === undefined) {
            throw new runtime.RequiredError('collaborationFilterInput','Required parameter requestParameters.collaborationFilterInput was null or undefined when calling apiHostCollaborationsByFiltersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations/byFilters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollaborationFilterInputToJSON(requestParameters.collaborationFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostCollaborationListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCollaborationsByFiltersPost(requestParameters: ApiHostCollaborationsByFiltersPostRequest, initOverrides?: RequestInit): Promise<HostCollaborationList> {
        const response = await this.apiHostCollaborationsByFiltersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCollaborationsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostCollaborationList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostCollaborationListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCollaborationsGet(initOverrides?: RequestInit): Promise<HostCollaborationList> {
        const response = await this.apiHostCollaborationsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCollaborationsIdCodesResendPostRaw(requestParameters: ApiHostCollaborationsIdCodesResendPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Collaboration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostCollaborationsIdCodesResendPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations/{id}/codes/resend`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollaborationFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCollaborationsIdCodesResendPost(requestParameters: ApiHostCollaborationsIdCodesResendPostRequest, initOverrides?: RequestInit): Promise<Collaboration> {
        const response = await this.apiHostCollaborationsIdCodesResendPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCollaborationsIdDeleteRaw(requestParameters: ApiHostCollaborationsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostCollaborationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostCollaborationsIdDelete(requestParameters: ApiHostCollaborationsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostCollaborationsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostCollaborationsIdDevicecodesResendPostRaw(requestParameters: ApiHostCollaborationsIdDevicecodesResendPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostCollaborationsIdDevicecodesResendPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations/{id}/devicecodes/resend`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiHostCollaborationsIdDevicecodesResendPost(requestParameters: ApiHostCollaborationsIdDevicecodesResendPostRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.apiHostCollaborationsIdDevicecodesResendPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCollaborationsIdGetRaw(requestParameters: ApiHostCollaborationsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostCollaborationDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostCollaborationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostCollaborationDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCollaborationsIdGet(requestParameters: ApiHostCollaborationsIdGetRequest, initOverrides?: RequestInit): Promise<HostCollaborationDetail> {
        const response = await this.apiHostCollaborationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCollaborationsIdStatePutRaw(requestParameters: ApiHostCollaborationsIdStatePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Collaboration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostCollaborationsIdStatePut.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling apiHostCollaborationsIdStatePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations/{id}/{state}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"state"}}`, encodeURIComponent(String(requestParameters.state))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollaborationFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCollaborationsIdStatePut(requestParameters: ApiHostCollaborationsIdStatePutRequest, initOverrides?: RequestInit): Promise<Collaboration> {
        const response = await this.apiHostCollaborationsIdStatePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCollaborationsPostRaw(requestParameters: ApiHostCollaborationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hostCollaborationInput === null || requestParameters.hostCollaborationInput === undefined) {
            throw new runtime.RequiredError('hostCollaborationInput','Required parameter requestParameters.hostCollaborationInput was null or undefined when calling apiHostCollaborationsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostCollaborationInputToJSON(requestParameters.hostCollaborationInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostCollaborationsPost(requestParameters: ApiHostCollaborationsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostCollaborationsPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostCollaborationsUpdatePostRaw(requestParameters: ApiHostCollaborationsUpdatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.hostCollaborationDetail === null || requestParameters.hostCollaborationDetail === undefined) {
            throw new runtime.RequiredError('hostCollaborationDetail','Required parameter requestParameters.hostCollaborationDetail was null or undefined when calling apiHostCollaborationsUpdatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborations/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostCollaborationDetailToJSON(requestParameters.hostCollaborationDetail),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostCollaborationsUpdatePost(requestParameters: ApiHostCollaborationsUpdatePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostCollaborationsUpdatePostRaw(requestParameters, initOverrides);
    }

}
