/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewReservationDoneScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import newReservationDoneScreenStyle from "@styles/host/reservations/NewReservationDoneScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openDashboardReservations} from "@helpers/NavigationHelper";
import {ConfirmButton} from "@components/ConfirmButton";
import {
    getReservationBeanFromNavigationProps,
    getUserFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {HouseDoneBackgroundLeftImage} from "@components/Images/HouseDoneBackgroundLeftImage";
import {HouseDoneBackgroundRightImage} from "@components/Images/HouseDoneBackgroundRightImage";
import {AvenirHeavyPrimaryText, AvenirLightGreyText} from "@components/StyledText";
import {ReservationBean} from "../../../models/beans/ReservationBean";
import {DebugButton} from "@components/DebugButton";
import {AccommodationSelection} from "../../../models/beans/AccommodationSelection";
import {HouseCompleteFactory} from "../../../models/factory/HouseCompleteFactory";
import {HouseForAccommodationsSelection} from "../../../models/interfaces/HouseForAccommodationsSelectionBean";
import {AccommodationRecapBlock, PeriodRecapBlock, UserDataRecapBlock} from "@components/RecapContainerBlock";
import {GuestFactory} from "../../../models/factory/GuestFactory";
import {HostReservationsApi} from "@services/src/apis/index";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {HostReservationInput} from "@services/src/models/HostReservationInput";

interface State extends LoaderState {
    reservationBean: ReservationBean,
}

export default class NewReservationDoneScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_reservation_done.title');

        let reservationBean = getReservationBeanFromNavigationProps(this) || new ReservationBean();
        if (!reservationBean.houses) reservationBean.houses = [];

        this.state = {
            loading: false,
            reservationBean: reservationBean,
        };

    }


    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newReservationDoneScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View
                    style={[newReservationDoneScreenStyle.container, globalScreenStyle.globalMargins]}
                >
                    <View
                        style={[newReservationDoneScreenStyle.getStartedContainer, newReservationDoneScreenStyle.container]}>
                        <View style={newReservationDoneScreenStyle.welcomeContainer}>
                            <View style={{
                                flex: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}>
                                <HouseDoneBackgroundLeftImage style={{width: 90, height: 90, alignSelf: 'flex-end'}}/>
                            </View>
                            <View style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}>
                                <AvenirHeavyPrimaryText style={newReservationDoneScreenStyle.title}>
                                    {i18n.t('screens.new_reservation_done.its_done_1')}
                                </AvenirHeavyPrimaryText>
                                <AvenirHeavyPrimaryText style={newReservationDoneScreenStyle.title}>
                                    {i18n.t('screens.new_reservation_done.its_done_2')}
                                </AvenirHeavyPrimaryText>
                            </View>
                            <View style={{
                                flex: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}>
                                <HouseDoneBackgroundRightImage
                                    style={{width: 90, height: 90, alignSelf: 'flex-start'}}/>
                            </View>
                        </View>
                        <View style={newReservationDoneScreenStyle.installationContainer}>
                            <AvenirLightGreyText style={newReservationDoneScreenStyle.subtitle}>
                                {i18n.t('screens.new_reservation_done.recap')}
                            </AvenirLightGreyText>
                            <ScrollView
                                style={[newReservationDoneScreenStyle.container, newReservationDoneScreenStyle.scroll]}
                                contentContainerStyle={newReservationDoneScreenStyle.contentContainer}>
                                <View
                                    style={[{marginHorizontal: 10,}]}>
                                    <UserDataRecapBlock guest={this.state.reservationBean.guest}
                                                        style={{marginVertical: 5,}}/>
                                    <AccommodationRecapBlock houses={this.state.reservationBean.houses}
                                                             style={{marginVertical: 5,}}/>
                                    <PeriodRecapBlock from_date={this.state.reservationBean.from_date}
                                                      from_time={this.state.reservationBean.from_time}
                                                      to_date={this.state.reservationBean.to_date}
                                                      to_time={this.state.reservationBean.to_time}
                                                      style={{marginVertical: 5,}}/>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                    <ConfirmButton active={true} onPress={() => openNextPage(this)}
                                   title={i18n.t('buttons.new_reservation_done')}
                                   style={{marginBottom: 20}}/>
                </View>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let bean = context.state.reservationBean;
    bean.guest = GuestFactory.factory();
    let houses = HouseCompleteFactory.factoryArray(5);
    let housesWithSelectionBeans = HouseForAccommodationsSelection.fromHouseList(houses.filter(house => house.accommodationsCount > 0));
    housesWithSelectionBeans.forEach(house => {
        house.accommodationsSelectionBeans = AccommodationSelection.fromAccommodationList(house.accommodations);
    });
    housesWithSelectionBeans[0].accommodationsSelectionBeans.forEach(bean => bean.selected = true);
    bean.houses = housesWithSelectionBeans;
    context.setState({reservationBean: bean});
}

/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    let reservationBean = context.state.reservationBean;

    let input: HostReservationInput = {
        fromDate: reservationBean.from_date,
        fromTime: reservationBean.from_time,
        toDate: reservationBean.to_date,
        toTime: reservationBean.to_time,
        guestId: reservationBean.guest.id,
        houses: reservationBean.housesToReservationInput(),
    };
    new HostReservationsApi(context).apiHostReservationsPost({hostReservationInput: input}).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        openDashboardReservations(context, getUserFromNavigationProps(context));
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    });
}