/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationRow.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {
    AvenirBookText,
    AvenirHeavyPrimaryText,
    AvenirLightText,
    AvenirMediumPrimaryText,
    AvenirReservationStateText
} from "../StyledText";
import reservationRowStyles from "@styles/host/rows/ReservationRowStyle";
import {NavigationProps, openReservationDetail} from "@helpers/NavigationHelper";
import {GuestType, HostReservation, User} from "@services/src/models";
import Colors from "../../config/Colors";
import {DetailArrowImage} from "@components/Images/DetailArrowImage";
import {ReservationGuestImage} from "@components/Images/ReservationGuestImage";
import {DateHelper} from "@helpers/DateHelper";
import {i18n} from "@i18n/i18n";
import {CollaboratorIconColorImage} from "@components/Images/services/CollaboratorIconColorImage";
import {UserStateText, UserStateTextAbsolute} from "@components/UserStateText";

export interface ReservationRowProps {
    context: Component<NavigationProps, any>,
    reservation: HostReservation,
    onDismiss: () => void,
    user: User,
}

export function ReservationRow(props: ReservationRowProps) {
    return (
        <View style={[reservationRowStyles.flex, reservationRowStyles.container]}>
            <TouchableOpacity onPress={() => openReservationDetail(props.context, props.reservation, props.user, props.onDismiss)}>
                <View
                    style={[reservationRowStyles.bigContainer, reservationRowStyles.textContainer, {flexDirection: 'row',}]}>

                    <View style={{flex: 8,}}>

                        <View style={[reservationRowStyles.rowWithCenteredContent]}>
                            <AvenirReservationStateText style={[reservationRowStyles.flex]}
                                                        state={props.reservation.currentState}>
                                {i18n.t('common.' + props.reservation.currentState)}
                            </AvenirReservationStateText>
                        </View>

                        <View style={[reservationRowStyles.rowWithCenteredContent]}>
                            <View style={{flexDirection: 'row', flex: 1,}}>
                                <View style={reservationRowStyles.dateContainer}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[reservationRowStyles.flex, {textAlign: "left",}]}>
                                        {DateHelper.formatDateReservation(props.reservation.startAt)}
                                    </AvenirBookText>
                                </View>
                                <View style={reservationRowStyles.timeContainer}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[reservationRowStyles.flex, reservationRowStyles.timeText, {textAlign: "left",}]}>
                                        {DateHelper.formatTime(props.reservation.startAt)}
                                    </AvenirBookText>
                                </View>
                            </View>
                            <View style={{flexDirection: 'row', flex: 0.2,}}>
                                <AvenirHeavyPrimaryText>
                                    -
                                </AvenirHeavyPrimaryText>
                            </View>
                            <View style={{flexDirection: 'row', flex: 1,}}>
                                <View style={reservationRowStyles.dateContainer}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[reservationRowStyles.flex, {textAlign: "left",}]}>
                                        {DateHelper.formatDateReservation(props.reservation.endAt)}
                                    </AvenirBookText>
                                </View>
                                <View style={reservationRowStyles.timeContainer}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[reservationRowStyles.flex, reservationRowStyles.timeText, {textAlign: "left",}]}>
                                        {DateHelper.formatTime(props.reservation.endAt)}
                                    </AvenirBookText>
                                </View>
                            </View>
                        </View>

                        <View style={[reservationRowStyles.rowWithCenteredContent]}>
                            <AvenirLightText numberOfLines={1}
                                             style={[reservationRowStyles.flex, {
                                                 textAlign: "left",
                                                 color: Colors.reservationNameColor,
                                             }]}>
                                {props.reservation.house != null ? props.reservation.house.name : ''}
                            </AvenirLightText>

                        </View>

                        <View
                            style={{flexDirection: 'row', alignItems: 'center', alignContent: 'center', marginTop: 5,}}>
                            <View style={{flexDirection: 'row', flex: 0.1,}}>
                                {props.reservation.registry.type == GuestType.Client &&
                                <ReservationGuestImage style={[reservationRowStyles.guestIcon]}/>
                                }
                                {props.reservation.registry.type == GuestType.Collaborator &&
                                <CollaboratorIconColorImage style={[reservationRowStyles.guestIcon]}/>
                                }
                            </View>
                            <View style={{flexDirection: 'row', flex: 1,}}>
                                {props.reservation.registry.type == GuestType.Client &&
                                <AvenirLightText numberOfLines={1}
                                                 style={{
                                                     flex: 1,
                                                     textAlign: "left",
                                                     color: Colors.reservationGuestColor,
                                                 }}>
                                    {props.reservation.registry.firstName} {props.reservation.registry.lastName}
                                </AvenirLightText>
                                }
                                {props.reservation.registry.type == GuestType.Collaborator &&
                                <AvenirMediumPrimaryText numberOfLines={1}
                                                         style={{
                                                             flex: 1,
                                                             textAlign: "left",
                                                             color: Colors.reservationGuestColor,
                                                         }}>
                                    {props.reservation.registry.firstName} {props.reservation.registry.lastName}
                                </AvenirMediumPrimaryText>
                                }
                                <View>
                                    <UserStateTextAbsolute user={props.reservation.user}/>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={[reservationRowStyles.detailArrowContainer]}>
                        <DetailArrowImage style={[reservationRowStyles.detailArrow]}/>
                    </View>

                </View>
                <View style={{height: 1, backgroundColor: Colors.reservationRowLine, marginTop: 10,}}>

                </View>
            </TouchableOpacity>
        </View>
    );
}