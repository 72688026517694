/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HouseContactType,
    HouseContactTypeFromJSON,
    HouseContactTypeFromJSONTyped,
    HouseContactTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface HouseContact
 */
export interface HouseContact {
    /**
     * 
     * @type {number}
     * @memberof HouseContact
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HouseContact
     */
    contact?: string;
    /**
     * 
     * @type {HouseContactType}
     * @memberof HouseContact
     */
    type?: HouseContactType;
    /**
     * 
     * @type {number}
     * @memberof HouseContact
     */
    houseId?: number;
}

export function HouseContactFromJSON(json: any): HouseContact {
    return HouseContactFromJSONTyped(json, false);
}

export function HouseContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): HouseContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contact': !exists(json, 'contact') ? undefined : json['contact'],
        'type': !exists(json, 'type') ? undefined : HouseContactTypeFromJSON(json['type']),
        'houseId': !exists(json, 'house_id') ? undefined : json['house_id'],
    };
}

export function HouseContactToJSON(value?: HouseContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'contact': value.contact,
        'type': HouseContactTypeToJSON(value.type),
        'house_id': value.houseId,
    };
}

