/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorActionsScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 11:01 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import doorActionsScreenStyle from "@styles/host/doors/DoorActionsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openDoorDetail, openDoorPlan} from "@helpers/NavigationHelper";
import {ConfirmButton} from "@components/ConfirmButton";
import {
    getAuxUnlockEnabledFromNavigationProps,
    getDoorFromNavigationProps,
    getHouseFromNavigationProps,
    setHeaderTitle
} from "@helpers/NavigationDataHelper";
import {HostResourcesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {Device, House, Resource} from "@services/src/models/index";
import {HouseFactory} from "../../../models/factory/HouseFactory";
import {DeviceFactory} from "../../../models/factory/DeviceFactory";
import {DebugButton} from "@components/DebugButton";
import {Plan} from "@services/src/models";
import {AlertHelper} from "@helpers/AlertHelper";
import {UnlockResourceEnum} from "../../../models/enums/UnlockResourceEnum";

interface State extends LoaderState {
    door: Resource,
    house: House,
    auxUnlockEnabled: boolean,
    door_name: string,
    door_serial: string,
    door_description: string,
    device: Device,
    selectedPlanId: number,
    planAutorenew: boolean,
    planExpireDate: Date,
    plans: Array<Plan>,
}

export default class DoorActionsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;

    constructor(props) {
        super(props);
        let door = getDoorFromNavigationProps(this) || {};
        this.state = {
            door: door,
            auxUnlockEnabled: getAuxUnlockEnabledFromNavigationProps(this) || false,
            house: getHouseFromNavigationProps(this) || {},
            loading: false,
            door_name: door.name || '',
            door_serial: door.serial || '',
            door_description: door.description || '',
            device: null,
            selectedPlanId: null,
            planAutorenew: false,
            planExpireDate: null,
            plans: [],
        };

    }

    componentDidMount() {
        let door = getDoorFromNavigationProps(this) || {};
        let house = getHouseFromNavigationProps(this) || {};
        console.log(house);
        this.onRefresh(door);

        this.didFocusListener = this.props.navigation.addListener('didFocus', payload => {
            this.onRefresh(this.state.door);
        });
    }

    componentWillUnmount(): void {
        this.didFocusListener.remove();
    }

    onRefresh(door) {
        let self = this;
        const doorId = door.id + '';
        new HostResourcesApi(this).apiHostResourcesIdGet({id: doorId}).then(resp => {
            Logger.log(self.constructor.name, 'Ok');
            let data = resp.data;
            let device = data.devices != null && data.devices.length > 0 ? data.devices[0] : null;
            self.setState({
                door: data,
                device: device,
                //planAutorenew: data.autorenew, // TODO
            });
            setHeaderTitle(this.props, this.isAuxEnabled() ? device.auxUnlockText : data.name);
        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + error);
        });
    }

    /**
     * Checks if it is an aux device
     * @returns {boolean}
     */
    isAuxEnabled() {
        return this.state.device != null && this.state.auxUnlockEnabled;
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={doorActionsScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View
                    style={[doorActionsScreenStyle.buttonsContainer, globalScreenStyle.globalMargins]}>
                    <View style={[doorActionsScreenStyle.buttonView]}>
                        <ConfirmButton active={this.state.device != null}
                                       onPress={() => unlockDoor(this.isAuxEnabled(), this)}
                                       title={i18n.t('screens.door_actions.open')}/>
                    </View>
                    {!this.isAuxEnabled() &&
                    <View style={[doorActionsScreenStyle.buttonView]}>
                        <ConfirmButton active={true}
                                       onPress={() => openDoorDetail(this, this.state.house, this.state.door)}
                                       title={i18n.t('screens.door_actions.edit')}/>
                    </View>
                    }


                    <View style={[doorActionsScreenStyle.buttonView]}>
                        <ConfirmButton active={hasDevice(this)}
                                       onPress={() => hasDevice(this) ? openDoorPlan(this, this.state.house, this.state.door) : showMissingDeviceAlert(this)}
                                       title={i18n.t('screens.door_actions.manage_plan')}/>
                    </View>

                </View>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Checks if current state has a set device
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function hasDevice(context: Component<any, State>) {
    return context.state.device != null;
}

/**
 * Shows an alert about the missing device for this Resource
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function showMissingDeviceAlert(context: Component<any, State>) {
    return AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('screens.door_actions.missing_device'));
}

function debugUpdateData(context: Component<any, State>) {
    let fakeDevice = DeviceFactory.factory();
    context.setState({
        house: HouseFactory.factory(),
        device: fakeDevice,
    });

}

/**
 * Unlock a door using isAuxEnabled for index
 * @param {boolean} isAuxEnabled
 * @param {React.Component<any, State>} context
 */
function unlockDoor(isAuxEnabled: boolean, context: Component<any, State>) {
    let index = isAuxEnabled ? UnlockResourceEnum.AUX_RESOURCE : UnlockResourceEnum.MAIN_RESOURCE;
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed)
            new HostResourcesApi(context).apiHostResourcesIdUnlockPost({
                id: context.state.door.id + '',
                hostResourceUnlock: {index: index}
            }).then(resp => {
                Logger.log(context.constructor.name, "Ok");
                console.log(resp);
                AlertHelper.showSimpleAlert(i18n.t('alerts.success'), i18n.t('screens.door_actions.unlock_success_alert'))
            }).catch(async error => {
                Logger.warn(context.constructor.name, "Error " + error);
                var body = await error.json();
                console.log(body);
                AlertHelper.showSimpleAlert(i18n.t('alerts.failure'),i18n.t("screens.door_actions.unlock_failure_alert"));
            })
    });
}