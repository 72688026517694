/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ActivationCodeHelper.ts
 * @author     Christian Ascone
 * @date       1/25/21 9:37 AM
 */

export class ActivationCodeHelper {
    /**
     * Checks if the given string is a valid activation code
     * @param {string} toCheck
     * @returns {any}
     */
    public static checkActivationCodeIsValid(toCheck: string): boolean {
        if (toCheck == null || toCheck.trim().length == 0) {
            return false;
        }
        const normalized = toCheck.trim().toLowerCase();
        return normalized == 'demo' || normalized == 'bfree2021';
    }
}