/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       StripeOutput.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

// tslint:disable
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {exists} from '@services/src/runtime';

/**
 *
 * @export
 * @interface StripeOutput
 */
export interface StripeOutput {
    /**
     *
     * @type {string}
     * @memberof StripeOutput
     */
    id?: string;
}

export function StripeOutputFromJSON(json: any): StripeOutput {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function StripeOutputToJSON(value?: StripeOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
    };
}


