/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ResourceSelectionBlock.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import {
    AvenirBookPrimaryText,
    AvenirBookServiceGreyText,
    AvenirLightGreyText,
    AvenirLightPrimaryText
} from "./StyledText";
import blockStyle from "@styles/host/ResourceSelectionBlockStyle";
import {TriangleSelectedImage} from "@components/Images/TriangleSelectedImage";
import {PublicColorImage} from "@components/Images/services/PublicColorImage";
import {i18n} from "@i18n/i18n";
import {ResidentialColorImage} from "@components/Images/services/ResidentialColorImage";
import {ResidentialImage} from "@components/Images/services/ResidentialImage";
import {PublicImage} from "@components/Images/services/PublicImage";
import {ResourceType} from "@services/src/models";
import {LoaderState} from "@helpers/LoaderHelper";
import {Card} from "@services/src/models";
import {LogoBFreeSmallImage} from "@components/Images/LogoBFreeSmallImage";

interface State extends LoaderState{

}

interface ServiceSelectionBlockProps {
    onPress: (event: GestureResponderEvent) => void,
    style: StyleProp<ViewStyle>,
    active: boolean,
    card: Card,

}

interface ServiceSelectionMutexBlockProps extends ServiceSelectionBlockProps {

}

interface ServiceTypeBlockProps {
    onPress: (event: GestureResponderEvent) => void,
    style: StyleProp<ViewStyle>,
    card: Card,
}


/**
 * A view component with Card selection.
 * Based on 'active' property it returns the active or inactive view
 * @param {ServiceSelectionMutexBlockProps} props
 * @returns {any}
 * @constructor
 */
export function CardSelectionBlock(props: ServiceSelectionBlockProps) {
    return (
        <View style={[props.style, {height: 100}]}>
            {props.active &&
            <SelectionSelectedBlock onPress={props.onPress} card={props.card} style={[props.style]} />}
            {!props.active &&
            <SelectionNotSelectedBlock onPress={props.onPress} card={props.card} style={props.style} />}
        </View>
    );
}


/**
 * Component for selected service
 */
class SelectionSelectedBlock extends Component<any, ServiceTypeBlockProps> {
    render() {
        let labelKey = 'card.card';

        return (
            <View style={[this.props.style, {height: 100}]}>
                <TouchableOpacity onPress={()=>this.props.onPress} {...this.props}
                                  style={[blockStyle.serviceButtonTouchable,{height: 100}]}>
                    <LogoBFreeSmallImage style={{transform: [{scale: 0.65}], float: 'left', margin: 0}}/>
                    <View style={{flexDirection: 'column', alignItems: 'center', height: 100, padding: 25,}}>

                        <View style={[blockStyle.serviceTextContainer, {flexDirection: 'column'}]}>
                            <AvenirLightPrimaryText>
                                {(this.props.card.name && i18n.t('screens.new_card.card_name')+ ': ' + this.props.card.name) || ''}
                            </AvenirLightPrimaryText>

                            <AvenirLightGreyText style={blockStyle.serviceButtonText}>
                                {i18n.t('screens.new_card.code') + ': ' + this.props.card.code}
                            </AvenirLightGreyText>
                        </View>
                    </View>
                    <View style={{flex: 1, flexDirection: 'column'}}>
                        <TriangleSelectedImage style={[blockStyle.serviceButtonImage]}/>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}

/**
 * Component for NOT selected service
 */
class SelectionNotSelectedBlock extends Component<ServiceTypeBlockProps, any> {
    render() {
        let labelKey = 'card.card';

        return (
            <View style={[this.props.style, {height: 100}]}>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[blockStyle.serviceButtonTouchable, {height: 100}]}>
                    <LogoBFreeSmallImage style={{transform: [{scale: 0.65}], float: 'left', margin: 0}}/>
                    <View style={{flexDirection: 'column', alignItems: 'center', height: 100, padding: 25}}>

                        <View style={[blockStyle.serviceTextContainer, {flexDirection: 'column'}]}>
                            <AvenirLightPrimaryText>
                                {(this.props.card.name && i18n.t('screens.new_card.card_name')+ ': ' + this.props.card.name) || ''}
                            </AvenirLightPrimaryText>

                            <AvenirLightGreyText style={blockStyle.serviceButtonText}>
                                {i18n.t('screens.new_card.code') + ': ' + this.props.card.code}
                            </AvenirLightGreyText>
                        </View>
                    </View>
                    <View style={{flex: 1, flexDirection: 'row'}}>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}