/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    vat?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    pec?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    sdi?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    addressId?: number;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    hostId?: number;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'pec': !exists(json, 'pec') ? undefined : json['pec'],
        'sdi': !exists(json, 'sdi') ? undefined : json['sdi'],
        'addressId': !exists(json, 'address_id') ? undefined : json['address_id'],
        'hostId': !exists(json, 'host_id') ? undefined : json['host_id'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'vat': value.vat,
        'pec': value.pec,
        'sdi': value.sdi,
        'address_id': value.addressId,
        'host_id': value.hostId,
    };
}

