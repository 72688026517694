/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationResourceJoinFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import * as fakerStatic from 'faker';
import {AccommodationResourceJoin} from "@services/src/models";

export class AccommodationResourceJoinFactory {
    static factoryArray(size: number): AccommodationResourceJoin[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(AccommodationResourceJoinFactory.factory());
        }
        return array;
    }

    static factory(): AccommodationResourceJoin {
        return {
            id: fakerStatic.random.number(4),
            resourceId: fakerStatic.random.number(10),
            accommodationId: fakerStatic.random.number(10),
        };
    }
}

