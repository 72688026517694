/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseForAccommodationsSelectionBean.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */

import {House, HouseComplete} from "@services/src/models";
import {AccommodationSelectionBean} from "../beans/AccommodationSelection";

export interface HouseForAccommodationsSelectionBean extends HouseComplete {
    accommodationsSelectionBeans: Array<AccommodationSelectionBean>,
}

export class HouseForAccommodationsSelection {
    /**
     * Builds a bean from House
     * @param {House} house
     * @returns {HouseForAccommodationsSelectionBean}
     */
    static fromHouse(house: House): HouseForAccommodationsSelectionBean {
        let newHouse: HouseForAccommodationsSelectionBean = house as HouseForAccommodationsSelectionBean;
        newHouse.accommodationsSelectionBeans = [];
        return newHouse;
    }

    /**
     * Builds a bean list from House List
     * @param {House[]} houses
     * @returns {HouseForAccommodationsSelectionBean[]}
     */
    static fromHouseList(houses: House[]): HouseForAccommodationsSelectionBean[] {
        return houses.map(house => this.fromHouse(house));
    }
}