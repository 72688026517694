/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Card,
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
    CollaborationStateEnum,
    CollaborationStateEnumFromJSON,
    CollaborationStateEnumFromJSONTyped,
    CollaborationStateEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface Collaboration
 */
export interface Collaboration {
    /**
     * 
     * @type {number}
     * @memberof Collaboration
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Collaboration
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Collaboration
     */
    endAt?: Date;
    /**
     * 
     * @type {CollaborationStateEnum}
     * @memberof Collaboration
     */
    currentState?: CollaborationStateEnum;
    /**
     * 
     * @type {number}
     * @memberof Collaboration
     */
    collaboratorId?: number;
    /**
     * 
     * @type {number}
     * @memberof Collaboration
     */
    hostId?: number;
    /**
     * 
     * @type {Card}
     * @memberof Collaboration
     */
    card?: Card;
}

export function CollaborationFromJSON(json: any): Collaboration {
    return CollaborationFromJSONTyped(json, false);
}

export function CollaborationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Collaboration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startAt': !exists(json, 'start_at') ? undefined : (new Date(json['start_at'])),
        'endAt': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'currentState': !exists(json, 'current_state') ? undefined : CollaborationStateEnumFromJSON(json['current_state']),
        'collaboratorId': !exists(json, 'collaborator_id') ? undefined : json['collaborator_id'],
        'hostId': !exists(json, 'host_id') ? undefined : json['host_id'],
        'card': !exists(json, 'card') ? undefined : CardFromJSON(json['card']),
    };
}

export function CollaborationToJSON(value?: Collaboration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start_at': value.startAt === undefined ? undefined : (value.startAt.toISOString()),
        'end_at': value.endAt === undefined ? undefined : (value.endAt.toISOString()),
        'current_state': CollaborationStateEnumToJSON(value.currentState),
        'collaborator_id': value.collaboratorId,
        'host_id': value.hostId,
        'card': CardToJSON(value.card),
    };
}

