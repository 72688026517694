/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DateTimeInputBlock.web.tsx
 * @author     Christian Ascone
 * @date       10/31/19 10:26 AM
 */

import React, {Component} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import TextStyle from '@styles/TextStyle';
import {AvenirBookUpperLabelText} from "./StyledText";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {default as DateTimeInputBlockProps} from "./properties/DateTimeInputBlockProps";


export class DateTimeInputBlock extends Component<DateTimeInputBlockProps, any> {
    changeDate(date: Date) {
        this.props.onChangeDate(date);
    }

    changeTime(date: Date) {
        this.props.onChangeTime(date);
    }

    render() {
        return (
            <View style={{flexDirection: 'column', zIndex: -99,}}>
                <AvenirBookUpperLabelText>
                    {this.props.label}
                </AvenirBookUpperLabelText>
                <View style={{flexDirection: 'row', flex: 1,}}>
                    <TouchableOpacity style={[TextStyle.webDateContainer, {flex: 1,}]}>
                        <DatePicker
                            style={[this.props.style, TextStyle.separator,]}
                            selected={this.props.date}
                            onChange={(date) => this.changeDate(date)}
                            dateFormat="dd/MM/yyyy"
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={[TextStyle.webDateContainer, {flex: 1,}]}>
                        <DatePicker
                            style={[this.props.style, TextStyle.separatorTime,]}
                            selected={this.props.time}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="hh:mm aa"
                            onChange={(date) => this.changeTime(date)}
                        />
                    </TouchableOpacity>
                    <View style={[TextStyle.webDateContainer, {flex: 1,}]}>
                        {/* DUMMY */}
                        <Text></Text>
                    </View>
                </View>
            </View>
        );
    }
}