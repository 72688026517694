/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GuestType,
    GuestTypeFromJSON,
    GuestTypeFromJSONTyped,
    GuestTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReservationFilterInput
 */
export interface ReservationFilterInput {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReservationFilterInput
     */
    houseIds?: Array<number>;
    /**
     * 
     * @type {GuestType}
     * @memberof ReservationFilterInput
     */
    registryType?: GuestType;
    /**
     * 
     * @type {Date}
     * @memberof ReservationFilterInput
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReservationFilterInput
     */
    toDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReservationFilterInput
     */
    registryEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationFilterInput
     */
    reservationStateCheckedin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationFilterInput
     */
    reservationStatePending?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationFilterInput
     */
    reservationStateConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationFilterInput
     */
    reservationStateCanceled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationFilterInput
     */
    reservationStateCheckedout?: boolean;
}

export function ReservationFilterInputFromJSON(json: any): ReservationFilterInput {
    return ReservationFilterInputFromJSONTyped(json, false);
}

export function ReservationFilterInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationFilterInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'houseIds': !exists(json, 'house_ids') ? undefined : json['house_ids'],
        'registryType': !exists(json, 'registry_type') ? undefined : GuestTypeFromJSON(json['registry_type']),
        'fromDate': !exists(json, 'from_date') ? undefined : (new Date(json['from_date'])),
        'toDate': !exists(json, 'to_date') ? undefined : (new Date(json['to_date'])),
        'registryEmail': !exists(json, 'registry_email') ? undefined : json['registry_email'],
        'reservationStateCheckedin': !exists(json, 'reservation_state_checkedin') ? undefined : json['reservation_state_checkedin'],
        'reservationStatePending': !exists(json, 'reservation_state_pending') ? undefined : json['reservation_state_pending'],
        'reservationStateConfirmed': !exists(json, 'reservation_state_confirmed') ? undefined : json['reservation_state_confirmed'],
        'reservationStateCanceled': !exists(json, 'reservation_state_canceled') ? undefined : json['reservation_state_canceled'],
        'reservationStateCheckedout': !exists(json, 'reservation_state_checkedout') ? undefined : json['reservation_state_checkedout'],
    };
}

export function ReservationFilterInputToJSON(value?: ReservationFilterInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'house_ids': value.houseIds,
        'registry_type': GuestTypeToJSON(value.registryType),
        'from_date': value.fromDate === undefined ? undefined : (value.fromDate.toISOString().substr(0,10)),
        'to_date': value.toDate === undefined ? undefined : (value.toDate.toISOString().substr(0,10)),
        'registry_email': value.registryEmail,
        'reservation_state_checkedin': value.reservationStateCheckedin,
        'reservation_state_pending': value.reservationStatePending,
        'reservation_state_confirmed': value.reservationStateConfirmed,
        'reservation_state_canceled': value.reservationStateCanceled,
        'reservation_state_checkedout': value.reservationStateCheckedout,
    };
}

