/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       LocationHelper.ts
 * @author     Christian Ascone
 * @date       11/28/19 3:18 PM
 */


import {LocationData} from "expo-location";

export class LocationHelper {
    /**
     * Convert a Location to region for map
     * @returns {any}
     * @param location
     */
    public static locationToRegion(location: LocationData) {
        return {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
            latitudeDelta: 0.02,
            longitudeDelta: 0.02,
        };
    }

    /**
     * Gets a default region
     * @returns {{latitude: number; longitude: number; latitudeDelta: number; longitudeDelta: number}}
     */
    public static getDefaultRegion() {
        return {
            latitude: 41.8948,
            longitude: 12.485300000000052,
            latitudeDelta: 0,
            longitudeDelta: 0,
        };
    }
}