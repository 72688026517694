/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseSelection.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import {House} from "@services/src/models";

export interface HouseSelectionBean {
    house: House,
    selected: boolean,
}

export class HouseSelection {
    /**
     * Builds a bean from House
     * @param {House} house
     * @returns {HouseSelectionBean}
     */
    static fromHouse(house: House): HouseSelectionBean {
        return {house: house, selected: false};
    }

    /**
     * Builds a bean list from House List
     * @param {House[]} houses
     * @returns {HouseSelectionBean[]}
     */
    static fromHouseList(houses: House[]): HouseSelectionBean[] {
        return houses.map(house => this.fromHouse(house));
    }
}