/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationRow.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {AvenirBookGreyLabelText, AvenirLightPrimaryText} from "../StyledText";
import {RightArrowColorImage} from "@components/Images/RightArrowColorImage";
import accommodationRowStyles from "@styles/host/rows/AccommodationRowStyle";
import {NavigationProps, openAccommodationDetail} from "@helpers/NavigationHelper";
import {GatePublicColorImage} from "@components/Images/GatePublicColorImage";
import {GateResidentialColorImage} from "@components/Images/GateResidentialColorImage";
import {Accommodation, House} from "@services/src/models";

export interface AccommodationRowProps {
    accommodation_id: number,
    accommodation_name: string,
    residential_count: number,
    public_count: number,
    context: Component<NavigationProps, any>,
    house: House,
    accommodation: Accommodation,
}

export function AccommodationRow(props: AccommodationRowProps) {
    return (
        <View style={[accommodationRowStyles.flex, accommodationRowStyles.container]}>
            <TouchableOpacity onPress={() => openAccommodationDetail(props.context, props.house, props.accommodation)}>
                <View
                    style={[accommodationRowStyles.bigContainer, accommodationRowStyles.textContainer, {flexDirection: 'row',}]}>
                    <View style={{marginLeft: 5, flex: 7,}}>
                        <AvenirLightPrimaryText style={{marginLeft: 1, marginBottom: 3,}}>
                            {props.accommodation_name}
                        </AvenirLightPrimaryText>
                        <View style={{flexDirection: 'row', alignItems: 'baseline',}}>
                            <GateResidentialColorImage style={accommodationRowStyles.gateResidential}/>
                            <AvenirBookGreyLabelText
                                style={accommodationRowStyles.gateResidentialText}>{props.residential_count}</AvenirBookGreyLabelText>
                            <GatePublicColorImage style={accommodationRowStyles.gatePublic}/>
                            <AvenirBookGreyLabelText
                                style={accommodationRowStyles.gatePublicText}>{props.public_count}</AvenirBookGreyLabelText>
                        </View>
                    </View>
                    <View style={[accommodationRowStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[accommodationRowStyles.detailArrow]}/>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
}