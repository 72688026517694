/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddressProviderType,
    AddressProviderTypeFromJSON,
    AddressProviderTypeToJSON,
    ForgotPasswordRequest,
    ForgotPasswordRequestFromJSON,
    ForgotPasswordRequestToJSON,
    ForgotPasswordResponse,
    ForgotPasswordResponseFromJSON,
    ForgotPasswordResponseToJSON,
    HostType,
    HostTypeFromJSON,
    HostTypeToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface ApiAuthForgotPostRequest {
    forgotPasswordRequest: ForgotPasswordRequest;
}

export interface ApiRegisterPostRequest {
    password?: string;
    email?: string;
    phone?: string;
    name?: string;
    addressPlaceProvider?: AddressProviderType;
    addressStreet?: string;
    addressNumber?: string;
    addressCity?: string;
    addressLocality?: string;
    addressZip?: string;
    hostFirstName?: string;
    hostLastName?: string;
    hostName?: string;
    hostEmail?: string;
    hostMobile?: string;
    hostFiscalCode?: string;
    hostType?: HostType;
    hostUsageThreshold?: number;
    hostHasCompanyData?: boolean;
    companyName?: string;
    companyVat?: string;
    companyPec?: string;
    companySdi?: string;
    hostDevicesCode?: string;
    hostDevicesCodeToSave?: string;
    hostCardId?: number;
    photo?: Blob;
    photoBase64?: string;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async apiAuthForgotPostRaw(requestParameters: ApiAuthForgotPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ForgotPasswordResponse>> {
        if (requestParameters.forgotPasswordRequest === null || requestParameters.forgotPasswordRequest === undefined) {
            throw new runtime.RequiredError('forgotPasswordRequest','Required parameter requestParameters.forgotPasswordRequest was null or undefined when calling apiAuthForgotPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/auth/forgot`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestToJSON(requestParameters.forgotPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ForgotPasswordResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthForgotPost(requestParameters: ApiAuthForgotPostRequest, initOverrides?: RequestInit): Promise<ForgotPasswordResponse> {
        const response = await this.apiAuthForgotPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegisterPostRaw(requestParameters: ApiRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.phone !== undefined) {
            formParams.append('phone', requestParameters.phone as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.addressPlaceProvider !== undefined) {
            formParams.append('address_place_provider', requestParameters.addressPlaceProvider as any);
        }

        if (requestParameters.addressStreet !== undefined) {
            formParams.append('address_street', requestParameters.addressStreet as any);
        }

        if (requestParameters.addressNumber !== undefined) {
            formParams.append('address_number', requestParameters.addressNumber as any);
        }

        if (requestParameters.addressCity !== undefined) {
            formParams.append('address_city', requestParameters.addressCity as any);
        }

        if (requestParameters.addressLocality !== undefined) {
            formParams.append('address_locality', requestParameters.addressLocality as any);
        }

        if (requestParameters.addressZip !== undefined) {
            formParams.append('address_zip', requestParameters.addressZip as any);
        }

        if (requestParameters.hostFirstName !== undefined) {
            formParams.append('host_first_name', requestParameters.hostFirstName as any);
        }

        if (requestParameters.hostLastName !== undefined) {
            formParams.append('host_last_name', requestParameters.hostLastName as any);
        }

        if (requestParameters.hostName !== undefined) {
            formParams.append('host_name', requestParameters.hostName as any);
        }

        if (requestParameters.hostEmail !== undefined) {
            formParams.append('host_email', requestParameters.hostEmail as any);
        }

        if (requestParameters.hostMobile !== undefined) {
            formParams.append('host_mobile', requestParameters.hostMobile as any);
        }

        if (requestParameters.hostFiscalCode !== undefined) {
            formParams.append('host_fiscal_code', requestParameters.hostFiscalCode as any);
        }

        if (requestParameters.hostType !== undefined) {
            formParams.append('host_type', requestParameters.hostType as any);
        }

        if (requestParameters.hostUsageThreshold !== undefined) {
            formParams.append('host_usage_threshold', requestParameters.hostUsageThreshold as any);
        }

        if (requestParameters.hostHasCompanyData !== undefined) {
            formParams.append('host_has_company_data', requestParameters.hostHasCompanyData as any);
        }

        if (requestParameters.companyName !== undefined) {
            formParams.append('company_name', requestParameters.companyName as any);
        }

        if (requestParameters.companyVat !== undefined) {
            formParams.append('company_vat', requestParameters.companyVat as any);
        }

        if (requestParameters.companyPec !== undefined) {
            formParams.append('company_pec', requestParameters.companyPec as any);
        }

        if (requestParameters.companySdi !== undefined) {
            formParams.append('company_sdi', requestParameters.companySdi as any);
        }

        if (requestParameters.hostDevicesCode !== undefined) {
            formParams.append('host_devices_code', requestParameters.hostDevicesCode as any);
        }

        if (requestParameters.hostDevicesCodeToSave !== undefined) {
            formParams.append('host_devices_code_to_save', requestParameters.hostDevicesCodeToSave as any);
        }

        if (requestParameters.hostCardId !== undefined) {
            formParams.append('host_card_id', requestParameters.hostCardId as any);
        }

        if (requestParameters.photo !== undefined) {
            formParams.append('photo', requestParameters.photo as any);
        }

        if (requestParameters.photoBase64 !== undefined) {
            formParams.append('photo_base64', requestParameters.photoBase64 as any);
        }

        const response = await this.request({
            path: `/api/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async apiRegisterPost(requestParameters: ApiRegisterPostRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.apiRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
