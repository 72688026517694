/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AddDoorBlockStyle.ts
 * @author     Christian Ascone
 * @date       9/26/19 11:16 AM
 */

import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        marginBottom: 5,
    },
    image: {
        width: 25,
        height: 25,
        resizeMode: 'contain',
        marginRight: 5,
    },
});

export default styles;