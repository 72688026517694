/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Accommodation,
    AccommodationFromJSON,
    AccommodationToJSON,
    AccommodationCreateInput,
    AccommodationCreateInputFromJSON,
    AccommodationCreateInputToJSON,
} from '../models';

export interface ApiAccommodationsByHouseIdGetRequest {
    id: number;
}

export interface ApiAccommodationsIdDeleteRequest {
    id: number;
}

export interface ApiAccommodationsIdGetRequest {
    id: string;
}

export interface ApiAccommodationsIdPutRequest {
    id: number;
    accommodation: Accommodation;
}

export interface ApiAccommodationsPostRequest {
    accommodationCreateInput: AccommodationCreateInput;
}

/**
 * 
 */
export class AccommodationsApi extends runtime.BaseAPI {

    /**
     */
    async apiAccommodationsByHouseIdGetRaw(requestParameters: ApiAccommodationsByHouseIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Accommodation>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccommodationsByHouseIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodations/byHouse/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccommodationFromJSON));
    }

    /**
     */
    async apiAccommodationsByHouseIdGet(requestParameters: ApiAccommodationsByHouseIdGetRequest, initOverrides?: RequestInit): Promise<Array<Accommodation>> {
        const response = await this.apiAccommodationsByHouseIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccommodationsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Accommodation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccommodationFromJSON));
    }

    /**
     */
    async apiAccommodationsGet(initOverrides?: RequestInit): Promise<Array<Accommodation>> {
        const response = await this.apiAccommodationsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccommodationsIdDeleteRaw(requestParameters: ApiAccommodationsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccommodationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccommodationsIdDelete(requestParameters: ApiAccommodationsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAccommodationsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAccommodationsIdGetRaw(requestParameters: ApiAccommodationsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Accommodation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccommodationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccommodationFromJSON(jsonValue));
    }

    /**
     */
    async apiAccommodationsIdGet(requestParameters: ApiAccommodationsIdGetRequest, initOverrides?: RequestInit): Promise<Accommodation> {
        const response = await this.apiAccommodationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccommodationsIdPutRaw(requestParameters: ApiAccommodationsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccommodationsIdPut.');
        }

        if (requestParameters.accommodation === null || requestParameters.accommodation === undefined) {
            throw new runtime.RequiredError('accommodation','Required parameter requestParameters.accommodation was null or undefined when calling apiAccommodationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccommodationToJSON(requestParameters.accommodation),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccommodationsIdPut(requestParameters: ApiAccommodationsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAccommodationsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAccommodationsPostRaw(requestParameters: ApiAccommodationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Accommodation>> {
        if (requestParameters.accommodationCreateInput === null || requestParameters.accommodationCreateInput === undefined) {
            throw new runtime.RequiredError('accommodationCreateInput','Required parameter requestParameters.accommodationCreateInput was null or undefined when calling apiAccommodationsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccommodationCreateInputToJSON(requestParameters.accommodationCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccommodationFromJSON(jsonValue));
    }

    /**
     */
    async apiAccommodationsPost(requestParameters: ApiAccommodationsPostRequest, initOverrides?: RequestInit): Promise<Accommodation> {
        const response = await this.apiAccommodationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
