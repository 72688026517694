/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostResource,
    HostResourceFromJSON,
    HostResourceFromJSONTyped,
    HostResourceToJSON,
    ResponseStatus,
    ResponseStatusFromJSON,
    ResponseStatusFromJSONTyped,
    ResponseStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostResourceResponse
 */
export interface HostResourceResponse {
    /**
     * 
     * @type {ResponseStatus}
     * @memberof HostResourceResponse
     */
    status?: ResponseStatus;
    /**
     * 
     * @type {string}
     * @memberof HostResourceResponse
     */
    error?: string;
    /**
     * 
     * @type {HostResource}
     * @memberof HostResourceResponse
     */
    data?: HostResource;
}

export function HostResourceResponseFromJSON(json: any): HostResourceResponse {
    return HostResourceResponseFromJSONTyped(json, false);
}

export function HostResourceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostResourceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ResponseStatusFromJSON(json['status']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'data': !exists(json, 'data') ? undefined : HostResourceFromJSON(json['data']),
    };
}

export function HostResourceResponseToJSON(value?: HostResourceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ResponseStatusToJSON(value.status),
        'error': value.error,
        'data': HostResourceToJSON(value.data),
    };
}

