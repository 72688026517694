/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FilterContainerBlockStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import Colors from "../../config/Colors";
import * as Fonts from "../../config/Fonts";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
    },
    flex: {
        flex: 1,
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    flexBig: {
        flex: 5,
        flexDirection: 'row',
        borderLeftColor: Colors.filtersBorder,
        borderLeftWidth: 2,
        paddingHorizontal: 15,
        alignItems: 'center',
        alignContent: 'center',
    },
    background: {
        backgroundColor: Colors.filtersBackground,
    },
    checkTextContainer: {
        marginHorizontal: 3,
    },
    reservationStatusContainer: {
        margin: 3,
    },
    collaborationStatusContainer: {
        margin: 3,
    },
    StatusContainer: {
        margin: 3,
    },
    centerContent: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    textInputContainer: {
        paddingTop: 10,
        paddingBottom: 10,
        flexDirection: 'row',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        backgroundColor: 'white',
    },
    textInput: {
        fontFamily: Fonts.names.avenirBook,
        paddingLeft: 5,
        flex: 1,
    },
});

export default styles;