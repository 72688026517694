/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CreditType {
    Credit = 'CREDIT',
    Debit = 'DEBIT'
}

export function CreditTypeFromJSON(json: any): CreditType {
    return CreditTypeFromJSONTyped(json, false);
}

export function CreditTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditType {
    return json as CreditType;
}

export function CreditTypeToJSON(value?: CreditType | null): any {
    return value as any;
}

