/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Partner
 */
export interface Partner {
    /**
     * 
     * @type {number}
     * @memberof Partner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    lastName?: string;
}

export function PartnerFromJSON(json: any): Partner {
    return PartnerFromJSONTyped(json, false);
}

export function PartnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Partner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
    };
}

export function PartnerToJSON(value?: Partner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.firstName,
        'last_name': value.lastName,
    };
}

