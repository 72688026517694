/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Credit,
    CreditFromJSON,
    CreditToJSON,
} from '../models';

export interface ApiCreditsIdDeleteRequest {
    id: number;
}

export interface ApiCreditsIdGetRequest {
    id: string;
}

export interface ApiCreditsIdPutRequest {
    id: number;
    credit: Credit;
}

export interface ApiCreditsPostRequest {
    credit: Credit;
}

/**
 * 
 */
export class CreditsApi extends runtime.BaseAPI {

    /**
     */
    async apiCreditsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Credit>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/credits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreditFromJSON));
    }

    /**
     */
    async apiCreditsGet(initOverrides?: RequestInit): Promise<Array<Credit>> {
        const response = await this.apiCreditsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCreditsIdDeleteRaw(requestParameters: ApiCreditsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCreditsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/credits/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCreditsIdDelete(requestParameters: ApiCreditsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCreditsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCreditsIdGetRaw(requestParameters: ApiCreditsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Credit>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCreditsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/credits/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreditFromJSON(jsonValue));
    }

    /**
     */
    async apiCreditsIdGet(requestParameters: ApiCreditsIdGetRequest, initOverrides?: RequestInit): Promise<Credit> {
        const response = await this.apiCreditsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiCreditsIdPutRaw(requestParameters: ApiCreditsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiCreditsIdPut.');
        }

        if (requestParameters.credit === null || requestParameters.credit === undefined) {
            throw new runtime.RequiredError('credit','Required parameter requestParameters.credit was null or undefined when calling apiCreditsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/credits/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreditToJSON(requestParameters.credit),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCreditsIdPut(requestParameters: ApiCreditsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCreditsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiCreditsPostRaw(requestParameters: ApiCreditsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.credit === null || requestParameters.credit === undefined) {
            throw new runtime.RequiredError('credit','Required parameter requestParameters.credit was null or undefined when calling apiCreditsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/credits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreditToJSON(requestParameters.credit),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCreditsPost(requestParameters: ApiCreditsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiCreditsPostRaw(requestParameters, initOverrides);
    }

}
