import React, {Component} from 'react';
import {View, Image, Text, Platform} from "react-native";
import {i18n} from "@i18n/i18n";
import {AvenirBookPrimaryText} from "@components/StyledText";

export default class MaintenanceScreen extends Component<any, any>{
    constructor(props) {
        super(props);
    }

    render() {
        return(
          <View style={{flex: 1, justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
              <Image style={{height: '20%', width: '40%', ...Platform.select({web: {height: '40%', width: '20%'}})}} source={require('../assets/images/MaintenanceLogo-Bfree-centered.png')}/>
              <AvenirBookPrimaryText style={{textAlign: 'center'}}>{i18n.t('maintenance.message')}</AvenirBookPrimaryText>
          </View>
        );
    }
}