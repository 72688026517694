/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HostResourceFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import * as fakerStatic from 'faker';
import {HostResource, ResourceType} from "@services/src/models";
import {ResourceFactory} from "./ResourceFactory";
import {HouseFactory} from "./HouseFactory";

export class HostResourceFactory {
    static factoryArray(size: number, type: ResourceType = null): HostResource[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(HostResourceFactory.factory(type));
        }
        return array;
    }

    static factory(type: ResourceType = null): HostResource {
        const typeArray = [ResourceType.Public, ResourceType.Residential];
        if (type == null)
            type = typeArray[fakerStatic.random.number(typeArray.length - 1)];
        return {
            id: ResourceFactory.factory(type).id,
            type: ResourceFactory.factory(type).type,
            name: ResourceFactory.factory(type).name,
            serial: ResourceFactory.factory(type).serial,
            description: ResourceFactory.factory(type).description,
            latitude: ResourceFactory.factory(type).latitude,
            longitude: ResourceFactory.factory(type).longitude,
            currentState: ResourceFactory.factory(type).currentState,
            topic: ResourceFactory.factory(type).topic,
            house: HouseFactory.factory(),
        };
    }
}

