/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ErrorHelper.ts
 * @author     Christian Ascone
 * @date       2/24/20 12:41 PM
 */

import {i18n} from "@i18n/i18n";

export class ErrorHelper {

    /**
     * Gets a translated error message for server code
     * @param {any} error_message
     * @returns {any}
     */
    static getTranslatedServerErrorMessage(error_message) {
        return i18n.t('server_errors.' + error_message, {defaultValue: i18n.t('server_errors.error_generic')});
    }
}