/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DebugRoomBlock.tsx
 * @author     Christian Ascone
 * @date       3/10/20 2:38 PM
 */

import React, {Component} from 'react';
import {Button, TouchableOpacity, View} from 'react-native';
import {
    NavigationProps,
    openAccommodationDetail,
    openAccommodationsList,
    openDashboard,
    openDashboardEventsList,
    openDoorActions,
    openDoorBarcodeScanner,
    openDoorDetail,
    openDoorsList,
    openEmailVerification,
    openHouseDetail,
    openIntroReservation,
    openIntroReservationNewUser,
    openLogin,
    openNewAccommodation,
    openNewDoor,
    openNewHouseDone,
    openNewReservationAccommodationsRoute,
    openNewReservationDoneRoute,
    openNewReservationPeriodRoute,
    openPhoneVerification,
    openReservationDetail,
    openSettings,
    openSignup,
    openStripeDebugScreenRoute,
    openSupportReportRoute
} from "@helpers/NavigationHelper";
import {HouseFactory} from "../models/factory/HouseFactory";
import {ResourceFactory} from "../models/factory/ResourceFactory";
import {AvenirHeavyText} from "./StyledText";
import {AccommodationFactory} from "../models/factory/AccommodationFactory";
import {ReservationBean} from "../models/beans/ReservationBean";
import {testCrash} from "../config/SentryConfig";
import {Logger} from "@helpers/Logger";

interface DebugRoomProps {
    context: Component<NavigationProps, any>,
}

export class DebugRoomBlock extends Component<DebugRoomProps, any> {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
        }
    }

    toggle() {
        this.setState({active: !this.state.active});
    }

    render() {
        return (
            <TouchableOpacity onPress={() => this.toggle()} style={{display: 'flex', flexDirection: 'column'}}>
                <AvenirHeavyText style={{padding: 20,}}>DEBUG ROOM</AvenirHeavyText>
                <View style={{display: 'flex', flexDirection: 'row'}}>
                    {this.state.active &&
                    <View style={{flexDirection: 'column', flex: 1}}>
                        <View>
                            <Button onPress={() => openSignup(this.props.context, '')} title="Signup"/>
                        </View>
                        <View>
                            <Button onPress={() => testCrash()} title="Test Crash"/>
                        </View>
                        <View>
                            <Button onPress={() => openEmailVerification(this.props.context, {email: 'host@host.com'})}
                                    title="Email Verification"/>
                        </View>
                        <View>
                            <Button onPress={() => openDashboard(this.props.context, {})} title="Dashboard"/>
                        </View>
                        <View>
                            <Button onPress={() => openNewHouseDone(this.props.context, HouseFactory.factory())}
                                    title="New House Done"/>
                        </View>
                        <View>
                            <Button onPress={() => openHouseDetail(this.props.context, HouseFactory.factory())}
                                    title="House Detail"/>
                        </View>
                        <View>
                            <Button onPress={() => openDoorsList(this.props.context, HouseFactory.factory())}
                                    title="Doors List With House"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openNewReservationAccommodationsRoute(this.props.context, new ReservationBean())}
                                title="New reservation accommodations"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openDoorActions(this.props.context, HouseFactory.factory(), ResourceFactory.factory(), false)}
                                title="Door Actions"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openDoorActions(this.props.context, HouseFactory.factory(), ResourceFactory.factory(), true)}
                                title="Door Actions Aux"/>
                        </View>
                    </View>
                    }
                    {this.state.active &&
                    <View style={{flexDirection: 'column', flex: 1}}>
                        <View>
                            <Button onPress={() => openDoorsList(this.props.context, null)}
                                    title="Doors List NO House"/>
                        </View>
                        <View>
                            <Button onPress={() => openNewReservationPeriodRoute(this.props.context, {})}
                                    title="New reservation Period"/>
                        </View>
                        <View>
                            <Button onPress={() => openSettings(this.props.context)}
                                    title="DEBUG INFO"/>
                        </View>
                        <View>
                            <Button onPress={() => openLogin(this.props.context, '')} title="Login"/>
                        </View>
                        <View>
                            <Button onPress={() => openPhoneVerification(this.props.context, {
                                email: 'host@host.com',
                                phone: '333333333'
                            })}
                                    title="Phone Verification"/>
                        </View>
                        <View>
                            <Button onPress={() => openNewDoor(this.props.context, HouseFactory.factory())}
                                    title="New Door"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openNewReservationDoneRoute(this.props.context, new ReservationBean())}
                                title="New reservation done"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openSupportReportRoute(this.props.context)}
                                title="Support report"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openDoorBarcodeScanner(this.props.context, ResourceFactory.factory(), (data, type) => Logger.warn('DEBUG', `Type ${type}, Data ${data}`))}
                                title="Door Barcode"/>
                        </View>
                    </View>
                    }
                    {this.state.active &&
                    <View style={{flexDirection: 'column', flex: 1}}>
                        <View>
                            <Button
                                onPress={() => openDoorDetail(this.props.context, HouseFactory.factory(), ResourceFactory.factory())}
                                title="Door Detail"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openAccommodationsList(this.props.context, HouseFactory.factory())}
                                title="Accommodations list"/>
                        </View>
                        <View>
                            <Button onPress={() => openNewAccommodation(this.props.context, HouseFactory.factory())}
                                    title="New Accommodation"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openAccommodationDetail(this.props.context, HouseFactory.factory(), AccommodationFactory.factory())}
                                title="Accommodation Detail"/>
                        </View>
                        <View>
                            <Button onPress={() => openIntroReservation(this.props.context)}
                                    title="Intro reservation"/>
                        </View>
                        <View>
                            <Button onPress={() => openIntroReservationNewUser(this.props.context, {})}
                                    title="Intro reservation New User"/>
                        </View>
                        <View>
                            <Button
                                onPress={() => openReservationDetail(this.props.context, {}, () => console.log('Reservation detail'))}
                                title="Reservation detail"/>
                        </View>
                        <View>
                            <Button onPress={() => openStripeDebugScreenRoute(this.props.context)}
                                    title="Stripe Debug"/>
                        </View>
                        <View>
                            <Button onPress={() => openDashboardEventsList(this.props.context, {})}
                                    title="Events"/>
                        </View>
                    </View>
                    }
                </View>
            </TouchableOpacity>
        );
    }
}
