/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Collaborator,
    CollaboratorFromJSON,
    CollaboratorToJSON,
    VerificationCodeType,
    VerificationCodeTypeFromJSON,
    VerificationCodeTypeToJSON,
} from '../models';

export interface ApiHostCollaboratorsExistsEmailGetRequest {
    email: string;
}

export interface ApiHostCollaboratorsIdPutRequest {
    id: number;
    collaborator: Collaborator;
}

export interface ApiHostCollaboratorsPostRequest {
    collaborator: Collaborator;
}

export interface ApiHostCollaboratorsSendVerificationTypePostRequest {
    type: VerificationCodeType;
    collaborator: Collaborator;
}

/**
 * 
 */
export class HostCollaboratorsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostCollaboratorsExistsEmailGetRaw(requestParameters: ApiHostCollaboratorsExistsEmailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Collaborator>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling apiHostCollaboratorsExistsEmailGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborators/exists/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollaboratorFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCollaboratorsExistsEmailGet(requestParameters: ApiHostCollaboratorsExistsEmailGetRequest, initOverrides?: RequestInit): Promise<Collaborator> {
        const response = await this.apiHostCollaboratorsExistsEmailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCollaboratorsIdPutRaw(requestParameters: ApiHostCollaboratorsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostCollaboratorsIdPut.');
        }

        if (requestParameters.collaborator === null || requestParameters.collaborator === undefined) {
            throw new runtime.RequiredError('collaborator','Required parameter requestParameters.collaborator was null or undefined when calling apiHostCollaboratorsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborators/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CollaboratorToJSON(requestParameters.collaborator),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostCollaboratorsIdPut(requestParameters: ApiHostCollaboratorsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostCollaboratorsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostCollaboratorsPostRaw(requestParameters: ApiHostCollaboratorsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Collaborator>> {
        if (requestParameters.collaborator === null || requestParameters.collaborator === undefined) {
            throw new runtime.RequiredError('collaborator','Required parameter requestParameters.collaborator was null or undefined when calling apiHostCollaboratorsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborators`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollaboratorToJSON(requestParameters.collaborator),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollaboratorFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCollaboratorsPost(requestParameters: ApiHostCollaboratorsPostRequest, initOverrides?: RequestInit): Promise<Collaborator> {
        const response = await this.apiHostCollaboratorsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCollaboratorsSendVerificationTypePostRaw(requestParameters: ApiHostCollaboratorsSendVerificationTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiHostCollaboratorsSendVerificationTypePost.');
        }

        if (requestParameters.collaborator === null || requestParameters.collaborator === undefined) {
            throw new runtime.RequiredError('collaborator','Required parameter requestParameters.collaborator was null or undefined when calling apiHostCollaboratorsSendVerificationTypePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/collaborators/sendVerification/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollaboratorToJSON(requestParameters.collaborator),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostCollaboratorsSendVerificationTypePost(requestParameters: ApiHostCollaboratorsSendVerificationTypePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostCollaboratorsSendVerificationTypePostRaw(requestParameters, initOverrides);
    }

}
