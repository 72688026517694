/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ResourcePlanJoin,
    ResourcePlanJoinFromJSON,
    ResourcePlanJoinToJSON,
} from '../models';

export interface ApiResourcePlanJoinsIdDeleteRequest {
    id: number;
}

export interface ApiResourcePlanJoinsIdGetRequest {
    id: string;
}

export interface ApiResourcePlanJoinsIdPutRequest {
    id: number;
    resourcePlanJoin: ResourcePlanJoin;
}

export interface ApiResourcePlanJoinsPostRequest {
    resourcePlanJoin: ResourcePlanJoin;
}

/**
 * 
 */
export class ResourcePlanJoinsApi extends runtime.BaseAPI {

    /**
     */
    async apiResourcePlanJoinsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResourcePlanJoin>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_plan_joins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourcePlanJoinFromJSON));
    }

    /**
     */
    async apiResourcePlanJoinsGet(initOverrides?: RequestInit): Promise<Array<ResourcePlanJoin>> {
        const response = await this.apiResourcePlanJoinsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResourcePlanJoinsIdDeleteRaw(requestParameters: ApiResourcePlanJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcePlanJoinsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_plan_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourcePlanJoinsIdDelete(requestParameters: ApiResourcePlanJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourcePlanJoinsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResourcePlanJoinsIdGetRaw(requestParameters: ApiResourcePlanJoinsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResourcePlanJoin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcePlanJoinsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_plan_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourcePlanJoinFromJSON(jsonValue));
    }

    /**
     */
    async apiResourcePlanJoinsIdGet(requestParameters: ApiResourcePlanJoinsIdGetRequest, initOverrides?: RequestInit): Promise<ResourcePlanJoin> {
        const response = await this.apiResourcePlanJoinsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResourcePlanJoinsIdPutRaw(requestParameters: ApiResourcePlanJoinsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcePlanJoinsIdPut.');
        }

        if (requestParameters.resourcePlanJoin === null || requestParameters.resourcePlanJoin === undefined) {
            throw new runtime.RequiredError('resourcePlanJoin','Required parameter requestParameters.resourcePlanJoin was null or undefined when calling apiResourcePlanJoinsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_plan_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourcePlanJoinToJSON(requestParameters.resourcePlanJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourcePlanJoinsIdPut(requestParameters: ApiResourcePlanJoinsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourcePlanJoinsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResourcePlanJoinsPostRaw(requestParameters: ApiResourcePlanJoinsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourcePlanJoin === null || requestParameters.resourcePlanJoin === undefined) {
            throw new runtime.RequiredError('resourcePlanJoin','Required parameter requestParameters.resourcePlanJoin was null or undefined when calling apiResourcePlanJoinsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_plan_joins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourcePlanJoinToJSON(requestParameters.resourcePlanJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourcePlanJoinsPost(requestParameters: ApiResourcePlanJoinsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourcePlanJoinsPostRaw(requestParameters, initOverrides);
    }

}
