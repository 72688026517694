/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationTitleImage.tsx
 * @author     Christian Ascone
 * @date       10/14/19 2:58 PM
 */

import React from 'react';
import {Image} from "react-native";

export function ReservationTitleImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                require('../../assets/images/reservation_title_image.png')
            }
        />
    );
}