/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AlertHelper.ts
 * @author     Christian Ascone
 * @date       3/18/20 11:00 AM
 */

import {Alert, Platform} from "react-native";
import {i18n} from "@i18n/i18n";
import {GenericHelper} from "./GenericHelper";
import {ErrorHelper} from "@helpers/ErrorHelper";

export class AlertHelper {

    /**
     * Shows a simple alert with title and message with a single OK button
     *
     * @param title
     * @param message
     * @param callback
     */
    static showSimpleAlert(title, message, callback: () => void = null) {
        if (Platform.OS === 'web') {
            alert(message);
            if (callback != null) callback();
        } else {
            Alert.alert(
                title,
                message,
                [
                    {text: 'OK', onPress: () => callback != null ? callback() : console.log('OK Pressed')},
                ],
                {cancelable: false},
            );
        }
    }

    /**
     * Show a simple error alert
     */
    static async showSimpleErrorAlert() {
        if (Platform.OS === 'ios')
            await GenericHelper.delay(500);
        if (Platform.OS === 'web') {
            alert(i18n.t('errors.something_went_wrong'));
        } else {
            Alert.alert(
                i18n.t('error'),
                i18n.t('errors.something_went_wrong'),
                [
                    {text: 'OK', onPress: () => console.log('OK Pressed')},
                ],
                {cancelable: false},
            );
        }
    }

    /**
     * Show a simple error alert with given error message.
     * It translates the given error message.
     * @param error_message
     * @returns {Promise<void>}
     */
    static async showErrorAlertWithServerMessage(error_message) {
        if (Platform.OS === 'ios')
            await GenericHelper.delay(500);
        if (Platform.OS === 'web') {
            alert(ErrorHelper.getTranslatedServerErrorMessage(error_message));
        } else {
            Alert.alert(
                i18n.t('error'),
                ErrorHelper.getTranslatedServerErrorMessage(error_message),
                [
                    {text: 'OK', onPress: () => console.log('OK Pressed')},
                ],
                {cancelable: false},
            );
        }
    }

    /**
     * Show an alert with "yes" and "no" button
     * @param title
     * @param message
     * @param {(boolean) => void} callback
     */
    static showYesNoAlert(title, message, callback: (boolean) => void) {
        if (Platform.OS === 'web') {
            callback(confirm(message));
        } else {
            Alert.alert(
                title,
                message,
                [
                    {text: i18n.t('alerts.yes'), onPress: () => callback(true)},
                    {text: i18n.t('alerts.no'), onPress: () => callback(false)},
                ],
                {cancelable: false},
            );
        }
    }

    /**
     * Show an alert with confirm
     * @param {(boolean) => void} callback
     */
    static showConfirmAlert(callback: (boolean) => void) {
        return this.showYesNoAlert(i18n.t('alerts.attention'), i18n.t('alerts.are_you_sure'), callback);
    }

    /**
     * Show an alert to delete or replace an image
     * @param {() => void} replaceCallback
     * @param {() => void} deleteCallback
     */
    static showImageSelectionAlert(replaceCallback: () => void, deleteCallback: () => void) {
        if (Platform.OS === 'web') {
            let replace = confirm(i18n.t('alerts.want_replace_image'));
            if (replace) {
                replaceCallback();
                return;
            }
            let deleteIt = confirm(i18n.t('alerts.want_delete_image'));
            if (deleteIt)
                deleteCallback();
        } else {
            Alert.alert(
                i18n.t('alerts.attention'),
                i18n.t('alerts.which_action_image'),
                [
                    {text: i18n.t('alerts.replace'), onPress: () => replaceCallback()},
                    {text: i18n.t('alerts.delete'), onPress: () => deleteCallback()},
                    {text: i18n.t('alerts.cancel'),},
                ],
                {cancelable: false},
            );
        }
    }
}