/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseDoneBackgroundRightImage.tsx
 * @author     Christian Ascone
 * @date       9/20/19 5:37 PM
 */

import React from 'react';
import {Image} from "react-native";

export function HouseDoneBackgroundRightImage(props) {
    return (
        <Image
            {...props}
            style={[props.style, {resizeMode: "contain"}]}
            source={
                require('../../assets/images/house_success_right.png')
            }
            resizeMode="contain"
        />
    );
}