/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    locale?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    emailVerifiedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    phoneVerifiedAt?: Date;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'emailVerifiedAt': !exists(json, 'email_verified_at') ? undefined : (new Date(json['email_verified_at'])),
        'phoneVerifiedAt': !exists(json, 'phone_verified_at') ? undefined : (new Date(json['phone_verified_at'])),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'locale': value.locale,
        'email_verified_at': value.emailVerifiedAt === undefined ? undefined : (value.emailVerifiedAt.toISOString()),
        'phone_verified_at': value.phoneVerifiedAt === undefined ? undefined : (value.phoneVerifiedAt.toISOString()),
    };
}

