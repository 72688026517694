/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HostResourceUnlock
 */
export interface HostResourceUnlock {
    /**
     * 
     * @type {number}
     * @memberof HostResourceUnlock
     */
    index?: number;
}

export function HostResourceUnlockFromJSON(json: any): HostResourceUnlock {
    return HostResourceUnlockFromJSONTyped(json, false);
}

export function HostResourceUnlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostResourceUnlock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'index': !exists(json, 'index') ? undefined : json['index'],
    };
}

export function HostResourceUnlockToJSON(value?: HostResourceUnlock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'index': value.index,
    };
}

