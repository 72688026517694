/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AddButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {AvenirLightPrimaryText} from "./StyledText";
import {AddImage} from "@components/Images/AddImage";
import buttonStyle from "@styles/ButtonStyle";
import AddButtonProps from "./properties/AddButtonProps";

export function AddButton(props: AddButtonProps) {
    return (
        <View>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[buttonStyle.addButtonTouchable]}>
                <AddImage style={buttonStyle.addButtonImage}/>
                <AvenirLightPrimaryText style={buttonStyle.addButtonText}>{props.title}</AvenirLightPrimaryText>
            </TouchableOpacity>
        </View>
    );
}