/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Guest,
    GuestFromJSON,
    GuestToJSON,
} from '../models';

export interface ApiGuestsIdDeleteRequest {
    id: number;
}

export interface ApiGuestsIdGetRequest {
    id: string;
}

export interface ApiGuestsIdPutRequest {
    id: number;
    guest: Guest;
}

export interface ApiGuestsPostRequest {
    guest: Guest;
}

/**
 * 
 */
export class GuestsApi extends runtime.BaseAPI {

    /**
     */
    async apiGuestsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Guest>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/guests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GuestFromJSON));
    }

    /**
     */
    async apiGuestsGet(initOverrides?: RequestInit): Promise<Array<Guest>> {
        const response = await this.apiGuestsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGuestsIdDeleteRaw(requestParameters: ApiGuestsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGuestsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/guests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiGuestsIdDelete(requestParameters: ApiGuestsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiGuestsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiGuestsIdGetRaw(requestParameters: ApiGuestsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Guest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGuestsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/guests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestFromJSON(jsonValue));
    }

    /**
     */
    async apiGuestsIdGet(requestParameters: ApiGuestsIdGetRequest, initOverrides?: RequestInit): Promise<Guest> {
        const response = await this.apiGuestsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiGuestsIdPutRaw(requestParameters: ApiGuestsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGuestsIdPut.');
        }

        if (requestParameters.guest === null || requestParameters.guest === undefined) {
            throw new runtime.RequiredError('guest','Required parameter requestParameters.guest was null or undefined when calling apiGuestsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/guests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GuestToJSON(requestParameters.guest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiGuestsIdPut(requestParameters: ApiGuestsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiGuestsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiGuestsPostRaw(requestParameters: ApiGuestsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.guest === null || requestParameters.guest === undefined) {
            throw new runtime.RequiredError('guest','Required parameter requestParameters.guest was null or undefined when calling apiGuestsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/guests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GuestToJSON(requestParameters.guest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiGuestsPost(requestParameters: ApiGuestsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiGuestsPostRaw(requestParameters, initOverrides);
    }

}
