/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CollaborationFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */


import * as fakerStatic from 'faker';
import {Collaboration, CollaborationStateEnum, ReservationStateEnum} from "@services/src/models";

export class CollaborationFactory {


    static factory() : Collaboration {

        const typeArray = [CollaborationStateEnum.Ongoing, CollaborationStateEnum.Pending, CollaborationStateEnum.Canceled, CollaborationStateEnum.Confirmed, CollaborationStateEnum.Expired];
        var type = typeArray[fakerStatic.random.number(typeArray.length - 1)];
        return {
            id: fakerStatic.random.number(4),
            startAt: fakerStatic.date.future(),
            endAt: fakerStatic.date.future(),
            hostId: fakerStatic.random.number(4),
            state: type,
        };
    }
}

