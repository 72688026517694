/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       IntroCollaborationScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, View,} from 'react-native';

import {AvenirHeavyPrimaryText, AvenirLightGreyText} from '@components/StyledText';
import {i18n} from "../../../i18n/i18n";
import introScreenStyle from "@styles/host/collaborations/IntroCollaborationScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {TextInputBlock} from "@components/TextInputBlock";
import {ConfirmButton} from "@components/ConfirmButton";
import {normalizeEmail, validateEmail} from "../../../modules/Validator";
import {HostCollaboratorsApi} from "@services/src/apis/index";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openIntroCollaborationNewUser, openNewCollaborationCollaboratorRoute} from "@helpers/NavigationHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {CollaborationTitleImage} from "@components/Images/CollaborationTitleImage";

interface State extends LoaderState {
    email: string,
}

export default class IntroCollaborationScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            loading: false,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <KeyboardAvoidingView style={introScreenStyle.container}>
                <Loader state={this.state}/>
                <View
                                      style={[introScreenStyle.getStartedContainer, introScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <View style={introScreenStyle.welcomeContainer}>
                        <View style={introScreenStyle.titleTextContainer}>
                            <AvenirHeavyPrimaryText style={{fontSize: 25,}}>
                                {i18n.t('screens.intro_collaboration.title')}
                            </AvenirHeavyPrimaryText>
                        </View>
                        <View style={{flex: 2, alignContent: 'center', alignSelf: 'center',}}>
                            <CollaborationTitleImage style={introScreenStyle.welcomeImage}/>
                        </View>
                    </View>

                    <View style={introScreenStyle.welcomeContainer}>
                        <AvenirLightGreyText>
                            {i18n.t('screens.intro_collaboration.subtitle')}
                        </AvenirLightGreyText>
                    </View>

                    <View style={introScreenStyle.inputContainer}>
                        <TextInputBlock style={introScreenStyle.emailInput} keyboardType='email-address'
                                        label={i18n.t('inputs.mail_address')} text={this.state.email}
                                        onChangeText={(text) => this.setState({email: text})}/>
                    </View>

                    <View style={introScreenStyle.buttonContainer}>
                        <ConfirmButton onPress={() => openNextPage(this)} title={i18n.t('buttons.intro_collaboration')}
                                       active={true} style={{}}/>
                    </View>
                </View>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Checks if the email is valid and update state
 * @param context Current view context
 * @param text Input text
 */
function checkEmail(context, text) {
    context.setState({email: text.toLowerCase()});
    return validateEmail(text);
}

/**
 * Open the next page if email is valid
 * @param context
 */
function openNextPage(context) {
    if (!checkEmail(context, context.state.email)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_email'));
        return;
    }

    new HostCollaboratorsApi(context).apiHostCollaboratorsExistsEmailGet({email: context.state.email}).then(resp => {
        if (resp == null) {
            Logger.log(context.constructor.name, "Email does not exist");
            openIntroCollaborationNewUser(context, {email: normalizeEmail(context.state.email)});
        } else {
            Logger.log(context.constructor.name, "Email EXISTS");
            openNewCollaborationCollaboratorRoute(context, resp);
        }
    }).catch(error => {
        Logger.warn(context.constructor.name, "Email does not exist");
        openIntroCollaborationNewUser(context, {email: normalizeEmail(context.state.email)});
    });
}