/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FilterHouseImageButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import ButtonStyle from "@styles/ButtonStyle";
import {CheckImage} from "@components/Images/CheckImage";
import FilterHouseImageButtonProps from "./properties/FilterHouseImageButtonProps";

export function FilterHouseImageButton(props: FilterHouseImageButtonProps) {
    let uri = props.selectedImageUri != null && props.selectedImageUri != '' ? { uri: props.selectedImageUri} : require('../assets/images/avatar_placeholder.png');
    return (
        <View style={props.style}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[ButtonStyle.filterHouseImageContainer, props.selected ? ButtonStyle.filterHouseImageSelectedContainer : null,]}>
                <Image style={ButtonStyle.filterHouseImage} source={
                    uri
                }/>
                {props.selected &&
                <CheckImage style={ButtonStyle.filterCheck}/>
                }
            </TouchableOpacity>
        </View>
    );
}