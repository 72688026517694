/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ResidentialColorAddImage.tsx
 * @author     Christian Ascone
 * @date       9/26/19 10:14 AM
 */

import React from 'react';
import {Image} from "react-native";

export function ResidentialColorAddImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                require('../../../assets/images/residential_color_add.png')
            }
        />
    );
}