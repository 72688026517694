/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SegmentHelper.ts
 * @author     Christian Ascone
 * @date       9/17/19 5:23 PM
 */

export const RESERVATIONS_DASHBOARD_SEGMENT_TAB = 1;
export const HOUSES_DASHBOARD_SEGMENT_TAB = 2;
export const CREDITS_DASHBOARD_SEGMENT_TAB = 3;
export const EVENTS_DASHBOARD_SEGMENT_TAB = 3;