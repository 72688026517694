/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostHouse,
    HostHouseFromJSON,
    HostHouseFromJSONTyped,
    HostHouseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HouseDetail
 */
export interface HouseDetail {
    /**
     * 
     * @type {HostHouse}
     * @memberof HouseDetail
     */
    data?: HostHouse;
}

export function HouseDetailFromJSON(json: any): HouseDetail {
    return HouseDetailFromJSONTyped(json, false);
}

export function HouseDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): HouseDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : HostHouseFromJSON(json['data']),
    };
}

export function HouseDetailToJSON(value?: HouseDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': HostHouseToJSON(value.data),
    };
}

