/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HostResourceDetail,
    HostResourceDetailFromJSON,
    HostResourceDetailToJSON,
    HostResourceInput,
    HostResourceInputFromJSON,
    HostResourceInputToJSON,
    HostResourceList,
    HostResourceListFromJSON,
    HostResourceListToJSON,
    HostResourceResponse,
    HostResourceResponseFromJSON,
    HostResourceResponseToJSON,
    HostResourceUnlock,
    HostResourceUnlockFromJSON,
    HostResourceUnlockToJSON,
    ReservationGuestBean,
    ReservationGuestBeanFromJSON,
    ReservationGuestBeanToJSON,
    ResourceFilterInput,
    ResourceFilterInputFromJSON,
    ResourceFilterInputToJSON,
} from '../models';

export interface ApiHostResourcesByFiltersPostRequest {
    resourceFilterInput: ResourceFilterInput;
}

export interface ApiHostResourcesByHouseIdGetRequest {
    id: number;
}

export interface ApiHostResourcesIdCandeleteGetRequest {
    id: number;
}

export interface ApiHostResourcesIdDeleteRequest {
    id: number;
}

export interface ApiHostResourcesIdGetRequest {
    id: string;
}

export interface ApiHostResourcesIdPutRequest {
    id: number;
    hostResourceInput: HostResourceInput;
}

export interface ApiHostResourcesIdUnlockPostRequest {
    id: string;
    hostResourceUnlock: HostResourceUnlock;
}

export interface ApiHostResourcesPostRequest {
    hostResourceInput: HostResourceInput;
}

/**
 * 
 */
export class HostResourcesApi extends runtime.BaseAPI {

    /**
     */
    async apiHostResourcesByFiltersPostRaw(requestParameters: ApiHostResourcesByFiltersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostResourceList>> {
        if (requestParameters.resourceFilterInput === null || requestParameters.resourceFilterInput === undefined) {
            throw new runtime.RequiredError('resourceFilterInput','Required parameter requestParameters.resourceFilterInput was null or undefined when calling apiHostResourcesByFiltersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources/byFilters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceFilterInputToJSON(requestParameters.resourceFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostResourceListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostResourcesByFiltersPost(requestParameters: ApiHostResourcesByFiltersPostRequest, initOverrides?: RequestInit): Promise<HostResourceList> {
        const response = await this.apiHostResourcesByFiltersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostResourcesByHouseIdGetRaw(requestParameters: ApiHostResourcesByHouseIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostResourceList>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcesByHouseIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources/byHouse/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostResourceListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostResourcesByHouseIdGet(requestParameters: ApiHostResourcesByHouseIdGetRequest, initOverrides?: RequestInit): Promise<HostResourceList> {
        const response = await this.apiHostResourcesByHouseIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostResourcesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostResourceList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostResourceListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostResourcesGet(initOverrides?: RequestInit): Promise<HostResourceList> {
        const response = await this.apiHostResourcesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostResourcesIdCandeleteGetRaw(requestParameters: ApiHostResourcesIdCandeleteGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ReservationGuestBean>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcesIdCandeleteGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources/{id}/candelete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReservationGuestBeanFromJSON));
    }

    /**
     */
    async apiHostResourcesIdCandeleteGet(requestParameters: ApiHostResourcesIdCandeleteGetRequest, initOverrides?: RequestInit): Promise<Array<ReservationGuestBean>> {
        const response = await this.apiHostResourcesIdCandeleteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostResourcesIdDeleteRaw(requestParameters: ApiHostResourcesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostResourcesIdDelete(requestParameters: ApiHostResourcesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostResourcesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostResourcesIdGetRaw(requestParameters: ApiHostResourcesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostResourceDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostResourceDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHostResourcesIdGet(requestParameters: ApiHostResourcesIdGetRequest, initOverrides?: RequestInit): Promise<HostResourceDetail> {
        const response = await this.apiHostResourcesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostResourcesIdPutRaw(requestParameters: ApiHostResourcesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcesIdPut.');
        }

        if (requestParameters.hostResourceInput === null || requestParameters.hostResourceInput === undefined) {
            throw new runtime.RequiredError('hostResourceInput','Required parameter requestParameters.hostResourceInput was null or undefined when calling apiHostResourcesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HostResourceInputToJSON(requestParameters.hostResourceInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostResourcesIdPut(requestParameters: ApiHostResourcesIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostResourcesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostResourcesIdUnlockPostRaw(requestParameters: ApiHostResourcesIdUnlockPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostResourceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcesIdUnlockPost.');
        }

        if (requestParameters.hostResourceUnlock === null || requestParameters.hostResourceUnlock === undefined) {
            throw new runtime.RequiredError('hostResourceUnlock','Required parameter requestParameters.hostResourceUnlock was null or undefined when calling apiHostResourcesIdUnlockPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources/{id}/unlock`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostResourceUnlockToJSON(requestParameters.hostResourceUnlock),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostResourceResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiHostResourcesIdUnlockPost(requestParameters: ApiHostResourcesIdUnlockPostRequest, initOverrides?: RequestInit): Promise<HostResourceResponse> {
        const response = await this.apiHostResourcesIdUnlockPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostResourcesPostRaw(requestParameters: ApiHostResourcesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostResourceResponse>> {
        if (requestParameters.hostResourceInput === null || requestParameters.hostResourceInput === undefined) {
            throw new runtime.RequiredError('hostResourceInput','Required parameter requestParameters.hostResourceInput was null or undefined when calling apiHostResourcesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostResourceInputToJSON(requestParameters.hostResourceInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostResourceResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiHostResourcesPost(requestParameters: ApiHostResourcesPostRequest, initOverrides?: RequestInit): Promise<HostResourceResponse> {
        const response = await this.apiHostResourcesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
