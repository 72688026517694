/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccommodationResources,
    AccommodationResourcesFromJSON,
    AccommodationResourcesFromJSONTyped,
    AccommodationResourcesToJSON,
    House,
    HouseFromJSON,
    HouseFromJSONTyped,
    HouseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostCollaborationAccommodationJoin
 */
export interface HostCollaborationAccommodationJoin {
    /**
     * 
     * @type {number}
     * @memberof HostCollaborationAccommodationJoin
     */
    id?: number;
    /**
     * 
     * @type {House}
     * @memberof HostCollaborationAccommodationJoin
     */
    house?: House;
    /**
     * 
     * @type {Array<AccommodationResources>}
     * @memberof HostCollaborationAccommodationJoin
     */
    accommodations?: Array<AccommodationResources>;
}

export function HostCollaborationAccommodationJoinFromJSON(json: any): HostCollaborationAccommodationJoin {
    return HostCollaborationAccommodationJoinFromJSONTyped(json, false);
}

export function HostCollaborationAccommodationJoinFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostCollaborationAccommodationJoin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'house': !exists(json, 'house') ? undefined : HouseFromJSON(json['house']),
        'accommodations': !exists(json, 'accommodations') ? undefined : ((json['accommodations'] as Array<any>).map(AccommodationResourcesFromJSON)),
    };
}

export function HostCollaborationAccommodationJoinToJSON(value?: HostCollaborationAccommodationJoin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'house': HouseToJSON(value.house),
        'accommodations': value.accommodations === undefined ? undefined : ((value.accommodations as Array<any>).map(AccommodationResourcesToJSON)),
    };
}

