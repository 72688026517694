/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResourceType,
    ResourceTypeFromJSON,
    ResourceTypeFromJSONTyped,
    ResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResourceFilterInput
 */
export interface ResourceFilterInput {
    /**
     * 
     * @type {Array<number>}
     * @memberof ResourceFilterInput
     */
    houseIds?: Array<number>;
    /**
     * 
     * @type {ResourceType}
     * @memberof ResourceFilterInput
     */
    type?: ResourceType;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceFilterInput
     */
    online?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceFilterInput
     */
    resourceStateActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceFilterInput
     */
    resourceStateExpiring?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResourceFilterInput
     */
    resourceStateExpired?: boolean;
}

export function ResourceFilterInputFromJSON(json: any): ResourceFilterInput {
    return ResourceFilterInputFromJSONTyped(json, false);
}

export function ResourceFilterInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceFilterInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'houseIds': !exists(json, 'house_ids') ? undefined : json['house_ids'],
        'type': !exists(json, 'type') ? undefined : ResourceTypeFromJSON(json['type']),
        'online': !exists(json, 'online') ? undefined : json['online'],
        'resourceStateActive': !exists(json, 'resource_state_active') ? undefined : json['resource_state_active'],
        'resourceStateExpiring': !exists(json, 'resource_state_expiring') ? undefined : json['resource_state_expiring'],
        'resourceStateExpired': !exists(json, 'resource_state_expired') ? undefined : json['resource_state_expired'],
    };
}

export function ResourceFilterInputToJSON(value?: ResourceFilterInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'house_ids': value.houseIds,
        'type': ResourceTypeToJSON(value.type),
        'online': value.online,
        'resource_state_active': value.resourceStateActive,
        'resource_state_expiring': value.resourceStateExpiring,
        'resource_state_expired': value.resourceStateExpired,
    };
}

