/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationFiltersScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {Platform, StyleSheet} from "react-native";
import Colors from "../../../config/Colors";
import {Header} from "react-navigation";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.background,
        ...Platform.select({
            web: {
                height: Layout.window.height - Header.HEIGHT,
            },
        }),
    },
    scrollContainer: {
        flex: 1,
    },
    scrollOuter: {
        marginVertical: 20,
        marginHorizontal: 15,
    },
    forgotPassword: {
        textAlign: 'right',
    },
    developmentModeText: {
        marginBottom: 20,
        color: 'rgba(0,0,0,0.4)',
        fontSize: 14,
        lineHeight: 19,
        textAlign: 'center',
    },
    contentContainer: {
        justifyContent: 'space-between',
    },
    welcomeContainer: {
        marginTop: 10,
        alignSelf: 'center',
    },
    inputContainer: {
        flex: 1,
    },
    buttonContainer: {
        flex: 2,
        marginTop: 10,
        marginBottom: 20,
    },
    serviceSelectionContainer: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 20,
    },
    serviceBlock: {
        flex: 1,
        height: 50,
    },
    residentialBlock: {
        marginRight: 5,
    },
    publicBlock: {
        marginLeft: 5,
    },
    welcomeImage: {
        width: 150,
        height: 50,
        resizeMode: 'contain',
        marginTop: 3,
    },
    welcomeText: {
        flex: 1,
    },
    getStartedContainer: {
        height: '100%',
    },
    getStartedText: {
        fontSize: 17,
        color: Colors.textColor,
        lineHeight: 24,
        textAlign: 'center',
    },
    bigContainer: {
        flex: 1,
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    },
    textContainer: {
        flex: 1,
        margin: 0,
        padding: 10,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    doorImage: {
        width: 35,
        height: 35,
        alignSelf: 'center',
        resizeMode: 'contain',
    },
    flex: {
        flex: 1,
    },
});

export default styles;