/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationsScreen.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import reservationsScreenStyle from "@styles/host/reservations/ReservationsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openIntroReservation, openReservationFiltersModal} from "@helpers/NavigationHelper";
import {AddButton} from "@components/AddButton";
import {
    getHouseFromNavigationProps,
    getUserFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {HostReservation, House, ReservationFilterInput, User} from "@services/src/models/index";
import {HostHousesApi, HostReservationsApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {DebugButton} from "@components/DebugButton";
import {NoDataLabel} from "@components/NoDataLabel";
import {FiltersClickableBlock} from "@components/ClickableBlock";
import {HouseSelection, HouseSelectionBean} from "../../../models/beans/HouseSelection";
import {ReservationRow} from "@components/rows/ReservationRow";
import {HostReservationFactory} from "../../../models/factory/HostReservationFactory";
import {FilterHelper} from "@helpers/FilterHelper";

interface State extends LoaderState {
    house: House,
    houseSelectionBeans: Array<HouseSelectionBean>,
    user: User,
    reservations: Array<HostReservation>,
    filters: ReservationFilterInput,
    filters_count: number,
}

export default class ReservationsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.reservations.title');

        this.state = {
            house: getHouseFromNavigationProps(this),
            houseSelectionBeans: [],
            loading: false,
            user: getUserFromNavigationProps(this),
            reservations: [],
            filters: {
                registryEmail: '',
            },
            filters_count: 0,
            refreshing: false,
        };
    }

    componentDidMount() {
        this.getAllReservations();
        //this.getReservations();
        this.didFocusListener = this.props.navigation.addListener('didFocus', payload => {
            this.getReservations();
        });
    }

    componentWillUnmount(): void {
        this.didFocusListener.remove();
    }

    render() {
        return (
            <View style={[reservationsScreenStyle.container]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[reservationsScreenStyle.container, globalScreenStyle.globalMargins]}>
                    {/*{this.state.house != null &&*/}
                    {/*<View>*/}
                    {/*<AvenirMediumPrimaryHalfOpacityText style={{textAlign: 'center'}}>*/}
                    {/*{this.state.house.name}*/}
                    {/*</AvenirMediumPrimaryHalfOpacityText>*/}
                    {/*</View>*/}
                    {/*}*/}
                    <View>
                        <FiltersClickableBlock
                            onPress={() => openReservationFiltersModal(this, this.state.filters, (filters) => this.applyFilters(filters), this.state.houseSelectionBeans)}
                            title={this.state.filters_count > 0 ? i18n.t('screens.reservations.filters_with_count', {count: this.state.filters_count}) : i18n.t('screens.reservations.filters')}
                            style={{marginVertical: 10, width: '40%', alignSelf: 'center',}}/>
                    </View>
                    <View style={globalScreenStyle.scrollContainerWithBottomButton}>
                        <ScrollView
                            style={[reservationsScreenStyle.container]}
                            contentContainerStyle={[reservationsScreenStyle.contentContainer]}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing}
                                                onRefresh={() => this.getReservations()}/>
                            }>
                            {this.state.reservations.map((reservation, key) => (
                                <View
                                    key={key}
                                    style={[reservationsScreenStyle.doorContainer]}>
                                    <ReservationRow context={this}
                                                    reservation={reservation}
                                                    user={reservation.user}
                                                    onDismiss={() => this.getReservations()}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.reservations} show={this.state.showNoData}/>
                    </View>
                    <View style={[{position: 'absolute', bottom: 20}]}>
                        <AddButton title={i18n.t('screens.reservations.add_button')}
                                   onPress={() => openIntroReservation(this)}/>
                    </View>
                </View>
            </View>
        );
    }

    applyFilters(filters: ReservationFilterInput) {
        console.log(filters);
        this.setState({filters: filters, filters_count: FilterHelper.countReservationsFilters(filters)}, () => {
            this.getReservations();
        });
    }

    private getReservations() {
        if (this.state.filters_count == 0)
            this.getAllReservations();
        else
            this.getReservationsByFilters();
    }

    private getAllReservations() {
        let self = this;
        new HostReservationsApi(this).apiHostReservationsGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} reservations for host.`);
            self.setState({reservations: resp.data, showNoData: true});
            self.getHouses();
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }

    private getReservationsByFilters() {
        let self = this;
        Logger.log(this.constructor.name, `Calling with filters: ${JSON.stringify(this.state.filters)}`);
        new HostReservationsApi(this).apiHostReservationsByFiltersPost({reservationFilterInput: this.state.filters}).then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} reservations by filters for host.`);
            self.setState({reservations: resp.data, showNoData: true});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }

    private getHouses() {
        let self = this;
        new HostHousesApi(this).apiHostHousesGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} resources for host.`);
            self.setState({houseSelectionBeans: HouseSelection.fromHouseList(resp.data)});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
        });
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({reservations: HostReservationFactory.factoryArray(15)});
}