/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationSelectionRow.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React from 'react';
import {GestureResponderEvent, TouchableOpacity, View} from 'react-native';
import {AvenirBookGreyLabelText, AvenirBookPrimaryText, AvenirBookServiceGreyText} from "../StyledText";
import {Accommodation} from "@services/src/models";
import accommodationDetailStyle from "@styles/host/rows/AccommodationSelectionRowStyle";
import {CheckImage} from "@components/Images/CheckImage";
import {CheckEmptyImage} from "@components/Images/CheckEmptyImage";
import accommodationRowStyles from "@styles/host/rows/AccommodationRowStyle";
import {GateResidentialColorImage} from "@components/Images/GateResidentialColorImage";
import {GatePublicColorImage} from "@components/Images/GatePublicColorImage";

export interface AccommodationSelectionRowProps {
    accommodation: Accommodation,
    selected: boolean,
    active: boolean,
    onPress: (event: GestureResponderEvent) => void,
}

export function AccommodationSelectionRow(props: AccommodationSelectionRowProps) {
    return (
        <TouchableOpacity style={accommodationDetailStyle.serviceSelectionContainer} onPress={props.onPress}>
            <View
                style={[accommodationDetailStyle.bigContainer, accommodationDetailStyle.textContainer, {flexDirection: 'row',}]}>
                <View style={{
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                    marginRight: 5,
                }}>
                    {props.selected &&
                    <CheckImage style={[accommodationDetailStyle.accommodationImage,]}/>
                    }
                    {!props.selected && ( props.active ?
                    <CheckEmptyImage style={[accommodationDetailStyle.accommodationImage,]}/>
                    : <CheckEmptyImage style={[accommodationDetailStyle.accommodationImage, {borderColor: "grey"}]}/>
                    )}
                </View>
                <View style={{marginLeft: 5, flex: 7,}}>
                    {props.active ? <AvenirBookPrimaryText style={{marginLeft: 1, marginBottom: 3,}}>
                        {props.accommodation.name}
                    </AvenirBookPrimaryText> : <AvenirBookServiceGreyText style={{marginLeft: 1, marginBottom: 3,}}>
                        {props.accommodation.name}
                        </AvenirBookServiceGreyText> }
                    <View style={{flexDirection: 'row', alignItems: 'baseline',}}>
                        <GateResidentialColorImage style={accommodationRowStyles.gateResidential}/>
                        <AvenirBookGreyLabelText
                            style={accommodationRowStyles.gateResidentialText}>{props.accommodation.residentialResourcesCount}</AvenirBookGreyLabelText>
                        <GatePublicColorImage style={accommodationRowStyles.gatePublic}/>
                        <AvenirBookGreyLabelText
                            style={accommodationRowStyles.gatePublicText}>{props.accommodation.publicResourcesCount}</AvenirBookGreyLabelText>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
}