/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HostHouseDelete,
    HostHouseDeleteFromJSON,
    HostHouseDeleteToJSON,
    HostHouseInput,
    HostHouseInputFromJSON,
    HostHouseInputToJSON,
    HostHouseList,
    HostHouseListFromJSON,
    HostHouseListToJSON,
    HouseComplete,
    HouseCompleteFromJSON,
    HouseCompleteToJSON,
    HouseCompleteList,
    HouseCompleteListFromJSON,
    HouseCompleteListToJSON,
    HouseDetail,
    HouseDetailFromJSON,
    HouseDetailToJSON,
    HouseFilterInput,
    HouseFilterInputFromJSON,
    HouseFilterInputToJSON,
} from '../models';

export interface ApiHostHousesByFiltersPostRequest {
    houseFilterInput: HouseFilterInput;
}

export interface ApiHostHousesIdCandeleteGetRequest {
    id: number;
}

export interface ApiHostHousesIdCompleteGetRequest {
    id: string;
}

export interface ApiHostHousesIdDeleteRequest {
    id: number;
}

export interface ApiHostHousesIdGetRequest {
    id: string;
}

export interface ApiHostHousesIdPutRequest {
    id: number;
    hostHouseInput: HostHouseInput;
}

export interface ApiHostHousesPostRequest {
    hostHouseInput: HostHouseInput;
}

/**
 * 
 */
export class HostHousesApi extends runtime.BaseAPI {

    /**
     */
    async apiHostHousesByFiltersPostRaw(requestParameters: ApiHostHousesByFiltersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HouseCompleteList>> {
        if (requestParameters.houseFilterInput === null || requestParameters.houseFilterInput === undefined) {
            throw new runtime.RequiredError('houseFilterInput','Required parameter requestParameters.houseFilterInput was null or undefined when calling apiHostHousesByFiltersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/houses/byFilters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HouseFilterInputToJSON(requestParameters.houseFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HouseCompleteListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostHousesByFiltersPost(requestParameters: ApiHostHousesByFiltersPostRequest, initOverrides?: RequestInit): Promise<HouseCompleteList> {
        const response = await this.apiHostHousesByFiltersPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostHousesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostHouseList>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/houses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostHouseListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostHousesGet(initOverrides?: RequestInit): Promise<HostHouseList> {
        const response = await this.apiHostHousesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostHousesIdCandeleteGetRaw(requestParameters: ApiHostHousesIdCandeleteGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostHouseDelete>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostHousesIdCandeleteGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/houses/{id}/candelete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostHouseDeleteFromJSON(jsonValue));
    }

    /**
     */
    async apiHostHousesIdCandeleteGet(requestParameters: ApiHostHousesIdCandeleteGetRequest, initOverrides?: RequestInit): Promise<HostHouseDelete> {
        const response = await this.apiHostHousesIdCandeleteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostHousesIdCompleteGetRaw(requestParameters: ApiHostHousesIdCompleteGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HouseComplete>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostHousesIdCompleteGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/houses/{id}/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HouseCompleteFromJSON(jsonValue));
    }

    /**
     */
    async apiHostHousesIdCompleteGet(requestParameters: ApiHostHousesIdCompleteGetRequest, initOverrides?: RequestInit): Promise<HouseComplete> {
        const response = await this.apiHostHousesIdCompleteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostHousesIdDeleteRaw(requestParameters: ApiHostHousesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostHousesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/houses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostHousesIdDelete(requestParameters: ApiHostHousesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostHousesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostHousesIdGetRaw(requestParameters: ApiHostHousesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HouseDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostHousesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/houses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HouseDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHostHousesIdGet(requestParameters: ApiHostHousesIdGetRequest, initOverrides?: RequestInit): Promise<HouseDetail> {
        const response = await this.apiHostHousesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostHousesIdPutRaw(requestParameters: ApiHostHousesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HouseDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostHousesIdPut.');
        }

        if (requestParameters.hostHouseInput === null || requestParameters.hostHouseInput === undefined) {
            throw new runtime.RequiredError('hostHouseInput','Required parameter requestParameters.hostHouseInput was null or undefined when calling apiHostHousesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/houses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HostHouseInputToJSON(requestParameters.hostHouseInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HouseDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHostHousesIdPut(requestParameters: ApiHostHousesIdPutRequest, initOverrides?: RequestInit): Promise<HouseDetail> {
        const response = await this.apiHostHousesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostHousesPostRaw(requestParameters: ApiHostHousesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HouseDetail>> {
        if (requestParameters.hostHouseInput === null || requestParameters.hostHouseInput === undefined) {
            throw new runtime.RequiredError('hostHouseInput','Required parameter requestParameters.hostHouseInput was null or undefined when calling apiHostHousesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/houses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostHouseInputToJSON(requestParameters.hostHouseInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HouseDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHostHousesPost(requestParameters: ApiHostHousesPostRequest, initOverrides?: RequestInit): Promise<HouseDetail> {
        const response = await this.apiHostHousesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
