/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorRow.tsx
 * @author     Christian Ascone
 * @date       3/27/20 3:04 PM
 */

import React, {Component} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {
    AvenirHeavyLabelText,
    AvenirLightGreyText,
    AvenirLightPrimaryText,
    AvenirMediumPrimaryText
} from "../StyledText";
import {RightArrowColorImage} from "@components/Images/RightArrowColorImage";
import doorRowStyles from "@styles/host/rows/DoorRowStyle";
import {NavigationProps, openDoorActions} from "@helpers/NavigationHelper";
import {Device, House, Resource, ResourceStateEnum, ResourceType} from "@services/src/models";
import {PublicColorImage} from "@components/Images/services/PublicColorImage";
import {ResidentialColorImage} from "@components/Images/services/ResidentialColorImage";
import {StatusConnectionDotView} from "../StatusDotView";
import {i18n} from "@i18n/i18n";

export interface DoorRowProps {
    house: House,
    context: Component<NavigationProps, any>,
    online: boolean,
    activationStatus: ResourceStateEnum,
    resource: Resource,
    device?: Device,
}

export function DoorRow(props: DoorRowProps) {
    let prefix = 'rows.door.';
    const isAuxUnlockEnabled = props.device != null && props.device.auxUnlockEnabled;
    return (
        <View style={[doorRowStyles.flex, doorRowStyles.container]}>
            <TouchableOpacity
                onPress={() => openDoorActions(props.context, props.house, props.resource, isAuxUnlockEnabled)}>
                <View
                    style={[doorRowStyles.bigContainer, doorRowStyles.textContainer, {flexDirection: 'row',}]}>
                    <View style={{flex: 7,}}>
                        <View style={{flexDirection: 'row', alignContent: 'center', alignItems: 'center'}}>
                            {props.resource.type == ResourceType.Public &&
                            <PublicColorImage style={[doorRowStyles.doorImage,]}/>
                            }
                            {props.resource.type == ResourceType.Residential &&
                            <ResidentialColorImage style={[doorRowStyles.doorImage,]}/>
                            }
                            <AvenirMediumPrimaryText numberOfLines={1}
                                                     style={{flex: 1, textAlign: "left", marginHorizontal: 10,}}>
                                {isAuxUnlockEnabled ? props.device.auxUnlockText : props.resource.name}
                            </AvenirMediumPrimaryText>
                            <AvenirLightPrimaryText numberOfLines={1} style={{flex: 1, textAlign: "left",}}>
                                {props.house.name}
                            </AvenirLightPrimaryText>
                        </View>
                        <View style={{flexDirection: 'row', alignItems: 'center', alignContent: 'center', marginTop: 5,}}>
                            <StatusConnectionDotView enabled={props.online} style={doorRowStyles.flex}/>
                            {/* Commented out the resource status cause it is unused */}
                            {/*<StatusActivationDotView status={props.activationStatus} style={doorRowStyles.flex}/>*/}
                            {props.resource.currentPlan != null &&
                            <View style={[doorRowStyles.flex, {flexDirection: 'row',}]}>
                                <AvenirLightGreyText numberOfLines={1} style={{marginRight: 2,}}>
                                    {i18n.t(prefix + 'credits_plan')}:
                                </AvenirLightGreyText>
                                <AvenirHeavyLabelText style={{}}>
                                    {props.resource.currentPlan}
                                </AvenirHeavyLabelText>
                            </View>
                            }
                        </View>
                    </View>
                    <View style={[doorRowStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[doorRowStyles.detailArrow]}/>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
}