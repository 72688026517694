/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    UserToken,
    UserTokenFromJSON,
    UserTokenToJSON,
} from '../models';

export interface ApiUserTokensIdDeleteRequest {
    id: number;
}

export interface ApiUserTokensIdGetRequest {
    id: string;
}

export interface ApiUserTokensIdPutRequest {
    id: number;
    userToken: UserToken;
}

export interface ApiUserTokensPostRequest {
    userToken: UserToken;
}

/**
 * 
 */
export class UserTokensApi extends runtime.BaseAPI {

    /**
     */
    async apiUserTokensGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserToken>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/user_tokens`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserTokenFromJSON));
    }

    /**
     */
    async apiUserTokensGet(initOverrides?: RequestInit): Promise<Array<UserToken>> {
        const response = await this.apiUserTokensGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserTokensIdDeleteRaw(requestParameters: ApiUserTokensIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserTokensIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/user_tokens/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserTokensIdDelete(requestParameters: ApiUserTokensIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserTokensIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUserTokensIdGetRaw(requestParameters: ApiUserTokensIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserToken>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserTokensIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/user_tokens/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTokenFromJSON(jsonValue));
    }

    /**
     */
    async apiUserTokensIdGet(requestParameters: ApiUserTokensIdGetRequest, initOverrides?: RequestInit): Promise<UserToken> {
        const response = await this.apiUserTokensIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUserTokensIdPutRaw(requestParameters: ApiUserTokensIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUserTokensIdPut.');
        }

        if (requestParameters.userToken === null || requestParameters.userToken === undefined) {
            throw new runtime.RequiredError('userToken','Required parameter requestParameters.userToken was null or undefined when calling apiUserTokensIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/user_tokens/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserTokenToJSON(requestParameters.userToken),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserTokensIdPut(requestParameters: ApiUserTokensIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserTokensIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUserTokensPostRaw(requestParameters: ApiUserTokensPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userToken === null || requestParameters.userToken === undefined) {
            throw new runtime.RequiredError('userToken','Required parameter requestParameters.userToken was null or undefined when calling apiUserTokensPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/user_tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserTokenToJSON(requestParameters.userToken),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserTokensPost(requestParameters: ApiUserTokensPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUserTokensPostRaw(requestParameters, initOverrides);
    }

}
