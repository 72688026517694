/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccommodationResourceJoin
 */
export interface AccommodationResourceJoin {
    /**
     * 
     * @type {number}
     * @memberof AccommodationResourceJoin
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AccommodationResourceJoin
     */
    resourceId?: number;
    /**
     * 
     * @type {number}
     * @memberof AccommodationResourceJoin
     */
    accommodationId?: number;
}

export function AccommodationResourceJoinFromJSON(json: any): AccommodationResourceJoin {
    return AccommodationResourceJoinFromJSONTyped(json, false);
}

export function AccommodationResourceJoinFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccommodationResourceJoin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'accommodationId': !exists(json, 'accommodation_id') ? undefined : json['accommodation_id'],
    };
}

export function AccommodationResourceJoinToJSON(value?: AccommodationResourceJoin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'resource_id': value.resourceId,
        'accommodation_id': value.accommodationId,
    };
}

