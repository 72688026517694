/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorsScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 10:43 AM
 */

import React, {Component} from 'react';
import {RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import doorsScreenStyle from "@styles/host/doors/DoorsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openDoorFiltersModal, openNewDoor} from "@helpers/NavigationHelper";
import {AddButton} from "@components/AddButton";
import {
    getHouseFromNavigationProps,
    getUserFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {HostResource, House, ResourceFilterInput, User} from "@services/src/models/index";
import {AvenirMediumPrimaryHalfOpacityText} from "@components/StyledText";
import {HostHousesApi, HostResourcesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {DoorRow} from "@components/rows/DoorRow";
import {DebugButton} from "@components/DebugButton";
import {HostResourceFactory} from "../../../models/factory/HostResourceFactory";
import {NoDataLabel} from "@components/NoDataLabel";
import {FiltersClickableBlock} from "@components/ClickableBlock";
import {HouseSelection, HouseSelectionBean} from "../../../models/beans/HouseSelection";
import {FilterHelper} from "@helpers/FilterHelper";
import {DoorAuxUnlockHelper} from "@helpers/DoorAuxUnlockHelper";

interface State extends LoaderState {
    house: House,
    houseSelectionBeans: Array<HouseSelectionBean>,
    user: User,
    resources: Array<HostResource>,
    filters: ResourceFilterInput,
    filters_count: number,
}

export default class DoorsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.doors.title');

        this.state = {
            house: getHouseFromNavigationProps(this),
            houseSelectionBeans: [],
            loading: false,
            user: getUserFromNavigationProps(this),
            resources: [],
            filters: {},
            filters_count: 0,
        };
    }

    componentDidMount() {
        if (this.state.house != null && this.state.house.id != null) {
            this.getResourcesByHouse();
        } else {
            this.getAllResources();
        }

        this.didFocusListener = this.props.navigation.addListener('didFocus', payload => {
            this.onRefresh();
        });
    }

    componentWillUnmount(): void {
        this.didFocusListener.remove();
    }

    render() {
        return (
            <View style={[doorsScreenStyle.container,]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[doorsScreenStyle.container, globalScreenStyle.globalMargins]}>
                    {this.state.house != null &&
                    <View>
                        <AvenirMediumPrimaryHalfOpacityText style={{textAlign: 'center'}}>
                            {this.state.house.name}
                        </AvenirMediumPrimaryHalfOpacityText>
                    </View>
                    }
                    {(this.state.house == null || this.state.house.id == null) &&
                    <View>
                        <FiltersClickableBlock
                            onPress={() => openDoorFiltersModal(this, this.state.filters, (filters) => this.applyFilters(filters), this.state.houseSelectionBeans)}
                            title={this.state.filters_count > 0 ? i18n.t('screens.doors.filters_with_count', {count: this.state.filters_count}) : i18n.t('screens.doors.filters')}
                            style={{marginVertical: 10, width: '40%', alignSelf: 'center',}}/>
                    </View>
                    }
                    <View style={globalScreenStyle.doorScrollContainer}>
                        <ScrollView
                            style={[doorsScreenStyle.container]}
                            contentContainerStyle={[doorsScreenStyle.contentContainer]}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()}/>
                            }>
                            {this.state.resources.map((resource, key) => (
                                <View
                                    key={key}>
                                    <View
                                        style={[doorsScreenStyle.doorContainer]}>
                                        <DoorRow house={resource.house}
                                                 context={this}
                                                 resource={resource}
                                                 activationStatus={resource.currentState}
                                                 online={resource.devices != null && resource.devices.length > 0 && resource.devices[0].online}/>
                                    </View>
                                    {DoorAuxUnlockHelper.resourceHasAuxUnlock(resource) &&
                                    <View
                                        style={[doorsScreenStyle.doorContainer]}>
                                        <DoorRow house={resource.house}
                                                 context={this}
                                                 resource={resource}
                                                 activationStatus={resource.currentState}
                                                 online={resource.devices != null && resource.devices.length > 0 && resource.devices[0].online}
                                                 device={resource.devices[0]}/>
                                    </View>
                                    }
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.resources} show={this.state.showNoData}/>
                    </View>
                    {this.state.house != null && <View style={[{position: 'absolute', bottom: 20}]}>
                        <AddButton title={i18n.t('screens.doors.add_button')}
                                   onPress={() => openNewDoor(this, this.state.house)}/>
                    </View>
                    }
                </View>
            </View>
        );
    }

    applyFilters(filters: ResourceFilterInput) {
        console.log(filters);
        this.setState({filters: filters, filters_count: FilterHelper.countDoorsFilters(filters)});
        this.getResourcesByFilters();
    }

    private getResourcesByHouse() {
        let self = this;
        new HostResourcesApi(this).apiHostResourcesByHouseIdGet({id: this.state.house.id}).then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} resources by house ${self.state.house.id} for host.`);
            self.setState({resources: resp.data, showNoData: true});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }

    private getAllResources() {
        let self = this;
        new HostResourcesApi(this).apiHostResourcesGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} resources for host.`);
            self.setState({resources: resp.data, showNoData: true});
            self.getHouses();
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }

    private getResourcesByFilters() {
        let self = this;
        Logger.log(this.constructor.name, `Calling with filters: ${JSON.stringify(this.state.filters)}`);
        new HostResourcesApi(this).apiHostResourcesByFiltersPost({resourceFilterInput: this.state.filters}).then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} resources by filters for host.`);
            self.setState({resources: resp.data, showNoData: true});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }

    private getHouses() {
        let self = this;
        new HostHousesApi(this).apiHostHousesGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} resources for host.`);
            self.setState({houseSelectionBeans: HouseSelection.fromHouseList(resp.data)});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
        });
    }

    private onRefresh() {
        if (this.state.house != null && this.state.house.id != null)
            this.getResourcesByHouse();
        else
            this.getResourcesByFilters();
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({resources: HostResourceFactory.factoryArray(15)});
}