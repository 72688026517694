/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccommodationResourceJoin,
    AccommodationResourceJoinFromJSON,
    AccommodationResourceJoinToJSON,
} from '../models';

export interface ApiAccommodationResourceJoinsIdDeleteRequest {
    id: number;
}

export interface ApiAccommodationResourceJoinsIdGetRequest {
    id: string;
}

export interface ApiAccommodationResourceJoinsIdPutRequest {
    id: number;
    accommodationResourceJoin: AccommodationResourceJoin;
}

export interface ApiAccommodationResourceJoinsPostRequest {
    accommodationResourceJoin: AccommodationResourceJoin;
}

/**
 * 
 */
export class AccommodationResourceJoinsApi extends runtime.BaseAPI {

    /**
     */
    async apiAccommodationResourceJoinsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AccommodationResourceJoin>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodation_resource_joins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccommodationResourceJoinFromJSON));
    }

    /**
     */
    async apiAccommodationResourceJoinsGet(initOverrides?: RequestInit): Promise<Array<AccommodationResourceJoin>> {
        const response = await this.apiAccommodationResourceJoinsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccommodationResourceJoinsIdDeleteRaw(requestParameters: ApiAccommodationResourceJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccommodationResourceJoinsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodation_resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccommodationResourceJoinsIdDelete(requestParameters: ApiAccommodationResourceJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAccommodationResourceJoinsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAccommodationResourceJoinsIdGetRaw(requestParameters: ApiAccommodationResourceJoinsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccommodationResourceJoin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccommodationResourceJoinsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodation_resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccommodationResourceJoinFromJSON(jsonValue));
    }

    /**
     */
    async apiAccommodationResourceJoinsIdGet(requestParameters: ApiAccommodationResourceJoinsIdGetRequest, initOverrides?: RequestInit): Promise<AccommodationResourceJoin> {
        const response = await this.apiAccommodationResourceJoinsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAccommodationResourceJoinsIdPutRaw(requestParameters: ApiAccommodationResourceJoinsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAccommodationResourceJoinsIdPut.');
        }

        if (requestParameters.accommodationResourceJoin === null || requestParameters.accommodationResourceJoin === undefined) {
            throw new runtime.RequiredError('accommodationResourceJoin','Required parameter requestParameters.accommodationResourceJoin was null or undefined when calling apiAccommodationResourceJoinsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodation_resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccommodationResourceJoinToJSON(requestParameters.accommodationResourceJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccommodationResourceJoinsIdPut(requestParameters: ApiAccommodationResourceJoinsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAccommodationResourceJoinsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAccommodationResourceJoinsPostRaw(requestParameters: ApiAccommodationResourceJoinsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accommodationResourceJoin === null || requestParameters.accommodationResourceJoin === undefined) {
            throw new runtime.RequiredError('accommodationResourceJoin','Required parameter requestParameters.accommodationResourceJoin was null or undefined when calling apiAccommodationResourceJoinsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/accommodation_resource_joins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccommodationResourceJoinToJSON(requestParameters.accommodationResourceJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAccommodationResourceJoinsPost(requestParameters: ApiAccommodationResourceJoinsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAccommodationResourceJoinsPostRaw(requestParameters, initOverrides);
    }

}
