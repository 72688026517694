/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CollaborationDetailsContainerBlock.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import style from "@styles/host/collaborations/CollaborationDetailsContainerBlockStyle";
import {
    AvenirBookGreyLabelText, AvenirBookServiceGreyText,
    AvenirBookText,
    AvenirBookUpperLabelText,
    AvenirLightGreyText,
    AvenirLightPrimaryText, AvenirLightText,
    AvenirMediumPrimaryText,
    AvenirHeavyText
} from "../StyledText";
import {i18n} from "@i18n/i18n";
import {GuestIconImage} from "@components/Images/services/GuestIconImage";
import {
    Device,
    HostCollaborationAccommodationJoin,
    HostCollaborationDetail,
    House,
    Collaborator,
    User,
    VerificationCodeType
} from "@services/src/models";
import {TextInputBlock} from "../TextInputBlock";
import {DateHelper} from "@helpers/DateHelper";
import {BfreeRecapImage} from "@components/Images/BfreeRecapImage";
import {PeriodImage} from "@components/Images/PeriodImage";
import {UserStateText} from "@components/UserStateText";
import {NavigationProps, openCollaborationEditAccommodations} from "@helpers/NavigationHelper";
import {AccommodationSelectionBean} from "../../models/beans/AccommodationSelection";
import Colors from "../../config/Colors";
import {RoundedButton} from "@components/RoundedButton";

interface CollaborationDetailsContainerBlockProps {
    title: string,
    image: any,
    children?: any,
    style?: StyleProp<ViewStyle>,
}

export function CollaborationDetailsContainerBlock(props: CollaborationDetailsContainerBlockProps) {
    return (
        <View style={[style.flex, style.container, props.style]}>
            <View
                style={[style.flex, style.flexWrap, style.background, style.header]}>
                {props.image}
                <View style={style.leftBorderView}/>
            </View>
            <View style={[style.flexBig, style.background]}>
                <View style={style.textContainer}>
                    <AvenirBookGreyLabelText style={[style.titleText]}>
                        {props.title}
                    </AvenirBookGreyLabelText>
                </View>
                {props.children}
            </View>
        </View>
    );
}

interface CollaborationUserDataBlockProps {
    collaborator: Collaborator,
    user: User,
    style?: StyleProp<ViewStyle>,
    emailStatePress: () => void,
    phoneStatePress: () => void,
}

export function CollaborationUserDataBlock(props: CollaborationUserDataBlockProps) {
    let hasName = props.collaborator != null && props.collaborator.firstName != null && props.collaborator.lastName != null;
    let name = hasName ? `${props.collaborator.firstName} ${props.collaborator.lastName}` : '';
    let collaborator = props.collaborator || {};
    let email = props.collaborator != null ? `${collaborator.email}` : '';
    let mobile = props.collaborator != null ? `${collaborator.mobile}` : '';
    let user = props.user || null;

    return (
        <CollaborationDetailsContainerBlock title={i18n.t('common.user_data')} style={props.style}
                                          image={<GuestIconImage style={[style.headImage]}/>}>
            <View style={[style.flex, {flexDirection: 'column'}]}>
                <TextInputBlock label={i18n.t('components.collaboration_details.name')}
                                onChangeText={null}
                                placeholder=' '
                                text={name} autoCapitalize={'none'}/>
                <TextInputBlock label={i18n.t('components.collaboration_details.mail_address')}
                                onChangeText={null}
                                placeholder=' '
                                text={email} autoCapitalize={'none'}
                                style={{marginBottom: 0,}}/>


                <UserStateText user={user}
                               style={{marginTop: 5, marginBottom: 10,}} codeType={VerificationCodeType.Email}/>

                {user && !user.emailVerifiedAt && <RoundedButton isQr={false} style={{marginBottom: 10}} onPress={props.emailStatePress} title={i18n.t("components.collaboration_details.resend_email_verification")}/>}


                <TextInputBlock label={i18n.t('components.collaboration_details.mobile')}
                                onChangeText={null}
                                placeholder=' '
                                text={mobile} autoCapitalize={'none'}
                                style={{marginBottom: 0}}/>
                <TouchableOpacity onPress={props.phoneStatePress}>
                    <UserStateText user={user}
                                   style={{marginTop: 5, marginBottom: 10,}} codeType={VerificationCodeType.Phone}/>
                    {user && !user.phoneVerifiedAt && <RoundedButton isQr={false} style={{marginBottom: 10}} onPress={props.phoneStatePress} title={i18n.t("components.collaboration_details.resend_phone_verification")}/>}
                </TouchableOpacity>
            </View>
        </CollaborationDetailsContainerBlock>
    );
}

interface CollaborationAccommodationBlockProps {
    hostCollaborationAccommodationJoins: Array<HostCollaborationAccommodationJoin>,
    style?: StyleProp<ViewStyle>,
    context: Component<NavigationProps, any>,
    collaboration: HostCollaborationDetail,
    onChange: (house: House, beans: Array<AccommodationSelectionBean>) => void,
    onPressEnabled: boolean,
    unsent: Array<Device>,
    resendCodes: () => void,
}

export function CollaborationAccommodationBlock(props: CollaborationAccommodationBlockProps) {
    let housesData = [];
    if (props.hostCollaborationAccommodationJoins) {
       // Group data by house
        props.hostCollaborationAccommodationJoins.forEach(join => {
            let houseId = join.house.id;
            let house = housesData[houseId];
            if (house == null) {
                let newHouse = join.house;
                housesData[houseId] = {house: newHouse, accommodations: []};
            }
            housesData[houseId].accommodations.push(join.accommodations);
        });
    }

    return (
        <CollaborationDetailsContainerBlock title={i18n.t('common.accommodation')} style={props.style}
                                          image={<BfreeRecapImage style={[style.headImage]}/>}>
            <View style={[style.flex, {flexDirection: 'column'}]}>
                {housesData.map((houseData, key) => (
                    <TouchableOpacity key={key} style={[style.accommodationsRowContainer, {marginBottom: 0}]}
                                      onPress={() => props.onPressEnabled ? openCollaborationEditAccommodations(props.context, props.collaboration, houseData.house, houseData.accommodations, (accommodations) => props.onChange(houseData, accommodations)) : null}>
                        <View flex-box style={{flexDirection: 'column', marginBottom: 0}}>
                            <AvenirLightPrimaryText>
                                {houseData.house.name}
                            </AvenirLightPrimaryText>
                            <AvenirMediumPrimaryText>
                                {houseData.house.accommodationsCount <= houseData.accommodations.length ? i18n.t('components.collaboration_details.all_accommodations') : i18n.t('components.collaboration_details.selected_accommodations', {accommodations: houseData.accommodations.length})}
                            </AvenirMediumPrimaryText>
                        </View>
                        <View style={[style.detailArrowContainer]}>
                            {/* TODO: Enable and fix detail for collaboration*/}
                            {/*<RightArrowColorImage style={[style.detailArrow]}/>*/}
                        </View>
                    </TouchableOpacity>
                ))}
            </View>
            {props.unsent && props.unsent.length == 0 &&
            <AvenirLightText style={{
                color: props.unsent.length == 0 ? Colors.collaborationConfirmed : Colors.collaborationPending,
                margin: 10,
                marginLeft: 0,
            }}> {i18n.t('components.collaboration_details.updated')}</AvenirLightText>}
            {props.unsent != null && props.unsent.length != 0 && <RoundedButton isQr={false} title={i18n.t('components.collaboration_details.update_now')} style={{marginTop: 5}} onPress={props.resendCodes}/>}
        </CollaborationDetailsContainerBlock>
    );
}

function getAccommodationsText(houseData){
    console.log("HOUSEDATA::::: ",houseData);
    if (houseData.house.accommodationsCount <= houseData.accommodations.length)
        return i18n.t('components.reservation_details.all_accommodations');
    if (houseData.accommodations.length == 1)
        return houseData.accommodations[0].name 
    return  i18n.t('components.reservation_details.selected_accommodations', {accommodations: houseData.accommodations.length});
}

interface CollaborationPeriodBlockProps {
    from_datetime: Date,
    to_datetime: Date,
    style?: StyleProp<ViewStyle>,
}

export function CollaborationPeriodBlock(props: CollaborationPeriodBlockProps) {
    return (
        <CollaborationDetailsContainerBlock title={i18n.t('common.period')} style={props.style}
                                          image={<PeriodImage style={[style.headImage]}/>}>
            <View style={[style.flex, {flexDirection: 'column', marginTop: 5,}]}>
                <View style={{flex: 1}}>
                    <AvenirBookUpperLabelText>
                        {i18n.t('rows.recap.from_date')}
                    </AvenirBookUpperLabelText>
                </View>
                <View style={[style.flex, style.periodRowContainer, {flexDirection: 'row'}]}>
                    <View style={{flex: 2}}>
                        <AvenirBookText>
                            {DateHelper.formatDate(props.from_datetime)}
                        </AvenirBookText>
                    </View>
                    <View style={{flex: 2}}>
                        <AvenirLightGreyText>
                            {DateHelper.formatTime(props.from_datetime)}
                        </AvenirLightGreyText>
                    </View>
                </View>
            </View>
            <View style={[style.flex, {flexDirection: 'column', marginTop: 5,}]}>
                <View style={{flex: 1}}>
                    <AvenirBookUpperLabelText>
                        {i18n.t('rows.recap.to_date')}
                    </AvenirBookUpperLabelText>
                </View>
                <View style={[style.flex, style.periodRowContainer, {flexDirection: 'row'}]}>
                    <View style={{flex: 2}}>
                        <AvenirBookText>
                            {DateHelper.formatDate(props.to_datetime)}
                        </AvenirBookText>
                    </View>
                    <View style={{flex: 2}}>
                        <AvenirLightGreyText>
                            {DateHelper.formatTime(props.to_datetime)}
                        </AvenirLightGreyText>
                    </View>
                </View>
            </View>
        </CollaborationDetailsContainerBlock>
    );
}