/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourcePlanJoinFilterInput
 */
export interface ResourcePlanJoinFilterInput {
    /**
     * 
     * @type {boolean}
     * @memberof ResourcePlanJoinFilterInput
     */
    current?: boolean;
}

export function ResourcePlanJoinFilterInputFromJSON(json: any): ResourcePlanJoinFilterInput {
    return ResourcePlanJoinFilterInputFromJSONTyped(json, false);
}

export function ResourcePlanJoinFilterInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourcePlanJoinFilterInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current': !exists(json, 'current') ? undefined : json['current'],
    };
}

export function ResourcePlanJoinFilterInputToJSON(value?: ResourcePlanJoinFilterInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current': value.current,
    };
}

