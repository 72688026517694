/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseExpandableRowStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import Colors from "../../../config/Colors";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
    },
    bigContainer: {
        flex: 1,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
    },
    leftText: {
        flex: 2,
        alignSelf: 'flex-start',
    },
    flex: {
        flex: 1,
    },
    textContainer: {
        flex: 1,
        padding: 12,
        flexDirection: 'row',
        backgroundColor: 'white',
        borderColor: Colors.addContactBackground,
        borderWidth: 0.5,
    },
    bottomBorder: {
        borderColor: Colors.addContactBackground,
        borderBottomWidth: 0.5,
    },
    accommodationContainer: {
        flex: 1,
        paddingLeft: 12,
        paddingRight: 12,
        flexDirection: 'column',
        backgroundColor: 'white',
        borderColor: Colors.addContactBackground,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
    },
    listSeparator: {
        width: '90%',
        alignSelf: 'center',
        height: 1,
        backgroundColor: Colors.listSeparator,
    },
    houseImage: {
        width: 35,
        height: 35,
        borderRadius: 17.5,
        alignSelf: 'center',
    },
    gateResidential: {
        marginRight: 10,
        height: 13,
        width: 0.76 * 15,
        opacity: 0.5,
        resizeMode: 'contain',
    },
    gateResidentialText: {
        fontSize: 12,
        marginRight: 8,
        textAlign: 'center',
        alignSelf: 'center',
        textAlignVertical: 'center',
    },
    gatePublic: {
        marginRight: 10,
        height: 13,
        width: 1.44 * 15,
        opacity: 0.5,
        resizeMode: 'contain',
    },
    gatePublicText: {
        fontSize: 12,
        marginRight: 8,
        textAlign: 'center',
        alignSelf: 'center',
    },
});

export default styles;