/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       StripeApi.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

// tslint:disable
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '@services/src/runtime';
import {Configuration} from '@services/src/runtime';
import {StripeOutput, StripeOutputFromJSON} from "@helpers/stripe/models/StripeOutput";
import {LoaderState} from "@helpers/LoaderHelper";
import {Component} from "react";
import {environment} from "../../../environments/environment";


export interface HttpsApiStripeComV1CheckoutSessionsPostRequest {
    paymentMethodTypes?: string;
    lineItemsName?: string;
    lineItemsDescription?: string;
    lineItemsAmount?: string;
    lineItemsCurrency?: string;
    lineItemsQuantity?: string;
    successUrl?: string;
    cancelUrl?: string;
}

/**
 * no description
 */
export class StripeApi extends runtime.BaseAPI {
    constructor(context: Component<any, LoaderState>) {
        super(context, new Configuration({basePath: ' '}));
    }

    /**
     */
    async httpsApiStripeComV1CheckoutSessionsPostRaw(requestParameters: HttpsApiStripeComV1CheckoutSessionsPostRequest): Promise<runtime.ApiResponse<StripeOutput>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Authorization'] = 'Bearer ' + environment.stripePrivateKey;
        headerParameters['Content-Type'] = 'application/x-www-form-urlencoded';

        if (requestParameters.paymentMethodTypes !== undefined) {
            queryParameters['payment_method_types[]'] = requestParameters.paymentMethodTypes as any;
        }

        if (requestParameters.lineItemsName !== undefined) {
            queryParameters['line_items[][name]'] = requestParameters.lineItemsName as any;
        }

        if (requestParameters.lineItemsDescription !== undefined) {
            queryParameters['line_items[][description]'] = requestParameters.lineItemsDescription as any;
        }

        if (requestParameters.lineItemsAmount !== undefined) {
            queryParameters['line_items[][amount]'] = requestParameters.lineItemsAmount as any;
        }

        if (requestParameters.lineItemsCurrency !== undefined) {
            queryParameters['line_items[][currency]'] = requestParameters.lineItemsCurrency as any;
        }

        if (requestParameters.lineItemsQuantity !== undefined) {
            queryParameters['line_items[][quantity]'] = requestParameters.lineItemsQuantity as any;
        }

        if (requestParameters.successUrl !== undefined) {
            queryParameters['success_url'] = requestParameters.successUrl as any;
        }

        if (requestParameters.cancelUrl !== undefined) {
            queryParameters['cancel_url'] = requestParameters.cancelUrl as any;
        }

        let response = null;
        try {
            response = await this.request({
                path: `https://api.stripe.com/v1/checkout/sessions`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: [],
            });
        } catch (e) {
            console.warn("ERROR");
            console.warn(e);
        }

        return new runtime.JSONApiResponse(response, (jsonValue) => StripeOutputFromJSON(jsonValue));
    }

    /**
     */
    async httpsApiStripeComV1CheckoutSessionsPost(requestParameters: HttpsApiStripeComV1CheckoutSessionsPostRequest): Promise<StripeOutput> {
        const response = await this.httpsApiStripeComV1CheckoutSessionsPostRaw(requestParameters);
        return await response.value();
    }

}
