/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AddressToCoordinatesFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import {AddressToCoordinates} from "@services/src/models";
import * as fakerStatic from 'faker';

export class AddressToCoordinatesFactory {
    static factoryArray(size: number): AddressToCoordinates[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(AddressToCoordinatesFactory.factory());
        }
        return array;
    }

    static factory(): AddressToCoordinates {
        return {
            lat: fakerStatic.address.latitude(),
            lng: fakerStatic.address.longitude(),
            accuracy: 'ROOFTOP',
            formattedAddress: `${fakerStatic.address.streetName()} ${fakerStatic.address.city()}`,
            placeId: fakerStatic.random.alphaNumeric(27),
            addressComponents: [],
        };
    }
}

