/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorDetailScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 3:10 PM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import doorDetailScreenStyle from "@styles/host/doors/DoorDetailScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {goBack, NavigationProps, openDoorBarcodeScanner, openResourceDelete} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {getDoorFromNavigationProps, getHouseFromNavigationProps, setHeaderTitle} from "@helpers/NavigationDataHelper";
import {HostResourcesApi} from "@services/src/apis/index";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {Device, HostResourceInput, House, Resource, ResourceStateEnum, ResourceType} from "@services/src/models/index";
import {HouseFactory} from "../../../models/factory/HouseFactory";
import {TextAreaBlock} from "@components/TextAreaBlock";
import {GearButton} from "@components/ImageButton";
import {ReadonlyTextBlock} from "@components/ReadonlyTextBlock";
import {DeviceFactory} from "../../../models/factory/DeviceFactory";
import {DoorDetailBlock} from "@components/details/DoorDetailBlock";
import {DebugButton} from "@components/DebugButton";
import {SwitchBlock} from "@components/SwitchBlock";
import {RoundedButton} from "@components/RoundedButton";
import {PlatformHelper} from "@helpers/PlatformHelper";
import {ResourceFactory} from "../../../models/factory/ResourceFactory";
import {BfreeX1} from "@helpers/BfreeX1";

interface State extends LoaderState {
    door: Resource,
    house: House,
    door_name: string,
    door_serial: string,
    door_description: string,
    device_serial: string,
    device_imei: string,
    device_partNumber: string,
    device_aux_unlock_text_enabled: boolean,
    device_aux_unlock_text: string,
    device_aux_unlock_time: string,
    selectedType: ResourceType,
    advanced_settings_enabled: boolean,
    device: Device,
}

export default class DoorDetailScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        let door = getDoorFromNavigationProps(this) || {};
        this.state = {
            door: door,
            house: getHouseFromNavigationProps(this) || {},
            loading: false,
            door_name: door.name || '',
            door_serial: door.serial || '',
            door_description: door.description || '',
            selectedType: door.type,
            advanced_settings_enabled: false,
            device: {},
            device_serial: '',
            device_imei: '',
            device_partNumber: '',
            device_aux_unlock_text_enabled: false,
            device_aux_unlock_text: '',
            device_aux_unlock_time: '',
        };

    }

    componentDidMount() {
        let door = getDoorFromNavigationProps(this) || {};
        let house = getHouseFromNavigationProps(this) || {};
        console.log(house);
        setHeaderTitle(this.props, house.name);
        let self = this;
        new HostResourcesApi(this).apiHostResourcesIdGet({id: door.id + ''}).then(resp => {
            Logger.log(self.constructor.name, 'Ok');
            let data = resp.data;
            let device = data.devices != null && data.devices.length > 0 ? data.devices[0] : {};
            self.setState({
                door: data,
                device: device,
                door_name: door.name,
                door_serial: door.serial,
                door_description: door.description,
                device_serial: device.serial,
                device_imei: device.imei,
                device_partNumber: device.partNumber,
                device_aux_unlock_text_enabled: device.auxUnlockEnabled,
                device_aux_unlock_text: device.auxUnlockText,
                device_aux_unlock_time: device.auxUnlockTime,
            });
        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + error);
        });
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={doorDetailScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View
                    style={[doorDetailScreenStyle.scrollContainer, globalScreenStyle.globalMargins, doorDetailScreenStyle.scrollOuter]}>
                    <ScrollView
                        style={[doorDetailScreenStyle.scrollContainer,]}
                        contentContainerStyle={doorDetailScreenStyle.contentContainer}>
                        <View
                            style={[doorDetailScreenStyle.getStartedContainer, doorDetailScreenStyle.scrollContainer]}>
                            <View
                                style={doorDetailScreenStyle.inputContainer}>

                                <DoorDetailBlock activationStatus={ResourceStateEnum.Active} door={this.state.door}
                                                 onDelete={() => deleteDoor(this)} online={this.state.device.online}/>

                                <TextInputBlock label={i18n.t('screens.door_detail.door_name')}
                                                onChangeText={(text) => this.setState({door_name: text})}
                                                text={this.state.door_name}/>
                                {!PlatformHelper.isWeb() && !(this.state.device != null &&
                                    this.state.device.imei != null && this.state.device.imei.trim().length > 0) &&
                                <RoundedButton title={i18n.t('screens.door_detail.scan_imei')}
                                               style={{marginBottom: 10,}}
                                               isQr={false}
                                               onPress={() => openDoorBarcodeScanner(this, {}, (type, data, enc_code) => this.setState({
                                                   device_imei: data,
                                                   device_serial: enc_code,
                                                   door_serial: enc_code
                                               }))}/>
                                }
                                <ReadonlyTextBlock label={i18n.t('screens.door_detail.device_id')}
                                                   onChangeText={(text) => this.setState({door_serial: text})}
                                                   text={this.state.door_serial}/>
                                <TextAreaBlock label={i18n.t('screens.door_detail.door_description')} placeholder=' '
                                               onChangeText={(text) => this.setState({door_description: text})}
                                               text={this.state.door_description}/>

                                <View style={{flexDirection: 'row', marginTop: 10,}}>
                                    <View style={{flex: 0.5}}/>
                                    <GearButton active={this.state.advanced_settings_enabled}
                                                onPress={() => toggleAdvancedSettings(this)}
                                                title={i18n.t('screens.door_detail.advanced_settings')}
                                                style={{flex: 1}}/>
                                </View>

                                {this.state.advanced_settings_enabled &&
                                <View style={{marginTop: 30,}}>

                                    {this.state.device != null &&
                                    this.state.device.imei != null && this.state.device.imei.trim().length > 0 &&
                                    <RoundedButton title={i18n.t('screens.door_detail.umount_device')}
                                                   style={{marginBottom: 10,}}
                                                   isQr={false}
                                                   onPress={() => umountDevice(this)}/>
                                    }
                                    <TextInputBlock label={i18n.t('screens.door_detail.device_serial')}
                                                    onChangeText={(text) => this.setState({device_serial: text})}
                                                    text={this.state.device_serial}/>
                                    <TextInputBlock label={i18n.t('screens.door_detail.device_imei')}
                                                    editable={false}
                                                    onChangeText={(text) => this.setState({device_imei: text})}
                                                    text={this.state.device_imei}/>
                                    <TextInputBlock label={i18n.t('screens.door_detail.device_part_number')}
                                                    onChangeText={(text) => this.setState({device_partNumber: text})}
                                                    text={this.state.device_partNumber}/>
                                    <SwitchBlock label={i18n.t('screens.door_detail.device_aux_unlock_text_enabled')}
                                                 value={this.state.device_aux_unlock_text_enabled}
                                                 onValueChange={(bool) => this.setState({device_aux_unlock_text_enabled: bool})}/>
                                    {this.state.device_aux_unlock_text_enabled &&
                                    <TextInputBlock label={i18n.t('screens.door_detail.device_aux_unlock_text')}
                                                    onChangeText={(text) => this.setState({device_aux_unlock_text: text})}
                                                    text={this.state.device_aux_unlock_text}/>}
                                    {this.state.device_aux_unlock_text_enabled &&
                                    <TextInputBlock label={i18n.t('screens.door_detail.device_aux_unlock_time')}
                                                    onChangeText={(text) => this.setState({device_aux_unlock_time: text})}
                                                    text={this.state.device_aux_unlock_time}/>}
                                    <ReadonlyTextBlock label={i18n.t('screens.door_detail.device_firmware_version')}
                                                       onChangeText={(text) => (this.state.device.firmwareVersion = text) && this.setState({device: this.state.device})}
                                                       text={this.state.device.firmwareVersion}/>
                                    <ReadonlyTextBlock label={i18n.t('screens.door_detail.device_time_server')}
                                                       onChangeText={(text) => (this.state.device.timeServer = text) && this.setState({device: this.state.device})}
                                                       text={this.state.device.timeServer}/>
                                    <ReadonlyTextBlock label={i18n.t('screens.door_detail.device_time_zone')}
                                                       onChangeText={(text) => (this.state.device.timeZone = text) && this.setState({device: this.state.device})}
                                                       text={this.state.device.timeZone}/>
                                    <ReadonlyTextBlock label={i18n.t('screens.door_detail.device_unlock_time')}
                                                       onChangeText={(text) => (this.state.device.unlockTime = text) && this.setState({device: this.state.device})}
                                                       text={this.state.device.unlockTime}/>
                                    <ReadonlyTextBlock label={i18n.t('screens.door_detail.device_alert_text')}
                                                       onChangeText={(text) => (this.state.device.alertText = text) && this.setState({device: this.state.device})}
                                                       text={this.state.device.alertText}/>
                                </View>
                                }
                            </View>
                        </View>
                    </ScrollView>
                    <ConfirmButtonBottom active={dataChanged(this)} onPress={() => updateDoor(this)}
                                         title={i18n.t('buttons.new_door')}
                                         style={{marginHorizontal: 0, marginBottom: 0,}}/>
                </View>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Toggle advanced settings
 * @param {React.Component<any, State>} context
 */
function toggleAdvancedSettings(context: Component<any, State>) {
    context.setState({advanced_settings_enabled: !context.state.advanced_settings_enabled});
}

/**
 * Checks if house name is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function doorNameValid(context: Component<any, State>) {
    return context.state.door_name != null && context.state.door_name != '';
}

/**
 * Checks if house serial is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function doorSerialValid(context: Component<any, State>) {
    return context.state.door_serial != null && context.state.door_serial != '';
}

/**
 * Checks if aux unlock is disabled or it is enabled and
 * aux unlock text is set
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function doorAuxOk(context: Component<any, State>) {
    return !context.state.device_aux_unlock_text_enabled || (context.state.device_aux_unlock_text != null && context.state.device_aux_unlock_text != '');
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return doorSerialValid(context) && doorNameValid(context) && doorAuxOk(context);
}

function deleteDoor(context: Component<any, State>) {
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed) {
            new HostResourcesApi(context).apiHostResourcesIdCandeleteGet({id: context.state.door.id}).then(resp => {
                    openResourceDelete(context, resp, context.state.door);
            }).catch(error => {
                AlertHelper.showSimpleErrorAlert();
            });
        }
    });
}

function debugUpdateData(context: Component<any, State>) {
    let device = DeviceFactory.factory();
    let door = ResourceFactory.factory();
    context.setState({
        house: HouseFactory.factory(),
        device: device,
        door: door,
        door_name: door.name,
        door_serial: door.serial,
        door_description: door.description,
        device_serial: device.serial,
        device_imei: device.imei,
        device_partNumber: device.partNumber,
        device_aux_unlock_text_enabled: device.auxUnlockEnabled,
        device_aux_unlock_text: device.auxUnlockText,
        device_aux_unlock_time: device.auxUnlockTime,
    });

}

/**
 * Checks if data changed
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataChanged(context: Component<any, State>) {
    let state = context.state;
    if (state.house == null)
        return false;
    if (state.door == null)
        return false;
    let doorNameChanged = state.door_name != null && state.door_name.trim() != state.door.name;
    let doorSerialChanged = state.door_serial != null && state.door_serial.trim() != state.door.serial;
    let doorDescriptionChanged = state.door_description != null && state.door_description.trim() != (state.door.description || '');
    let device_serialChanged = state.device_serial != null && state.device_serial.trim() != state.device.serial;
    let device_imeiChanged = state.device_imei != null && state.device_imei.trim() != state.device.imei;
    let device_partNumberChanged = state.device_partNumber != null && state.device_partNumber.trim() != state.device.partNumber;
    let device_auxUnlockText = state.device_aux_unlock_text != null && state.device_aux_unlock_text.trim() != state.device.auxUnlockText;
    let device_auxUnlockTime = state.device_aux_unlock_time != null && state.device_aux_unlock_time.trim() != state.device.auxUnlockTime;
    let device_auxUnlockEnabledChanged = state.device_aux_unlock_text_enabled != null && state.device_aux_unlock_text_enabled != state.device.auxUnlockEnabled;

    return (doorNameChanged || doorSerialChanged || doorDescriptionChanged || device_serialChanged || device_imeiChanged || device_partNumberChanged || device_auxUnlockText || device_auxUnlockTime || device_auxUnlockEnabledChanged) && (dataComplete(context));
}

/**
 * Update door removing device
 * @param context
 */
function umountDevice(context: Component<any, State>) {
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed)
            context.setState({device_imei: ''}, () => {
                updateDoor(context);
            });
    });
}

/**
 * Update door
 * @param context
 */
function updateDoor(context: Component<any, State>) {
    if (!dataChanged(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.door_data_unchanged'));
        return;
    }
    let device = context.state.device;
    if (device != null)
        device.resourceId = null; // Resource id cannot be sent
    let resource: HostResourceInput = {
        name: context.state.door_name,
        serial: context.state.door_serial,
        description: context.state.door_description,
        device: {
            serial: context.state.device_serial,
            imei: context.state.device_imei,
            partNumber: context.state.device_partNumber,
            auxUnlockEnabled: context.state.device_aux_unlock_text_enabled,
            auxUnlockText: context.state.device_aux_unlock_text,
            auxUnlockTime: context.state.device_aux_unlock_time,
        },
    };
    new HostResourcesApi(context).apiHostResourcesIdPut({
        id: context.state.door.id,
        hostResourceInput: resource
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp);
        goBack(context);
    }).catch(async error => {

        var body = await error.json();
        console.log(body);
        Logger.warn(context.constructor.name, "Error " + body);
        AlertHelper.showErrorAlertWithServerMessage(body.error);
    })
}