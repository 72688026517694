/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CreditsBalanceType {
    Unknown = 'UNKNOWN',
    Increase = 'INCREASE',
    Decrease = 'DECREASE'
}

export function CreditsBalanceTypeFromJSON(json: any): CreditsBalanceType {
    return CreditsBalanceTypeFromJSONTyped(json, false);
}

export function CreditsBalanceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditsBalanceType {
    return json as CreditsBalanceType;
}

export function CreditsBalanceTypeToJSON(value?: CreditsBalanceType | null): any {
    return value as any;
}

