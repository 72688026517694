/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationSelection.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import {Accommodation, AccommodationResources} from "@services/src/models";

export interface AccommodationSelectionBean {
    accommodation: AccommodationResources,
    selected: boolean,
}

export class AccommodationSelection {
    /**
     * Builds a bean from Accommodation
     * @param {AccommodationResources} accommodation
     * @param selected
     * @returns {AccommodationSelectionBean}
     */
    static fromAccommodation(accommodation, selected = false): AccommodationSelectionBean {
        return {accommodation: accommodation, selected: selected};
    }

    /**
     * Builds a bean list from Accommodation List
     * @param {Accommodation[]} accommodations
     * @param selected
     * @returns {AccommodationSelectionBean[]}
     */
    static fromAccommodationList(accommodations: any[], selected = false): AccommodationSelectionBean[] {
        return accommodations.map(accommodation => this.fromAccommodation(accommodation, selected));
    }
}