/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import * as fakerStatic from 'faker';
import {Accommodation} from "@services/src/models";

export class AccommodationFactory {
    static factoryArray(size: number): Accommodation[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(AccommodationFactory.factory());
        }
        return array;
    }

    static factory(): Accommodation {
        return {
            id: fakerStatic.random.number(4),
            name: fakerStatic.commerce.department(),
            description: fakerStatic.lorem.paragraph(1).substr(0, 255),
        };
    }
}

