/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseSelectionRow.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

/*
 * Copybottom (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseRow.tsx
 * @author     Christian Ascone
 * @date       9/19/19 7:45 PM
 */

import React from 'react';
import {GestureResponderEvent, TouchableOpacity, View} from 'react-native';
import {i18n} from "@i18n/i18n";
import {
    AvenirBookGreyLabelText,
    AvenirHeavyAvailableText,
    AvenirHeavyNotAvailableText,
    AvenirLightPrimaryText,
    AvenirMediumPrimaryText
} from "../StyledText";
import {RightArrowColorImage} from "@components/Images/RightArrowColorImage";
import houseSelectionRowStyles from "@styles/host/rows/HouseSelectionRowStyle";
import {AvatarPlaceholderImage} from "@components/Images/AvatarPlaceholderImage";
import {GatePublicColorImage} from "@components/Images/GatePublicColorImage";
import {GateResidentialColorImage} from "@components/Images/GateResidentialColorImage";
import {House} from "@services/src/models";
import {CheckImage} from "@components/Images/CheckImage";

export interface HouseSelectionRowProps {
    house_id: number,
    house_name: string,
    accommodations: number,
    free: number,
    residential_count: number,
    public_count: number,
    house: House,
    onPress: (event: GestureResponderEvent) => void,
    selected: boolean,
}

export function HouseSelectionRow(props: HouseSelectionRowProps) {
    let prefix = 'rows.house_selection.';
    return (
        <View style={[houseSelectionRowStyles.flex, houseSelectionRowStyles.container]}>
            <TouchableOpacity onPress={props.onPress}>
                <View
                    style={[houseSelectionRowStyles.bigContainer, houseSelectionRowStyles.textContainer, {flexDirection: 'row',}]}>
                    <View style={{flex: 1, marginHorizontal: 10,}}>
                        {props.selected &&
                        <CheckImage style={[houseSelectionRowStyles.houseImage]}/>
                        }
                        {!props.selected &&
                        <AvatarPlaceholderImage style={[houseSelectionRowStyles.houseImage]}
                                                imageUri={props.house.photoPath}/>
                        }
                    </View>
                    <View style={{marginLeft: 5, flex: 7,}}>
                        <AvenirLightPrimaryText style={{marginLeft: 1,}}>
                            {props.house_name}
                        </AvenirLightPrimaryText>
                        <View style={{
                            flexDirection: 'row',
                            marginLeft: 1,
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                        }}>
                            <AvenirMediumPrimaryText
                                numberOfLines={1}
                                style={[houseSelectionRowStyles.flex, houseSelectionRowStyles.leftText]}>{i18n.t(prefix + 'free_accommodations', {accommodations: props.accommodations})}</AvenirMediumPrimaryText>
                            {props.free > 0 &&
                            <AvenirHeavyAvailableText style={{flex: 1, marginLeft: 5,}}>
                                {i18n.t(prefix + 'free', {free_number: props.free})}
                            </AvenirHeavyAvailableText>
                            }
                            {props.free == null || props.free == 0 &&
                            <AvenirHeavyNotAvailableText style={{flex: 1, marginLeft: 5,}}>
                                {i18n.t(prefix + 'free', {free_number: props.free})}
                            </AvenirHeavyNotAvailableText>
                            }
                        </View>
                        <View style={{flexDirection: 'row', alignItems: 'baseline',}}>
                            <GateResidentialColorImage style={houseSelectionRowStyles.gateResidential}/>
                            <AvenirBookGreyLabelText
                                style={houseSelectionRowStyles.gateResidentialText}>{props.residential_count}</AvenirBookGreyLabelText>
                            <GatePublicColorImage style={houseSelectionRowStyles.gatePublic}/>
                            <AvenirBookGreyLabelText
                                style={houseSelectionRowStyles.gatePublicText}>{props.public_count}</AvenirBookGreyLabelText>
                        </View>
                    </View>
                    <View style={[houseSelectionRowStyles.detailArrowContainer]}>
                        <RightArrowColorImage style={[houseSelectionRowStyles.detailArrow]}/>
                    </View>
                </View>
            </TouchableOpacity>

        </View>
    );
}