/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CompanyFactory.ts
 * @author     Christian Ascone
 * @date       26/03/2021, 13:24
 */


import * as fakerStatic from 'faker';
import {Company, Host} from "@services/src/models";

export class CompanyFactory {
    static factoryArray(size: number): Host[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(CompanyFactory.factory());
        }
        return array;
    }

    static factory(): Company {
        return {
            name: fakerStatic.company.companyName(),
            vat: fakerStatic.random.alphaNumeric(11),
            pec: fakerStatic.internet.email(),
            sdi: fakerStatic.random.alphaNumeric(7)
        };
    }
}

