/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewCollaborationPeriodScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import newCollaborationPeriodScreenStyle from "@styles/host/collaborations/NewCollaborationPeriodScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openNewCollaborationHousesRoute} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {getCollaboratorFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import {Collaborator} from "@services/src/models/index";
import {AvenirHeavyPrimaryText} from "@components/StyledText";
import {DateTimeInputBlock} from "@components/DateTimeInputBlock";
import {CollaborationBean, CollaborationBeanBuilder} from "../../../models/beans/CollaborationBean";


interface State extends LoaderState {
    collaborator: Collaborator,
    from_date: Date,
    from_time: Date,
    to_date: Date,
    to_time: Date,
    from_active: boolean,
    to_active: boolean,
}

export default class NewCollaborationPeriodScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_collaboration_period.title');
        let collaborator = getCollaboratorFromNavigationProps(this) || {};
        this.state = {
            collaborator: collaborator,
            loading: false,
            from_date: new Date(),
            from_time: new Date(),
            to_date: new Date(),
            to_time: new Date(),
            to_active: false,
            from_active: false,
        };

    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={[newCollaborationPeriodScreenStyle.container, globalScreenStyle.globalMargins]}>
                <View>
                    {/* TODO: Wizard */}
                </View>
                <Loader state={this.state}/>
                <View style={[newCollaborationPeriodScreenStyle.container, newCollaborationPeriodScreenStyle.contentContainer]}>

                    <View
                        style={[newCollaborationPeriodScreenStyle.getStartedContainer, newCollaborationPeriodScreenStyle.scrollContainer]}>
                        <View
                            style={[newCollaborationPeriodScreenStyle.inputContainer, ]}>
                            <AvenirHeavyPrimaryText>
                                {i18n.t('screens.new_collaboration_period.section_title')}
                            </AvenirHeavyPrimaryText>

                            <DateTimeInputBlock style={newCollaborationPeriodScreenStyle.DateTimeInputBlock}
                                                label={i18n.t('screens.new_collaboration_period.from_date')}
                                                date={this.state.from_date} time={this.state.from_time}
                                                onChangeTime={(date) => this.setState({from_time: !isNaN(date.getTime()) ? date : this.state.from_time, from_active: false})}
                                                onChangeDate={(date) => this.setState({from_date: !isNaN(date.getTime()) ? date : this.state.from_date, from_active: false})}
                                                active={this.state.from_active}
                                                onActivation={() => this.onActivation(this, 'from')}/>


                            <DateTimeInputBlock style={[newCollaborationPeriodScreenStyle.DateTimeInputBlock, this.state.from_active ? {marginTop: '50%'} : null]}
                                                label={i18n.t('screens.new_collaboration_period.to_date')}
                                                date={this.state.to_date} time={this.state.to_time}
                                                onChangeTime={(date) => this.setState({to_time: !isNaN(date.getTime()) ? date : this.state.to_time, to_active: false})}
                                                onChangeDate={(date) => this.setState({to_date: !isNaN(date.getTime()) ? date : this.state.to_date, to_active: false})}
                                                active={this.state.to_active}
                                                onActivation={() => this.onActivation(this, 'to')}/>

                        </View>
                    </View>

                </View>
                <ConfirmButtonBottom active={dataComplete(this)} onPress={() => openNextPage(this)}
                                     title={i18n.t('buttons.new_collaboration_period')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    }

    onActivation(context, type){
        console.log("FROM: ", context.state.from_active);
        console.log("TO: ", context.state.to_active);
        context.setState(type == 'from' ? {from_active: true, to_active: false} : {from_active: false, to_active: true});
    }
}

/**
 * Checks if data is valid
 * @param {Date} data
 * @returns {boolean}
 */
function dataIsValid(data: Date) {
    return data != null;
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return dataIsValid(context.state.from_date) && dataIsValid(context.state.from_time) && dataIsValid(context.state.to_date) && dataIsValid(context.state.to_time) && context.state.to_date.getTime() > context.state.from_date.getTime();
}


/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.collaboration_data_incomplete'));
        return;
    }

    let collaborationBean = new CollaborationBeanBuilder()
        .withFrom(context.state.from_date, context.state.from_time)
        .withTo(context.state.to_date, context.state.to_time)
        .withCollaborator(context.state.collaborator)
        .build();
    openNewCollaborationHousesRoute(context, collaborationBean);
}