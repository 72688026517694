/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       TextStyle.ts
 * @author     Christian Ascone
 * @date       10/31/19 5:24 PM
 */

import {StyleSheet} from "react-native";
import Colors from "../config/Colors";
import * as Fonts from "../config/Fonts";

const styles = StyleSheet.create({
    primaryText: {
        color: Colors.primaryColor,
    },
    primaryColorHalfOpacityText: {
        color: Colors.primaryColorHalfOpacity,
    },
    titleText: {
        color: Colors.titleColor,
    },
    greyText: {
        color: Colors.subtitleTextColor,
    },
    captionText: {
        color: Colors.captionColor,
    },
    topLabelText: {
        color: Colors.topLabelColor,
    },
    labelColorText: {
        color: Colors.labelColor,
    },
    availableText: {
        color: Colors.available,
    },
    notAvailableText: {
        color: Colors.notAvailable,
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    underlined: {
        textDecorationLine: 'underline',
    },
    upperLabel: {
        color: Colors.subtitleTextColor,
        letterSpacing: 2,
        fontSize: 10,
        lineHeight: 10,
        marginBottom: 10,
    },
    inlineLabel: {
        color: Colors.subtitleTextColor,
        letterSpacing: 2,
        fontSize: 12,
    },
    greyLabel: {
        color: Colors.numberLabelColor,
    },
    separator: {
        fontFamily: Fonts.names.avenirBook,
        borderBottomColor: Colors.separatorColor,
        borderBottomWidth: 1,
        paddingBottom: 5,
        marginBottom: 20
    },
    separatorDate: {
        fontFamily: Fonts.names.avenirBook,
    },
    separatorTime: {
        fontFamily: Fonts.names.avenirBook,
        color: Colors.subtitleTextColor,
    },
    webDateContainer: {
        borderBottomColor: Colors.separatorColor,
        borderBottomWidth: 1,
        paddingBottom: 5,
        marginBottom: 20
    },
    dateTimeContainer: {
        borderBottomColor: Colors.separatorColor,
        borderBottomWidth: 1,
        marginBottom: 20,
        padding: 5,
    },
    textArea: {
        textAlignVertical: 'top',
        height: 70,
        fontFamily: Fonts.names.avenirBook,
        borderColor: Colors.separatorColor,
        borderWidth: 1,
        paddingBottom: 5,
        marginBottom: 20
    },
    separatorContainer: {
        flexDirection: 'row',
        borderBottomColor: Colors.separatorColor,
        borderBottomWidth: 1,
        marginBottom: 20
    },
});

export default styles;