/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewDoorScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 3:10 PM
 */

import React, {Component} from 'react';
import {Alert, KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import newDoorScreenStyle from "@styles/host/doors/NewDoorScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {
    goBack,
    NavigationProps,
    openCardBarcodeScanner,
    openCardsRoute,
    openDoorBarcodeScanner
} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {
    getCardCodeFromNavigationProps, getDismissCallbackFromNavigationProps,
    getHouseFromNavigationProps, getPassepartoutDismissCallbackFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {HostCardsApi, HostResourcesApi} from "@services/src/apis/index";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {Device, DeviceType, HostResourceInput, House, ResourceType, ResponseStatus} from "@services/src/models/index";
import {AvenirBookUpperLabelText, AvenirMediumPrimaryHalfOpacityText} from "@components/StyledText";
import {PublicSelectionBlock, ResidentialSelectionBlock} from "@components/ResourceSelectionBlock";
import {HouseFactory} from "../../../models/factory/HouseFactory";
import {TextAreaBlock} from "@components/TextAreaBlock";
import {GearButton} from "@components/ImageButton";
import {ReadonlyTextBlock} from "@components/ReadonlyTextBlock";
import {DeviceFactory} from "../../../models/factory/DeviceFactory";
import {DebugButton} from "@components/DebugButton";
import {SwitchBlock} from "@components/SwitchBlock";
import {RoundedButton} from "@components/RoundedButton";
import {PlatformHelper} from "@helpers/PlatformHelper";
import {Card} from "@services/src/models/Card";
import NewCardScreenStyle from "@styles/host/cards/NewCardScreenStyle";
import {BfreeX1} from "@helpers/BfreeX1";

interface State extends LoaderState {
    card: Card;
    code: string;
    code_encrypted: string;
    card_name: string;
    description: string;
    onDismiss: (number) => void;
}

export default class NewCardScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_card.title');
        this.state = {
            loading: false,
            card: {},
            code: getCardCodeFromNavigationProps(this) || '',
            code_encrypted: '',
            card_name: '',
            description: '',
            onDismiss: getDismissCallbackFromNavigationProps(this) || null,
        };

    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newDoorScreenStyle.container}>
                <Loader state={this.state}/>
                <ScrollView
                    style={[newDoorScreenStyle.scrollContainer, globalScreenStyle.globalMargins]}
                    contentContainerStyle={newDoorScreenStyle.contentContainer}>
                    <View
                        style={[newDoorScreenStyle.getStartedContainer, newDoorScreenStyle.scrollContainer]}>
                        <View
                            style={NewCardScreenStyle.inputContainer}>

                                <RoundedButton title={i18n.t('screens.new_card.scan')}
                                               style={{marginBottom: 10,}}
                                               isQr={true}
                                               onPress={() => openCardBarcodeScanner(this, "NewCard",(data) => {
                                                       if (data)
                                                           this.setState({code: data, code_encrypted: BfreeX1.decode(data)});
                                                       else
                                                           AlertHelper.showSimpleAlert(i18n.t('screens.new_card.errors.unknown_code_title'), i18n.t('screens.new_card.errors.unknown_code'));
                                                   }
                                               )}/>

                            {//This is actually read only, editable just for debugging purpose. Change this before production
                                <TextInputBlock label={i18n.t('screens.new_card.code')} text={this.state.code}
                                                onChangeText={(text) => this.setState({code: text})}
                                />}
                            {//These are not
                                            }
                                <TextInputBlock label={i18n.t('screens.new_card.card_name')}
                                                onChangeText={(text) => this.setState({card_name: text})}
                                />

                                <TextInputBlock label={i18n.t('screens.new_card.description')}
                                onChangeText={(text) => this.setState({description: text})}
                                />


                            </View>


                    </View>
                </ScrollView>
                <ConfirmButtonBottom active={dataComplete(this)} onPress={() => openNextPage(this)}
                                     title={i18n.t('buttons.new_door')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    }
}


/**
 * Checks if house serial is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function cardCodeValid(context: Component<any, State>) {
    return context.state.code != null && context.state.code != '';
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return cardCodeValid(context);
}


/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.door_data_incomplete'));
        return;
    }
    let card: Card =  {};
    let self = context;
    card.code = context.state.code;
    console.log(context.state.code_encrypted);
    card.codeEncrypted = context.state.code_encrypted;
    let valore = BfreeX1.decode(card.code);
    console.log(valore);
    card.codeEncrypted = valore;
    card.name = context.state.card_name;
    card.description = context.state.description;
    new HostCardsApi(context).apiHostCardsPost({
        card
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp);
        if (resp == null)
            AlertHelper.showSimpleErrorAlert();
        else if(getCardCodeFromNavigationProps(context)) {
            context.state.onDismiss(resp.id);
            context.props.navigation.pop();
        }
        else
            openCardsRoute(context);
    }).catch(async error => {
        Logger.warn(context.constructor.name, error);
        var body = await error;
        console.log('');
        AlertHelper.showErrorAlertWithServerMessage(body.error);
        console.log(body.error);
    })
}