/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       PlanSelectionBlockStyle.ts
 * @author     Christian Ascone
 * @date       1/14/20 3:32 PM
 */

import {StyleSheet} from "react-native";
import Colors from "../../config/Colors";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
    },
    flex: {
        flex: 1,
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    title: {
        textTransform: 'uppercase',
        letterSpacing: 1,
    },
    flexBig: {
        flex: 5,
        flexDirection: 'row',
        borderLeftColor: Colors.filtersBorder,
        borderLeftWidth: 2,
        paddingHorizontal: 15,
        alignItems: 'center',
        alignContent: 'center',
        marginTop: 10,
    },
    picker: {
        height: 40,
        borderColor: Colors.primaryColor,
        borderWidth: 1,
        backgroundColor: Colors.background,
    },
    autorenewSwitch: {
        width: 50,
        height: 40,
        resizeMode: 'contain',
    },
});

export default styles;