/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProfileHostTransformer,
    ProfileHostTransformerFromJSON,
    ProfileHostTransformerFromJSONTyped,
    ProfileHostTransformerToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserProfileTransformerDto
 */
export interface UserProfileTransformerDto {
    /**
     * 
     * @type {number}
     * @memberof UserProfileTransformerDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileTransformerDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileTransformerDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileTransformerDto
     */
    phone?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileTransformerDto
     */
    emailVerifiedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileTransformerDto
     */
    phoneVerifiedAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserProfileTransformerDto
     */
    roles?: Array<string>;
    /**
     * 
     * @type {ProfileHostTransformer}
     * @memberof UserProfileTransformerDto
     */
    host?: ProfileHostTransformer;
}

export function UserProfileTransformerDtoFromJSON(json: any): UserProfileTransformerDto {
    return UserProfileTransformerDtoFromJSONTyped(json, false);
}

export function UserProfileTransformerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileTransformerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'emailVerifiedAt': !exists(json, 'email_verified_at') ? undefined : (new Date(json['email_verified_at'])),
        'phoneVerifiedAt': !exists(json, 'phone_verified_at') ? undefined : (new Date(json['phone_verified_at'])),
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'host': !exists(json, 'host') ? undefined : ProfileHostTransformerFromJSON(json['host']),
    };
}

export function UserProfileTransformerDtoToJSON(value?: UserProfileTransformerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'email_verified_at': value.emailVerifiedAt === undefined ? undefined : (value.emailVerifiedAt.toISOString()),
        'phone_verified_at': value.phoneVerifiedAt === undefined ? undefined : (value.phoneVerifiedAt.toISOString()),
        'roles': value.roles,
        'host': ProfileHostTransformerToJSON(value.host),
    };
}

