/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    KeyValueDto,
    KeyValueDtoFromJSON,
    KeyValueDtoToJSON,
    Resource,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models';

export interface ApiResourcesActiveIdPutRequest {
    id: number;
    inlineObject: InlineObject;
}

export interface ApiResourcesByHouseIdGetRequest {
    id: number;
}

export interface ApiResourcesIdDeleteRequest {
    id: number;
}

export interface ApiResourcesIdGetRequest {
    id: string;
}

export interface ApiResourcesIdPutRequest {
    id: number;
    resource: Resource;
}

export interface ApiResourcesOpenChannelIdGetRequest {
    id: number;
}

export interface ApiResourcesPostRequest {
    resource: Resource;
}

/**
 * 
 */
export class ResourcesApi extends runtime.BaseAPI {

    /**
     */
    async apiResourcesActiveIdPutRaw(requestParameters: ApiResourcesActiveIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcesActiveIdPut.');
        }

        if (requestParameters.inlineObject === null || requestParameters.inlineObject === undefined) {
            throw new runtime.RequiredError('inlineObject','Required parameter requestParameters.inlineObject was null or undefined when calling apiResourcesActiveIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resources/active/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourcesActiveIdPut(requestParameters: ApiResourcesActiveIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourcesActiveIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResourcesByHouseIdGetRaw(requestParameters: ApiResourcesByHouseIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Resource>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcesByHouseIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resources/byHouse/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceFromJSON));
    }

    /**
     */
    async apiResourcesByHouseIdGet(requestParameters: ApiResourcesByHouseIdGetRequest, initOverrides?: RequestInit): Promise<Array<Resource>> {
        const response = await this.apiResourcesByHouseIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResourcesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Resource>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceFromJSON));
    }

    /**
     */
    async apiResourcesGet(initOverrides?: RequestInit): Promise<Array<Resource>> {
        const response = await this.apiResourcesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResourcesIdDeleteRaw(requestParameters: ApiResourcesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourcesIdDelete(requestParameters: ApiResourcesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourcesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResourcesIdGetRaw(requestParameters: ApiResourcesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     */
    async apiResourcesIdGet(requestParameters: ApiResourcesIdGetRequest, initOverrides?: RequestInit): Promise<Resource> {
        const response = await this.apiResourcesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResourcesIdPutRaw(requestParameters: ApiResourcesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcesIdPut.');
        }

        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling apiResourcesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourcesIdPut(requestParameters: ApiResourcesIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourcesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResourcesOpenChannelIdGetRaw(requestParameters: ApiResourcesOpenChannelIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<KeyValueDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourcesOpenChannelIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resources/openChannel/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyValueDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiResourcesOpenChannelIdGet(requestParameters: ApiResourcesOpenChannelIdGetRequest, initOverrides?: RequestInit): Promise<KeyValueDto> {
        const response = await this.apiResourcesOpenChannelIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResourcesPostRaw(requestParameters: ApiResourcesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling apiResourcesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resources`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceToJSON(requestParameters.resource),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     */
    async apiResourcesPost(requestParameters: ApiResourcesPostRequest, initOverrides?: RequestInit): Promise<Resource> {
        const response = await this.apiResourcesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
