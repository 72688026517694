/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HostAccommodationFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import {HostAccommodation} from "@services/src/models";
import {AccommodationFactory} from "./AccommodationFactory";
import {AccommodationResourceJoinFactory} from "./AccommodationResourceJoinFactory";

export class HostAccommodationFactory {
    static factoryArray(size: number): HostAccommodation[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(HostAccommodationFactory.factory());
        }
        return array;
    }

    static factory(): HostAccommodation {
        return {
            id: AccommodationFactory.factory().id,
            name: AccommodationFactory.factory().name,
            description: AccommodationFactory.factory().description,
            accommodationResourceJoins: AccommodationResourceJoinFactory.factoryArray(5),
        };
    }
}

