/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       IntroCollaborationNewUserScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {View,} from 'react-native';

import {AvenirLightGreyText} from '@components/StyledText';
import {i18n} from "../../../i18n/i18n";
import introScreenStyle from "@styles/host/collaborations/IntroCollaborationNewUserScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {ConfirmButton} from "@components/ConfirmButton";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openNewCollaborationCollaboratorRoute} from "@helpers/NavigationHelper";
import {getCollaboratorFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {UserNotFoundImage} from "@components/Images/UserNotFoundImage";
import {Collaborator} from "@services/src/models/index";

interface State extends LoaderState {
    collaborator: Collaborator,
}

export default class IntroCollaborationNewUserScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.intro_collaboration_new_user.title');

        this.state = {
            collaborator: getCollaboratorFromNavigationProps(this),
            loading: false,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <View style={introScreenStyle.container}>
                <Loader state={this.state}/>
                <View
                    style={[introScreenStyle.container, globalScreenStyle.globalMargins, {
                        display: 'flex',
                        flexDirection: 'column',
                    }]}>
                    <View style={introScreenStyle.welcomeContainer}>
                        <View style={{flex: 1, alignContent: 'center', alignSelf: 'center',}}>
                            <UserNotFoundImage style={introScreenStyle.welcomeImage}/>
                        </View>
                    </View>

                    <View style={introScreenStyle.subtitleContainer}>
                        <AvenirLightGreyText style={{marginBottom: 10,}}>
                            {i18n.t('screens.intro_collaboration_new_user.subtitle_1')}
                        </AvenirLightGreyText>


                        <AvenirLightGreyText style={{marginTop: 10,}}>
                            {i18n.t('screens.intro_collaboration_new_user.subtitle_2')}
                        </AvenirLightGreyText>
                    </View>


                    <View style={introScreenStyle.buttonContainer}>
                        <ConfirmButton onPress={() => openNextPage(this)}
                                       title={i18n.t('buttons.intro_collaboration_new_user')}
                                       active={true} style={{}}/>
                    </View>
                </View>
            </View>
        );
    }
}

/**
 * Open the next page if email is valid
 * @param context
 */
function openNextPage(context) {
    openNewCollaborationCollaboratorRoute(context, context.state.collaborator);
}