/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationFiltersScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, TouchableOpacity, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import screenStyle from "@styles/host/reservations/ReservationFiltersScreenStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {dismissModal, NavigationProps} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {
    getDoorFiltersDismissCallbackFromNavigationProps,
    getFiltersHousesFromNavigationProps,
    getReservationFiltersFromNavigationProps
} from "@helpers/NavigationDataHelper";
import {ReservationFilterInput, ResourceType} from "@services/src/models/index";
import {
    DoorHousesFilterBlock,
    ReservationEmailFilterBlock,
    ReservationStatusFilterBlock
} from "@components/FilterContainerBlock";
import {AvenirHeavyPrimaryText} from "@components/StyledText";
import {CloseColorImage} from "@components/Images/CloseColorImage";
import {Logger} from "@helpers/Logger";
import {HouseSelectionBean} from "../../../models/beans/HouseSelection";

interface State extends LoaderState {
    housesSelectionBeans: Array<HouseSelectionBean>,
    selectedType: ResourceType,
    reservation_filters: ReservationFilterInput,
    onDismiss: (result: ReservationFilterInput) => void,
    applied: boolean,
}

export default class ReservationFiltersScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            housesSelectionBeans: getFiltersHousesFromNavigationProps(this) || [],
            selectedType: null,
            reservation_filters: getReservationFiltersFromNavigationProps(this) || {
                registryType: null,
                fromDate: null,
                toDate: null,
                registryEmail: '',
                reservationStateCheckedin: false,
                reservationStatePending: false,
                reservationStateConfirmed: false,
                reservationStateCanceled: false,
            },
            onDismiss: getDoorFiltersDismissCallbackFromNavigationProps(this) || null,
            applied: false,
        };

    }

    componentWillUnmount() {
        if (this.state.applied)
            this.state.onDismiss(this.state.reservation_filters);
    }

    updateCheckedin(checkedin: boolean) {
        let filters = this.state.reservation_filters;
        filters.reservationStateCheckedin = checkedin;
        this.setState({reservation_filters: filters});
    }

    updateCheckedout(checkedout: boolean) {
        let filters = this.state.reservation_filters;
        filters.reservationStateCheckedout = checkedout;
        this.setState({reservation_filters: filters});
    }

    updatePending(pending: boolean) {
        let filters = this.state.reservation_filters;
        filters.reservationStatePending = pending;
        this.setState({reservation_filters: filters});
    }

    updateConfirmed(confirmed: boolean) {
        let filters = this.state.reservation_filters;
        filters.reservationStateConfirmed = confirmed;
        this.setState({reservation_filters: filters});
    }

    updateCanceled(canceled: boolean) {
        let filters = this.state.reservation_filters;
        filters.reservationStateCanceled = canceled;
        this.setState({reservation_filters: filters});
    }

    updateEmail(text: string) {
        if (text != null)
            text = text.trim();
        let filters = this.state.reservation_filters;
        filters.registryEmail = text;
        this.setState({reservation_filters: filters});
    }

    updateSelectedHouse(key) {
        let houses = this.state.housesSelectionBeans;
        houses[key].selected = !houses[key].selected;
        let filters = this.state.reservation_filters;
        filters.houseIds = houses.filter(bean => bean.selected).map(bean => bean.house.id);
        console.log(filters.houseIds);
        this.setState({housesSelectionBeans: houses, reservation_filters: filters});
    }

    render() {
        return (
            <View style={screenStyle.container}>
                <Loader state={this.state}/>
                <View
                    style={[screenStyle.scrollContainer, screenStyle.scrollOuter]}>
                    <View style={{flexDirection: 'row', marginBottom: 10,}}>
                        <View style={[screenStyle.flex,]}>
                            <AvenirHeavyPrimaryText>
                                {i18n.t('screens.reservation_filters.title')}
                            </AvenirHeavyPrimaryText>
                        </View>
                        <View style={[screenStyle.flex, {alignContent: 'flex-end', alignItems: 'flex-end',}]}>
                            <TouchableOpacity onPress={() => dismissModal(this)}>
                                <CloseColorImage style={{width: 20, height: 20,}}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View
                        style={[screenStyle.getStartedContainer, screenStyle.scrollContainer]}>
                        {this.state.housesSelectionBeans != null && this.state.housesSelectionBeans.length > 0 &&
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <DoorHousesFilterBlock onPress={(key) => this.updateSelectedHouse(key)}
                                                   houseSelectionBeans={this.state.housesSelectionBeans}
                                                   style={{flex: 1, flexDirection: 'column'}}/>
                        </View>
                        }
                        {/* TODO */}
                        {/*<View style={{flex: 0.5, flexDirection: 'row'}}>*/}
                        {/*<ReservationDateFilterBlock*/}
                        {/*value={this.state.reservation_filters.registryEmail}*/}
                        {/*onChangeText={(text) => this.updateEmail(text)}*/}
                        {/*/>*/}
                        {/*</View>*/}
                        <View style={{flex: 1,}}>
                            <ReservationStatusFilterBlock
                                checkedin={this.state.reservation_filters.reservationStateCheckedin}
                                pending={this.state.reservation_filters.reservationStatePending}
                                confirmed={this.state.reservation_filters.reservationStateConfirmed}
                                canceled={this.state.reservation_filters.reservationStateCanceled}
                                checkedout={this.state.reservation_filters.reservationStateCheckedout}
                                onPressCheckedin={() => this.updateCheckedin(!this.state.reservation_filters.reservationStateCheckedin)}
                                onPressPending={() => this.updatePending(!this.state.reservation_filters.reservationStatePending)}
                                onPressConfirmed={() => this.updateConfirmed(!this.state.reservation_filters.reservationStateConfirmed)}
                                onPressCanceled={() => this.updateCanceled(!this.state.reservation_filters.reservationStateCanceled)}
                                onPressCheckedout={() => this.updateCheckedout(!this.state.reservation_filters.reservationStateCheckedout)}
                            />
                        </View>
                        <KeyboardAvoidingView behavior={"height"} style={{flex: 0.5, flexDirection: 'row'}}>
                            <ReservationEmailFilterBlock
                                value={this.state.reservation_filters.registryEmail}
                                onChangeText={(text) => this.updateEmail(text)}/>
                        </KeyboardAvoidingView>
                        <View style={screenStyle.flex}>
                            {/*Dummy*/}
                        </View>
                    </View>
                    <ConfirmButtonBottom active={true} onPress={() => applyFilters(this)}
                                         title={i18n.t('buttons.reservation_filters')}
                                         style={{marginHorizontal: 0, marginBottom: 0,}}/>
                </View>
            </View>
        );
    }
}

/**
 * Apply selected filters
 * @param {React.Component<any, State>} context
 */
function applyFilters(context: Component<NavigationProps, State>) {
    Logger.log(context.constructor.name, "Applying door filters");
    context.setState({applied: true});
    dismissModal(context);
}