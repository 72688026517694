/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Card,
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
    ReservationStateEnum,
    ReservationStateEnumFromJSON,
    ReservationStateEnumFromJSONTyped,
    ReservationStateEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface Reservation
 */
export interface Reservation {
    /**
     * 
     * @type {number}
     * @memberof Reservation
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Reservation
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Reservation
     */
    endAt?: Date;
    /**
     * 
     * @type {ReservationStateEnum}
     * @memberof Reservation
     */
    currentState?: ReservationStateEnum;
    /**
     * 
     * @type {number}
     * @memberof Reservation
     */
    registryId?: number;
    /**
     * 
     * @type {number}
     * @memberof Reservation
     */
    hostId?: number;
    /**
     * 
     * @type {Card}
     * @memberof Reservation
     */
    card?: Card;
}

export function ReservationFromJSON(json: any): Reservation {
    return ReservationFromJSONTyped(json, false);
}

export function ReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Reservation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startAt': !exists(json, 'start_at') ? undefined : (new Date(json['start_at'])),
        'endAt': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'currentState': !exists(json, 'current_state') ? undefined : ReservationStateEnumFromJSON(json['current_state']),
        'registryId': !exists(json, 'registry_id') ? undefined : json['registry_id'],
        'hostId': !exists(json, 'host_id') ? undefined : json['host_id'],
        'card': !exists(json, 'card') ? undefined : CardFromJSON(json['card']),
    };
}

export function ReservationToJSON(value?: Reservation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start_at': value.startAt === undefined ? undefined : (value.startAt.toISOString()),
        'end_at': value.endAt === undefined ? undefined : (value.endAt.toISOString()),
        'current_state': ReservationStateEnumToJSON(value.currentState),
        'registry_id': value.registryId,
        'host_id': value.hostId,
        'card': CardToJSON(value.card),
    };
}

