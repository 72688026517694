/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    HouseContact,
    HouseContactFromJSON,
    HouseContactFromJSONTyped,
    HouseContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostHouseInput
 */
export interface HostHouseInput {
    /**
     * 
     * @type {number}
     * @memberof HostHouseInput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HostHouseInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HostHouseInput
     */
    description?: string;
    /**
     * 
     * @type {Address}
     * @memberof HostHouseInput
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof HostHouseInput
     */
    photoBase64?: string;
    /**
     * 
     * @type {Array<HouseContact>}
     * @memberof HostHouseInput
     */
    houseContacts?: Array<HouseContact>;
}

export function HostHouseInputFromJSON(json: any): HostHouseInput {
    return HostHouseInputFromJSONTyped(json, false);
}

export function HostHouseInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostHouseInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'photoBase64': !exists(json, 'photo_base64') ? undefined : json['photo_base64'],
        'houseContacts': !exists(json, 'houseContacts') ? undefined : ((json['houseContacts'] as Array<any>).map(HouseContactFromJSON)),
    };
}

export function HostHouseInputToJSON(value?: HostHouseInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'address': AddressToJSON(value.address),
        'photo_base64': value.photoBase64,
        'houseContacts': value.houseContacts === undefined ? undefined : ((value.houseContacts as Array<any>).map(HouseContactToJSON)),
    };
}

