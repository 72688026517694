/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationRowStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import Colors from "../../../config/Colors";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
    },
    bigContainer: {
        flex: 1,
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    },
    bigNumber: {
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: 30,
    },
    leftText: {
        flex: 2,
        alignSelf: 'flex-start',
    },
    bottomNumber: {
        textAlign: 'right',
        flex: 1,
        marginLeft: 5,
        marginRight: 5,
    },
    bottomText: {
        flex: 7,
        textAlign: 'center',
    },
    detailArrowContainer: {
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        alignSelf: 'center',
    },
    dateArrow: {
        width: 8,
        height: 12,
    },
    detailArrow: {
        alignSelf: 'flex-end',
        width: 8,
        height: 10,
    },
    collaboratorIcon: {
        width: 17,
        height: 15,
    },
    bottomContainer: {
        flex: 2,
    },
    flex: {
        flex: 1,
    },
    textContainer: {
        flex: 1,
        margin: 0,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    doorImage: {
        width: 20,
        height: 20,
        alignSelf: 'center',
        resizeMode: 'contain',
    },
    dot: {
        width: 5,
        height: 5,
        borderRadius: 20,
        marginRight: 5,
        overflow: 'hidden',
    },
    rowWithCenteredContent: {
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        width: (((Layout.window.width-1310)/Layout.window.width)*16)+30 + '%',
    },
    dateContainer: {
        flex: 1,
    },
    timeContainer: {
        flexDirection: 'row',
        flex: 0.5,
    },
    timeText: {
        color: Colors.reservationTime,
        fontSize: 10,
        alignSelf: 'flex-end',
    },
});

export default styles;