/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       MainTabNavigator.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */

import React, {Component} from 'react';
import {createStackNavigator, StackNavigatorConfig} from 'react-navigation';
import {Platform, TouchableOpacity, View,} from 'react-native';
import TabBarIcon from '@components/TabBarIcon';
import MainScreen from "@screens/MainScreen";
import LoginScreen from "@screens/LoginScreen";
import SignupScreen from "@screens/host/SignupScreen";
import {LeftArrowImage} from "@components/Images/LeftArrowImage";
import globalScreenStyle from "@styles/GlobalStyle";
import headerStyle from "@styles/HeaderStyle";
import {LogoBFreeSmallImage} from "@components/Images/LogoBFreeSmallImage";
import {AvenirHeavyTitleText} from "@components/StyledText";
import {getHeaderTitle} from "@helpers/NavigationDataHelper";
import DrawerTabNavigator from "../drawer/DrawerTabNavigator";
import {NavigationProps, openDoorBarcodeScanner} from "@helpers/NavigationHelper";
import DoorFiltersScreen from "@screens/host/doors/DoorFiltersScreen";
import EmailVerificationScreen from "@screens/host/user/EmailVerificationScreen";
import PhoneVerificationScreen from "@screens/host/user/PhoneVerificationScreen";
import ReservationFiltersScreen from "@screens/host/reservations/ReservationFiltersScreen";
import StripeDebugScreen from "@screens/host/StripeDebugScreen";
import CollaborationFiltersScreen from "@screens/host/collaborations/CollaborationFiltersScreen";
import ProfilePassepartoutScreen from "@screens/host/user/ProfilePassepartoutScreen";
import ProfileScreen from "@screens/host/user/ProfileScreen";
import CardsScreen from "@screens/host/cards/CardsScreen";
import NewHouseScreen from "@screens/host/house/NewHouseScreen";
import NewDoorScreen from "@screens/host/doors/NewDoorScreen";
import NewAccommodationScreen from "@screens/host/accommodations/NewAccommodationScreen";
import FirstMainScreen from "@screens/First/FirstMainScreen";
import FirstLoginScreen from "@screens/First/FirstLoginScreen";
import FirstNewHouseScreen from "@screens/First/FirstNewHouseScreen";
import FirstNewDoorScreen from "@screens/First/FirstNewDoorScreen";
import FirstNewAccommodationScreen from "@screens/First/FirstNewAccommodationScreen";
import FirstNewHouseDoneScreen from "@screens/First/FirstNewHouseDoneScreen";
import BarcodeScannerScreen from "@screens/BarcodeScannerScreen";
import FirstBarcodeScannerScreen from "@screens/First/FirstBarcodeScannerScreen";

const config: StackNavigatorConfig = Platform.select({
    ios: {
        headerMode: 'float',
        initialRouteName: 'Home',
        headerLayoutPreset: 'center',
    },
    android: {
        headerMode: 'screen',
        initialRouteName: 'Home',
        headerLayoutPreset: 'center',
    },
    default: {
        headerMode: 'screen',
        initialRouteName: 'Home',
        headerLayoutPreset: 'center',
    },
});

//Navigation Drawer Structure for all screen
class NavigationHeaderLeftBack extends Component<NavigationProps, any> {
    /**
     * Go to previous page
     */
    goBack = () => {
        //Props to open/close the drawer
        this.props.navigation.pop();
    };

    render() {
        return (
            <View style={globalScreenStyle.globalMargins}>
                <TouchableOpacity onPress={this.goBack.bind(this)}>
                    <LeftArrowImage style={headerStyle.headerLeftBackImage}/>
                </TouchableOpacity>
            </View>
        );
    }
}

//Navigation Drawer Structure for all screen
class NavigationHeaderTitle extends Component<NavigationProps, any> {
    render() {
        let title = getHeaderTitle(this.props);
        let visible = title != null && title.trim() !== '';
        if (visible)
            return (
                <View style={headerStyle.headerTitleContainer}>
                    <LogoBFreeSmallImage style={headerStyle.headerTitleImage}/>
                    <AvenirHeavyTitleText>{title}</AvenirHeavyTitleText>
                </View>
            );
        else
            return null;
    }
}

/**
 * Gets options for Navigation header
 * @param navigation
 * @returns object
 */
function headerNavigationOptions(navigation) {
    return {
        headerLeft: <NavigationHeaderLeftBack navigation={navigation}/>,
        headerTitle: <NavigationHeaderTitle navigation={navigation}/>,
        headerStyle: headerStyle.header
    }
}

const HomeStack = createStackNavigator(
    {
        Home: {
            screen: MainScreen,
        },
        EmailVerification: {
            screen: EmailVerificationScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        PhoneVerification: {
            screen: PhoneVerificationScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    {
        headerMode: 'none',
    }
);

const LoginStack = createStackNavigator(
    {
        Home: {
            screen: LoginScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    config
);

const FirstLoginStack = createStackNavigator(
    {
        Home: {
            screen: FirstMainScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        FirstLoginScreen:{
            screen: FirstLoginScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },

    },
    config
);

const FirstConfigurationStack = createStackNavigator({
    Home: {
        screen: FirstNewHouseScreen,
        navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
    },
    FirstNewHouseDoneScreen:{
        screen: FirstNewHouseDoneScreen,
        navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
    },
    FirstNewDoorScreen:{
        screen: FirstNewDoorScreen,
        navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
    },
    FirstDoorBarcodeScanner:{
        screen: FirstBarcodeScannerScreen,
        navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
    },
    FirstNewAccommodation: {
        screen: FirstNewAccommodationScreen,
        navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
    },
 },

);

const SignupStack = createStackNavigator(
    {
        Home: {
            screen: SignupScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        EmailVerification: {
            screen: EmailVerificationScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        PhoneVerification: {
            screen: PhoneVerificationScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        FirstLogin:{
            screen: FirstLoginStack,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
        FirstConfig:{
            screen: FirstConfigurationStack,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        }
    },
    {headerMode: 'none'}
);

const DoorFiltersStack = createStackNavigator(
    {
        Home: {
            screen: DoorFiltersScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    {
        mode: 'modal',
        headerMode: 'none',
    }
);

const ReservationFiltersStack = createStackNavigator(
    {
        Home: {
            screen: ReservationFiltersScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    {
        mode: 'modal',
        headerMode: 'none',
    }
);

const CollaborationFiltersStack = createStackNavigator(
    {
        Home: {
            screen: CollaborationFiltersScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    {
        mode: 'modal',
        headerMode: 'none',
    }
);

const PassepartoutStack = createStackNavigator(
    {
        Home: {
            screen: ProfilePassepartoutScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    {
        mode: 'modal',
        headerMode: 'none',
    }
);

const DebugStack = createStackNavigator(
    {
        Home: {
            screen: StripeDebugScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    config
);

const RootStack = createStackNavigator(
    {
        Main: {
            screen: HomeStack,
        },
        Login: {
            screen: LoginStack
        },
        Signup: {
            screen: SignupStack
        },
        Dashboard: {
            screen: DrawerTabNavigator,
        },
        DoorFiltersStack: {
            screen: DoorFiltersStack,
        },
        ReservationFiltersStack: {
            screen: ReservationFiltersStack,
        },
        CollaborationFiltersStack: {
            screen: CollaborationFiltersStack,
        },
        PassepartoutStack: {
            screen: PassepartoutStack,
        },
        DebugStack: {
            screen: DebugStack,
        },
    },
    {
        headerMode: 'none',
    }
);

HomeStack.navigationOptions = {
    tabBarLabel: 'Home',
    tabBarIcon: ({focused}) => (
        <TabBarIcon
            focused={focused}
            name={
                Platform.OS === 'ios'
                    ? `ios-information-circle${focused ? '' : '-outline'}`
                    : 'md-information-circle'
            }
        />
    ),
};


export default RootStack;
