/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       LoginScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, TouchableOpacity, View,} from 'react-native';
import {i18n} from "@i18n/i18n";
import loginScreenStyle from "@styles/LoginScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import * as StorageHelper from "@helpers/StorageHelper";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {AvenirBookUnderlinedText, AvenirLightGreyText, AvenirMediumPrimaryText} from "@components/StyledText";
import {PasswordInputBlock} from "@components/TextInputBlock";
import {goBack, NavigationProps, openDashboard} from "@helpers/NavigationHelper";
import {ConfirmButton} from "@components/ConfirmButton";
import {environment} from "../environments/environment";
import {AuthApi, OauthApi} from "@services/src/apis";
import {OAuthRequestFromJSON} from "@services/src/models";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {getEmailFromNavigationProps} from "@helpers/NavigationDataHelper";
import {ApiAuthForgotPostRequest} from "@services/src/apis/AuthApi";

interface State extends LoaderState {
    email: string,
    password: string,
    passwordValid: boolean,
}

export default class LoginScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        this.state = {
            email: getEmailFromNavigationProps(this),
            password: "",
            passwordValid: false,
            loading: false,
        };
    }

    render() {
        return (
            <View style={loginScreenStyle.container}>
                <Loader state={this.state}/>
                <KeyboardAvoidingView behavior="height"
                                      style={loginScreenStyle.container}
                                      contentContainerStyle={loginScreenStyle.contentContainer}>
                    <View
                        style={[loginScreenStyle.getStartedContainer, loginScreenStyle.container, globalScreenStyle.globalMargins]}>
                        <View style={loginScreenStyle.welcomeContainer}>
                            <AvenirMediumPrimaryText>{i18n.t('screens.login.title')}</AvenirMediumPrimaryText>
                            <AvenirLightGreyText>{i18n.t('screens.login.description')}</AvenirLightGreyText>
                        </View>
                        <View style={loginScreenStyle.inputContainer}>
                            <PasswordInputBlock label={i18n.t('inputs.password')}
                                                text={this.state.password}
                                                onChangeText={(text) => checkPassword(this, text)}/>
                        </View>
                        <View style={loginScreenStyle.buttonContainer}>
                            <ConfirmButton onPress={() => openNextPage(this)} title={i18n.t('buttons.login')}
                                           active={true} style={{}}/>
                        </View>
                        <TouchableOpacity style={loginScreenStyle.buttonContainer}
                                          onPress={() => forgotPassword(this)}>
                            <AvenirBookUnderlinedText
                                style={loginScreenStyle.forgotPassword}>{i18n.t('screens.login.forgot_password')}</AvenirBookUnderlinedText>
                        </TouchableOpacity>
                    </View>
                </KeyboardAvoidingView>
            </View>
        );
    }
}

/**
 * Checks password is not null or empty
 * @param context
 * @param text
 */
function checkPassword(context: Component<NavigationProps, State>, text) {
    context.setState({password: text});
    if (text != null && text.trim() != '')
        context.setState({passwordValid: true});
}

/**
 * Sends request for forgotten password
 * @param {React.Component<NavigationProps, State>} context
 */
function forgotPassword(context: Component<NavigationProps, State>) {
    let email = context.state.email;
    let forgotPasswordRequest: ApiAuthForgotPostRequest = {forgotPasswordRequest: {email: email}};
    new AuthApi(context).apiAuthForgotPost(forgotPasswordRequest).then((resp) => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp);
        console.log(resp.message);
        AlertHelper.showSimpleAlert('', i18n.t('screens.login.forgot_password_success', {email: email}), function () {
            goBack(context);
        });
    }).catch((error) => {
        Logger.log(context.constructor.name, "Ko: " + error);
        AlertHelper.showSimpleErrorAlert();
        goBack(context);
    });
}

/**
 * Open the next page if password is valid
 * @param context
 */
function openNextPage(context: Component<NavigationProps, State>) {
    if (!context.state.passwordValid) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.empty_password'));
        return;
    }

    let par = OAuthRequestFromJSON({
        grant_type: 'password',
        username: context.state.email,
        password: context.state.password,
        client_id: environment.oauthClientId,
        client_secret: environment.oauthClientSecret
    });
    new OauthApi(context).oauthTokenPost({oAuthRequest: par}).then((resp) => {
        Logger.log(context.constructor.name, "Ok");
        if (resp.accessToken && resp.refreshToken) {
            StorageHelper.storeAccessToken(resp.accessToken);
            StorageHelper.storeRefreshToken(resp.refreshToken);
            openDashboard(context, null);
        } else {
            AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.wrong_credentials_error'));
        }
    }).catch((error) => {
        Logger.log(context.constructor.name, "Ko: " + error);
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.wrong_credentials_error'));
    });
}