/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReadonlyTextBlock.tsx
 * @author     Christian Ascone
 * @date       10/31/19 10:26 AM
 */

import React from 'react';
import {View} from 'react-native';
import TextStyle from '@styles/TextStyle';
import {AvenirBookText, AvenirBookUpperLabelText} from "./StyledText";

export function ReadonlyTextBlock(props) {
    return (
        <View style={[props.style, TextStyle.separatorContainer]}>
            <AvenirBookUpperLabelText style={{flex: 1, marginBottom: 0,}}>
                {props.label}
            </AvenirBookUpperLabelText>
            <AvenirBookText style={[TextStyle.upperLabel, {flex: 1, textAlign: 'right'}]}>
                {props.text}
            </AvenirBookText>
        </View>
    );
}