/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationsScreen.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {BackHandler, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import accommodationsScreenStyle from "@styles/host/accommodations/AccommodationScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openNewAccommodation} from "@helpers/NavigationHelper";
import {AddButton} from "@components/AddButton";
import {getHouseFromNavigationProps, getUserFromNavigationProps, setHeaderTitle} from "@helpers/NavigationDataHelper";
import {Accommodation, House, User} from "@services/src/models/index";
import {AccommodationRow} from "@components/rows/AccommodationRow";
import {AccommodationFactory} from "../../../models/factory/AccommodationFactory";
import {HostAccommodationsApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {AvenirHeavyPrimaryText} from "@components/StyledText";
import {DebugButton} from "@components/DebugButton";
import {NoDataLabel} from "@components/NoDataLabel";

interface State extends LoaderState {
    user: User,
    house: House,
    accommodations: Array<Accommodation>,
}

export default class AccommodationScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            user: getUserFromNavigationProps(this),
            house: getHouseFromNavigationProps(this),
            accommodations: [],
        };

    }

    componentDidMount() {
        if (this.state.house != null && this.state.house.id != null) {
            this.getResourcesByHouse();
            setHeaderTitle(this.props, this.state.house.name);

            this.didFocusListener = this.props.navigation.addListener('didFocus', payload => {
                this.getResourcesByHouse();
            });
        }
    }

    componentWillUnmount(): void {
        this.didFocusListener.remove();
        BackHandler.addEventListener('hardwareBackPress', this.backaction);
    }

    backaction = () => {
        this.props.navigation.popToTop();
        return true;
    }

    render() {
        return (
            <View style={[accommodationsScreenStyle.container]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[accommodationsScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <View style={globalScreenStyle.scrollContainerWithBottomButton}>
                        <ScrollView
                            style={accommodationsScreenStyle.container}
                            contentContainerStyle={accommodationsScreenStyle.contentContainer}>
                            <View style={accommodationsScreenStyle.welcomeContainer}>
                                <AvenirHeavyPrimaryText>
                                    {i18n.t('screens.accommodations.subtitle')}
                                </AvenirHeavyPrimaryText>
                            </View>

                            {this.state.accommodations.map((accommodation, key) => (
                                <View
                                    key={key}
                                    style={[accommodationsScreenStyle.accommodationContainer]}>
                                    <AccommodationRow context={this}
                                                      accommodation_id={accommodation.id}
                                                      accommodation_name={accommodation.name}
                                                      public_count={accommodation.publicResourcesCount}
                                                      residential_count={accommodation.residentialResourcesCount}
                                                      accommodation={accommodation}
                                                      house={this.state.house}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.accommodations} show={this.state.showNoData}/>
                    </View>
                    <View style={[{position: 'absolute', bottom: 20}]}>
                        <AddButton title={i18n.t('screens.dashboard.blocks.accommodations.add_button')}
                                   onPress={() => openNewAccommodation(this, this.state.house)}/>
                    </View>
                </View>
            </View>
        );
    }

    private getResourcesByHouse() {
        let self = this;
        new HostAccommodationsApi(this).apiHostAccommodationsByHouseIdGet({id: this.state.house.id}).then(resp => {
            Logger.log(self.constructor.name, "Ok");
            self.setState({accommodations: resp, showNoData: false});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({accommodations: AccommodationFactory.factoryArray(5)});
}