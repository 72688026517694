/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EventType,
    EventTypeFromJSON,
    EventTypeToJSON,
    HostEventList,
    HostEventListFromJSON,
    HostEventListToJSON,
} from '../models';

export interface ApiHostEventsGetRequest {
    count: number;
    type?: EventType;
}

/**
 * 
 */
export class HostEventsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostEventsGetRaw(requestParameters: ApiHostEventsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostEventList>> {
        if (requestParameters.count === null || requestParameters.count === undefined) {
            throw new runtime.RequiredError('count','Required parameter requestParameters.count was null or undefined when calling apiHostEventsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostEventListFromJSON(jsonValue));
    }

    /**
     */
    async apiHostEventsGet(requestParameters: ApiHostEventsGetRequest, initOverrides?: RequestInit): Promise<HostEventList> {
        const response = await this.apiHostEventsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
