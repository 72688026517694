/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Plan,
    PlanFromJSON,
    PlanToJSON,
} from '../models';

export interface ApiPlansIdDeleteRequest {
    id: number;
}

export interface ApiPlansIdGetRequest {
    id: string;
}

export interface ApiPlansIdPutRequest {
    id: number;
    plan: Plan;
}

export interface ApiPlansPostRequest {
    plan: Plan;
}

/**
 * 
 */
export class PlansApi extends runtime.BaseAPI {

    /**
     */
    async apiPlansGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Plan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanFromJSON));
    }

    /**
     */
    async apiPlansGet(initOverrides?: RequestInit): Promise<Array<Plan>> {
        const response = await this.apiPlansGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPlansIdDeleteRaw(requestParameters: ApiPlansIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPlansIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPlansIdDelete(requestParameters: ApiPlansIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPlansIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPlansIdGetRaw(requestParameters: ApiPlansIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPlansIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     */
    async apiPlansIdGet(requestParameters: ApiPlansIdGetRequest, initOverrides?: RequestInit): Promise<Plan> {
        const response = await this.apiPlansIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPlansIdPutRaw(requestParameters: ApiPlansIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPlansIdPut.');
        }

        if (requestParameters.plan === null || requestParameters.plan === undefined) {
            throw new runtime.RequiredError('plan','Required parameter requestParameters.plan was null or undefined when calling apiPlansIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PlanToJSON(requestParameters.plan),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPlansIdPut(requestParameters: ApiPlansIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPlansIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPlansPostRaw(requestParameters: ApiPlansPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.plan === null || requestParameters.plan === undefined) {
            throw new runtime.RequiredError('plan','Required parameter requestParameters.plan was null or undefined when calling apiPlansPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/plans`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PlanToJSON(requestParameters.plan),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPlansPost(requestParameters: ApiPlansPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPlansPostRaw(requestParameters, initOverrides);
    }

}
