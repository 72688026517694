/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PeriodicReading,
    PeriodicReadingFromJSON,
    PeriodicReadingToJSON,
} from '../models';

export interface ApiPeriodicReadingFilterbyResourceIdGetRequest {
    id: number;
}

export interface ApiPeriodicReadingIdDeleteRequest {
    id: number;
}

export interface ApiPeriodicReadingIdGetRequest {
    id: number;
}

export interface ApiPeriodicReadingIdPutRequest {
    id: number;
    periodicReading: PeriodicReading;
}

export interface ApiPeriodicReadingPostRequest {
    periodicReading: PeriodicReading;
}

/**
 * 
 */
export class PeriodicReadingsApi extends runtime.BaseAPI {

    /**
     */
    async apiPeriodicReadingFilterbyResourceIdGetRaw(requestParameters: ApiPeriodicReadingFilterbyResourceIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PeriodicReading>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPeriodicReadingFilterbyResourceIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/periodic_reading/filterbyResource/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PeriodicReadingFromJSON));
    }

    /**
     */
    async apiPeriodicReadingFilterbyResourceIdGet(requestParameters: ApiPeriodicReadingFilterbyResourceIdGetRequest, initOverrides?: RequestInit): Promise<Array<PeriodicReading>> {
        const response = await this.apiPeriodicReadingFilterbyResourceIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPeriodicReadingGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PeriodicReading>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/periodic_reading`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PeriodicReadingFromJSON));
    }

    /**
     */
    async apiPeriodicReadingGet(initOverrides?: RequestInit): Promise<Array<PeriodicReading>> {
        const response = await this.apiPeriodicReadingGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPeriodicReadingIdDeleteRaw(requestParameters: ApiPeriodicReadingIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPeriodicReadingIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/periodic_reading/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPeriodicReadingIdDelete(requestParameters: ApiPeriodicReadingIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPeriodicReadingIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPeriodicReadingIdGetRaw(requestParameters: ApiPeriodicReadingIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPeriodicReadingIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/periodic_reading/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPeriodicReadingIdGet(requestParameters: ApiPeriodicReadingIdGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPeriodicReadingIdGetRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPeriodicReadingIdPutRaw(requestParameters: ApiPeriodicReadingIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPeriodicReadingIdPut.');
        }

        if (requestParameters.periodicReading === null || requestParameters.periodicReading === undefined) {
            throw new runtime.RequiredError('periodicReading','Required parameter requestParameters.periodicReading was null or undefined when calling apiPeriodicReadingIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/periodic_reading/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PeriodicReadingToJSON(requestParameters.periodicReading),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPeriodicReadingIdPut(requestParameters: ApiPeriodicReadingIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPeriodicReadingIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPeriodicReadingPostRaw(requestParameters: ApiPeriodicReadingPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.periodicReading === null || requestParameters.periodicReading === undefined) {
            throw new runtime.RequiredError('periodicReading','Required parameter requestParameters.periodicReading was null or undefined when calling apiPeriodicReadingPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/periodic_reading`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PeriodicReadingToJSON(requestParameters.periodicReading),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPeriodicReadingPost(requestParameters: ApiPeriodicReadingPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPeriodicReadingPostRaw(requestParameters, initOverrides);
    }

}
