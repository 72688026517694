/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SupportReportType,
    SupportReportTypeFromJSON,
    SupportReportTypeFromJSONTyped,
    SupportReportTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SupportReport
 */
export interface SupportReport {
    /**
     * 
     * @type {number}
     * @memberof SupportReport
     */
    id?: number;
    /**
     * 
     * @type {SupportReportType}
     * @memberof SupportReport
     */
    type?: SupportReportType;
    /**
     * 
     * @type {string}
     * @memberof SupportReport
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportReport
     */
    platform?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportReport
     */
    version?: string;
    /**
     * 
     * @type {number}
     * @memberof SupportReport
     */
    hostId?: number;
}

export function SupportReportFromJSON(json: any): SupportReport {
    return SupportReportFromJSONTyped(json, false);
}

export function SupportReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : SupportReportTypeFromJSON(json['type']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'hostId': !exists(json, 'host_id') ? undefined : json['host_id'],
    };
}

export function SupportReportToJSON(value?: SupportReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': SupportReportTypeToJSON(value.type),
        'message': value.message,
        'platform': value.platform,
        'version': value.version,
        'host_id': value.hostId,
    };
}

