/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HeaderStyle.ts
 * @author     Christian Ascone
 * @date       9/18/19 4:04 PM
 */

import {Platform, StyleSheet} from "react-native";

const styles = StyleSheet.create({
    headerTitleContainer: {
        alignSelf: 'center',
        flexDirection: 'row',
        alignItems: 'center',

    },
    headerTitleImage: {
        width: 10,
        height: 10,
        marginRight: 5,
    },
    headerLeftBackImage: {
        width: 20,
        height: 20,
    },
    header: {
        elevation: 0,
        borderBottomColor: 'transparent',
        borderBottomWidth: 0,
        shadowColor: 'transparent',
        marginTop: '5%',
        ...Platform.select({
            web: {
                marginTop: 0,
            }
        }),
        shadowRadius: 0,
    },
});

export default styles;