/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResourcePlanJoin
 */
export interface ResourcePlanJoin {
    /**
     * 
     * @type {number}
     * @memberof ResourcePlanJoin
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ResourcePlanJoin
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ResourcePlanJoin
     */
    endDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcePlanJoin
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourcePlanJoin
     */
    creditsAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ResourcePlanJoin
     */
    autorenew?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResourcePlanJoin
     */
    resourceId?: number;
    /**
     * 
     * @type {number}
     * @memberof ResourcePlanJoin
     */
    planId?: number;
}

export function ResourcePlanJoinFromJSON(json: any): ResourcePlanJoin {
    return ResourcePlanJoinFromJSONTyped(json, false);
}

export function ResourcePlanJoinFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourcePlanJoin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startDate': !exists(json, 'start_date') ? undefined : (new Date(json['start_date'])),
        'endDate': !exists(json, 'end_date') ? undefined : (new Date(json['end_date'])),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'creditsAmount': !exists(json, 'credits_amount') ? undefined : json['credits_amount'],
        'autorenew': !exists(json, 'autorenew') ? undefined : json['autorenew'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'planId': !exists(json, 'plan_id') ? undefined : json['plan_id'],
    };
}

export function ResourcePlanJoinToJSON(value?: ResourcePlanJoin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start_date': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'end_date': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'active': value.active,
        'credits_amount': value.creditsAmount,
        'autorenew': value.autorenew,
        'resource_id': value.resourceId,
        'plan_id': value.planId,
    };
}

