/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SignupScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../i18n/i18n";
import signupScreenStyle from "@styles/host/SignupScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader from "@helpers/LoaderHelper";
import {NumberInputBlock, PasswordInputBlock, TextInputBlock} from "@components/TextInputBlock";
import {NavigationProps, openEmailVerification} from "@helpers/NavigationHelper";
import {ConfirmButton} from "@components/ConfirmButton";
import {AddressProviderType, HostType, UserRegisterDto} from "@services/src/models/index";
import {AvatarImageButton} from "@components/AvatarImageButton";
import {SwitchBlock} from "@components/SwitchBlock";
import {AvenirLightGreyText} from "@components/StyledText";
import {getEmailFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import {AuthApi} from "@services/src";
import {Logger} from "@helpers/Logger";
import {DebugButton} from "@components/DebugButton";
import {AddressFactory} from "../../models/factory/AddressFactory";
import {HostFactory} from "../../models/factory/HostFactory";
import {PermissionManager} from "../../modules/PermissionManager";
import {ImagesManager} from "../../modules/ImagesManager";
import {PhotoUploadState} from "@components/states/PhotoUploadState";
import {UserState} from "@components/states/UserState";
import {ActivationCodeHelper} from "@helpers/ActivationCodeHelper";
import {normalizeEmail} from "../../modules/Validator";

interface State extends UserState {
    activation_code: string,
}

export default class SignupScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.signup.title');
        this.state = {
            loading: false,
            activation_code: '',
            mail_address: getEmailFromNavigationProps(this),
            password: '',
            first_name: '',
            last_name: '',
            mobile: '',
            country: '',
            address: '',
            town_city: '',
            zip: '',
            language: 'it',
            devices_code: '',
            fiscal_code: '',
            fiscal_code_valid: false,
            insert_company_data: false,
            company_name: '',
            company_country: '',
            company_vat: '',
            company_address: '',
            company_town_city: '',
            company_pec_mail: '',
            company_sdi_code: '',
            usage_threshold: '0',
            agree_privacy_policy: '',
            user_photo_uri: null,
            user_photo_base64: null,
            countryList: []
        };

    }

    componentDidMount() {
        let self = this;
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={signupScreenStyle.container}>
                <Loader state={this.state}/>
                <ScrollView
                    style={signupScreenStyle.container}
                    contentContainerStyle={signupScreenStyle.contentContainer}>
                    <DebugButton onPress={() => debugUpdateData(this)}/>
                    <View
                        style={[signupScreenStyle.getStartedContainer, signupScreenStyle.container, globalScreenStyle.globalMargins]}>
                        <View style={signupScreenStyle.welcomeContainer}>
                            <AvatarImageButton onPress={() => updateImage(this)} style={{}}
                                               imageUri={this.state.user_photo_uri}/>
                        </View>
                        <View
                            style={signupScreenStyle.inputContainer}>
                            <TextInputBlock label={i18n.t('screens.signup.activation_code')}
                                            onChangeText={(text) => this.setState({activation_code: text})}
                                            text={this.state.activation_code} autoCapitalize={'none'}/>
                            <TextInputBlock label={i18n.t('screens.signup.mail_address')}
                                            onChangeText={(text) => this.setState({mail_address: normalizeEmail(text)})}
                                            text={this.state.mail_address} autoCapitalize={'none'}/>
                            <PasswordInputBlock label={i18n.t('screens.signup.password')}
                                                onChangeText={(text) => this.setState({password: text})}
                                                text={this.state.password}/>
                            <TextInputBlock label={i18n.t('screens.signup.first_name')}
                                            onChangeText={(text) => this.setState({first_name: text})}
                                            text={this.state.first_name}/>
                            <TextInputBlock label={i18n.t('screens.signup.last_name')}
                                            onChangeText={(text) => this.setState({last_name: text})}
                                            text={this.state.last_name}/>
                            <TextInputBlock label={i18n.t('screens.signup.mobile')}
                                            onChangeText={(text) => this.setState({mobile: text})}
                                            text={this.state.mobile}/>
                            <TextInputBlock label={i18n.t('screens.signup.country')}
                                            onChangeText={(text) => this.setState({country: text})}
                                            text={this.state.country}/>
                            <TextInputBlock label={i18n.t('screens.signup.address')}
                                            onChangeText={(text) => this.setState({address: text})}
                                            text={this.state.address}/>
                            <TextInputBlock label={i18n.t('screens.signup.town_city')}
                                            onChangeText={(text) => this.setState({town_city: text})}
                                            text={this.state.town_city}/>
                            <TextInputBlock label={i18n.t('screens.signup.zip')}
                                            onChangeText={(text) => this.setState({zip: text})}
                                            text={this.state.zip}/>
                            <TextInputBlock label={i18n.t('screens.signup.language')}
                                            onChangeText={(text) => this.setState({language: text})}
                                            text={this.state.language}/>
                            <TextInputBlock label={i18n.t('screens.signup.fiscal_code')}
                                            onChangeText={(text) => checkFiscalCode(this, text)}
                                            text={this.state.fiscal_code}/>
                            <SwitchBlock label={i18n.t('screens.signup.insert_company_data')}
                                         value={this.state.insert_company_data}
                                         onValueChange={(text) => this.setState({insert_company_data: text})}/>
                            {this.state.insert_company_data &&
                            <View style={{marginTop: 10,}}>
                                <TextInputBlock label={i18n.t('screens.signup.company_name')}
                                                onChangeText={(text) => this.setState({company_name: text})}
                                                text={this.state.company_name}/>
                                <TextInputBlock label={i18n.t('screens.signup.company_country')}
                                                onChangeText={(text) => this.setState({company_country: text})}
                                                text={this.state.company_country}/>
                                <TextInputBlock label={i18n.t('screens.signup.company_vat')}
                                                onChangeText={(text) => this.setState({company_vat: text})}
                                                text={this.state.company_vat}/>
                                <TextInputBlock label={i18n.t('screens.signup.company_address')}
                                                onChangeText={(text) => this.setState({company_address: text})}
                                                text={this.state.company_address}/>
                                <TextInputBlock label={i18n.t('screens.signup.company_town_city')}
                                                onChangeText={(text) => this.setState({company_town_city: text})}
                                                text={this.state.company_town_city}/>
                                <TextInputBlock label={i18n.t('screens.signup.company_pec_mail')}
                                                onChangeText={(text) => this.setState({company_pec_mail: text})}
                                                text={this.state.company_pec_mail}/>
                                <TextInputBlock label={i18n.t('screens.signup.company_sdi_code')}
                                                onChangeText={(text) => this.setState({company_sdi_code: text})}
                                                text={this.state.company_sdi_code}/>
                                <NumberInputBlock label={i18n.t('screens.signup.usage_threshold')}
                                                  onChangeText={(text) => this.setState({usage_threshold: text})}
                                                  text={this.state.usage_threshold}/>
                            </View>
                            }
                            <ConfirmButton style={{marginTop: 20,}} onPress={() => openNextPage(this)}
                                           title={i18n.t('buttons.signup')}
                                           active={true}/>
                            <AvenirLightGreyText
                                style={{marginTop: 20,}}>{i18n.t('screens.signup.agree_privacy_policy')}</AvenirLightGreyText>
                            <View style={{height: 20}}/>
                        </View>
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Update the image.
 * It checks the current state.
 * In case the image has already been taken it asks user to delete or replace it
 * @param {React.Component<any, State>} context
 * @returns {Promise<void>}
 */
function updateImage(context: Component<any, PhotoUploadState>) {
    if (context.state.user_photo_uri == null)
        return openImagePicker(context);
    AlertHelper.showImageSelectionAlert(function () {
        openImagePicker(context);
    }, function () {
        context.setState({user_photo_base64: null, user_photo_uri: null});
    });
}

/**
 * Open the image picker for profile photo
 * @param {React.Component<any, State>} context
 */
async function openImagePicker(context: Component<any, PhotoUploadState>) {
    await PermissionManager.checkCameraRollPermission();
    let result = await ImagesManager.chooseImage();

    // @ts-ignore
    context.setState({user_photo_uri: result.uri, user_photo_base64: result.base64 || result.uri});
}

/**
 * Update context with debug data
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let address = AddressFactory.factory();
    let host = HostFactory.factory();
    context.setState({
        activation_code: '',
        mail_address: host.email,
        password: 'testtest',
        first_name: host.firstName,
        last_name: host.lastName,
        mobile: host.mobile,
        country: address.locality,
        address: address.street,
        town_city: address.city,
        zip: address.zip,
        language: 'IT',
        fiscal_code: host.fiscalCode,
        fiscal_code_valid: true,
    });
}

/**
 * Checks Fiscal Code validity
 * @param context
 * @param text
 */
function checkFiscalCode(context: Component<any, State>, text) {
    context.setState({fiscal_code: text});
    if (text !== null && text.length == 16)
        context.setState({fiscal_code_valid: true});
}

/**
 * Show alert for missing field
 * @param localizationKey
 */
function showMissingFieldAlert(localizationKey) {
    AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.you_must_fill_field', {field: i18n.t('screens.signup.' + localizationKey)}));
}

/**
 * Validate every field
 * @param context
 * @returns {boolean}
 */
function validateData(context: Component<any, State>): boolean {
    if (context.state.activation_code == null || context.state.activation_code.trim().length == 0) {
        showMissingFieldAlert('activation_code');
        return false;
    }
    const activationCodeValid = ActivationCodeHelper.checkActivationCodeIsValid(context.state.activation_code);
    if (!activationCodeValid) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_activation_code'));
        return false;
    }
    if (context.state.mail_address == null || context.state.mail_address.trim().length == 0) {
        showMissingFieldAlert('mail_address');
        return false;
    }
    if (context.state.password == null || context.state.password.trim().length == 0) {
        showMissingFieldAlert('password');
        return false;
    }
    if (context.state.first_name == null || context.state.first_name.trim().length == 0) {
        showMissingFieldAlert('first_name');
        return false;
    }
    if (context.state.last_name == null || context.state.last_name.trim().length == 0) {
        showMissingFieldAlert('last_name');
        return false;
    }
    if (context.state.mobile == null || context.state.mobile.trim().length == 0) {
        showMissingFieldAlert('mobile');
        return false;
    }
    if (context.state.country == null || context.state.country.trim().length == 0) {
        showMissingFieldAlert('country');
        return false;
    }
    if (context.state.address == null || context.state.address.trim().length == 0) {
        showMissingFieldAlert('address');
        return false;
    }
    if (context.state.town_city == null || context.state.town_city.trim().length == 0) {
        showMissingFieldAlert('town_city');
        return false;
    }
    if (context.state.zip == null || context.state.zip.trim().length == 0) {
        showMissingFieldAlert('zip');
        return false;
    }
    if (context.state.language == null || context.state.language.trim().length == 0) {
        showMissingFieldAlert('language');
        return false;
    }
    if (context.state.fiscal_code == null || context.state.fiscal_code.trim().length == 0) {
        showMissingFieldAlert('fiscal_code');
        return false;
    }
    let passwordValid = context.state.password.length >= 8;
    if (!passwordValid) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_password'));
        return false;
    }
    if (!context.state.fiscal_code_valid) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_fiscal_code'));
        return false;
    }

    return true;
}

/**
 * Open the next page if password is valid
 * @param context
 */
async function openNextPage(context: Component<any, State>) {
    if (!validateData(context))
        return;

    let userDto: UserRegisterDto = {
        password: context.state.password,
        email: context.state.mail_address,
        name: context.state.first_name + ' ' + context.state.last_name,
        phone: context.state.mobile,
        addressPlaceProvider: AddressProviderType.GoogleMaps,
        addressStreet: context.state.address,
        addressLocality: context.state.country,
        addressCity: context.state.town_city,
        addressZip: context.state.zip,
        hostFirstName: context.state.first_name,
        hostLastName: context.state.last_name,
        hostName: context.state.first_name + ' ' + context.state.last_name,
        hostEmail: context.state.mail_address,
        hostMobile: context.state.mobile,
        hostFiscalCode: context.state.fiscal_code,
        hostType: HostType.Private,
        hostUsageThreshold: +context.state.usage_threshold,
        hostHasCompanyData: context.state.insert_company_data,
        photoBase64: context.state.user_photo_base64,
    };
    if (context.state.insert_company_data) {
        userDto.hostType = HostType.Company;
        userDto.companyName = context.state.company_name;
        userDto.companyVat = context.state.company_vat;
        userDto.companyPec = context.state.company_pec_mail;
        userDto.companySdi = context.state.company_sdi_code;
    }
    new AuthApi(context).apiRegisterPost(userDto).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        openEmailVerification(context, resp);
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    });
}