/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       RoundedButton.tsx
 * @author     Christian Ascone
 * @date       2/26/20 5:56 PM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import ButtonStyle from "@styles/ButtonStyle";
import {AvenirMediumText} from "./StyledText";
import {Icon} from "react-native-elements";
import {QrCodeIcon} from "@components/Images/QrCodeIcon";
import Colors from "../config/Colors";

interface RoundedButtonProps {
    style?: StyleProp<ViewStyle>,
    onPress: (event: GestureResponderEvent) => void,
    title: string,
    isQr: boolean,
}

export class RoundedButton extends Component<RoundedButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <View>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[ButtonStyle.enabledRoundedButton, this.props.style, {flexDirection: 'row', justifyContent:'center'}]}>
                    <AvenirMediumText style={[ButtonStyle.roundedButtonText, {marginHorizontal: '10%'}]}>{this.props.title}</AvenirMediumText>
                    {this.props.isQr && <QrCodeIcon style={{color: Colors.primaryColor, height: 20, width: 20}}/>}
                </TouchableOpacity>
            </View>
        );
    }
}