/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ArrayHelper.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import {ResourceSelectionBean} from "../models/beans/ResourceSelection";
import {Reservation, ReservationStateEnum} from "@services/src/models";

export class ArrayHelper {
    /**
     * Filters the list of ResourceSelectionBean.
     * It returns only selected items
     * @param {ResourceSelectionBean[]} list
     * @returns {any}
     */
    public static filterResourceSelectionBean(list: ResourceSelectionBean[]) {
        return list.filter(resourceSelectionBean => resourceSelectionBean.selected);
    }

    /**
     * Counts number of reservations with given state in given array
     * @param {Reservation[]} list
     * @param {ReservationStateEnum} state
     * @returns {any}
     */
    public static countReservationsByState(list: Reservation[], state: ReservationStateEnum) {
        if (list == null || list.length == 0) return 0;
        switch (state) {
            case ReservationStateEnum.Pending:
                return list.filter(r => r.currentState == ReservationStateEnum.Pending).length;
            case ReservationStateEnum.Confirmed:
                return list.filter(r => r.currentState == ReservationStateEnum.Confirmed).length;
            case ReservationStateEnum.Checkedin:
                return list.filter(r => r.currentState == ReservationStateEnum.Checkedin).length;
            default:
                return 0;
        }
    }

    /**
     * Turn enum into array
     * @param enumme
     * @returns {any}
     */
    public static enumToArray(enumme) {
        return Object.keys(enumme)
            .map(key => enumme[key]);
    }
}