/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseCompleteFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import {House, HouseComplete} from "@services/src/models";
import {HouseFactory} from "./HouseFactory";
import {AccommodationFactory} from "./AccommodationFactory";

export class HouseCompleteFactory {
    static factoryArray(size: number): House[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(HouseCompleteFactory.factory());
        }
        return array;
    }

    static factory(): HouseComplete {
        return {
            description: HouseFactory.factory().description,
            id: HouseFactory.factory().id,
            name: HouseFactory.factory().name,
            address: HouseFactory.factory().address,
            photoPath: HouseFactory.factory().photoPath,
            accommodations: AccommodationFactory.factoryArray(5),
            accommodationsCount: 5,
        };
    }
}

