/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       MainNavigator.js
 * @author     Christian Ascone
 * @date       9/13/19 11:01 AM
 */

import React from 'react';
import {createAppContainer} from 'react-navigation';
import NeedToUpdateNavigator from './NeedToUpdateNavigator.tsx';

export default createAppContainer(
    NeedToUpdateNavigator
);
