/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Guest,
    GuestFromJSON,
    GuestToJSON,
    VerificationCodeType,
    VerificationCodeTypeFromJSON,
    VerificationCodeTypeToJSON,
} from '../models';

export interface ApiHostGuestsExistsEmailGetRequest {
    email: string;
}

export interface ApiHostGuestsIdPutRequest {
    id: number;
    guest: Guest;
}

export interface ApiHostGuestsPostRequest {
    guest: Guest;
}

export interface ApiHostGuestsSendVerificationTypePostRequest {
    type: VerificationCodeType;
    guest: Guest;
}

/**
 * 
 */
export class HostGuestsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostGuestsExistsEmailGetRaw(requestParameters: ApiHostGuestsExistsEmailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Guest>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling apiHostGuestsExistsEmailGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/guests/exists/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestFromJSON(jsonValue));
    }

    /**
     */
    async apiHostGuestsExistsEmailGet(requestParameters: ApiHostGuestsExistsEmailGetRequest, initOverrides?: RequestInit): Promise<Guest> {
        const response = await this.apiHostGuestsExistsEmailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostGuestsIdPutRaw(requestParameters: ApiHostGuestsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostGuestsIdPut.');
        }

        if (requestParameters.guest === null || requestParameters.guest === undefined) {
            throw new runtime.RequiredError('guest','Required parameter requestParameters.guest was null or undefined when calling apiHostGuestsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/guests/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GuestToJSON(requestParameters.guest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostGuestsIdPut(requestParameters: ApiHostGuestsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostGuestsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostGuestsPostRaw(requestParameters: ApiHostGuestsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Guest>> {
        if (requestParameters.guest === null || requestParameters.guest === undefined) {
            throw new runtime.RequiredError('guest','Required parameter requestParameters.guest was null or undefined when calling apiHostGuestsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/guests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GuestToJSON(requestParameters.guest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GuestFromJSON(jsonValue));
    }

    /**
     */
    async apiHostGuestsPost(requestParameters: ApiHostGuestsPostRequest, initOverrides?: RequestInit): Promise<Guest> {
        const response = await this.apiHostGuestsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostGuestsSendVerificationTypePostRaw(requestParameters: ApiHostGuestsSendVerificationTypePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiHostGuestsSendVerificationTypePost.');
        }

        if (requestParameters.guest === null || requestParameters.guest === undefined) {
            throw new runtime.RequiredError('guest','Required parameter requestParameters.guest was null or undefined when calling apiHostGuestsSendVerificationTypePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/guests/sendVerification/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GuestToJSON(requestParameters.guest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostGuestsSendVerificationTypePost(requestParameters: ApiHostGuestsSendVerificationTypePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostGuestsSendVerificationTypePostRaw(requestParameters, initOverrides);
    }

}
