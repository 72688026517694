import {
    GestureResponderEvent, Platform,
    ScrollView,
    StyleProp,
    StyleSheet,
    TouchableOpacity,
    View,
    ViewStyle
} from "react-native";
import React, {Component} from "react";
import {AvenirBookPrimaryText} from "@components/StyledText";

import {LoaderState} from "@helpers/LoaderHelper";
import {SupportReportType} from "@services/src";
import Colors from "../config/Colors";
import houseRowStyles from "@styles/host/rows/HouseRowStyle";
import {RightArrowColorImage} from "@components/Images/RightArrowColorImage";
import {DetailArrowColorImage} from "@components/Images/DetailArrowColorImage";
import {DetailArrowImage} from "@components/Images/DetailArrowImage";


interface PickerProps {
    items: SupportReportType[],
    selectedItem: SupportReportType,
    onValueChange: (selectedItem)=>void,

}


interface State extends LoaderState{
    selectedItem: SupportReportType;
    active: boolean;
}

interface itemProps {
    onPress: ()=>void
    value: string,
}

function Item(props: itemProps) {
    return (
        <View style={{padding: 5, borderTopWidth: 0.5}}>
            <TouchableOpacity onPress={props.onPress}>
                <AvenirBookPrimaryText style={{ fontSize: 12, alignSelf: 'center'}}>
                    {props.value}
                </AvenirBookPrimaryText>
            </TouchableOpacity>
        </View>
    );
}

export default class AndroidPicker extends Component<PickerProps, State> {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            active: false,
            selectedItem: SupportReportType.Altro,
        };

    }

    render(){
        return(
            <View style={{marginVertical: '15%',alignContent: 'center', ...Platform.select({web: {marginVertical: '2%'}})}}>
                <TouchableOpacity onPress={()=>{this.setState({active: !this.state.active}); console.log("Picker Clicked, State: ", this.state.active);}}>
                   <View style={{borderStyle: 'solid', borderWidth: 1, borderColor: Colors.primaryColor, display: 'flex', position: 'relative', padding: 10, flexDirection: 'row', justifyContent: 'center',}}>
                       <AvenirBookPrimaryText style={{ fontSize: 18, }}> {this.state.selectedItem} </AvenirBookPrimaryText>
                       <DetailArrowImage style={this.state.active ? [styles.detailActiveArrow] : [styles.detailArrow]}/>
                   </View>
                </TouchableOpacity>
                    {console.log("Current activ: ", this.state.active)}
                    {this.state.active ? <View style={{borderColor: Colors.primaryColor, borderWidth: 1}}>
                        {this.props.items.map((item, key)=>{
                        console.log("State: active");
                        return(
                        <Item
                              onPress={() => this.selectItem(key)}
                              value={item}/>

                        );}
                    ) }</View> : null}


            </View>

        );

    }

    selectItem(key: number){
        console.log("Item Clicked");
        this.setState({active: false, selectedItem: this.props.items[key]});
        this.props.onValueChange(this.state.selectedItem);
    }
}

const styles = StyleSheet.create({
    detailArrow: {
        alignSelf: 'center',
            width: 8,
            height: 8,
        position:'absolute',
        right: 10,
    },
    detailActiveArrow:{
        alignSelf: 'center',
        width: 8,
        height: 8,
        transform: [{rotateZ: "90deg" }],
        position: 'absolute',
        right: 10
    }
})