/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SwitchBlock.tsx
 * @author     Christian Ascone
 * @date       10/31/19 10:26 AM
 */

import React from 'react';
import {Switch, View} from 'react-native';
import {AvenirBookSwitchText} from "./StyledText";
import SwitchStyle from "@styles/SwitchStyle";
import Colors from "../config/Colors";

export function SwitchBlock(props) {
    return (
        <View style={SwitchStyle.switchContainer}>
            <AvenirBookSwitchText style={SwitchStyle.switchText}>
                {props.label}
            </AvenirBookSwitchText>
            <Switch value={props.value} onValueChange={props.onValueChange} style={SwitchStyle.switch} trackColor={{true: Colors.primaryColor, false: 'grey'}}/>
        </View>
    );
}
