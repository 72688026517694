/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AccommodationSelectionRowStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    serviceSelectionContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    bigContainer: {
        flex: 1,
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    },
    textContainer: {
        flex: 1,
        margin: 0,
        padding: 10,
        flexDirection: 'row',
    },
    accommodationImage: {
        width: 35,
        height: 35,
        alignSelf: 'center',
        resizeMode: 'contain',
    },
    flex: {
        flex: 1,
    },
});

export default styles;