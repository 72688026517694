/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CreditsRowStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 3:20 PM
 */

import {StyleSheet} from "react-native";
import Colors from "../../../config/Colors";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
    },
    rightNumber: {
        textAlign: 'right',
        flex: 1,
        marginLeft: 5,
        marginRight: 5,
    },
    icon: {
        width: 20,
        height: 20,
        resizeMode: 'contain',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    text: {
        flex: 1,
        alignSelf: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    smallText: {
        flex: 0.5,
        alignSelf: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    detailArrowContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    detailArrow: {
        alignSelf: 'flex-end',
        width: 8,
        height: 8,
    },
    flex: {
        flex: 1,
    },
    textContainer: {
        flex: 1,
        margin: 1.5,
        padding: 15,
        flexDirection: 'row',
        backgroundColor: Colors.background,
    },
});

export default styles;