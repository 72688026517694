/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    ResourceStateEnum,
    ResourceStateEnumFromJSON,
    ResourceStateEnumFromJSONTyped,
    ResourceStateEnumToJSON,
    ResourceType,
    ResourceTypeFromJSON,
    ResourceTypeFromJSONTyped,
    ResourceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostResourceInput
 */
export interface HostResourceInput {
    /**
     * 
     * @type {number}
     * @memberof HostResourceInput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HostResourceInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HostResourceInput
     */
    serial?: string;
    /**
     * 
     * @type {string}
     * @memberof HostResourceInput
     */
    description?: string;
    /**
     * 
     * @type {ResourceType}
     * @memberof HostResourceInput
     */
    type?: ResourceType;
    /**
     * 
     * @type {number}
     * @memberof HostResourceInput
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof HostResourceInput
     */
    longitude?: number;
    /**
     * 
     * @type {ResourceStateEnum}
     * @memberof HostResourceInput
     */
    currentState?: ResourceStateEnum;
    /**
     * 
     * @type {string}
     * @memberof HostResourceInput
     */
    topic?: string;
    /**
     * 
     * @type {Device}
     * @memberof HostResourceInput
     */
    device?: Device;
    /**
     * 
     * @type {number}
     * @memberof HostResourceInput
     */
    houseId?: number;
}

export function HostResourceInputFromJSON(json: any): HostResourceInput {
    return HostResourceInputFromJSONTyped(json, false);
}

export function HostResourceInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostResourceInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : ResourceTypeFromJSON(json['type']),
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'currentState': !exists(json, 'current_state') ? undefined : ResourceStateEnumFromJSON(json['current_state']),
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'device': !exists(json, 'device') ? undefined : DeviceFromJSON(json['device']),
        'houseId': !exists(json, 'house_id') ? undefined : json['house_id'],
    };
}

export function HostResourceInputToJSON(value?: HostResourceInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'serial': value.serial,
        'description': value.description,
        'type': ResourceTypeToJSON(value.type),
        'latitude': value.latitude,
        'longitude': value.longitude,
        'current_state': ResourceStateEnumToJSON(value.currentState),
        'topic': value.topic,
        'device': DeviceToJSON(value.device),
        'house_id': value.houseId,
    };
}

