/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorDetailScreenStyle.ts
 * @author     Christian Ascone
 * @date       2/24/20 5:38 PM
 */

import {Platform, StyleSheet} from "react-native";
import Colors from "../../../config/Colors";
import {Header} from "react-navigation";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.background,
        ...Platform.select({
            web: {
                maxHeight: Layout.window.height - Header.HEIGHT,
            },
        }),
    },
    scrollContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
    scrollOuter: {
        borderTopWidth: 0.5,
        borderTopColor: Colors.separatorColor,
        marginVertical: 20,
        padding: 10,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.15,
        shadowRadius: 3.84,

        elevation: 3,
        backgroundColor: "white" // invisible color
    },
    contentContainer: {
        justifyContent: 'space-between',
    },
    inputContainer: {
        flex: 1,
    },
    getStartedContainer: {
        height: '100%',
    },
});

export default styles;