/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccommodationResources,
    AccommodationResourcesFromJSON,
    AccommodationResourcesFromJSONTyped,
    AccommodationResourcesToJSON,
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Host,
    HostFromJSON,
    HostFromJSONTyped,
    HostToJSON,
    HouseContact,
    HouseContactFromJSON,
    HouseContactFromJSONTyped,
    HouseContactToJSON,
} from './';

/**
 * 
 * @export
 * @interface HouseComplete
 */
export interface HouseComplete {
    /**
     * 
     * @type {number}
     * @memberof HouseComplete
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HouseComplete
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HouseComplete
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof HouseComplete
     */
    publicResourcesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof HouseComplete
     */
    residentialResourcesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof HouseComplete
     */
    accommodationsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof HouseComplete
     */
    photoPath?: string;
    /**
     * 
     * @type {Address}
     * @memberof HouseComplete
     */
    address?: Address;
    /**
     * 
     * @type {Host}
     * @memberof HouseComplete
     */
    host?: Host;
    /**
     * 
     * @type {Array<HouseContact>}
     * @memberof HouseComplete
     */
    houseContacts?: Array<HouseContact>;
    /**
     * 
     * @type {Array<AccommodationResources>}
     * @memberof HouseComplete
     */
    accommodations?: Array<AccommodationResources>;
}

export function HouseCompleteFromJSON(json: any): HouseComplete {
    return HouseCompleteFromJSONTyped(json, false);
}

export function HouseCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): HouseComplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'publicResourcesCount': !exists(json, 'public_resources_count') ? undefined : json['public_resources_count'],
        'residentialResourcesCount': !exists(json, 'residential_resources_count') ? undefined : json['residential_resources_count'],
        'accommodationsCount': !exists(json, 'accommodations_count') ? undefined : json['accommodations_count'],
        'photoPath': !exists(json, 'photo_path') ? undefined : json['photo_path'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'host': !exists(json, 'host') ? undefined : HostFromJSON(json['host']),
        'houseContacts': !exists(json, 'houseContacts') ? undefined : ((json['houseContacts'] as Array<any>).map(HouseContactFromJSON)),
        'accommodations': !exists(json, 'accommodations') ? undefined : ((json['accommodations'] as Array<any>).map(AccommodationResourcesFromJSON)),
    };
}

export function HouseCompleteToJSON(value?: HouseComplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'public_resources_count': value.publicResourcesCount,
        'residential_resources_count': value.residentialResourcesCount,
        'accommodations_count': value.accommodationsCount,
        'photo_path': value.photoPath,
        'address': AddressToJSON(value.address),
        'host': HostToJSON(value.host),
        'houseContacts': value.houseContacts === undefined ? undefined : ((value.houseContacts as Array<any>).map(HouseContactToJSON)),
        'accommodations': value.accommodations === undefined ? undefined : ((value.accommodations as Array<any>).map(AccommodationResourcesToJSON)),
    };
}

