/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationEditAccommodationsScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import reservationDetailScreenStyle from "@styles/host/reservations/ReservationEditAccommodationsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps} from "@helpers/NavigationHelper";
import {
    getAccommodationsFromNavigationProps,
    getGenericDismissCallbackFromNavigationProps,
    getHouseFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {DebugButton} from "@components/DebugButton";
import {HouseCompleteFactory} from "../../../models/factory/HouseCompleteFactory";
import {HostHousesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {HostReservationDetail} from "@services/src/models/index";
import {RegistryFactory} from "../../../models/factory/RegistryFactory";
import {ReservationFactory} from "../../../models/factory/ReservationFactory";
import {UserFactory} from "../../../models/factory/UserFactory";
import {ReservationStateText} from "@components/ReservationStateText";
import {AvenirBookUpperInlineText} from "@components/StyledText";
import {
    HouseForAccommodationsSelection,
    HouseForAccommodationsSelectionBean
} from "../../../models/interfaces/HouseForAccommodationsSelectionBean";
import {HouseExpandableRow} from "@components/rows/HouseExpandableRow";
import {AccommodationSelection, AccommodationSelectionBean} from "../../../models/beans/AccommodationSelection";

interface State extends LoaderState {
    reservation: HostReservationDetail,
    house: HouseForAccommodationsSelectionBean,
    onDismiss: (beans: Array<AccommodationSelectionBean>) => void,
}

export default class ReservationEditAccommodationsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.reservation_detail.title');


        this.state = {
            loading: false,
            reservation: {},
            house: {
                accommodationsSelectionBeans: []
            },
            onDismiss: getGenericDismissCallbackFromNavigationProps(this),
        };

    }

    componentDidMount() {
        let self = this;
        let house = getHouseFromNavigationProps(this) || {};
        let selectedAccommodations = getAccommodationsFromNavigationProps(this) || [];
        new HostHousesApi(this).apiHostHousesIdCompleteGet({id: house.id + ''}).then(resp => {
            Logger.log(self.constructor.name, 'Ok');
            let house = resp;
            let houseWithSelectionBeans = HouseForAccommodationsSelection.fromHouse(house);
            houseWithSelectionBeans.accommodationsSelectionBeans = AccommodationSelection.fromAccommodationList(house.accommodations);
            houseWithSelectionBeans.accommodationsSelectionBeans.forEach(accommodation => {
                selectedAccommodations.forEach(selected => {
                    if (selected.id == accommodation.accommodation.accommodation.id) accommodation.selected = true;
                });
            });
            self.setState({house: houseWithSelectionBeans});
        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + error);
        });
    }

    /**
     * Update selected accommodation
     * @param {number} accommodationIndex
     */
    updateSelectedAccommodation(accommodationIndex: number) {
        let house = this.state.house;
        house.accommodationsSelectionBeans[accommodationIndex].selected = !house.accommodationsSelectionBeans[accommodationIndex].selected;
        this.setState({house: house});
    }

    componentWillUnmount() {
        this.state.onDismiss(this.state.house.accommodationsSelectionBeans);
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={reservationDetailScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View
                    style={[reservationDetailScreenStyle.container, globalScreenStyle.globalMargins]}
                >
                    <View style={{
                        flexDirection: 'row',
                        marginTop: 5,
                        alignItems: 'flex-end',
                        alignContent: 'flex-end',
                        justifyContent: 'flex-end'
                    }}>
                    </View>
                    <View
                        style={[reservationDetailScreenStyle.getStartedContainer, reservationDetailScreenStyle.container]}>
                        <View style={reservationDetailScreenStyle.installationContainer}>
                            <ScrollView
                                style={reservationDetailScreenStyle.container}
                                contentContainerStyle={reservationDetailScreenStyle.contentContainer}>
                                <View
                                    style={[reservationDetailScreenStyle.houseContainer]}>
                                    <HouseExpandableRow house_id={this.state.house.id}
                                                        house_name={this.state.house.name}
                                                        house={this.state.house}
                                                        onPress={null}
                                                        accommodationsSelectionBeans={this.state.house.accommodationsSelectionBeans}
                                                        onSelect={(accommodationIndex) => this.updateSelectedAccommodation(accommodationIndex)}
                                                        selected={true}/>
                                </View>

                            </ScrollView>
                        </View>
                    </View>
                    <View style={{marginVertical: 5, flexDirection: 'row',}}>
                        <AvenirBookUpperInlineText>
                            {i18n.t('screens.reservation_detail.reservation_state')}
                        </AvenirBookUpperInlineText>
                        <ReservationStateText state={this.state.reservation.currentState}/>
                    </View>
                </View>
            </KeyboardAvoidingView>
        );
    }

}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let reservation = context.state.reservation;
    let fakeReservation = ReservationFactory.factory();
    reservation.registry = RegistryFactory.factory();
    reservation.user = UserFactory.factory(true, true);
    reservation.startAt = fakeReservation.startAt;
    reservation.endAt = fakeReservation.endAt;
    reservation.currentState = fakeReservation.currentState;

    let house = HouseCompleteFactory.factory();
    let housesWithSelectionBeans = HouseForAccommodationsSelection.fromHouse(house);
    housesWithSelectionBeans.accommodationsSelectionBeans = AccommodationSelection.fromAccommodationList(house.accommodations);
    context.setState({reservation: reservation, house: housesWithSelectionBeans});
}