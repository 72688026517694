/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Accommodation,
    AccommodationFromJSON,
    AccommodationFromJSONTyped,
    AccommodationToJSON,
    House,
    HouseFromJSON,
    HouseFromJSONTyped,
    HouseToJSON,
    ReservationGuestBean,
    ReservationGuestBeanFromJSON,
    ReservationGuestBeanFromJSONTyped,
    ReservationGuestBeanToJSON,
    Resource,
    ResourceFromJSON,
    ResourceFromJSONTyped,
    ResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostHouseDelete
 */
export interface HostHouseDelete {
    /**
     * 
     * @type {Array<Accommodation>}
     * @memberof HostHouseDelete
     */
    accommodations?: Array<Accommodation>;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof HostHouseDelete
     */
    resources?: Array<Resource>;
    /**
     * 
     * @type {Array<ReservationGuestBean>}
     * @memberof HostHouseDelete
     */
    reservationGuestBeans?: Array<ReservationGuestBean>;
    /**
     * 
     * @type {House}
     * @memberof HostHouseDelete
     */
    house?: House;
}

export function HostHouseDeleteFromJSON(json: any): HostHouseDelete {
    return HostHouseDeleteFromJSONTyped(json, false);
}

export function HostHouseDeleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostHouseDelete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accommodations': !exists(json, 'accommodations') ? undefined : ((json['accommodations'] as Array<any>).map(AccommodationFromJSON)),
        'resources': !exists(json, 'resources') ? undefined : ((json['resources'] as Array<any>).map(ResourceFromJSON)),
        'reservationGuestBeans': !exists(json, 'reservationGuestBeans') ? undefined : ((json['reservationGuestBeans'] as Array<any>).map(ReservationGuestBeanFromJSON)),
        'house': !exists(json, 'house') ? undefined : HouseFromJSON(json['house']),
    };
}

export function HostHouseDeleteToJSON(value?: HostHouseDelete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accommodations': value.accommodations === undefined ? undefined : ((value.accommodations as Array<any>).map(AccommodationToJSON)),
        'resources': value.resources === undefined ? undefined : ((value.resources as Array<any>).map(ResourceToJSON)),
        'reservationGuestBeans': value.reservationGuestBeans === undefined ? undefined : ((value.reservationGuestBeans as Array<any>).map(ReservationGuestBeanToJSON)),
        'house': HouseToJSON(value.house),
    };
}

