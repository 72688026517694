/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorRowStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
        marginHorizontal: 2,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.15,
        shadowRadius: 3.84,

        elevation: 3,
        backgroundColor : "white" // invisible color
    },
    bigContainer: {
        flex: 1,
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    },
    bigNumber: {
        textAlign: 'center',
        alignSelf: 'center',
        fontSize: 30,
    },
    leftText: {
        flex: 2,
        alignSelf: 'flex-start',
    },
    bottomNumber: {
        textAlign: 'right',
        flex: 1,
        marginLeft: 5,
        marginRight: 5,
    },
    bottomText: {
        flex: 7,
        textAlign: 'center',
    },
    detailArrowContainer: {
        flex: 1,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        alignSelf: 'center',
    },
    detailArrow: {
        alignSelf: 'flex-end',
        width: 8,
        height: 8,
    },
    bottomContainer: {
        flex: 2,
    },
    flex: {
        flex: 1,
    },
    textContainer: {
        flex: 1,
        margin: 0,
        padding: 10,
        flexDirection: 'row',
        backgroundColor: 'white',
    },
    doorImage: {
        width: 20,
        height: 20,
        alignSelf: 'center',
        resizeMode: 'contain',
    },
    dot: {
        width: 5,
        height: 5,
        borderRadius: 20,
        marginRight: 5,
        overflow: 'hidden',
    },
});

export default styles;