/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreditType,
    CreditTypeFromJSON,
    CreditTypeFromJSONTyped,
    CreditTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Credit
 */
export interface Credit {
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Credit
     */
    amount?: number;
    /**
     * 
     * @type {CreditType}
     * @memberof Credit
     */
    type?: CreditType;
    /**
     * 
     * @type {string}
     * @memberof Credit
     */
    description?: string;
}

export function CreditFromJSON(json: any): Credit {
    return CreditFromJSONTyped(json, false);
}

export function CreditFromJSONTyped(json: any, ignoreDiscriminator: boolean): Credit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'type': !exists(json, 'type') ? undefined : CreditTypeFromJSON(json['type']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CreditToJSON(value?: Credit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'price': value.price,
        'amount': value.amount,
        'type': CreditTypeToJSON(value.type),
        'description': value.description,
    };
}

