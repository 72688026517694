/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CheckTextButton.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import checkTextButtonStyle from "@styles/CheckTextButtonStyle";
import {AvenirHeavyPrimaryText, AvenirLightText} from "./StyledText";
import {CheckImage} from "@components/Images/CheckImage";
import {CollaborationStateEnum, ReservationStateEnum} from "@services/src/models";
import Colors from "../config/Colors";

interface CheckTextButtonProps {
    selected: boolean,
    style?: StyleProp<ViewStyle>,
    onPress: (event: GestureResponderEvent) => void,
    title: string,
}

export class CheckTextButton extends Component<CheckTextButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <View style={[this.props.style,]}>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[checkTextButtonStyle.enabledButton, checkTextButtonStyle.backgroundEnabled]}>
                    <AvenirHeavyPrimaryText
                        style={checkTextButtonStyle.text}>{this.props.title}</AvenirHeavyPrimaryText>
                    <View style={checkTextButtonStyle.checkImageContainer}>
                        {this.props.selected &&
                        <CheckImage style={checkTextButtonStyle.checkImage}/>
                        }
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}

interface ReservationStateCheckTextButtonProps extends CheckTextButtonProps {
    state: ReservationStateEnum,
}
interface CollaborationStateCheckTextButtonProps extends CheckTextButtonProps {
    state: CollaborationStateEnum,
}

export class ReservationStateCheckTextButton extends Component<ReservationStateCheckTextButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        let textBlock = null;
        switch (this.props.state) {
            case ReservationStateEnum.Pending:
                textBlock = (<AvenirLightText
                    style={[checkTextButtonStyle.reservationStateText, {color: Colors.reservationPending},]}>{this.props.title}</AvenirLightText>);
                break;
            case ReservationStateEnum.Checkedin:
                textBlock = (<AvenirHeavyPrimaryText
                    style={[checkTextButtonStyle.reservationStateText,]}>{this.props.title}</AvenirHeavyPrimaryText>);
                break;
            case ReservationStateEnum.Confirmed:
                textBlock = (<AvenirLightText
                    style={[checkTextButtonStyle.reservationStateText, {color: Colors.reservationConfirmed},]}>{this.props.title}</AvenirLightText>);
                break;
            case ReservationStateEnum.Canceled:
                textBlock = (<AvenirLightText
                    style={[checkTextButtonStyle.reservationStateText, {color: Colors.reservationCanceled},]}>{this.props.title}</AvenirLightText>);
                break;
            case ReservationStateEnum.Checkedout:
                textBlock = (<AvenirLightText
                    style={[checkTextButtonStyle.reservationStateText, {color: Colors.reservationCheckedout},]}>{this.props.title}</AvenirLightText>);
                break;
        }

        return (
            <View style={[this.props.style,]}>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[checkTextButtonStyle.backgroundEnabled, {flexDirection: 'row',}]}>
                    {textBlock}
                    <View style={checkTextButtonStyle.checkImageContainer}>
                        {this.props.selected &&
                        <CheckImage style={checkTextButtonStyle.checkImage}/>
                        }
                    </View>
                </TouchableOpacity>
            </View>
        );
    }

}

export class CollaborationStateCheckTextButton extends Component<CollaborationStateCheckTextButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        let textBlock = null;
        switch (this.props.state) {
            case CollaborationStateEnum.Pending:
                textBlock = (<AvenirLightText
                    style={[checkTextButtonStyle.collaborationStateText, {color: Colors.collaborationPending},]}>{this.props.title}</AvenirLightText>);
                break;
            case CollaborationStateEnum.Ongoing:
                textBlock = (<AvenirHeavyPrimaryText
                    style={[checkTextButtonStyle.collaborationStateText,]}>{this.props.title}</AvenirHeavyPrimaryText>);
                break;
            case CollaborationStateEnum.Confirmed:
                textBlock = (<AvenirLightText
                    style={[checkTextButtonStyle.collaborationStateText, {color: Colors.collaborationConfirmed},]}>{this.props.title}</AvenirLightText>);
                break;
            case CollaborationStateEnum.Canceled:
                textBlock = (<AvenirLightText
                    style={[checkTextButtonStyle.collaborationStateText, {color: Colors.collaborationCanceled},]}>{this.props.title}</AvenirLightText>);
                break;
            case CollaborationStateEnum.Expired:
                textBlock = (<AvenirLightText
                    style={[checkTextButtonStyle.collaborationStateText, {color: Colors.collaborationCheckedout},]}>{this.props.title}</AvenirLightText>);
                break;
        }

        return (
            <View style={[this.props.style,]}>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[checkTextButtonStyle.backgroundEnabled, {flexDirection: 'row',}]}>
                    {textBlock}
                    <View style={checkTextButtonStyle.checkImageContainer}>
                        {this.props.selected &&
                        <CheckImage style={checkTextButtonStyle.checkImage}/>
                        }
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}