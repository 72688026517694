/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       EmailVerificationScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, TouchableOpacity, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import verificationStyle from "@styles/host/user/EmailVerificationScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openPhoneVerification} from "@helpers/NavigationHelper";
import {ConfirmButton} from "@components/ConfirmButton";
import {getUserFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {User, VerificationCodeType} from "@services/src/models/index";
import {AvenirBookPrimaryText, AvenirLightGreyText} from "@components/StyledText";
import {NumberInputBlock, TextInputBlock} from "@components/TextInputBlock";
import {UsersApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {AlertHelper} from "@helpers/AlertHelper";
import {MailVerificationImage} from "@components/Images/MailVerificationImage";

interface State extends LoaderState {
    code: string,
    user: User,
    latitude: number,
    longitude: number,
    markers: Array<any>,
}

export default class EmailVerificationScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.email_verification.title');
        this.state = {
            loading: false,
            user: getUserFromNavigationProps(this) || {},
            latitude: null,
            longitude: null,
            code: '',
            markers: [],
        };

    }

    componentDidMount() {
        resend(this);
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={verificationStyle.container}>
                <Loader state={this.state}/>
                <View
                    style={[verificationStyle.container, globalScreenStyle.globalMargins]}>
                    <View
                        style={[verificationStyle.getStartedContainer, verificationStyle.container]}>
                        <View style={verificationStyle.welcomeContainer}>
                            <MailVerificationImage style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                marginHorizontal: 70
                            }}/>
                        </View>
                        <View style={verificationStyle.subtitleContainer}>
                            <AvenirLightGreyText>
                                {i18n.t('screens.email_verification.description')}
                            </AvenirLightGreyText>
                        </View>
                        <View style={verificationStyle.installationContainer}>
                            <TextInputBlock label={i18n.t('screens.email_verification.mail_address')} placeholder=' '
                                            text={this.state.user.email} editable={false}/>
                        </View>
                        <View style={verificationStyle.installationContainer}>
                            <NumberInputBlock label={i18n.t('screens.email_verification.code')} placeholder=' '
                                              onChangeText={(text) => this.setState({code: text})}
                                              text={this.state.code}/>
                        </View>
                        <View style={verificationStyle.installationContainer}>
                            <ConfirmButton active={true} onPress={() => verify(this)}
                                           title={i18n.t('buttons.email_verification')}
                                           style={{marginBottom: 20}}/>
                        </View>
                        <View style={verificationStyle.resendContainer}>
                            <TouchableOpacity onPress={() => resend(this)}>
                                <AvenirBookPrimaryText
                                    style={verificationStyle.resendButton}>{i18n.t('screens.email_verification.resend')}</AvenirBookPrimaryText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Resend the verification code
 * @param {React.Component<any, State>} context
 */
function resend(context: Component<any, State>) {

    let parameter = {
        type: VerificationCodeType.Email,
        email: context.state.user.email,
    };
    new UsersApi(context).apiUsersSendVerificationTypeEmailPost(parameter).then(resp => {
        Logger.log(context.constructor.name, 'Ok');
    }).catch(error => {
        Logger.warn(context.constructor.name, 'Error');
        console.log(error);
        AlertHelper.showSimpleErrorAlert();
    });
}

/**
 * Verify email
 * @param context
 */
function verify(context: Component<any, State>) {
    let parameter = {
        type: VerificationCodeType.Email,
        code: context.state.code,
        user: context.state.user,
        email: context.state.user.email,
    };
    new UsersApi(context).apiUsersVerifyTypeCodeGet(parameter).then(resp => {
        Logger.log(context.constructor.name, 'Ok');
        openPhoneVerification(context, context.state.user);
    }).catch(error => {
        Logger.warn(context.constructor.name, 'Error');
        console.log(error);
        AlertHelper.showSimpleErrorAlert();
    });
}