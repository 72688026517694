/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    EventPriority,
    EventPriorityFromJSON,
    EventPriorityFromJSONTyped,
    EventPriorityToJSON,
    EventType,
    EventTypeFromJSON,
    EventTypeFromJSONTyped,
    EventTypeToJSON,
    Host,
    HostFromJSON,
    HostFromJSONTyped,
    HostToJSON,
    Resource,
    ResourceFromJSON,
    ResourceFromJSONTyped,
    ResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostEvent
 */
export interface HostEvent {
    /**
     * 
     * @type {number}
     * @memberof HostEvent
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HostEvent
     */
    shortDescriptionIt?: string;
    /**
     * 
     * @type {string}
     * @memberof HostEvent
     */
    shortDescriptionEn?: string;
    /**
     * 
     * @type {string}
     * @memberof HostEvent
     */
    longDescriptionIt?: string;
    /**
     * 
     * @type {string}
     * @memberof HostEvent
     */
    longDescriptionEn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HostEvent
     */
    read?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof HostEvent
     */
    datetime?: Date;
    /**
     * 
     * @type {EventType}
     * @memberof HostEvent
     */
    type?: EventType;
    /**
     * 
     * @type {EventPriority}
     * @memberof HostEvent
     */
    priority?: EventPriority;
    /**
     * 
     * @type {Resource}
     * @memberof HostEvent
     */
    resource?: Resource;
    /**
     * 
     * @type {Device}
     * @memberof HostEvent
     */
    device?: Device;
    /**
     * 
     * @type {Host}
     * @memberof HostEvent
     */
    host?: Host;
}

export function HostEventFromJSON(json: any): HostEvent {
    return HostEventFromJSONTyped(json, false);
}

export function HostEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'shortDescriptionIt': !exists(json, 'short_description_it') ? undefined : json['short_description_it'],
        'shortDescriptionEn': !exists(json, 'short_description_en') ? undefined : json['short_description_en'],
        'longDescriptionIt': !exists(json, 'long_description_it') ? undefined : json['long_description_it'],
        'longDescriptionEn': !exists(json, 'long_description_en') ? undefined : json['long_description_en'],
        'read': !exists(json, 'read') ? undefined : json['read'],
        'datetime': !exists(json, 'datetime') ? undefined : (new Date(json['datetime'])),
        'type': !exists(json, 'type') ? undefined : EventTypeFromJSON(json['type']),
        'priority': !exists(json, 'priority') ? undefined : EventPriorityFromJSON(json['priority']),
        'resource': !exists(json, 'resource') ? undefined : ResourceFromJSON(json['resource']),
        'device': !exists(json, 'device') ? undefined : DeviceFromJSON(json['device']),
        'host': !exists(json, 'host') ? undefined : HostFromJSON(json['host']),
    };
}

export function HostEventToJSON(value?: HostEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'short_description_it': value.shortDescriptionIt,
        'short_description_en': value.shortDescriptionEn,
        'long_description_it': value.longDescriptionIt,
        'long_description_en': value.longDescriptionEn,
        'read': value.read,
        'datetime': value.datetime === undefined ? undefined : (value.datetime.toISOString()),
        'type': EventTypeToJSON(value.type),
        'priority': EventPriorityToJSON(value.priority),
        'resource': ResourceToJSON(value.resource),
        'device': DeviceToJSON(value.device),
        'host': HostToJSON(value.host),
    };
}

