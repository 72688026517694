/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GuestType {
    Client = 'CLIENT',
    Collaborator = 'COLLABORATOR'
}

export function GuestTypeFromJSON(json: any): GuestType {
    return GuestTypeFromJSONTyped(json, false);
}

export function GuestTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuestType {
    return json as GuestType;
}

export function GuestTypeToJSON(value?: GuestType | null): any {
    return value as any;
}

