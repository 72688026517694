/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BoughtCredit
 */
export interface BoughtCredit {
    /**
     * 
     * @type {number}
     * @memberof BoughtCredit
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof BoughtCredit
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof BoughtCredit
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof BoughtCredit
     */
    deviceId?: number;
    /**
     * 
     * @type {number}
     * @memberof BoughtCredit
     */
    creditId?: number;
    /**
     * 
     * @type {number}
     * @memberof BoughtCredit
     */
    hostId?: number;
}

export function BoughtCreditFromJSON(json: any): BoughtCredit {
    return BoughtCreditFromJSONTyped(json, false);
}

export function BoughtCreditFromJSONTyped(json: any, ignoreDiscriminator: boolean): BoughtCredit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'creditId': !exists(json, 'credit_id') ? undefined : json['credit_id'],
        'hostId': !exists(json, 'host_id') ? undefined : json['host_id'],
    };
}

export function BoughtCreditToJSON(value?: BoughtCredit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'price': value.price,
        'amount': value.amount,
        'device_id': value.deviceId,
        'credit_id': value.creditId,
        'host_id': value.hostId,
    };
}

