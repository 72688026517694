/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Accommodation
 */
export interface Accommodation {
    /**
     * 
     * @type {number}
     * @memberof Accommodation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Accommodation
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Accommodation
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Accommodation
     */
    availability?: number;
    /**
     * 
     * @type {number}
     * @memberof Accommodation
     */
    publicResourcesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Accommodation
     */
    residentialResourcesCount?: number;
    /**
     * Id of house
     * @type {number}
     * @memberof Accommodation
     */
    houseId?: number;
    /**
     * 
     * @type {number}
     * @memberof Accommodation
     */
    hostId?: number;
}

export function AccommodationFromJSON(json: any): Accommodation {
    return AccommodationFromJSONTyped(json, false);
}

export function AccommodationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Accommodation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'availability': !exists(json, 'availability') ? undefined : json['availability'],
        'publicResourcesCount': !exists(json, 'public_resources_count') ? undefined : json['public_resources_count'],
        'residentialResourcesCount': !exists(json, 'residential_resources_count') ? undefined : json['residential_resources_count'],
        'houseId': !exists(json, 'house_id') ? undefined : json['house_id'],
        'hostId': !exists(json, 'host_id') ? undefined : json['host_id'],
    };
}

export function AccommodationToJSON(value?: Accommodation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'availability': value.availability,
        'public_resources_count': value.publicResourcesCount,
        'residential_resources_count': value.residentialResourcesCount,
        'house_id': value.houseId,
        'host_id': value.hostId,
    };
}

