/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorPlanScreen.tsx
 * @author     Christian Ascone
 * @date       3/27/20 2:41 PM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import doorPlanScreenStyle from "@styles/host/doors/DoorPlanScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {goBack, NavigationProps} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {getDoorFromNavigationProps, getHouseFromNavigationProps, setHeaderTitle} from "@helpers/NavigationDataHelper";
import {HostDevicesApi, HostResourcePlanJoinsApi, HostResourcesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {HostDevice, House, Resource, ResourcePlanJoin} from "@services/src/models/index";
import {HouseFactory} from "../../../models/factory/HouseFactory";
import {DeviceFactory} from "../../../models/factory/DeviceFactory";
import {DebugButton} from "@components/DebugButton";
import {Plan, UserProfileTransformerDto} from "@services/src/models";
import {PlansApi} from "@services/src/apis";
import {PlanSelectionBlock} from "@components/PlanSelectionBlock";
import {AlertHelper} from "@helpers/AlertHelper";
import {RoundedButton} from "@components/RoundedButton";
import {AvenirHeavyLabelText, AvenirHeavyPrimaryText} from "@components/StyledText";
import {LogoBFreeCreditImage} from "@components/Images/LogoBFreeCreditImage";
import {ProfileManager} from "../../../modules/ProfileManager";

interface State extends LoaderState {
    userProfile: UserProfileTransformerDto,
    door: Resource,
    house: House,
    device: HostDevice,
    resourcePlanJoin: ResourcePlanJoin,
    selectedPlanId: number,
    planAutorenew: boolean,
    planExpireDate: Date,
    plans: Array<Plan>,
}

export default class DoorPlanScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        let door = getDoorFromNavigationProps(this) || {};
        this.state = {
            userProfile: {host: {}},
            door: door,
            house: getHouseFromNavigationProps(this) || {},
            device: {},
            resourcePlanJoin: {},
            loading: false,
            selectedPlanId: null,
            planAutorenew: false,
            planExpireDate: null,
            plans: [],
        };

    }

    componentDidMount() {
        let door = getDoorFromNavigationProps(this) || {};
        let house = getHouseFromNavigationProps(this) || {};
        console.log(house);
        setHeaderTitle(this.props, house.name);
        let self = this;
        const doorId = door.id + '';
        new HostResourcesApi(this).apiHostResourcesIdGet({id: doorId}).then(resp => {
            Logger.log(self.constructor.name, 'Ok');
            let data = resp.data;
            let device = data.devices != null && data.devices.length > 0 ? data.devices[0] : {};
            self.setState({
                door: data,
            });
            this.downloadDevice(device);
        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + error);
        });

        ProfileManager.getProfile(this).then(resp => {
            Logger.log(self.constructor.name, `Found profile data.`);
            let userProfile: UserProfileTransformerDto = resp.data;
            userProfile.host.company = userProfile.host.company || {};
            self.setState({
                userProfile: userProfile,
            });
        });

        this.downloadPlans(self, doorId);
    }

    /**
     * Download the device detail (with bound credit balance)
     * @param device
     */
    private downloadDevice(device) {
        let self = this;
        new HostDevicesApi(this).apiHostDevicesIdGet({id: device.id + ''}).then(resp => {
            Logger.log(self.constructor.name, 'Ok');
            let data = resp.data;
            self.setState({
                device: data,
            });
        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + error);
        });
    }

    private getCurrentPlanForResource(doorId, self) {
        const resourcePlanJoinFilter = {id: doorId, resourcePlanJoinFilterInput: {current: true}};
        new HostResourcePlanJoinsApi(self).apiHostResourcePlanJoinsByResourceIdPost(resourcePlanJoinFilter).then(resp => {
            Logger.log(self.constructor.name, 'Ok');
            if (resp != null && resp.length > 0) {
                let hostResourcePlanJoin: ResourcePlanJoin = resp[0];
                self.setState({
                    resourcePlanJoin: hostResourcePlanJoin,
                    selectedPlanId: hostResourcePlanJoin.planId,
                    planExpireDate: hostResourcePlanJoin.endDate,
                    planAutorenew: hostResourcePlanJoin.autorenew,
                });
            }
        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + error);
        });
    }

    /**
     * Download all plans in order to fill select
     * @param self
     * @param doorId
     */
    private downloadPlans(self, doorId) {
        new PlansApi(self).apiPlansGet().then(resp => {
            let plans = resp;
            Logger.log(self.constructor.name, `Found ${plans.length} plans for host.`);
            self.setState({
                plans: plans,
            });
            self.getCurrentPlanForResource(doorId, self);
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }

    /**
     * Build the view for credits recap
     * @param text
     * @param creditsBalance
     * @returns {any}
     */
    private static creditsView(text, creditsBalance = 0) {
        return <View style={[doorPlanScreenStyle.row]}>
            <AvenirHeavyLabelText
                style={doorPlanScreenStyle.flex}>{text}: </AvenirHeavyLabelText>
            <View style={[doorPlanScreenStyle.row, doorPlanScreenStyle.creditsBlock]}>
                <AvenirHeavyPrimaryText>{creditsBalance} </AvenirHeavyPrimaryText>
                <LogoBFreeCreditImage style={doorPlanScreenStyle.creditImage}/>
            </View>
            <View style={doorPlanScreenStyle.flexMinor}/>
        </View>;
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={doorPlanScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <ScrollView
                    style={[doorPlanScreenStyle.scrollContainer, globalScreenStyle.globalMargins]}
                    contentContainerStyle={doorPlanScreenStyle.contentContainer}>
                    <View
                        style={[doorPlanScreenStyle.getStartedContainer, doorPlanScreenStyle.scrollContainer]}>
                        {DoorPlanScreen.creditsView(i18n.t('screens.door_plan.bound_credits'), this.state.device.boundCreditBalance)}
                        {DoorPlanScreen.creditsView(i18n.t('screens.door_plan.free_credits'), this.state.userProfile.host.creditBalance)}
                        <PlanSelectionBlock
                            plans={this.state.plans}
                            selectedPlanId={this.state.selectedPlanId}
                            onChange={(newId) => this.setState({selectedPlanId: newId})}
                            autorenew={this.state.planAutorenew}
                            onAutorenewClick={() => this.setState({planAutorenew: !this.state.planAutorenew})}
                            expireDate={this.state.planExpireDate}/>

                    </View>
                    {this.state.resourcePlanJoin.id != null &&
                    <RoundedButton onPress={() => stopPlan(this)} title={i18n.t('screens.door_plan.stop_plan')}
                                   isQr={false}
                                   style={doorPlanScreenStyle.stopPlanButton}/>
                    }
                </ScrollView>
                <ConfirmButtonBottom active={dataChanged(this)}
                                     onPress={() => updatePlan(this)}
                                     title={i18n.t('screens.door_plan.save')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    }
}

function debugUpdateData(context: Component<any, State>) {
    let fakeDevice: HostDevice = DeviceFactory.factory();
    fakeDevice.boundCreditBalance = 10;
    let userProfile: UserProfileTransformerDto = {
        host: {
            creditBalance: 30,
        }
    };
    context.setState({
        userProfile: userProfile,
        house: HouseFactory.factory(),
        device: fakeDevice,
    });
    let resourcePlanJoin: ResourcePlanJoin = {};
    context.setState({
        resourcePlanJoin: resourcePlanJoin
    })
}

/**
 * Checks if the plan has changed
 * @param state
 * @returns {boolean}
 */
function planChanged(state: State) {
    return state.resourcePlanJoin.planId != state.selectedPlanId;
}

/**
 * Checks if the autorenew option has changed
 * @param state
 * @returns {boolean}
 */
function autorenewChanged(state: State) {
    return state.resourcePlanJoin.autorenew != state.planAutorenew;
}

function dataChanged(context: Component<any, State>) {
    const state = context.state;
    let planIdChanged = planChanged(state);
    let autorenewOptionChanged = autorenewChanged(state);

    return planIdChanged || autorenewOptionChanged;
}

/**
 * Updates the plan for this Resource
 * @param resourceId
 * @param {React.Component<any, State>} context
 */
function updatePlanForResource(resourceId, context: React.Component<any, State>) {
    const params = {
        resourcePlanJoin: {
            resourceId: resourceId,
            startDate: new Date(),
            planId: context.state.selectedPlanId,
            autorenew: context.state.planAutorenew,
        }
    };
    new HostResourcePlanJoinsApi(context).apiHostResourcePlanJoinsPost(params).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        goBack(context);
    }).catch(async error => {
        Logger.warn(context.constructor.name, error);
        var body = await error.json();
        console.log(body);
        AlertHelper.showErrorAlertWithServerMessage(body.error);
    })
}

/**
 * Stop plan for this resource
 * @param {React.Component<any, State>} context
 */
function stopPlan(context: Component<any, State>) {
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed) {
            new HostResourcePlanJoinsApi(context).apiHostResourcePlanJoinsSuspendByResourceIdPost({id: context.state.door.id + ''}).then(resp => {
                Logger.log(context.constructor.name, "Ok");
                goBack(context);
            }).catch(async error => {
                Logger.warn(context.constructor.name, error);
                var body = await error.json();
                console.log(body);
                AlertHelper.showErrorAlertWithServerMessage(body.error);
            })
        }
    });
}

/**
 * Based on what changed it updates the autorenew option or the plan
 * for this Resource
 * @param {React.Component<any, State>} context
 */
function updatePlan(context: Component<any, State>) {
    if (!dataChanged(context)) return;

    const resourceId = context.state.door.id;
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed)
            updatePlanForResource(resourceId, context);
    });
}