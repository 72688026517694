/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SentryConfig.web.ts
 * @author     Christian Ascone
 * @date       10/23/19 4:15 PM
 */

import {environment} from "../environments/environment";

const sentryDsn = environment.sentryDsn;
const sentryEnableDevelopment = environment.sentryEnableDevelopment;

export const config = {
    sentryDsn: sentryDsn,
    sentryEnableDevelopment: sentryEnableDevelopment,
};

export function sentrySetup() {
    // Dummy
}

export function testCrash() {
    // Dummy
}