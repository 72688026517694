/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       StripeDebugScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */

import React, {Component} from 'react';
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps} from "@helpers/NavigationHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import StripeCheckoutSca from "expo-stripe-checkout-sca";
import {View} from "react-native";
import {DebugButton} from "@components/DebugButton";
import {environment} from "../../environments/environment";
import {HttpsApiStripeComV1CheckoutSessionsPostRequest, StripeApi} from "@helpers/stripe/apis/StripeApi";

interface State extends LoaderState {
    publicKey,
    sessionId,
    onPaymentSuccess,
    onClose,
    modalVisible,
}

export default class StripeDebugScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            publicKey: environment.stripePublicKey,
            sessionId: null,
            onClose: null,
            onPaymentSuccess: null,
            modalVisible: false,
        };
    }

    componentDidMount() {
        let self = this;
        let input: HttpsApiStripeComV1CheckoutSessionsPostRequest = {
            paymentMethodTypes: 'card',
            lineItemsName: 'Custom t-shirt',
            lineItemsDescription: 'Your custom designed t-shirt',
            lineItemsAmount: '100',
            lineItemsCurrency: 'usd',
            lineItemsQuantity: '1',
            successUrl: 'https://example.com/success',
            cancelUrl: 'https://example.com/cancel',
        };
        new StripeApi(self).httpsApiStripeComV1CheckoutSessionsPost(input).then(resp => {
            Logger.warn(self.constructor.name, "OK");
            Logger.warn(self.constructor.name, resp);
            self.setState({sessionId: resp.id});
        }).catch(error => {
            Logger.warn(self.constructor.name, "KO");
            Logger.warn(self.constructor.name, error);
        });
    }

    /**
     * Logs when webview url changes
     * @param e
     */
    log(e) {
        Logger.warn(this.constructor.name, e.url);
        let url: string = e.url;
        if (url.indexOf('success') >= 0) {
            Logger.warn(this.constructor.name, 'SUCCESS');
            this.setState({modalVisible: false});
            AlertHelper.showSimpleAlert('Stripe', 'OK');
        } else if (url.indexOf('cancel') >= 0) {
            Logger.warn(this.constructor.name, 'CANCEL');
            this.setState({modalVisible: false});
            AlertHelper.showSimpleAlert('Stripe', 'KO');
        }
    }

    render() {
        return (
            <View style={{flex: 1}}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                {this.state.sessionId != null &&
                <StripeCheckoutSca modalVisible={this.state.modalVisible}
                                   onClose={() => this.setState({modalVisible: false})}
                                   onNavigationStateChange={(e) => this.log(e)}
                                   publicKey={this.state.publicKey} sessionId={this.state.sessionId}/>
                }
            </View>
        );
    }
}

function debugUpdateData(context: Component<NavigationProps, State>) {
    context.setState({modalVisible: true});
}