/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NoDataLabel.tsx
 * @author     Christian Ascone
 * @date       10/31/19 1:04 PM
 */

import React from 'react';
import {View} from 'react-native';
import {AvenirHeavyText} from "./StyledText";
import {Header} from "react-navigation";
import Layout from "../config/Layout";
import {i18n} from "@i18n/i18n";

interface NoDataLabelProps {
    list: any[],
    show: boolean,
}

export function NoDataLabel(props: NoDataLabelProps) {
    if (!props.show || (props.list != null && props.list.length > 0))
        return null;
    return (
        <View style={{
            alignSelf: 'center',
            alignItems: 'center',
            alignContent: 'center',
            height: Layout.window.height - Header.HEIGHT,
            width: Layout.window.width,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            flexDirection: 'column',
            flex: 1,
        }}>

            <AvenirHeavyText style={{flex: 1, alignSelf: 'center'}}>{i18n.t('common.no_data')}</AvenirHeavyText>

        </View>
    );
}