/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    House,
    HouseFromJSON,
    HouseFromJSONTyped,
    HouseToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostHouseList
 */
export interface HostHouseList {
    /**
     * 
     * @type {Array<House>}
     * @memberof HostHouseList
     */
    data?: Array<House>;
}

export function HostHouseListFromJSON(json: any): HostHouseList {
    return HostHouseListFromJSONTyped(json, false);
}

export function HostHouseListFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostHouseList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(HouseFromJSON)),
    };
}

export function HostHouseListToJSON(value?: HostHouseList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(HouseToJSON)),
    };
}

