/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Accommodation,
    AccommodationFromJSON,
    AccommodationToJSON,
    AccommodationCreateInput,
    AccommodationCreateInputFromJSON,
    AccommodationCreateInputToJSON,
    HostAccommodationDetail,
    HostAccommodationDetailFromJSON,
    HostAccommodationDetailToJSON,
    ReservationGuestBean,
    ReservationGuestBeanFromJSON,
    ReservationGuestBeanToJSON,
} from '../models';

export interface ApiHostAccommodationsByHouseIdGetRequest {
    id: number;
}

export interface ApiHostAccommodationsIdCandeleteGetRequest {
    id: number;
}

export interface ApiHostAccommodationsIdDeleteRequest {
    id: number;
}

export interface ApiHostAccommodationsIdGetRequest {
    id: string;
}

export interface ApiHostAccommodationsIdPutRequest {
    id: number;
    accommodationCreateInput: AccommodationCreateInput;
}

export interface ApiHostAccommodationsPostRequest {
    accommodationCreateInput: AccommodationCreateInput;
}

/**
 * 
 */
export class HostAccommodationsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostAccommodationsByHouseIdGetRaw(requestParameters: ApiHostAccommodationsByHouseIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Accommodation>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostAccommodationsByHouseIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/accommodations/byHouse/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccommodationFromJSON));
    }

    /**
     */
    async apiHostAccommodationsByHouseIdGet(requestParameters: ApiHostAccommodationsByHouseIdGetRequest, initOverrides?: RequestInit): Promise<Array<Accommodation>> {
        const response = await this.apiHostAccommodationsByHouseIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostAccommodationsIdCandeleteGetRaw(requestParameters: ApiHostAccommodationsIdCandeleteGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ReservationGuestBean>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostAccommodationsIdCandeleteGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/accommodations/{id}/candelete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReservationGuestBeanFromJSON));
    }

    /**
     */
    async apiHostAccommodationsIdCandeleteGet(requestParameters: ApiHostAccommodationsIdCandeleteGetRequest, initOverrides?: RequestInit): Promise<Array<ReservationGuestBean>> {
        const response = await this.apiHostAccommodationsIdCandeleteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostAccommodationsIdDeleteRaw(requestParameters: ApiHostAccommodationsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostAccommodationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/accommodations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostAccommodationsIdDelete(requestParameters: ApiHostAccommodationsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostAccommodationsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostAccommodationsIdGetRaw(requestParameters: ApiHostAccommodationsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HostAccommodationDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostAccommodationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/accommodations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostAccommodationDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHostAccommodationsIdGet(requestParameters: ApiHostAccommodationsIdGetRequest, initOverrides?: RequestInit): Promise<HostAccommodationDetail> {
        const response = await this.apiHostAccommodationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostAccommodationsIdPutRaw(requestParameters: ApiHostAccommodationsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostAccommodationsIdPut.');
        }

        if (requestParameters.accommodationCreateInput === null || requestParameters.accommodationCreateInput === undefined) {
            throw new runtime.RequiredError('accommodationCreateInput','Required parameter requestParameters.accommodationCreateInput was null or undefined when calling apiHostAccommodationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/accommodations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccommodationCreateInputToJSON(requestParameters.accommodationCreateInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostAccommodationsIdPut(requestParameters: ApiHostAccommodationsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostAccommodationsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostAccommodationsPostRaw(requestParameters: ApiHostAccommodationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Accommodation>> {
        if (requestParameters.accommodationCreateInput === null || requestParameters.accommodationCreateInput === undefined) {
            throw new runtime.RequiredError('accommodationCreateInput','Required parameter requestParameters.accommodationCreateInput was null or undefined when calling apiHostAccommodationsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/accommodations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccommodationCreateInputToJSON(requestParameters.accommodationCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccommodationFromJSON(jsonValue));
    }

    /**
     */
    async apiHostAccommodationsPost(requestParameters: ApiHostAccommodationsPostRequest, initOverrides?: RequestInit): Promise<Accommodation> {
        const response = await this.apiHostAccommodationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
