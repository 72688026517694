/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseExpandableRow.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React from 'react';
import {GestureResponderEvent, TouchableOpacity, View} from 'react-native';
import {i18n} from "@i18n/i18n";
import {AvenirLightPrimaryText, AvenirMediumPrimaryText} from "../StyledText";
import rowStyles from "@styles/host/rows/HouseExpandableRowStyle";
import {AvatarPlaceholderImage} from "@components/Images/AvatarPlaceholderImage";
import {House} from "@services/src/models";
import {AccommodationSelectionBean} from "../../models/beans/AccommodationSelection";
import {AccommodationSelectionRow} from "./AccommodationSelectionRow";

export interface HouseExpandableRowProps {
    house_id: number,
    house_name: string,
    house: House,
    accommodationsSelectionBeans: Array<AccommodationSelectionBean>,
    onPress: (event: GestureResponderEvent) => void,
    onSelect: (key: number) => void,
    selected: boolean,
}

export function HouseExpandableRow(props: HouseExpandableRowProps) {
    let prefix = 'rows.accommodations_selection.';
    return (
        <View style={[rowStyles.flex, rowStyles.container]}>
            <TouchableOpacity onPress={props.onPress}>
                <View
                    style={[rowStyles.bigContainer, rowStyles.textContainer, props.selected ? null : rowStyles.bottomBorder, {flexDirection: 'row',}]}>
                    <View style={{flex: 1, marginHorizontal: 10,}}>
                        <AvatarPlaceholderImage style={[rowStyles.houseImage]}
                                                imageUri={props.house.photoPath}/>
                    </View>
                    <View style={{
                        marginLeft: 5,
                        flex: 7,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        alignContent: 'flex-start',
                    }}>
                        <AvenirMediumPrimaryText
                            style={{}}
                            numberOfLines={1}
                        >{i18n.t(prefix + 'caption')}</AvenirMediumPrimaryText>
                        <AvenirLightPrimaryText style={[rowStyles.flex, rowStyles.leftText]}>
                            {props.house_name}
                        </AvenirLightPrimaryText>
                    </View>
                </View>
            </TouchableOpacity>
            {props.selected && props.accommodationsSelectionBeans.map((bean, key) => (
                <View
                    style={[rowStyles.accommodationContainer, key == props.accommodationsSelectionBeans.length - 1 ? rowStyles.bottomBorder : null]}
                    key={key}>
                    <View
                        style={rowStyles.listSeparator}
                    />
                    <AccommodationSelectionRow accommodation={bean.accommodation.accommodation}
                                               selected={bean.selected}
                                               active={bean.accommodation.resources != null}
                                               onPress={() => props.onSelect(key)}/>
                </View>
            ))}

        </View>
    );
}