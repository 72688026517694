/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DashboardCreditsScreen.tsx
 * @author     Christian Ascone
 * @date       3/25/20 5:54 PM
 */

import React, {Component} from 'react';
import {RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../i18n/i18n";
import dashboardCreditsScreenStyle from "@styles/host/DashboardCreditsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {
    NavigationProps,
    openDashboardHouses,
    openDashboardReservations,
    openGetCredits
} from "@helpers/NavigationHelper";
import {DashboardSegment} from "@components/segments/DashboardSegment";
import {CREDITS_DASHBOARD_SEGMENT_TAB} from "@helpers/SegmentHelper";
import {DashboardCreditsInfoBlock} from "@components/DashboardInfoBlock";
import {AddButton} from "@components/AddButton";
import {
    getUserFromNavigationProps,
    setHeaderProfileImage,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {UserProfileTransformerDto} from "@services/src/models/index";
import {ProfileManager} from "../../modules/ProfileManager";

interface State extends LoaderState {
    userProfile: UserProfileTransformerDto,
}

export default class DashboardCreditsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.dashboard.title');

        this.state = {
            userProfile: {
                host: {}
            },
            loading: false,
        };
    }

    componentDidMount() {
        let self = this;
        ProfileManager.getProfile(this).then(resp => {
            console.log(resp);
            setHeaderProfileImage(self.props, resp.data.host.photoPath);
            this.setState({userProfile: resp.data})
        });
    }

    onRefresh() {
        let self = this;
    }

    render() {
        return (
            <View style={[dashboardCreditsScreenStyle.container]}>
                <Loader state={this.state}/>
                <View style={[dashboardCreditsScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <DashboardSegment activeTab={CREDITS_DASHBOARD_SEGMENT_TAB}
                                      onPressThird={(event) => console.log('Credits')}
                                      onPressSecond={(event) => openDashboardHouses(this, getUserFromNavigationProps(this))}
                                      onPressFirst={(event) => openDashboardReservations(this, getUserFromNavigationProps(this))}/>
                    <View style={globalScreenStyle.scrollContainerWithBottomButton}>
                        <ScrollView
                            style={dashboardCreditsScreenStyle.container}
                            contentContainerStyle={dashboardCreditsScreenStyle.contentContainer}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()}/>
                            }>
                            <View style={dashboardCreditsScreenStyle.welcomeContainer}>
                                <DashboardCreditsInfoBlock credits={this.state.userProfile.host.creditBalance} active_devices={0}
                                                           current_monthly_consumption={0}
                                                           remaining_months={0}
                                                           context={this}/>

                            </View>

                            <View
                                style={[dashboardCreditsScreenStyle.getStartedContainer, globalScreenStyle.globalMargins]}>

                            </View>

                        </ScrollView>
                    </View>
                    <View style={[{position: 'absolute', bottom: 20}]}>
                        <AddButton title={i18n.t('screens.dashboard.blocks.credits.add_button')}
                                   onPress={() => openGetCredits(this)}/>
                    </View>
                </View>
            </View>
        );
    }
}