/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AddDoorBlock.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import React from 'react';
import {ScrollView, StyleProp, View, ViewStyle} from 'react-native';
import Colors from "../config/Colors";
import {i18n} from "@i18n/i18n";
import {DoorSelectionRow} from "./rows/DoorSelectionRow";
import {AvenirHeavyPrimaryText} from "./StyledText";
import {ResidentialColorAddImage} from "@components/Images/services/ResidentialColorAddImage";
import {ResourceSelectionBean} from "../models/beans/ResourceSelection";
import {ResourceStateEnum, ResourceType} from "@services/src/models";
import {PublicColorAddImage} from "@components/Images/services/PublicColorAddImage";
import addDoorBlockStyle from "@styles/AddDoorBlockStyle";
import {NoDataLabel} from "./NoDataLabel";

interface AddDoorProps {
    style: StyleProp<ViewStyle>,
    type: ResourceType,
    doorsSelection: Array<ResourceSelectionBean>,
    onPress: (number) => void,
    showNoData?: boolean,
}

export function AddDoorBlock(props: AddDoorProps) {
    return (
        <View style={props.style}>
            {props.type == ResourceType.Public &&
            <View style={addDoorBlockStyle.titleContainer}>
                <PublicColorAddImage
                    style={addDoorBlockStyle.image}/>
                <AvenirHeavyPrimaryText>{i18n.t('rows.door.add_public_door')}</AvenirHeavyPrimaryText>
            </View>
            }
            {props.type == ResourceType.Residential &&
            <View style={addDoorBlockStyle.titleContainer}>
                <ResidentialColorAddImage
                    style={addDoorBlockStyle.image}/>
                <AvenirHeavyPrimaryText>{i18n.t('rows.door.add_residential_door')}</AvenirHeavyPrimaryText>
            </View>
            }
            <ScrollView style={{backgroundColor: Colors.background}}>
                {props.doorsSelection.map((doorSelection, key) => (
                    <DoorSelectionRow key={key} door={doorSelection.resource} online={doorSelection.resource.online}
                                      activationStatus={ResourceStateEnum.Active} selected={doorSelection.selected}
                                      onPress={() => props.onPress(key)}/>

                ))}
                <NoDataLabel list={props.doorsSelection} show={props.showNoData}/>
            </ScrollView>
        </View>
    );
}