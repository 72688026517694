/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       UnlockResourceEnum.ts
 * @author     Christian Ascone
 * @date       3/18/20 10:21 AM
 */

export enum UnlockResourceEnum {
    MAIN_RESOURCE = 1,
    AUX_RESOURCE = 2,
}