/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationsScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {Platform, StyleSheet} from "react-native";
import Colors from "../../../config/Colors";
import {Header} from "react-navigation";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        top: 0,
        bottom: 0,
        height: Layout.window.height - Header.HEIGHT,
        ...Platform.select({
            web:{
                maxHeight: Layout.window.height - Header.HEIGHT,
                minHeight: Layout.window.height - Header.HEIGHT,
            }
        }),

    },
    developmentModeText: {
        marginBottom: 20,
        color: 'rgba(0,0,0,0.4)',
        fontSize: 14,
        lineHeight: 19,
        textAlign: 'center',
    },
    doorContainer: {
        alignItems: 'center',
        marginBottom: 10,
    },
    contentContainer: {
        paddingTop: 30,
        marginBottom: 10,
        overflow: 'scroll',
        ...Platform.select({
            web: {
                height: '80%',
            },
        }),
    },
    welcomeContainer: {
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 20,
    },
    welcomeImage: {
        width: 100,
        height: 80,
        resizeMode: 'contain',
        marginTop: 3,
        marginLeft: -10,
    },
    getStartedContainer: {
        alignItems: 'center',
    },
    getStartedText: {
        fontSize: 17,
        color: Colors.textColor,
        lineHeight: 24,
        textAlign: 'center',
    },
});

export default styles;