/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostType,
    HostTypeFromJSON,
    HostTypeFromJSONTyped,
    HostTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Host
 */
export interface Host {
    /**
     * 
     * @type {number}
     * @memberof Host
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    fiscalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    photoPath?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    devicesCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Host
     */
    devicesCodeToSave?: string;
    /**
     * 
     * @type {number}
     * @memberof Host
     */
    cardId?: number;
    /**
     * 
     * @type {HostType}
     * @memberof Host
     */
    type?: HostType;
    /**
     * 
     * @type {number}
     * @memberof Host
     */
    usageThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof Host
     */
    creditBalance?: number;
    /**
     * 
     * @type {number}
     * @memberof Host
     */
    boundCreditBalance?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Host
     */
    hasCompanyData?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Host
     */
    addressId?: number;
    /**
     * 
     * @type {number}
     * @memberof Host
     */
    userId?: number;
}

export function HostFromJSON(json: any): Host {
    return HostFromJSONTyped(json, false);
}

export function HostFromJSONTyped(json: any, ignoreDiscriminator: boolean): Host {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fiscalCode': !exists(json, 'fiscal_code') ? undefined : json['fiscal_code'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'photoPath': !exists(json, 'photo_path') ? undefined : json['photo_path'],
        'devicesCode': !exists(json, 'devices_code') ? undefined : json['devices_code'],
        'devicesCodeToSave': !exists(json, 'devices_code_to_save') ? undefined : json['devices_code_to_save'],
        'cardId': !exists(json, 'card_id') ? undefined : json['card_id'],
        'type': !exists(json, 'type') ? undefined : HostTypeFromJSON(json['type']),
        'usageThreshold': !exists(json, 'usage_threshold') ? undefined : json['usage_threshold'],
        'creditBalance': !exists(json, 'credit_balance') ? undefined : json['credit_balance'],
        'boundCreditBalance': !exists(json, 'bound_credit_balance') ? undefined : json['bound_credit_balance'],
        'hasCompanyData': !exists(json, 'has_company_data') ? undefined : json['has_company_data'],
        'addressId': !exists(json, 'address_id') ? undefined : json['address_id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
    };
}

export function HostToJSON(value?: Host | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'name': value.name,
        'fiscal_code': value.fiscalCode,
        'mobile': value.mobile,
        'photo_path': value.photoPath,
        'devices_code': value.devicesCode,
        'devices_code_to_save': value.devicesCodeToSave,
        'card_id': value.cardId,
        'type': HostTypeToJSON(value.type),
        'usage_threshold': value.usageThreshold,
        'credit_balance': value.creditBalance,
        'bound_credit_balance': value.boundCreditBalance,
        'has_company_data': value.hasCompanyData,
        'address_id': value.addressId,
        'user_id': value.userId,
    };
}

