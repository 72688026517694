/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * 
     * @type {number}
     * @memberof Plan
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof Plan
     */
    price?: string;
}

export function PlanFromJSON(json: any): Plan {
    return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'price': !exists(json, 'price') ? undefined : json['price'],
    };
}

export function PlanToJSON(value?: Plan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'level': value.level,
        'price': value.price,
    };
}

