/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeviceType,
    DeviceTypeFromJSON,
    DeviceTypeFromJSONTyped,
    DeviceTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    id?: number;
    /**
     * 
     * @type {DeviceType}
     * @memberof Device
     */
    type?: DeviceType;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    topic?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    serial?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    partNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    timeServer?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    timeZone?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    imei?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    firmwareVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    unlockTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    alertText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    auxUnlockEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    auxUnlockText?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    auxUnlockTime?: string;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    partnerId?: number;
    /**
     * 
     * @type {number}
     * @memberof Device
     */
    resourceId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Device
     */
    online?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    protocolVersion?: string;
    /**
     * 
     * @type {Date}
     * @memberof Device
     */
    installedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    cypherKey?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    serialNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    brokerPassword?: string;
}

export function DeviceFromJSON(json: any): Device {
    return DeviceFromJSONTyped(json, false);
}

export function DeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Device {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : DeviceTypeFromJSON(json['type']),
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'serial': !exists(json, 'serial') ? undefined : json['serial'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'deviceVersion': !exists(json, 'device_version') ? undefined : json['device_version'],
        'partNumber': !exists(json, 'part_number') ? undefined : json['part_number'],
        'timeServer': !exists(json, 'time_server') ? undefined : json['time_server'],
        'timeZone': !exists(json, 'time_zone') ? undefined : json['time_zone'],
        'imei': !exists(json, 'imei') ? undefined : json['imei'],
        'firmwareVersion': !exists(json, 'firmware_version') ? undefined : json['firmware_version'],
        'unlockTime': !exists(json, 'unlock_time') ? undefined : json['unlock_time'],
        'alertText': !exists(json, 'alert_text') ? undefined : json['alert_text'],
        'auxUnlockEnabled': !exists(json, 'aux_unlock_enabled') ? undefined : json['aux_unlock_enabled'],
        'auxUnlockText': !exists(json, 'aux_unlock_text') ? undefined : json['aux_unlock_text'],
        'auxUnlockTime': !exists(json, 'aux_unlock_time') ? undefined : json['aux_unlock_time'],
        'partnerId': !exists(json, 'partner_id') ? undefined : json['partner_id'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'online': !exists(json, 'online') ? undefined : json['online'],
        'protocolVersion': !exists(json, 'protocol_version') ? undefined : json['protocol_version'],
        'installedOn': !exists(json, 'installed_on') ? undefined : (new Date(json['installed_on'])),
        'cypherKey': !exists(json, 'cypher_key') ? undefined : json['cypher_key'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'serialNumber': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'brokerPassword': !exists(json, 'broker_password') ? undefined : json['broker_password'],
    };
}

export function DeviceToJSON(value?: Device | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': DeviceTypeToJSON(value.type),
        'topic': value.topic,
        'serial': value.serial,
        'code': value.code,
        'device_version': value.deviceVersion,
        'part_number': value.partNumber,
        'time_server': value.timeServer,
        'time_zone': value.timeZone,
        'imei': value.imei,
        'firmware_version': value.firmwareVersion,
        'unlock_time': value.unlockTime,
        'alert_text': value.alertText,
        'aux_unlock_enabled': value.auxUnlockEnabled,
        'aux_unlock_text': value.auxUnlockText,
        'aux_unlock_time': value.auxUnlockTime,
        'partner_id': value.partnerId,
        'resource_id': value.resourceId,
        'active': value.active,
        'online': value.online,
        'protocol_version': value.protocolVersion,
        'installed_on': value.installedOn === undefined ? undefined : (value.installedOn.toISOString()),
        'cypher_key': value.cypherKey,
        'phone_number': value.phoneNumber,
        'serial_number': value.serialNumber,
        'broker_password': value.brokerPassword,
    };
}

