/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Feature,
    FeatureFromJSON,
    FeatureToJSON,
} from '../models';

export interface ApiFeaturesIdDeleteRequest {
    id: number;
}

export interface ApiFeaturesIdGetRequest {
    id: string;
}

export interface ApiFeaturesIdPutRequest {
    id: number;
    feature: Feature;
}

export interface ApiFeaturesPostRequest {
    feature: Feature;
}

/**
 * 
 */
export class FeaturesApi extends runtime.BaseAPI {

    /**
     */
    async apiFeaturesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Feature>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeatureFromJSON));
    }

    /**
     */
    async apiFeaturesGet(initOverrides?: RequestInit): Promise<Array<Feature>> {
        const response = await this.apiFeaturesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFeaturesIdDeleteRaw(requestParameters: ApiFeaturesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFeaturesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/features/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFeaturesIdDelete(requestParameters: ApiFeaturesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFeaturesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFeaturesIdGetRaw(requestParameters: ApiFeaturesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Feature>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFeaturesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/features/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFromJSON(jsonValue));
    }

    /**
     */
    async apiFeaturesIdGet(requestParameters: ApiFeaturesIdGetRequest, initOverrides?: RequestInit): Promise<Feature> {
        const response = await this.apiFeaturesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiFeaturesIdPutRaw(requestParameters: ApiFeaturesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFeaturesIdPut.');
        }

        if (requestParameters.feature === null || requestParameters.feature === undefined) {
            throw new runtime.RequiredError('feature','Required parameter requestParameters.feature was null or undefined when calling apiFeaturesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/features/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FeatureToJSON(requestParameters.feature),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFeaturesIdPut(requestParameters: ApiFeaturesIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFeaturesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiFeaturesPostRaw(requestParameters: ApiFeaturesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.feature === null || requestParameters.feature === undefined) {
            throw new runtime.RequiredError('feature','Required parameter requestParameters.feature was null or undefined when calling apiFeaturesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/features`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeatureToJSON(requestParameters.feature),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiFeaturesPost(requestParameters: ApiFeaturesPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiFeaturesPostRaw(requestParameters, initOverrides);
    }

}
