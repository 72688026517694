/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       PlanSelectionBlock.tsx
 * @author     Christian Ascone
 * @date       1/14/20 6:19 PM
 */

import React from 'react';
import {Picker, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import planSelectionBlockStyle from "@styles/host/PlanSelectionBlockStyle";
import {i18n} from "@i18n/i18n";
import {Plan} from "@services/src/models";
import {AvenirBookGreyLabelText, AvenirLightGreyText} from "@components/StyledText";
import {SwitchOffImage} from "@components/Images/SwitchOffImage";
import {SwitchOnImage} from "@components/Images/SwitchOnImage";
import {DateHelper} from "@helpers/DateHelper";

interface PlanSelectionBlockProps {
    style?: StyleProp<ViewStyle>,
    plans: Array<Plan>,
    selectedPlanId: number,
    autorenew: boolean,
    expireDate: Date,
    onAutorenewClick: () => void,
    onChange: (selectedPlanId: number) => void,
}

export function PlanSelectionBlock(props: PlanSelectionBlockProps) {
    const selectedPlanId = props.selectedPlanId || '';
    return (
        <View style={[planSelectionBlockStyle.container, props.style]}>
            <View
                style={[planSelectionBlockStyle.flexWrap,]}>
                <AvenirBookGreyLabelText style={planSelectionBlockStyle.title}>
                    {i18n.t('components.plan_selection.title')}
                </AvenirBookGreyLabelText>
            </View>
            <View style={[planSelectionBlockStyle.flexBig,]}>
                <View style={[planSelectionBlockStyle.flex, {flexDirection: 'column'}]}>
                    <Picker
                        selectedValue={selectedPlanId}
                        style={planSelectionBlockStyle.picker}
                        onValueChange={(itemValue, itemIndex) =>
                            props.onChange(itemValue)
                        }>

                        <Picker.Item label={i18n.t('components.plan_selection.select_plan')} value=''/>
                        {props.plans.map((plan, key) => (
                            <Picker.Item key={key}
                                         label={i18n.t('components.plan_selection.plan_label', {
                                             name: plan.name.toUpperCase(),
                                             credits: plan.price
                                         })}
                                         value={plan.id}/>
                        ))}
                    </Picker>
                    <View style={[planSelectionBlockStyle.flex, {flexDirection: 'row', marginTop: 10,}]}>
                        <View style={[planSelectionBlockStyle.flex, {flexDirection: 'column'}]}>
                            <AvenirLightGreyText style={[planSelectionBlockStyle.flex]}>
                                {i18n.t('components.plan_selection.autorenew')}
                            </AvenirLightGreyText>
                            <TouchableOpacity style={[planSelectionBlockStyle.flex]} onPress={props.onAutorenewClick}>
                                {props.autorenew &&
                                <SwitchOnImage style={planSelectionBlockStyle.autorenewSwitch}/>
                                }
                                {!props.autorenew &&
                                <SwitchOffImage style={planSelectionBlockStyle.autorenewSwitch}/>
                                }
                            </TouchableOpacity>
                        </View>
                        <View style={[planSelectionBlockStyle.flex, {flexDirection: 'column'}]}>
                            <AvenirLightGreyText style={[planSelectionBlockStyle.flex]}>
                                {i18n.t('components.plan_selection.service_expiry_date')}
                            </AvenirLightGreyText>
                            <AvenirLightGreyText style={[planSelectionBlockStyle.flex, {}]}>
                                {props.expireDate != null ? DateHelper.formatDate(props.expireDate) : '-'}
                            </AvenirLightGreyText>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
}