/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    AddressToCoordinates,
    AddressToCoordinatesFromJSON,
    AddressToCoordinatesToJSON,
} from '../models';

export interface ApiAddressesGetCoordinatesForAddressAddressGetRequest {
    address: string;
}

export interface ApiAddressesIdDeleteRequest {
    id: number;
}

export interface ApiAddressesIdGetRequest {
    id: string;
}

export interface ApiAddressesIdPutRequest {
    id: number;
    address: Address;
}

export interface ApiAddressesPostRequest {
    address: Address;
}

/**
 * 
 */
export class AddressesApi extends runtime.BaseAPI {

    /**
     */
    async apiAddressesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Address>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressFromJSON));
    }

    /**
     */
    async apiAddressesGet(initOverrides?: RequestInit): Promise<Array<Address>> {
        const response = await this.apiAddressesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAddressesGetCoordinatesForAddressAddressGetRaw(requestParameters: ApiAddressesGetCoordinatesForAddressAddressGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AddressToCoordinates>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling apiAddressesGetCoordinatesForAddressAddressGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/addresses/getCoordinatesForAddress/{address}`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters.address))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressToCoordinatesFromJSON(jsonValue));
    }

    /**
     */
    async apiAddressesGetCoordinatesForAddressAddressGet(requestParameters: ApiAddressesGetCoordinatesForAddressAddressGetRequest, initOverrides?: RequestInit): Promise<AddressToCoordinates> {
        const response = await this.apiAddressesGetCoordinatesForAddressAddressGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAddressesIdDeleteRaw(requestParameters: ApiAddressesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAddressesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAddressesIdDelete(requestParameters: ApiAddressesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAddressesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAddressesIdGetRaw(requestParameters: ApiAddressesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAddressesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     */
    async apiAddressesIdGet(requestParameters: ApiAddressesIdGetRequest, initOverrides?: RequestInit): Promise<Address> {
        const response = await this.apiAddressesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiAddressesIdPutRaw(requestParameters: ApiAddressesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAddressesIdPut.');
        }

        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling apiAddressesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAddressesIdPut(requestParameters: ApiAddressesIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAddressesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiAddressesPostRaw(requestParameters: ApiAddressesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.address === null || requestParameters.address === undefined) {
            throw new runtime.RequiredError('address','Required parameter requestParameters.address was null or undefined when calling apiAddressesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/addresses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.address),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiAddressesPost(requestParameters: ApiAddressesPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiAddressesPostRaw(requestParameters, initOverrides);
    }

}
