/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HouseContactType {
    Phone = 'PHONE',
    Email = 'EMAIL'
}

export function HouseContactTypeFromJSON(json: any): HouseContactType {
    return HouseContactTypeFromJSONTyped(json, false);
}

export function HouseContactTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HouseContactType {
    return json as HouseContactType;
}

export function HouseContactTypeToJSON(value?: HouseContactType | null): any {
    return value as any;
}

