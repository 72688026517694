/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       BarcodeScannerScreen.tsx
 * @author     Christian Ascone
 * @date       2/26/20 6:07 PM
 */

import React, {Component} from 'react';
import {Button, StyleSheet, Text, View} from 'react-native';
import {BarCodeScanner} from 'expo-barcode-scanner';
import {
    getCardBarcodeDismissCallbackFromNavigationProps,
    getDoorBarcodeDismissCallbackFromNavigationProps,
    getStartingScreenFromNavigationProps,
} from "@helpers/NavigationDataHelper";
import {dismissModal, goBack, NavigationProps, openPassepartoutModal} from "@helpers/NavigationHelper";
import {LoaderState} from "@helpers/LoaderHelper";
import {Logger} from "@helpers/Logger";
import {PermissionManager} from "../modules/PermissionManager";
import {DeviceScanHelper} from "@helpers/DeviceScanHelper";
import {BfreeX1} from "@helpers/BfreeX1";
import { useNavigation } from '@react-navigation/native'

interface State extends LoaderState {
    granted: boolean,
    scanned: boolean,
    onDismiss: (data) => void,
    type: string,
    code: string,
    data_encrypted: string,
    startingScreen: string,
}

export default class CardBarcodeScannerScreen extends Component<NavigationProps, State> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            granted: null,
            scanned: false,
            onDismiss: getCardBarcodeDismissCallbackFromNavigationProps(this) || null,
            type: null,
            code: null,
            data_encrypted: null,
            startingScreen: getStartingScreenFromNavigationProps(this) || null,
        };
    }

    componentDidMount() {
        this.checkPermissions();
    }

    componentWillUnmount() {
        this.state.onDismiss(this.state.code);
    }

    handleBarCodeScanned(data) {
        const imei = DeviceScanHelper.extractCodeFromCard(data.data);
        if (imei[1] == "TAGX:" || imei[1] == "IDX:")
            this.setState({scanned: true, code: imei[0]}, ()=>{
                Logger.log(this.constructor.name, data);
                this.state.onDismiss(this.state.code);
                this.props.navigation.goBack();
            });
        else
            this.setState({scanned: true, code: imei[0]}, ()=> {
                Logger.log(this.constructor.name, data);
                this.state.onDismiss(this.state.code);
                this.props.navigation.goBack();
            });
    };

    render() {
        if (this.state.granted === false) {
            return <Text>No access to camera</Text>;
        }

        return (
            <View
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}>
                <BarCodeScanner
                    onBarCodeScanned={(data) => this.state.scanned ? undefined : this.handleBarCodeScanned(data)}
                    style={StyleSheet.absoluteFillObject}
                />

                {this.state.scanned &&
                <Button title={'Tap to Scan Again'} onPress={() => this.setState({scanned: false})}/>}
            </View>
        );
    }

    private async checkPermissions() {
        const status = await PermissionManager.checkCameraPermission();
        this.setState({granted: status});
    }
}