/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewReservationPeriodScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {Platform, StyleSheet} from "react-native";
import {Header} from "react-navigation";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        ...Platform.select({
            web: {
                height: Layout.window.height - Header.HEIGHT,
                textAlign: 'center',
                marginTop: '1%',
            },
        }),

    },
    scrollContainer: {
        flex: 1,
        backgroundColor: '#fff',
        marginTop: 50,
    },
    contentContainer: {
        justifyContent: 'space-between',

    },

    inputContainer: {
        ...Platform.select({
            web: {
                flexDirection: 'column',
            },
        }),
        flex: 1,
        marginTop: 10,
        marginBottom: 20,
    },
    DateTimeInputBlock: {

    },
    flex: {
        flex: 1,
    },
    getStartedContainer: {
        height: '100%',
    },
});

export default styles;