/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       EventHelper.ts
 * @author     Christian Ascone
 * @date       04/03/2021, 12:52
 */

import {Event} from '@services/src';
import {getCurrentLocale, LanguagesEnum} from "@i18n/i18n";

export class EventHelper {
    /**
     * Build a string for row event detail
     * @param event
     */
    public static rowDetailString(event: Event): string {
        if (event.device == null)
            return '';
        const device = event.device;
        const auxText = device.auxUnlockText;
        const resourceName = event.resource != null ? event.resource.name : '';

        return device.auxUnlockEnabled ? `${resourceName} (${auxText})` : resourceName;
    }

    /**
     * Returns a trimmed version of long description for given event
     * @param event
     */
    public static trimmedLongDescription(event: Event): string {
        if (event == null)
            return null;
        const locale = getCurrentLocale();
        let longDescription = locale == null || locale == LanguagesEnum.English ? event.longDescriptionEn : event.longDescriptionIt;
        if (longDescription == null || longDescription.length <= 30)
            return longDescription;
        return longDescription != null ? longDescription.substr(0, 27) + '...' : '';
    }
}