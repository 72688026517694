export class BfreeX1 {
    public static numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];


    public static encode(sIn: string) {
        let sRes = "";

        let iCh;
        let iAdd = 5;
        let iMin;
        let iMax;
        if (sIn.length > 0) {
            for (let i = sIn ? sIn.length - 1 : 0; i >= 0; i--) {
                iCh = sIn[i];

                if (BfreeX1.numbers.includes(iCh))
                    iCh = BfreeX1.numbers.indexOf(iCh);
                else
                    iCh = iCh.charCodeAt(0);
                let val = iCh;
                iMin = 0;
                iMax = 9;
                if ((iCh >= iMin) && (iCh <= iMax)) {
                    iCh = iCh + iAdd;
                    if (iCh > iMax) {
                        iCh = iCh + iMin - iMax - 1;
                    }
                    sRes = sRes + iCh.toString();

                    if (iAdd + iCh - iMin < 8) {
                        iAdd = iAdd + iCh - iMin;
                    } else
                        iAdd = iCh - iMin;
                } else {
                    iMin = 'A'.charCodeAt(0);
                    iMax = 'F'.charCodeAt(0);
                    if ((iCh >= iMin) && (iCh <= iMax)) {
                        iCh = iCh + Math.floor(iAdd/2);
                        if (iCh > iMax) {
                            iCh = iCh + iMin - iMax - 1;
                        }

                        sRes = sRes + String.fromCharCode(iCh);

                        if (iAdd + iCh - iMin < 8) {
                            iAdd = iAdd + iCh - iMin;
                        } else
                            iAdd = iCh - iMin;
                    } else {
                        sRes = sRes + String.fromCharCode(val);
                    }
                }

            }
        }

        return sRes;
    }


    public static decode(sIn: string) {


        let sRes = "";
        var iCh;
        let val;
        let iAdd = 5;
        let iMin;
        let iMax;
        let adders = [];

        if (sIn.length > 0) {
            for (let i = 0; i < sIn.length; i++) {


                iCh = sIn[i];
                if (BfreeX1.numbers.includes(iCh))
                    iCh = BfreeX1.numbers.indexOf(iCh);
                else
                    iCh = iCh.charCodeAt(0);

                val = iCh;
                iMin = 0;
                iMax = 9;
                if ((iCh >= iMin) && (iCh <= iMax)) {
                    iCh = iCh - iAdd;
                    if (iAdd + val - iMin < 8)
                        iAdd = iAdd + val - iMin;
                    else
                        iAdd = val - iMin;
                    if (iCh < iMin) {
                        iCh = iCh + iMax - iMin + 1;
                    }
                    sRes = iCh.toString() + sRes;

                } else {
                    iMin = 'A'.charCodeAt(0);
                    iMax = 'F'.charCodeAt(0);
                    if ((iCh >= iMin) && (iCh <= iMax)) {


                        iCh = iCh - Math.floor(iAdd/2);

                        if (iAdd + val - iMin < 8)
                            iAdd = iAdd + val - iMin;
                        else
                            iAdd = val - iMin;

                        if (iCh < iMin) {

                            iCh = iCh + iMax - iMin + 1;

                        }
                        
                        sRes = String.fromCharCode(iCh) + sRes;

                    } else {
                        sRes = String.fromCharCode(val) + sRes;
                    }
                }


                adders.push(iAdd);

            }

        }

        return sRes;
    }

}