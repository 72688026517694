/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Registry,
    RegistryFromJSON,
    RegistryToJSON,
} from '../models';

export interface ApiRegistriesIdDeleteRequest {
    id: number;
}

export interface ApiRegistriesIdGetRequest {
    id: string;
}

export interface ApiRegistriesIdPutRequest {
    id: number;
    registry: Registry;
}

export interface ApiRegistriesPostRequest {
    registry: Registry;
}

/**
 * 
 */
export class RegistriesApi extends runtime.BaseAPI {

    /**
     */
    async apiRegistriesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Registry>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/registries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegistryFromJSON));
    }

    /**
     */
    async apiRegistriesGet(initOverrides?: RequestInit): Promise<Array<Registry>> {
        const response = await this.apiRegistriesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistriesIdDeleteRaw(requestParameters: ApiRegistriesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistriesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/registries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistriesIdDelete(requestParameters: ApiRegistriesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiRegistriesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRegistriesIdGetRaw(requestParameters: ApiRegistriesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Registry>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistriesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/registries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistryFromJSON(jsonValue));
    }

    /**
     */
    async apiRegistriesIdGet(requestParameters: ApiRegistriesIdGetRequest, initOverrides?: RequestInit): Promise<Registry> {
        const response = await this.apiRegistriesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiRegistriesIdPutRaw(requestParameters: ApiRegistriesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRegistriesIdPut.');
        }

        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry','Required parameter requestParameters.registry was null or undefined when calling apiRegistriesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/registries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RegistryToJSON(requestParameters.registry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistriesIdPut(requestParameters: ApiRegistriesIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiRegistriesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiRegistriesPostRaw(requestParameters: ApiRegistriesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.registry === null || requestParameters.registry === undefined) {
            throw new runtime.RequiredError('registry','Required parameter requestParameters.registry was null or undefined when calling apiRegistriesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/registries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegistryToJSON(requestParameters.registry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiRegistriesPost(requestParameters: ApiRegistriesPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiRegistriesPostRaw(requestParameters, initOverrides);
    }

}
