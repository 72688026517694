/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DeviceFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import * as fakerStatic from 'faker';
import {Device, DeviceType} from "@services/src/models";

export class DeviceFactory {
    static factoryArray(size: number): Device[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(DeviceFactory.factory());
        }
        return array;
    }

    static factory(): Device {
        return {
            id: fakerStatic.random.number(4),
            type: DeviceType.Standard,
            topic: '',
            serial: fakerStatic.random.uuid(),
            code: fakerStatic.random.uuid(),
            deviceVersion: '1.0.0',
            partNumber: fakerStatic.random.number() + '',
            timeServer: 'xxxxxxx',
            timeZone: 'xxxxxxxx',
            imei: fakerStatic.random.uuid(),
            firmwareVersion: '1.0.1',
            unlockTime: fakerStatic.random.number({min: 1000, max: 5000}) + '',
            alertText: 'Alert text',
            auxUnlockText: 'Unlock text',
            auxUnlockTime: fakerStatic.random.number({min: 1000, max: 5000}) + '',
            installedOn: fakerStatic.date.recent(),
        };
    }
}

