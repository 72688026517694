/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewCollaborationDoneScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import newCollaborationDoneScreenStyle from "@styles/host/collaborations/NewCollaborationDoneScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openCollaborations} from "@helpers/NavigationHelper";
import {ConfirmButton} from "@components/ConfirmButton";
import {
    getCardSelectionBeanFromNavigationProps,
    getCollaborationBeanFromNavigationProps,
    getUserFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {HouseDoneBackgroundLeftImage} from "@components/Images/HouseDoneBackgroundLeftImage";
import {HouseDoneBackgroundRightImage} from "@components/Images/HouseDoneBackgroundRightImage";
import {AvenirHeavyPrimaryText, AvenirLightGreyText} from "@components/StyledText";
import {CollaborationBean} from "../../../models/beans/CollaborationBean";
import {DebugButton} from "@components/DebugButton";
import {AccommodationSelection} from "../../../models/beans/AccommodationSelection";
import {HouseCompleteFactory} from "../../../models/factory/HouseCompleteFactory";
import {HouseForAccommodationsSelection} from "../../../models/interfaces/HouseForAccommodationsSelectionBean";
import {
    AccommodationRecapBlock,
    CollaboratorDataRecapBlock,
    PeriodRecapBlock,
    UserDataRecapBlock
} from "@components/RecapContainerBlock";
import {CollaboratorFactory} from "../../../models/factory/CollaboratorFactory";
import {HostCollaborationsApi} from "@services/src/apis/index";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {HostCollaborationInput} from "@services/src/models/HostCollaborationInput";
import {Card} from "@services/src/models/Card";
import {CardSelectionBean} from "../../../models/beans/CardSelection";

interface State extends LoaderState {
    collaborationBean: CollaborationBean,
    cards: CardSelectionBean[],
}

export default class NewCollaborationDoneScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_collaboration_done.title');

        let collaborationBean = getCollaborationBeanFromNavigationProps(this) || new CollaborationBean();
        if (!collaborationBean.houses) collaborationBean.houses = [];

        this.state = {
            loading: false,
            collaborationBean: collaborationBean,
            cards: getCardSelectionBeanFromNavigationProps(this) || [],
        };

    }


    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newCollaborationDoneScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View
                    style={[newCollaborationDoneScreenStyle.container, globalScreenStyle.globalMargins]}
                >
                    <View
                        style={[newCollaborationDoneScreenStyle.getStartedContainer, newCollaborationDoneScreenStyle.container]}>
                        <View style={newCollaborationDoneScreenStyle.welcomeContainer}>
                            <View style={{
                                flex: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}>
                                <HouseDoneBackgroundLeftImage style={{width: 90, height: 90, alignSelf: 'flex-end'}}/>
                            </View>
                            <View style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}>
                                <AvenirHeavyPrimaryText style={newCollaborationDoneScreenStyle.title}>
                                    {i18n.t('screens.new_collaboration_done.its_done_1')}
                                </AvenirHeavyPrimaryText>
                                <AvenirHeavyPrimaryText style={newCollaborationDoneScreenStyle.title}>
                                    {i18n.t('screens.new_collaboration_done.its_done_2')}
                                </AvenirHeavyPrimaryText>
                            </View>
                            <View style={{
                                flex: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}>
                                <HouseDoneBackgroundRightImage
                                    style={{width: 90, height: 90, alignSelf: 'flex-start'}}/>
                            </View>
                        </View>
                        <View style={newCollaborationDoneScreenStyle.installationContainer}>
                            <AvenirLightGreyText style={newCollaborationDoneScreenStyle.subtitle}>
                                {i18n.t('screens.new_collaboration_done.recap')}
                            </AvenirLightGreyText>
                            <ScrollView
                                style={[newCollaborationDoneScreenStyle.container, newCollaborationDoneScreenStyle.scroll]}
                                contentContainerStyle={newCollaborationDoneScreenStyle.contentContainer}>
                                <View
                                    style={[{marginHorizontal: 10,}]}>
                                    <CollaboratorDataRecapBlock collaborator={this.state.collaborationBean.collaborator}
                                                        style={{marginVertical: 5,}}/>
                                    <AccommodationRecapBlock houses={this.state.collaborationBean.houses}
                                                             style={{marginVertical: 5,}}/>
                                    <PeriodRecapBlock from_date={this.state.collaborationBean.from_date}
                                                      from_time={this.state.collaborationBean.from_time}
                                                      to_date={this.state.collaborationBean.to_date}
                                                      to_time={this.state.collaborationBean.to_time}
                                                      style={{marginVertical: 5}}/>

                                </View>
                                <AvenirLightGreyText>
                                    {this.state.cards.length > 0 && this.state.cards.length + i18n.t('screens.new_collaboration_done.cards')}
                                    {this.state.cards.length <= 0  && i18n.t('screens.new_collaboration_done.noCards')}
                                </AvenirLightGreyText>
                            </ScrollView>
                        </View>
                    </View>
                    <ConfirmButton active={true} onPress={() => openNextPage(this)}
                                   title={i18n.t('buttons.new_collaboration_done')}
                                   style={{marginBottom: 20}}/>
                </View>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let bean = context.state.collaborationBean;
    bean.collaborator = CollaboratorFactory.factory();
    let houses = HouseCompleteFactory.factoryArray(5);
    let housesWithSelectionBeans = HouseForAccommodationsSelection.fromHouseList(houses.filter(house => house.accommodationsCount > 0));
    housesWithSelectionBeans.forEach(house => {
        house.accommodationsSelectionBeans = AccommodationSelection.fromAccommodationList(house.accommodations);
    });
    housesWithSelectionBeans[0].accommodationsSelectionBeans.forEach(bean => bean.selected = true);
    bean.houses = housesWithSelectionBeans;
    context.setState({collaborationBean: bean});
}

/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    let collaborationBean = context.state.collaborationBean;

    let input: HostCollaborationInput = {
        fromDate: collaborationBean.from_date,
        fromTime: collaborationBean.from_time,
        toDate: collaborationBean.to_date,
        toTime: collaborationBean.to_time,
        collaboratorId: collaborationBean.collaborator.id,
        houses: collaborationBean.housesToCollaborationInput(),
        cards: [],
    };
    context.state.cards.forEach(card => {
        input.cards.push(card.card);
    });
    new HostCollaborationsApi(context).apiHostCollaborationsPost({hostCollaborationInput: input}).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        openCollaborations(context, getUserFromNavigationProps(context));
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    });
}