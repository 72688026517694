/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       EventFactory.ts
 * @author     Christian Ascone
 * @date       2/2/21 10:45 AM
 */


import * as fakerStatic from 'faker';
import {Event, EventPriority, EventType} from "@services/src/models";
import {HostFactory} from "./HostFactory";
import {ResourceFactory} from "./ResourceFactory";
import {DeviceFactory} from "./DeviceFactory";

export class EventFactory {
    static factoryArray(size: number): Event[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(EventFactory.factory());
        }
        return array;
    }

    static factory(priority: EventPriority = null): Event {
        const priorityArray = [EventPriority.Low, EventPriority.Medium, EventPriority.High];
        if (priority == null)
            priority = priorityArray[fakerStatic.random.number(priorityArray.length - 1)];
        return {
            id: fakerStatic.random.number(4),
            shortDescriptionIt: fakerStatic.lorem.paragraph(1).substr(0, 20),
            shortDescriptionEn: fakerStatic.lorem.paragraph(1).substr(0, 20),
            longDescriptionIt: fakerStatic.lorem.paragraph(1).substr(0, 255),
            longDescriptionEn: fakerStatic.lorem.paragraph(1).substr(0, 255),
            type: EventType.Generic,
            priority: priority,
            datetime: fakerStatic.date.future(),
            read: fakerStatic.random.boolean(),
            resource: ResourceFactory.factory(),
            device: DeviceFactory.factory(),
            host: HostFactory.factory(),
        };
    }
}

