/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Partner,
    PartnerFromJSON,
    PartnerToJSON,
} from '../models';

export interface ApiPartnersIdDeleteRequest {
    id: number;
}

export interface ApiPartnersIdGetRequest {
    id: string;
}

export interface ApiPartnersIdPutRequest {
    id: number;
    partner: Partner;
}

export interface ApiPartnersPostRequest {
    partner: Partner;
}

/**
 * 
 */
export class PartnersApi extends runtime.BaseAPI {

    /**
     */
    async apiPartnersGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Partner>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/partners`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PartnerFromJSON));
    }

    /**
     */
    async apiPartnersGet(initOverrides?: RequestInit): Promise<Array<Partner>> {
        const response = await this.apiPartnersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPartnersIdDeleteRaw(requestParameters: ApiPartnersIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPartnersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPartnersIdDelete(requestParameters: ApiPartnersIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPartnersIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPartnersIdGetRaw(requestParameters: ApiPartnersIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Partner>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPartnersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartnerFromJSON(jsonValue));
    }

    /**
     */
    async apiPartnersIdGet(requestParameters: ApiPartnersIdGetRequest, initOverrides?: RequestInit): Promise<Partner> {
        const response = await this.apiPartnersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiPartnersIdPutRaw(requestParameters: ApiPartnersIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiPartnersIdPut.');
        }

        if (requestParameters.partner === null || requestParameters.partner === undefined) {
            throw new runtime.RequiredError('partner','Required parameter requestParameters.partner was null or undefined when calling apiPartnersIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/partners/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartnerToJSON(requestParameters.partner),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPartnersIdPut(requestParameters: ApiPartnersIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPartnersIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiPartnersPostRaw(requestParameters: ApiPartnersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.partner === null || requestParameters.partner === undefined) {
            throw new runtime.RequiredError('partner','Required parameter requestParameters.partner was null or undefined when calling apiPartnersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/partners`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartnerToJSON(requestParameters.partner),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiPartnersPost(requestParameters: ApiPartnersPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiPartnersPostRaw(requestParameters, initOverrides);
    }

}
