/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GuestType,
    GuestTypeFromJSON,
    GuestTypeFromJSONTyped,
    GuestTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Collaborator
 */
export interface Collaborator {
    /**
     * 
     * @type {number}
     * @memberof Collaborator
     */
    id?: number;
    /**
     * 
     * @type {GuestType}
     * @memberof Collaborator
     */
    type?: GuestType;
    /**
     * 
     * @type {string}
     * @memberof Collaborator
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Collaborator
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Collaborator
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Collaborator
     */
    mobile?: string;
}

export function CollaboratorFromJSON(json: any): Collaborator {
    return CollaboratorFromJSONTyped(json, false);
}

export function CollaboratorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Collaborator {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : GuestTypeFromJSON(json['type']),
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
    };
}

export function CollaboratorToJSON(value?: Collaborator | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': GuestTypeToJSON(value.type),
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'mobile': value.mobile,
    };
}

