/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeviceResourceJoin,
    DeviceResourceJoinFromJSON,
    DeviceResourceJoinToJSON,
} from '../models';

export interface ApiDeviceResourceJoinsIdDeleteRequest {
    id: number;
}

export interface ApiDeviceResourceJoinsIdGetRequest {
    id: string;
}

export interface ApiDeviceResourceJoinsIdPutRequest {
    id: number;
    deviceResourceJoin: DeviceResourceJoin;
}

export interface ApiDeviceResourceJoinsPostRequest {
    deviceResourceJoin: DeviceResourceJoin;
}

/**
 * 
 */
export class DeviceResourceJoinsApi extends runtime.BaseAPI {

    /**
     */
    async apiDeviceResourceJoinsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<DeviceResourceJoin>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/device_resource_joins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceResourceJoinFromJSON));
    }

    /**
     */
    async apiDeviceResourceJoinsGet(initOverrides?: RequestInit): Promise<Array<DeviceResourceJoin>> {
        const response = await this.apiDeviceResourceJoinsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDeviceResourceJoinsIdDeleteRaw(requestParameters: ApiDeviceResourceJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDeviceResourceJoinsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/device_resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDeviceResourceJoinsIdDelete(requestParameters: ApiDeviceResourceJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDeviceResourceJoinsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDeviceResourceJoinsIdGetRaw(requestParameters: ApiDeviceResourceJoinsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeviceResourceJoin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDeviceResourceJoinsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/device_resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceResourceJoinFromJSON(jsonValue));
    }

    /**
     */
    async apiDeviceResourceJoinsIdGet(requestParameters: ApiDeviceResourceJoinsIdGetRequest, initOverrides?: RequestInit): Promise<DeviceResourceJoin> {
        const response = await this.apiDeviceResourceJoinsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDeviceResourceJoinsIdPutRaw(requestParameters: ApiDeviceResourceJoinsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDeviceResourceJoinsIdPut.');
        }

        if (requestParameters.deviceResourceJoin === null || requestParameters.deviceResourceJoin === undefined) {
            throw new runtime.RequiredError('deviceResourceJoin','Required parameter requestParameters.deviceResourceJoin was null or undefined when calling apiDeviceResourceJoinsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/device_resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceResourceJoinToJSON(requestParameters.deviceResourceJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDeviceResourceJoinsIdPut(requestParameters: ApiDeviceResourceJoinsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDeviceResourceJoinsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDeviceResourceJoinsPostRaw(requestParameters: ApiDeviceResourceJoinsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceResourceJoin === null || requestParameters.deviceResourceJoin === undefined) {
            throw new runtime.RequiredError('deviceResourceJoin','Required parameter requestParameters.deviceResourceJoin was null or undefined when calling apiDeviceResourceJoinsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/device_resource_joins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceResourceJoinToJSON(requestParameters.deviceResourceJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDeviceResourceJoinsPost(requestParameters: ApiDeviceResourceJoinsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDeviceResourceJoinsPostRaw(requestParameters, initOverrides);
    }

}
