/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       LogoBFreeImage.tsx
 * @author     Christian Ascone
 * @date       9/12/19 9:45 AM
 */

import React from 'react';
import {Image} from "react-native";

export function LogoBFreeImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                require('../../assets/images/Logo-B-Free_0001_Livello-1-copia.png')
            }
        />
    );
}