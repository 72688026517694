/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ResidentialColorImage.tsx
 * @author     Christian Ascone
 * @date       9/23/19 1:15 PM
 */

import React from 'react';
import {Image} from "react-native";

export function ResidentialColorImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                require('../../../assets/images/residential_color.png')
            }
        />
    );
}