/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       VersionHelper.ts
 * @author     Christian Ascone
 * @date       2/21/20 1:14 PM
 */

import PackageJson from '@root/package';

export class VersionHelper {
    /**
     * Gets the app version
     * @returns {string}
     */
    static getAppVersion(): string {
        return PackageJson.version
    }
}