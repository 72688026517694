/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FirstNewHouseScreen.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../i18n/i18n";
import newHouseScreenStyle from "@styles/host/house/NewHouseScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {NavigationProps, openFirstNewHouseDone, openNewHouseDone} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {AvatarImageButton} from "@components/AvatarImageButton";
import {setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import MapView from 'react-native-maps';
import {TextInputBlockWithButton} from "@components/TextInputBlockWithButton";
import {AddressesApi, HostHousesApi} from "@services/src/apis/index";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {AddressToCoordinatesFactory} from "../../models/factory/AddressToCoordinatesFactory";
import {
    AddressProviderType,
    AddressToCoordinates,
    HostHouseInput,
    HouseContact,
    HouseContactType
} from "@services/src/models/index";
import {DebugButton} from "@components/DebugButton";
import {PermissionManager} from "../../modules/PermissionManager";
import {ImagesManager} from "../../modules/ImagesManager";
import {PhotoUploadState} from "@components/states/PhotoUploadState";
import * as Location from 'expo-location';
import {LocationHelper} from "@helpers/LocationHelper";
import {TextInputBlockWithDelete} from "@components/TextInputBlockWithDelete";
import {AddRowClickableBlock} from "@components/ClickableBlock";
import {validatePhoneNumber} from "../../modules/Validator";
import {AvenirBookServiceGreyText, AvenirBookServiceRedText} from "@components/StyledText";

interface State extends PhotoUploadState {
    house_name: string,
    house_address: string,
    region: any,
    placeId: string,
    latitude: number,
    longitude: number,
    markers: Array<any>,
    house_phone_contacts: Array<HouseContact>,
    locationFound: boolean,
}

export default class FirstNewHouseScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_house.title');
        this.state = {
            loading: false,
            house_name: '',
            house_address: '',
            placeId: null,
            latitude: null,
            longitude: null,
            region: LocationHelper.getDefaultRegion(),
            user_photo_uri: null,
            user_photo_base64: null,
            markers: [],
            house_phone_contacts: [],
            locationFound: true,
        };

    }

    componentDidMount() {
        this.getLocation();
    }

    async getLocation() {
        await PermissionManager.checkLocationPermission();
        let location = await Location.getCurrentPositionAsync({});
        if (location != null && location.coords != null) {
            let region = LocationHelper.locationToRegion(location);
            this.setState({region: region});
        }
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newHouseScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateMap(this)}/>
                <Loader state={this.state}/>
                <ScrollView
                    style={[newHouseScreenStyle.container, globalScreenStyle.globalMargins]}
                    contentContainerStyle={newHouseScreenStyle.contentContainer}>
                    <View
                        style={[newHouseScreenStyle.getStartedContainer, newHouseScreenStyle.container]}>
                        <View style={newHouseScreenStyle.welcomeContainer}>
                            <AvatarImageButton onPress={() => updateImage(this)} style={{}}
                                               imageUri={this.state.user_photo_uri}/>
                        </View>
                        <View
                            style={newHouseScreenStyle.inputContainer}>
                            <TextInputBlock label={i18n.t('screens.new_house.house_name')}
                                            onChangeText={(text) => this.setState({house_name: text})}
                                            text={this.state.house_name}/>
                            <TextInputBlockWithButton onPress={() => searchAddress(this)} active={addressValid(this)}
                                                      label={i18n.t('screens.new_house.house_address')}
                                                      onChangeText={(text) => this.setState({house_address: text})}
                                                      text={this.state.house_address}
                                                      buttonTitle={i18n.t('screens.new_house.search_address')}/>
                            {!this.state.locationFound && <AvenirBookServiceRedText> {i18n.t('screens.new_house.address_not_found')} </AvenirBookServiceRedText>}
                            <View style={{borderRadius: 10, overflow: 'hidden'}}>
                                <MapView style={{height: 150}} region={this.state.region}>
                                    {this.state.markers.map((marker, key) => (
                                        // @ts-ignore
                                        <MapView.Marker
                                            key={key}
                                            coordinate={marker.latlng}
                                            title={marker.title}
                                            description={marker.description}
                                        />
                                    ))}
                                </MapView>
                            </View>

                            <AddRowClickableBlock
                                onPress={() => addContactRow(this)}
                                title={i18n.t('screens.new_house.add_contact', {count: 1})}
                                style={{marginVertical: 10, width: '100%', alignSelf: 'center',}}/>
                            {this.state.house_phone_contacts.map((contact, key) => (
                                <TextInputBlockWithDelete key={key}
                                                          label={i18n.t('screens.new_house.contact', {index: key + 1})}
                                                          text={contact.contact}
                                                          placeholder=' '
                                                          onPress={() => removeContact(this, key)}
                                                          onChangeText={(text) => updateContact(this, key, text)}/>
                            ))}
                        </View>
                    </View>
                </ScrollView>
                <ConfirmButtonBottom active={dataComplete(this)} onPress={() => openNextPage(this)}
                                     title={i18n.t('buttons.new_house')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Adds a new contact row
 * @param {React.Component<any, State>} context
 */
function addContactRow(context: Component<any, State>) {
    let contacts = context.state.house_phone_contacts;
    let max_contacts = 3;
    if (contacts != null && contacts.length >= max_contacts) {
        AlertHelper.showSimpleAlert(i18n.t('alerts.attention'), i18n.t('screens.new_house.max_contacts_alert', {contacts_number: max_contacts}));
        return;
    }
    contacts.push({contact: '', type: HouseContactType.Phone});
    context.setState({house_phone_contacts: contacts});
}

/**
 * Update a contact
 * @param {React.Component<any, State>} context
 * @param {number} key
 * @param {string} text
 */
function updateContact(context: Component<any, State>, key: number, text: string) {
    if (text != null && text.trim() != '' && !validatePhoneNumber(text)) return;
    let contacts = context.state.house_phone_contacts;
    contacts[key].contact = text;
    context.setState({house_phone_contacts: contacts});
}

/**
 * Remove a contact row with given key
 * @param {React.Component<any, State>} context
 * @param {number} key
 */
function removeContact(context: Component<any, State>, key: number) {
    let contacts = context.state.house_phone_contacts;
    delete contacts[key];
    contacts = contacts.filter((val) => val !== undefined);
    context.setState({house_phone_contacts: contacts});
}

/**
 * Update the image.
 * It checks the current state.
 * In case the image has already been taken it asks user to delete or replace it
 * @param {React.Component<any, State>} context
 * @returns {Promise<void>}
 */
function updateImage(context: Component<any, PhotoUploadState>) {
    if (context.state.user_photo_uri == null)
        return openImagePicker(context);
    AlertHelper.showImageSelectionAlert(function () {
        openImagePicker(context);
    }, function () {
        context.setState({user_photo_base64: null, user_photo_uri: null});
    });
}

/**
 * Open the image picker for profile photo
 * @param {React.Component<any, State>} context
 */
async function openImagePicker(context: Component<any, PhotoUploadState>) {
    await PermissionManager.checkCameraRollPermission();
    let result = await ImagesManager.chooseImage();

    // @ts-ignore
    context.setState({user_photo_uri: result.uri, user_photo_base64: result.base64 || result.uri});
}

/**
 * Checks if address is not null and not empty
 * @param context
 * @returns {boolean}
 */
function addressValid(context: Component<any, State>) {
    return context.state.house_address != null && context.state.house_address != '';
}

/**
 * Checks if house name is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function houseNameValid(context: Component<any, State>) {
    return context.state.house_name != null && context.state.house_name != '';
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return addressValid(context) && context.state.latitude != null && context.state.longitude != null && houseNameValid(context);
}

/**
 * Update map with coordinates object
 * @param context
 * @param addressToCoordinates
 */
function updateMapRegion(context: Component<any, State>, addressToCoordinates: AddressToCoordinates) {
    let lat = +addressToCoordinates.lat;
    let lng = +addressToCoordinates.lng;
    if (addressToCoordinates.accuracy == "result_not_found")
        context.setState({locationFound: false});
    else
        context.setState({locationFound: true});
    context.setState({
        latitude: lat,
        longitude: lng,
        placeId: addressToCoordinates.placeId,
        region: {
            latitude: lat,
            longitude: lng,
            latitudeDelta: 0.02,
            longitudeDelta: 0.02,
        },
        markers: [
            {
                latlng: {
                    latitude: lat,
                    longitude: lng,
                },
                title: i18n.t('screens.new_house.house_position'),
                description: context.state.house_name,
            }
        ]
    });
}

/**
 * Updates map with fake data
 * @param context
 */
function debugUpdateMap(context: Component<any, State>) {
    let addressToCoordinates = AddressToCoordinatesFactory.factory();
    updateMapRegion(context, addressToCoordinates);
}

/**
 * Search address coordinates and updates map
 * @param context
 */
function searchAddress(context: Component<any, State>) {
    if (!addressValid(context)) return;

    Logger.log(context.constructor.name, 'Calling geocode service');
    new AddressesApi(context).apiAddressesGetCoordinatesForAddressAddressGet({address: context.state.house_address}).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp);
        updateMapRegion(context, resp);
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + JSON.stringify(error));
    });
}



/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.house_data_incomplete'));
        return;
    }
    let house: HostHouseInput = {
        name: context.state.house_name,
        description: context.state.house_name,
        address: {
            fullAddress: context.state.house_address,
            latitude: context.state.latitude,
            longitude: context.state.longitude,
            placeId: context.state.placeId,
            placeProvider: AddressProviderType.GoogleMaps,
        },
        houseContacts: context.state.house_phone_contacts,
        photoBase64: context.state.user_photo_base64,
    };
    new HostHousesApi(context).apiHostHousesPost({
        hostHouseInput: house
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp.data);
        openFirstNewHouseDone(context, resp.data);
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    })
}