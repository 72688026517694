/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceResourceJoin
 */
export interface DeviceResourceJoin {
    /**
     * 
     * @type {number}
     * @memberof DeviceResourceJoin
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceResourceJoin
     */
    deviceId?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceResourceJoin
     */
    resourceId?: number;
}

export function DeviceResourceJoinFromJSON(json: any): DeviceResourceJoin {
    return DeviceResourceJoinFromJSONTyped(json, false);
}

export function DeviceResourceJoinFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceResourceJoin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
    };
}

export function DeviceResourceJoinToJSON(value?: DeviceResourceJoin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'device_id': value.deviceId,
        'resource_id': value.resourceId,
    };
}

