/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostEvent,
    HostEventFromJSON,
    HostEventFromJSONTyped,
    HostEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostEventList
 */
export interface HostEventList {
    /**
     * 
     * @type {Array<HostEvent>}
     * @memberof HostEventList
     */
    data?: Array<HostEvent>;
}

export function HostEventListFromJSON(json: any): HostEventList {
    return HostEventListFromJSONTyped(json, false);
}

export function HostEventListFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostEventList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(HostEventFromJSON)),
    };
}

export function HostEventListToJSON(value?: HostEventList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(HostEventToJSON)),
    };
}

