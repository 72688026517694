/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationBean.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import {Guest, HostReservationHouseInput} from "@services/src/models";
import {HouseForAccommodationsSelectionBean} from "../interfaces/HouseForAccommodationsSelectionBean";

export class ReservationBean {
    guest: Guest;
    from_date: Date;
    from_time: Date;
    to_date: Date;
    to_time: Date;
    houses: Array<HouseForAccommodationsSelectionBean> = [];

    constructor() {
        this.houses = [];
    }

    /**
     * Maps the houses array to input for new reservation
     * @returns {Array<HostReservationHouseInput>}
     */
    housesToReservationInput(): Array<HostReservationHouseInput> {
        return this.houses.map(house => {
            return {
                id: house.id,
                accommodations: house.accommodationsSelectionBeans.filter(bean => bean.selected).flatMap(bean => bean.accommodation.accommodation.id),
            };
        });
    }
}

/**
 * Builder class for ReservationBean
 */
export class ReservationBeanBuilder {
    private guest: Guest;
    private from_date: Date;
    private from_time: Date;
    private to_date: Date;
    private to_time: Date;
    private houses: Array<HouseForAccommodationsSelectionBean>;

    withFrom(from_date: Date, from_time: Date) {
        this.from_date = from_date;
        this.from_time = from_time;
        return this;
    }

    withTo(to_date: Date, to_time: Date) {
        this.to_date = to_date;
        this.to_time = to_time;
        return this;
    }

    withGuest(guest: Guest) {
        this.guest = guest;
        return this;
    }

    withHouses(houses: Array<HouseForAccommodationsSelectionBean>) {
        this.houses = houses;
        return this;
    }

    build() {
        let bean = new ReservationBean();
        bean.guest = this.guest;
        bean.from_date = this.from_date;
        bean.from_time = this.from_time;
        bean.to_date = this.to_date;
        bean.to_time = this.to_time;
        bean.houses = this.houses;
        return bean;
    }
}
