/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ImageButtonStyle.ts
 * @author     Christian Ascone
 * @date       9/23/19 6:13 PM
 */

import {StyleSheet} from "react-native";
import Colors from "../config/Colors";

const styles = StyleSheet.create({
    enabledButton: {
        paddingTop:5,
        paddingBottom:5,
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
    },
    backgroundEnabled: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        backgroundColor: Colors.buttonEnabledBackground,
    },
    backgroundDisabled: {
        backgroundColor: Colors.background,
    },
    text: {
        textAlign:'center',
        padding: 5,
        textAlignVertical: 'center',
    },
    textActive: {
        color: 'white',
    },
    textInactive: {
        color: Colors.primaryColor,
    },
    avatarPlaceholderAdd: {
        width: 30,
        height: 30,
        position: 'absolute',
        left: 60,
    },
    addButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    addButtonImage: {
        width: 40, height: 40,
    },
    addButtonText: {
        marginLeft: 20,
    },
    inputBlockButtonContainer: {
        flexDirection: 'row',
        display: 'flex',
    },
    inputBlockButtonInputContainer: {
        flex: 3,
        flexDirection: 'column',
    },
    inputBlockButtonButtonContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    deleteButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 0.5,
        borderColor: Colors.buttonBorder,
        borderRadius: 3,
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    deleteButtonImage: {
        width: 15,
        height: 15,
        resizeMode: 'contain',
    },
});

export default styles;