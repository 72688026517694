/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseDetailScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 11:00 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, TouchableOpacity, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import houseDetailScreenStyle from "@styles/host/house/HouseDetailScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {
    goBack,
    NavigationProps,
    openAccommodationsList,
    openDoorsList, openHouseDelete,
    openNewAccommodation
} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {AvatarImageButton} from "@components/AvatarImageButton";
import {getHouseFromNavigationProps, setHeaderTitle} from "@helpers/NavigationDataHelper";
import MapView from 'react-native-maps';
import {TextInputBlockWithButton} from "@components/TextInputBlockWithButton";
import {AddressesApi, HostHousesApi} from "@services/src/apis/index";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {
    AddressProviderType,
    HostHouse,
    HostHouseInput,
    HouseContact,
    HouseContactType,
    HouseDetail
} from "@services/src/models/index";
import {HouseFactory} from "../../../models/factory/HouseFactory";
import {AddClickableBlock, AddRowClickableBlock, ListClickableBlock} from "@components/ClickableBlock";
import {DeleteButton} from "@components/DeleteButton";
import {DebugButton} from "@components/DebugButton";
import {ImagesManager} from "../../../modules/ImagesManager";
import {PermissionManager} from "../../../modules/PermissionManager";
import {PhotoUploadState} from "@components/states/PhotoUploadState";
import {LocationHelper} from "@helpers/LocationHelper";
import * as Location from "expo-location";
import {TextInputBlockWithDelete} from "@components/TextInputBlockWithDelete";
import {validatePhoneNumber} from "../../../modules/Validator";

interface State extends PhotoUploadState {
    house_name: string,
    house_address: string,
    house: HostHouse,
    region: any,
    placeId: string,
    latitude: number,
    longitude: number,
    markers: Array<any>,
    house_phone_contacts: Array<HouseContact>,
    contacts_changed: boolean,
}

export default class HouseDetailScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);


        this.state = {
            loading: false,
            house_name: '',
            house_address: '',
            house: {},
            placeId: null,
            latitude: null,
            longitude: null,
            region: LocationHelper.getDefaultRegion(),
            user_photo_uri: null,
            user_photo_base64: null,
            markers: [],
            house_phone_contacts: [],
            contacts_changed: false,
        };

    }

    componentDidMount() {
        let house = getHouseFromNavigationProps(this) || {};
        setHeaderTitle(this.props, house.name);
        let self = this;
        new HostHousesApi(this).apiHostHousesIdGet({id: house.id + ''}).then(resp => {
            Logger.log(self.constructor.name, 'Ok');
            updateHouseState(self, resp);
        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + error);
            this.getLocation();
        });
    }

    async getLocation() {
        await PermissionManager.checkLocationPermission();
        let location = await Location.getCurrentPositionAsync({});
        if(location != null && location.coords != null){
            let region = LocationHelper.locationToRegion(location);
            this.setState({region: region});
        }
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={houseDetailScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateMap(this)}/>
                <Loader state={this.state}/>
                <ScrollView
                    style={[houseDetailScreenStyle.container, globalScreenStyle.globalMargins]}
                    contentContainerStyle={houseDetailScreenStyle.contentContainer}>
                    <View
                        style={[]}>
                        <View style={houseDetailScreenStyle.topContainer}>
                            <AvatarImageButton style={{flex: 1, alignSelf: 'center'}} onPress={() => updateImage(this)}
                                            imageUri={this.state.house.photoPath}/>
                            <View style={{flex: 2, flexDirection: 'column', alignSelf: 'center', marginLeft: 20,}}>
                                <AddClickableBlock style={{flex: 1, marginBottom: 5,}}
                                                   onPress={() => openNewAccommodation(this, this.state.house)}
                                                   title={i18n.t('screens.house_detail.add_accommodation')}/>
                                <ListClickableBlock style={{flex: 1, marginBottom: 5,}}
                                                    onPress={() => openAccommodationsList(this, this.state.house)}
                                                    title={i18n.t('screens.house_detail.accommodations_list')}/>
                                <ListClickableBlock style={{flex: 1, marginBottom: 5,}}
                                                    onPress={() => openDoorsList(this, this.state.house)}
                                                    title={i18n.t('screens.house_detail.doors_list')}/>
                                <View style={houseDetailScreenStyle.deleteButtonContainer}>
                                    <DeleteButton onPress={() => deleteHouse(this)} style={{flexDirection: 'column',}}/>
                                </View>
                            </View>
                        </View>
                        <View
                            style={houseDetailScreenStyle.inputContainer}>
                            <TextInputBlock label={i18n.t('screens.house_detail.house_name')}
                                            onChangeText={(text) => this.setState({house_name: text})}
                                            text={this.state.house_name}/>
                            <TextInputBlockWithButton onPress={() => searchAddress(this)} active={addressValid(this)}
                                                      label={i18n.t('screens.house_detail.house_address')}
                                                      onChangeText={(text) => this.setState({house_address: text})}
                                                      text={this.state.house_address}
                                                      buttonTitle={i18n.t('screens.house_detail.search_address')}/>
                            <View style={{borderRadius: 10, overflow: 'hidden'}}>
                                <MapView style={{height: 150}} region={this.state.region}>
                                    {this.state.markers.map((marker, key) => (
                                        // @ts-ignore
                                        <MapView.Marker
                                            key={key}
                                            coordinate={marker.latlng}
                                            title={marker.title}
                                            description={marker.description}
                                        />
                                    ))}
                                </MapView>
                            </View>

                            <AddRowClickableBlock
                                onPress={() => addContactRow(this)}
                                title={i18n.t('screens.house_detail.add_contact', {count: 1})}
                                style={{marginVertical: 10, width: '100%', alignSelf: 'center',}}/>
                            {this.state.house_phone_contacts.map((contact, key) => (
                                <TextInputBlockWithDelete key={key}
                                                          label={i18n.t('screens.house_detail.contact', {index: key + 1})}
                                                          text={contact.contact}
                                                          placeholder=' '
                                                          onPress={() => removeContact(this, key)}
                                                          onChangeText={(text) => updateContact(this, key, text)}/>
                            ))}
                        </View>
                    </View>
                </ScrollView>

                    <ConfirmButtonBottom active={dataChanged(this)} onPress={() => updateHouse(this)}
                                     title={i18n.t('buttons.house_detail')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    }
}

function deleteHouse(context: Component<any, State>) {
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed) {
            new HostHousesApi(context).apiHostHousesIdCandeleteGet({id: context.state.house.id}).then(resp => {
                    if (resp.accommodations || resp.resources)
                        openHouseDelete(context, resp);
                    else
                        new HostHousesApi(context).apiHostHousesIdDelete({id: context.state.house.id}).then(resp => {
                            goBack(context);
                        }).catch(error => {
                            AlertHelper.showSimpleErrorAlert();
                        });
            }).catch(error => {
                AlertHelper.showSimpleErrorAlert();
            });
        }
    });
}

/**
 * Adds a new contact row
 * @param {React.Component<any, State>} context
 */
function addContactRow(context: Component<any, State>) {
    let contacts = context.state.house_phone_contacts;
    let max_contacts = 3;
    if (contacts != null && contacts.length >= max_contacts) {
        AlertHelper.showSimpleAlert(i18n.t('alerts.attention'), i18n.t('screens.house_detail.max_contacts_alert', {contacts_number: max_contacts}));
        return;
    }
    contacts.push({contact: '', type: HouseContactType.Phone});
    context.setState({house_phone_contacts: contacts, contacts_changed: true});
}

/**
 * Update a contact
 * @param {React.Component<any, State>} context
 * @param {number} key
 * @param {string} text
 */
function updateContact(context: Component<any, State>, key: number, text: string) {
    if (text != null && text.trim() != '' && !validatePhoneNumber(text)) return;
    let contacts = context.state.house_phone_contacts;
    contacts[key].contact = text;
    context.setState({house_phone_contacts: contacts, contacts_changed: true});
}

/**
 * Remove a contact row with given key
 * @param {React.Component<any, State>} context
 * @param {number} key
 */
function removeContact(context: Component<any, State>, key: number) {
    let contacts = context.state.house_phone_contacts;
    delete contacts[key];
    contacts = contacts.filter((val) => val !== undefined);
    context.setState({house_phone_contacts: contacts, contacts_changed: true});
}

/**
 * Update the image.
 * It checks the current state.
 * In case the image has already been taken it asks user to delete or replace it
 * @param {React.Component<any, State>} context
 * @returns {Promise<void>}
 */
function updateImage(context: Component<any, State>) {
    if (context.state.house.photoPath == null && context.state.user_photo_uri == null)
        return openImagePicker(context);
    AlertHelper.showImageSelectionAlert(function () {
        openImagePicker(context);
    }, function () {
        let house = context.state.house;
        house.photoPath = null;
        context.setState({user_photo_base64: null, user_photo_uri: null, house: house,});
        context.setState({photo_changed: true});
    });
}

/**
 * Open the image picker for profile photo
 * @param {React.Component<any, State>} context
 */
async function openImagePicker(context: Component<any, State>) {
    await PermissionManager.checkCameraRollPermission();
    let result = await ImagesManager.chooseImage();

    // @ts-ignore
    context.setState({user_photo_uri: result.uri, user_photo_base64: result.base64 || result.uri});

    let house = context.state.house;
    // @ts-ignore
    house.photoPath = result.uri;
    context.setState({photo_changed: true, house: house,});
}

/**
 * Update component state with house data
 * @param {React.Component<NavigationProps, State>} context
 * @param {HouseDetail} houseDetail
 */
function updateHouseState(context: Component<NavigationProps, State>, houseDetail: HouseDetail) {
    let house = houseDetail.data;
    console.log(house);
    let address = house.address;
    address.fullAddress = address.fullAddress || '';
    context.setState({
        house: house,
        house_address: address.fullAddress,
        house_name: house.name,
        house_phone_contacts: house.houseContacts || []
    });
    updateMapRegion(context, house.address);
}

/**
 * Checks if address is not null and not empty
 * @param context
 * @returns {boolean}
 */
function addressValid(context: Component<any, State>) {
    return context.state.house_address != null && context.state.house_address != '';
}

/**
 * Checks if house name is not null and not empty
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function houseNameValid(context: Component<any, State>) {
    return context.state.house_name != null && context.state.house_name != '';
}

/**
 * Checks if data changed
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataChanged(context: Component<any, State>) {
    let state = context.state;
    let house = state.house;
    if (house == null || house.address == null)
        return false;
    let nameChanged = state.house_name.trim() != house.name.trim();
    let fullAddressChanged = state.house_address.trim() != house.address.fullAddress.trim();
    let latitudeChanged = state.latitude != house.address.latitude;
    let longitudeChanged = state.longitude != house.address.longitude;
    let gpsChanged = latitudeChanged || longitudeChanged;
    let imageChanged = state.photo_changed;
    let contactsChanged = state.contacts_changed;
    // Data changed if name changed OR gps changed with full address
    return (nameChanged || imageChanged || (fullAddressChanged && gpsChanged) || contactsChanged) && (addressValid(context) && houseNameValid(context));
}

/**
 * Update map with coordinates object
 * @param context
 * @param address
 */
function updateMapRegion(context: Component<any, State>, address) {
    let lat = +address.latitude || +address.lat;
    let lng = +address.longitude || +address.lng;
    if (!lat || !lng) {
        Logger.warn(context.constructor.name, 'Empty coordinates');
        return;
    }
    context.setState({
        latitude: lat,
        longitude: lng,
        placeId: address.placeId,
        region: {
            latitude: lat,
            longitude: lng,
            latitudeDelta: 0.02,
            longitudeDelta: 0.02,
        },
        markers: [
            {
                latlng: {
                    latitude: lat,
                    longitude: lng,
                },
                title: i18n.t('screens.house_detail.house_position'),
                description: context.state.house_name,
            }
        ]
    });
}

/**
 * Updates map with fake data
 * @param context
 */
function debugUpdateMap(context: Component<any, State>) {
    let house = HouseFactory.factory();
    updateHouseState(context, {data: house});
}

/**
 * Search address coordinates and updates map
 * @param context
 */
function searchAddress(context: Component<any, State>) {
    if (!addressValid(context)) return;

    Logger.log(context.constructor.name, 'Calling geocode service');
    new AddressesApi(context).apiAddressesGetCoordinatesForAddressAddressGet({address: context.state.house_address}).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        console.log(resp);
        updateMapRegion(context, resp);
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
    });
}

/**
 * Open the next page if password is complete
 * @param context
 */
function updateHouse(context: Component<any, State>) {
    if (!dataChanged(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.house_data_unchanged'));
        return;
    }
    let hostHouseInput: HostHouseInput = {
        name: context.state.house_name,
        description: context.state.house_name,
        address: {
            fullAddress: context.state.house_address,
            latitude: context.state.latitude,
            longitude: context.state.longitude,
            placeId: context.state.placeId,
            placeProvider: AddressProviderType.GoogleMaps,
        },
        houseContacts: context.state.house_phone_contacts,
    };

    if(context.state.photo_changed)
        hostHouseInput.photoBase64 = context.state.user_photo_base64;

    new HostHousesApi(context).apiHostHousesIdPut({id: context.state.house.id, hostHouseInput: hostHouseInput}).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        goBack(context);
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    });
}