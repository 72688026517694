/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Host,
    HostFromJSON,
    HostToJSON,
} from '../models';

export interface ApiHostsIdDeleteRequest {
    id: number;
}

export interface ApiHostsIdGetRequest {
    id: string;
}

export interface ApiHostsIdPutRequest {
    id: number;
    host: Host;
}

export interface ApiHostsPostRequest {
    host: Host;
}

/**
 * 
 */
export class HostsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Host>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/hosts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HostFromJSON));
    }

    /**
     */
    async apiHostsGet(initOverrides?: RequestInit): Promise<Array<Host>> {
        const response = await this.apiHostsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostsIdDeleteRaw(requestParameters: ApiHostsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/hosts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostsIdDelete(requestParameters: ApiHostsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostsIdGetRaw(requestParameters: ApiHostsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Host>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/hosts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HostFromJSON(jsonValue));
    }

    /**
     */
    async apiHostsIdGet(requestParameters: ApiHostsIdGetRequest, initOverrides?: RequestInit): Promise<Host> {
        const response = await this.apiHostsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostsIdPutRaw(requestParameters: ApiHostsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostsIdPut.');
        }

        if (requestParameters.host === null || requestParameters.host === undefined) {
            throw new runtime.RequiredError('host','Required parameter requestParameters.host was null or undefined when calling apiHostsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/hosts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HostToJSON(requestParameters.host),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostsIdPut(requestParameters: ApiHostsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostsPostRaw(requestParameters: ApiHostsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.host === null || requestParameters.host === undefined) {
            throw new runtime.RequiredError('host','Required parameter requestParameters.host was null or undefined when calling apiHostsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/hosts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HostToJSON(requestParameters.host),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostsPost(requestParameters: ApiHostsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostsPostRaw(requestParameters, initOverrides);
    }

}
