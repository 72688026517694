/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Card,
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
    HostCollaborationHouseInput,
    HostCollaborationHouseInputFromJSON,
    HostCollaborationHouseInputFromJSONTyped,
    HostCollaborationHouseInputToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostCollaborationInput
 */
export interface HostCollaborationInput {
    /**
     * 
     * @type {Date}
     * @memberof HostCollaborationInput
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostCollaborationInput
     */
    fromTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostCollaborationInput
     */
    toDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostCollaborationInput
     */
    toTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof HostCollaborationInput
     */
    collaboratorId?: number;
    /**
     * List of house data with accommodations
     * @type {Array<HostCollaborationHouseInput>}
     * @memberof HostCollaborationInput
     */
    houses?: Array<HostCollaborationHouseInput>;
    /**
     * List of cards associated with the collaboration
     * @type {Array<Card>}
     * @memberof HostCollaborationInput
     */
    cards?: Array<Card>;
}

export function HostCollaborationInputFromJSON(json: any): HostCollaborationInput {
    return HostCollaborationInputFromJSONTyped(json, false);
}

export function HostCollaborationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostCollaborationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromDate': !exists(json, 'from_date') ? undefined : (new Date(json['from_date'])),
        'fromTime': !exists(json, 'from_time') ? undefined : (new Date(json['from_time'])),
        'toDate': !exists(json, 'to_date') ? undefined : (new Date(json['to_date'])),
        'toTime': !exists(json, 'to_time') ? undefined : (new Date(json['to_time'])),
        'collaboratorId': !exists(json, 'collaborator_id') ? undefined : json['collaborator_id'],
        'houses': !exists(json, 'houses') ? undefined : ((json['houses'] as Array<any>).map(HostCollaborationHouseInputFromJSON)),
        'cards': !exists(json, 'cards') ? undefined : ((json['cards'] as Array<any>).map(CardFromJSON)),
    };
}

export function HostCollaborationInputToJSON(value?: HostCollaborationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_date': value.fromDate === undefined ? undefined : (value.fromDate.toISOString().substr(0,10)),
        'from_time': value.fromTime === undefined ? undefined : (value.fromTime.toISOString()),
        'to_date': value.toDate === undefined ? undefined : (value.toDate.toISOString().substr(0,10)),
        'to_time': value.toTime === undefined ? undefined : (value.toTime.toISOString()),
        'collaborator_id': value.collaboratorId,
        'houses': value.houses === undefined ? undefined : ((value.houses as Array<any>).map(HostCollaborationHouseInputToJSON)),
        'cards': value.cards === undefined ? undefined : ((value.cards as Array<any>).map(CardToJSON)),
    };
}

