/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       TextInputBlockWithDelete.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {TextInput, TouchableOpacity, View} from 'react-native';
import TextStyle from '@styles/TextStyle';
import buttonStyle from '@styles/ButtonStyle';
import {AvenirBookUpperLabelText} from "./StyledText";
import {CloseImage} from "@components/Images/CloseImage";

export function TextInputBlockWithDelete(props) {
    return (
        <View style={buttonStyle.inputBlockButtonContainer}>
            <View style={buttonStyle.inputBlockButtonInputContainer}>
                <AvenirBookUpperLabelText>
                    {props.label}
                </AvenirBookUpperLabelText>
                <TextInput
                    keyboardType={props.keyboardType}
                    style={[props.style, TextStyle.separator]}
                    secureTextEntry={props.secureTextEntry}
                    placeholder={props.placeholder || props.label}
                    onChangeText={props.onChangeText}
                    value={props.text}
                />
            </View>
            <View style={[buttonStyle.inputBlockButtonButtonContainer]}>
                <TouchableOpacity onPress={props.onPress}
                                  style={[{margin: 0, paddingTop: 0, paddingBottom: 0, alignSelf: 'flex-end',}]}>
                    <CloseImage style={{
                        width: 30, height: 30, resizeMode: 'contain', paddingBottom: 5,
                        marginBottom: 20
                    }}/>
                </TouchableOpacity>
            </View>
        </View>
    );
}
