/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AvatarImageButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {Image, TouchableOpacity, View} from 'react-native';
import ButtonStyle from "@styles/ButtonStyle";
import {AvatarPlaceholderImage} from "@components/Images/AvatarPlaceholderImage";
import {AddImage} from "@components/Images/AddImage";
import AvatarImageButtonProps from "./properties/AvatarImageButtonProps";

export function AvatarImageButton(props: AvatarImageButtonProps) {
    return (
        <View style={props.style}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[props.style]}>
                {props.imageUri == null &&
                <AvatarPlaceholderImage style={{width: 90, height: 90}}/>
                }
                {props.imageUri != null &&
                <Image style={{borderRadius: 45, width: 90, height: 90}} source={{
                    uri: props.imageUri
                }}/>
                }
                {!props.hideAddButton &&
                <AddImage style={ButtonStyle.avatarPlaceholderAdd}/>
                }
            </TouchableOpacity>
        </View>
    );
}