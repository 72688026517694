/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    House,
    HouseFromJSON,
    HouseFromJSONTyped,
    HouseToJSON,
    Registry,
    RegistryFromJSON,
    RegistryFromJSONTyped,
    RegistryToJSON,
    ReservationStateEnum,
    ReservationStateEnumFromJSON,
    ReservationStateEnumFromJSONTyped,
    ReservationStateEnumToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostReservation
 */
export interface HostReservation {
    /**
     * 
     * @type {number}
     * @memberof HostReservation
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof HostReservation
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostReservation
     */
    endAt?: Date;
    /**
     * 
     * @type {ReservationStateEnum}
     * @memberof HostReservation
     */
    currentState?: ReservationStateEnum;
    /**
     * 
     * @type {Registry}
     * @memberof HostReservation
     */
    registry?: Registry;
    /**
     * 
     * @type {House}
     * @memberof HostReservation
     */
    house?: House;
    /**
     * 
     * @type {User}
     * @memberof HostReservation
     */
    user?: User;
}

export function HostReservationFromJSON(json: any): HostReservation {
    return HostReservationFromJSONTyped(json, false);
}

export function HostReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostReservation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startAt': !exists(json, 'start_at') ? undefined : (new Date(json['start_at'])),
        'endAt': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'currentState': !exists(json, 'current_state') ? undefined : ReservationStateEnumFromJSON(json['current_state']),
        'registry': !exists(json, 'registry') ? undefined : RegistryFromJSON(json['registry']),
        'house': !exists(json, 'house') ? undefined : HouseFromJSON(json['house']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function HostReservationToJSON(value?: HostReservation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start_at': value.startAt === undefined ? undefined : (value.startAt.toISOString()),
        'end_at': value.endAt === undefined ? undefined : (value.endAt.toISOString()),
        'current_state': ReservationStateEnumToJSON(value.currentState),
        'registry': RegistryToJSON(value.registry),
        'house': HouseToJSON(value.house),
        'user': UserToJSON(value.user),
    };
}

