import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, Text, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import {LoaderState} from "@helpers/LoaderHelper";
import {
    Accommodation,
    HostHouseDelete,
    HostHousesApi, HostReservationsApi, HostAccommodationsApi,
    House,
    Reservation,
    ReservationGuestBean,
} from "@services/src";
import {goBack, NavigationProps} from "@helpers/NavigationHelper";
import {LocationHelper} from "@helpers/LocationHelper";
import {
    getAccommodationFromNavigationProps,
    getHouseDeleteFromNavigationProps,
    getReservationGuestFromNavigationProps
} from "@helpers/NavigationDataHelper";
import houseDetailScreenStyle from "@styles/host/house/HouseDetailScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {
    AvenirBookGreyLabelText,
    AvenirBookPrimaryText,
    AvenirBookServiceGreyText,
    AvenirLightText
} from "@components/StyledText";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {AlertHelper} from "@helpers/AlertHelper";
import Colors from "../../../config/Colors";
import {DeleteButton} from "@components/DeleteButton";
import {Logger} from "@helpers/Logger";


interface State extends LoaderState {
    recap: ReservationGuestBean[],
    accommodation: Accommodation,
}

export default class AccommodationDeleteRecap extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);


        this.state = {
            loading: false,
            recap: getReservationGuestFromNavigationProps(this),
            accommodation: getAccommodationFromNavigationProps(this),
        };

        console.log("Recap: ", this.state.recap.length);

    }

    render(){
        console.log("Recap: ", this.state.recap.length);
        return (
            <View style={{flex: 1}}>
                <ScrollView
                    style={[houseDetailScreenStyle.container, globalScreenStyle.globalMargins]}
                    contentContainerStyle={houseDetailScreenStyle.contentContainer}>
                    {this.state.recap.length > 0 ?
                        <View>
                            <AvenirBookPrimaryText
                                style={{textAlign: "center"}}> {i18n.t("screens.accommodation_delete.message")} </AvenirBookPrimaryText>
                            <View style={{margin: 5}}>
                                <AvenirBookPrimaryText
                                style={{marginBottom: 10}}>{i18n.t("screens.accommodation_delete.reservations")}</AvenirBookPrimaryText>
                                <AvenirBookServiceGreyText style={{marginBottom: 10}}>{i18n.t("screens.accommodation_delete.reservations_alert")}</AvenirBookServiceGreyText>
                                {this.state.recap && this.state.recap.map((reservationGuestBean, key) => (
                                    <ReservationBlock reservationGuestBean={reservationGuestBean}
                                                      deleteReservation={this.deleteReservation}
                                                      context={this}/>
                                ))}
                            </View>
                        </View>
                        :
                        <View style={{marginBottom: 50}}>
                            <AvenirBookPrimaryText
                                style={{textAlign: "center"}}> {i18n.t("screens.house_delete.nothing_to_delete_message")} </AvenirBookPrimaryText>
                        </View>
                    }
                </ScrollView>
                <ConfirmButtonBottom active={this.state.recap && this.state.recap.length == 0} onPress={() => {if (this.state.recap.length == 0) deleteAccommodation(this)}} title={i18n.t('buttons.confirm')}/>
            </View>
        );
    }


    deleteReservation(context, id){
        AlertHelper.showConfirmAlert(function (confirmed) {
            if (confirmed) {
                new HostReservationsApi(context).apiHostReservationsIdDelete({id: id}).then(resp => {

                    let recap = context.state.recap;
                    let keyave;
                    recap.map((bean, key)=>{
                        if (bean.reservation.id == id)
                            keyave = key;
                    });
                    recap.splice(keyave, 1);
                    context.setState({recap: recap});
                }).catch(error => {
                    console.log(error);
                    AlertHelper.showSimpleErrorAlert();
                });
            }
        });
    }
}

function deleteAccommodation(context){
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed)
            new HostAccommodationsApi(context).apiHostAccommodationsIdDelete({id: context.state.accommodation.id}).then((resp) => {
                context.props.navigation.popToTop();
            }).catch(error => {
                AlertHelper.showSimpleErrorAlert();
            });
    });
}

export interface AccommodationBlockProps {
    accommodation: Accommodation,
}

export interface AccommodationBlockProps {
    accommodation: Accommodation,
}

export interface ReservationBlockProps {
    reservationGuestBean: ReservationGuestBean,
    deleteReservation: (context, id: number) => void,
    context: any,
}





export function ReservationBlock(props: ReservationBlockProps) {
    console.log("Rendering ReservationBlock");
    return (
        <View style={{width: "100%", padding: 5,borderWidth: 1, borderColor: Colors.primaryColor,  flexDirection: "row", marginTop: 5, marginBottom: 5, display: "flex"}}>
            <View style={{flexDirection: "column"}}>
                <View style={{flexDirection: "row"}}>
                    <AvenirBookGreyLabelText>{i18n.t("screens.house_delete.name") }</AvenirBookGreyLabelText><AvenirLightText> { props.reservationGuestBean.guest.first_name} </AvenirLightText>
                </View>
                <View style={{flexDirection: "row"}}>
                    <AvenirBookGreyLabelText>{i18n.t("screens.house_delete.surname") }</AvenirBookGreyLabelText><AvenirLightText> { props.reservationGuestBean.guest.last_name} </AvenirLightText>
                </View>
                <View style={{flexDirection: "row"}}>
                    <AvenirBookGreyLabelText>{i18n.t("screens.house_delete.from")}</AvenirBookGreyLabelText><AvenirLightText> { props.reservationGuestBean.reservation.start_at} </AvenirLightText>
                </View>
                <View style={{flexDirection: "row"}}>
                    <AvenirBookGreyLabelText>{i18n.t("screens.house_delete.to")}</AvenirBookGreyLabelText><AvenirLightText> { props.reservationGuestBean.reservation.end_at} </AvenirLightText>
                </View>
                <View style={{flexDirection: "row"}}>
                    <AvenirBookGreyLabelText>{i18n.t("screens.house_delete.email")}</AvenirBookGreyLabelText><AvenirLightText> { props.reservationGuestBean.guest.email} </AvenirLightText>
                </View>
                <View style={{flexDirection: "row"}}>
                    <AvenirBookGreyLabelText>{i18n.t("screens.house_delete.phone_number")}</AvenirBookGreyLabelText><AvenirLightText> { props.reservationGuestBean.guest.mobile} </AvenirLightText>
                </View>
            </View>

            <View style={{position: "absolute", right:10, top: "45%"}}>
                <DeleteButton onPress={() => props.deleteReservation(props.context, props.reservationGuestBean.reservation.id)}/>
            </View>

        </View>
    );
}