/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       PlansTableImage.tsx
 * @author     Christian Ascone
 * @date       1/9/20 10:31 AM
 */

import React from 'react';
import {Image} from "react-native";
import {i18n} from "@i18n/i18n";

export function PlansTableImage(props) {
    let uri = i18n.locale == 'it-IT' ? require('../../assets/images/plans-table-ita.png') : require('../../assets/images/plans-table-eng.png');
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                uri
            }
        />
    );
}