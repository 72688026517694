/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SwitchStyle.ts
 * @author     Christian Ascone
 * @date       9/17/19 10:32 AM
 */

import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    switch: {
        alignSelf: 'flex-end',
    },
    switchContainer: {
        flexDirection: 'row',
        flex: 1,
    },
    switchText: {
        flex: 4,
    },
});

export default styles;