/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       LogoBFreeGreyImage.tsx
 * @author     Christian Ascone
 * @date       9/26/19 6:59 PM
 */

import React from 'react';
import {Image} from "react-native";

export function LogoBFreeGreyImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                require('../../assets/images/Logo-B-Free_0002_Livello-1.png')
            }
        />
    );
}