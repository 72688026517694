/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReservationAccommodationJoin
 */
export interface ReservationAccommodationJoin {
    /**
     * 
     * @type {number}
     * @memberof ReservationAccommodationJoin
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ReservationAccommodationJoin
     */
    reservationId?: number;
    /**
     * 
     * @type {number}
     * @memberof ReservationAccommodationJoin
     */
    accommodationId?: number;
    /**
     * 
     * @type {number}
     * @memberof ReservationAccommodationJoin
     */
    houseId?: number;
}

export function ReservationAccommodationJoinFromJSON(json: any): ReservationAccommodationJoin {
    return ReservationAccommodationJoinFromJSONTyped(json, false);
}

export function ReservationAccommodationJoinFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationAccommodationJoin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reservationId': !exists(json, 'reservation_id') ? undefined : json['reservation_id'],
        'accommodationId': !exists(json, 'accommodation_id') ? undefined : json['accommodation_id'],
        'houseId': !exists(json, 'house_id') ? undefined : json['house_id'],
    };
}

export function ReservationAccommodationJoinToJSON(value?: ReservationAccommodationJoin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reservation_id': value.reservationId,
        'accommodation_id': value.accommodationId,
        'house_id': value.houseId,
    };
}

