/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorAuxUnlockHelper.ts
 * @author     Christian Ascone
 * @date       3/10/20 9:41 AM
 */

import {HostResource} from "@services/src";

export class DoorAuxUnlockHelper {

    /**
     * Checks if given HostResource has a device with auxUnlockEnabled
     * @param {HostResource} hostResource
     * @returns {boolean | any}
     */
    static resourceHasAuxUnlock(hostResource: HostResource) {
        return hostResource != null && hostResource.devices != null && hostResource.devices.length > 0 && hostResource.devices[0].auxUnlockEnabled;
    }

    /**
     * It counts the number of aux doors
     * @param {HostResource[]} doors
     * @returns {int}
     */
    static countAuxDoors(doors: HostResource[]) {
        if (doors == null || doors.length == 0) return 0;

        let auxUnlockDevices = doors.filter(d => d.devices != null && d.devices.length > 0).map(d => d.devices[0]).filter(device => device.auxUnlockEnabled);
        return auxUnlockDevices.length;
    }
}