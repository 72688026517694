/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Host,
    HostFromJSON,
    HostFromJSONTyped,
    HostToJSON,
} from './';

/**
 * 
 * @export
 * @interface House
 */
export interface House {
    /**
     * 
     * @type {number}
     * @memberof House
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof House
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof House
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof House
     */
    publicResourcesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof House
     */
    residentialResourcesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof House
     */
    accommodationsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof House
     */
    photoPath?: string;
    /**
     * 
     * @type {Address}
     * @memberof House
     */
    address?: Address;
    /**
     * 
     * @type {Host}
     * @memberof House
     */
    host?: Host;
}

export function HouseFromJSON(json: any): House {
    return HouseFromJSONTyped(json, false);
}

export function HouseFromJSONTyped(json: any, ignoreDiscriminator: boolean): House {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'publicResourcesCount': !exists(json, 'public_resources_count') ? undefined : json['public_resources_count'],
        'residentialResourcesCount': !exists(json, 'residential_resources_count') ? undefined : json['residential_resources_count'],
        'accommodationsCount': !exists(json, 'accommodations_count') ? undefined : json['accommodations_count'],
        'photoPath': !exists(json, 'photo_path') ? undefined : json['photo_path'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'host': !exists(json, 'host') ? undefined : HostFromJSON(json['host']),
    };
}

export function HouseToJSON(value?: House | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'public_resources_count': value.publicResourcesCount,
        'residential_resources_count': value.residentialResourcesCount,
        'accommodations_count': value.accommodationsCount,
        'photo_path': value.photoPath,
        'address': AddressToJSON(value.address),
        'host': HostToJSON(value.host),
    };
}

