/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostCardStatus,
    HostCardStatusFromJSON,
    HostCardStatusFromJSONTyped,
    HostCardStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface CardsData
 */
export interface CardsData {
    /**
     * 
     * @type {Array<HostCardStatus>}
     * @memberof CardsData
     */
    data?: Array<HostCardStatus>;
}

export function CardsDataFromJSON(json: any): CardsData {
    return CardsDataFromJSONTyped(json, false);
}

export function CardsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(HostCardStatusFromJSON)),
    };
}

export function CardsDataToJSON(value?: CardsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(HostCardStatusToJSON)),
    };
}

