/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HouseContact,
    HouseContactFromJSON,
    HouseContactToJSON,
} from '../models';

export interface ApiHouseContactsIdDeleteRequest {
    id: number;
}

export interface ApiHouseContactsIdGetRequest {
    id: string;
}

export interface ApiHouseContactsIdPutRequest {
    id: number;
    houseContact: HouseContact;
}

export interface ApiHouseContactsPostRequest {
    houseContact: HouseContact;
}

/**
 * 
 */
export class HouseContactsApi extends runtime.BaseAPI {

    /**
     */
    async apiHouseContactsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<HouseContact>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/house_contacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HouseContactFromJSON));
    }

    /**
     */
    async apiHouseContactsGet(initOverrides?: RequestInit): Promise<Array<HouseContact>> {
        const response = await this.apiHouseContactsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHouseContactsIdDeleteRaw(requestParameters: ApiHouseContactsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHouseContactsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/house_contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHouseContactsIdDelete(requestParameters: ApiHouseContactsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHouseContactsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHouseContactsIdGetRaw(requestParameters: ApiHouseContactsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HouseContact>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHouseContactsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/house_contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HouseContactFromJSON(jsonValue));
    }

    /**
     */
    async apiHouseContactsIdGet(requestParameters: ApiHouseContactsIdGetRequest, initOverrides?: RequestInit): Promise<HouseContact> {
        const response = await this.apiHouseContactsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHouseContactsIdPutRaw(requestParameters: ApiHouseContactsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHouseContactsIdPut.');
        }

        if (requestParameters.houseContact === null || requestParameters.houseContact === undefined) {
            throw new runtime.RequiredError('houseContact','Required parameter requestParameters.houseContact was null or undefined when calling apiHouseContactsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/house_contacts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HouseContactToJSON(requestParameters.houseContact),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHouseContactsIdPut(requestParameters: ApiHouseContactsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHouseContactsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHouseContactsPostRaw(requestParameters: ApiHouseContactsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.houseContact === null || requestParameters.houseContact === undefined) {
            throw new runtime.RequiredError('houseContact','Required parameter requestParameters.houseContact was null or undefined when calling apiHouseContactsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/house_contacts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HouseContactToJSON(requestParameters.houseContact),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHouseContactsPost(requestParameters: ApiHouseContactsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHouseContactsPostRaw(requestParameters, initOverrides);
    }

}
