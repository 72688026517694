/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DashboardEventsScreenntsScreen.tsx
 * @author     Christian Ascone
 * @date       2/9/21 1:24 PM
 */

import React, {Component} from 'react';
import {RefreshControl, ScrollView, View,} from 'react-native';
import eventsScreenStyle from "../../styles/host/DashboardEventsScreenStyle";
import globalScreenStyle from "../../styles/GlobalStyle";
import {default as Loader, LoaderState} from "../../helpers/LoaderHelper";
import {NavigationProps, openDashboardReservations} from "../../helpers/NavigationHelper";
import {getUserFromNavigationProps, setHeaderTitleLocalized} from "../../helpers/NavigationDataHelper";
import {HostEvent, User} from "../../services/src/models/index";
import {DebugButton} from "../../components/DebugButton";
import {ApiHostEventsGetRequest, EventsApi, HostEventsApi} from "../../services/src/apis/index";
import {Logger} from "../../helpers/Logger";
import {NoDataLabel} from "../../components/NoDataLabel";
import {EventFactory} from "../../models/factory/EventFactory";
import {EventReadRow} from '../../components/rows/EventReadRow';
import {openDashboardHouses} from "@helpers/NavigationHelper";
import {EVENTS_DASHBOARD_SEGMENT_TAB} from "@helpers/SegmentHelper";
import {DashboardSegment} from "@components/segments/DashboardSegment";
import {setHeaderProfileImage} from "@helpers/NavigationDataHelper";
import {PlatformHelper} from "@helpers/PlatformHelper";
import {registerForPushNotificationsAsync} from "../../modules/PushManager";
import {ProfileManager} from "../../modules/ProfileManager";
import {AlertHelper} from "@helpers/AlertHelper";

interface State extends LoaderState {
    user: User,
    events: Array<HostEvent>,
}

export default class DashboardEventsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    load = () => {
        this.downloadEvents();
    };

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.dashboard.title');

        this.state = {
            loading: false,
            refreshing: false,
            user: getUserFromNavigationProps(this),
            events: [],
        };
    }

    componentDidMount() {
        let self = this;
        let isMounted = true;
        ProfileManager.getProfile(this).then(resp => {
            if (isMounted){
                console.log(resp);
                setHeaderProfileImage(self.props, resp.data.host.photoPath);
            }
        });

        if (!PlatformHelper.isWeb())
            registerForPushNotificationsAsync(this);

        this.props.navigation.addListener('willFocus', payload => {
            this.load()
        });
        return () => {isMounted = false;}
    }

    render() {
        return (
            <View style={[eventsScreenStyle.container]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[eventsScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <DashboardSegment activeTab={EVENTS_DASHBOARD_SEGMENT_TAB}
                                      onPressSecond={(event) => openDashboardReservations(this, getUserFromNavigationProps(this))}
                                      onPressThird={(event) => openDashboardHouses(this, getUserFromNavigationProps(this))}
                                      onPressFirst={(event) => console.log('Events')}/>
                    <View style={globalScreenStyle.scrollContainer}>
                        <ScrollView
                            style={eventsScreenStyle.container}
                            contentContainerStyle={eventsScreenStyle.contentContainer}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()}/>
                            }>
                            {this.state.events.map((event, key) => (
                                // @ts-ignore
                                <View
                                    key={key}
                                    style={[eventsScreenStyle.eventContainer]}>
                                    <EventReadRow event={event} context={this} read={event.read}
                                                  onPress={() => this.setReadEvent(this, event)}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.events} show={this.state.showNoData}/>
                    </View>
                </View>
            </View>
        );
    }

    /**
     * Set event as read
     * @param context
     * @param event
     */
    private setReadEvent(context: Component<any, State>, event: HostEvent) {
        if (event.read)
            return;
        new EventsApi(context).apiEventsIdReadPost({id: event.id + '', read: true}).then(resp => {
            event.read = true;
            context.setState({events: context.state.events});
        }).catch(() => {
            Logger.warn(self.constructor.name, "Cannot get events list");
            AlertHelper.showSimpleErrorAlert();
        });
    }

    private onRefresh() {
        this.downloadEvents();
    }

    private downloadEvents() {
        let self = this;

        let requestParameters: ApiHostEventsGetRequest = {
            count: 50,
        };
        new HostEventsApi(this).apiHostEventsGet(requestParameters).then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} events for host.`);
            self.setState({events: resp.data, showNoData: true,});
        }).catch(() => {
            Logger.warn(self.constructor.name, "Cannot get events list");
            self.setState({showNoData: true,});
        });
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({events: EventFactory.factoryArray(25)});
}