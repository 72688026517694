/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewReservationAccommodationsScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import newReservationScreenStyle from "@styles/host/reservations/NewReservationAccommodationsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {getReservationBeanFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {DebugButton} from "@components/DebugButton";
import {ReservationBean} from "../../../models/beans/ReservationBean";
import {i18n} from "../../../i18n/i18n";
import {AlertHelper} from "@helpers/AlertHelper";
import {AccommodationSelection} from "../../../models/beans/AccommodationSelection";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {House} from "@services/src/models/index";
import {HouseExpandableRow} from "@components/rows/HouseExpandableRow";
import {ApiHostHousesByFiltersPostRequest, HostHousesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {
    HouseForAccommodationsSelection,
    HouseForAccommodationsSelectionBean
} from "../../../models/interfaces/HouseForAccommodationsSelectionBean";
import {HouseCompleteFactory} from "../../../models/factory/HouseCompleteFactory";
import {NoDataLabel} from "@components/NoDataLabel";
import {NavigationProps, openNewReservationDoneRoute} from "@helpers/NavigationHelper";

interface State extends LoaderState {
    reservationBean: ReservationBean,
    houses: Array<HouseForAccommodationsSelectionBean>,
    selectedHouse: House,
}

export default class NewReservationAccommodationsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.new_reservation_accommodations.title');

        let reservationBean = getReservationBeanFromNavigationProps(this) || new ReservationBean();
        if (!reservationBean.houses) reservationBean.houses = [];
        this.state = {
            loading: false,
            reservationBean: reservationBean,
            houses: [],
            selectedHouse: {},
        };
    }

    componentDidMount() {
        let self = this;
        let filters: ApiHostHousesByFiltersPostRequest = {
            houseFilterInput: {
                ids: this.state.reservationBean.houses.map(house => house.id)
            }
        };

        new HostHousesApi(this).apiHostHousesByFiltersPost(filters).then(resp => {
            let houses = resp.data || [];
            let housesWithSelectionBeans = HouseForAccommodationsSelection.fromHouseList(houses.filter(house => house.accommodationsCount > 0));
            housesWithSelectionBeans.forEach(house => {
                house.accommodationsSelectionBeans = AccommodationSelection.fromAccommodationList(house.accommodations);
            });
            Logger.log(self.constructor.name, `Found ${houses.length} houses for host.`);
            self.setState({houses: housesWithSelectionBeans, showNoData: true,});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get houses list");
            self.setState({showNoData: true,});
        });
    }

    /**
     * Update the selected house in order to expand the list
     * @param {House} house
     */
    updateSelectedHouse(house: House) {
        let selectedHouse = this.state.selectedHouse;
        if (selectedHouse.id != null && selectedHouse.id == house.id)
            this.setState({selectedHouse: {}});
        else
            this.setState({selectedHouse: house});
    }

    /**
     * Update selected accommodation
     * @param {number} key
     * @param {number} accommodationIndex
     */
    updateSelectedAccommodation(key: number, accommodationIndex: number) {
        let houses = this.state.houses;
        let house = houses[key];
        house.accommodationsSelectionBeans[accommodationIndex].selected = !house.accommodationsSelectionBeans[accommodationIndex].selected;
        houses[key] = house;
        this.setState({houses: houses});
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={[newReservationScreenStyle.container, globalScreenStyle.containerContainer]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[newReservationScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <View style={newReservationScreenStyle.scrollContainerWithBottomButton}>
                        <ScrollView
                            style={newReservationScreenStyle.container}
                            contentContainerStyle={newReservationScreenStyle.contentContainer}>
                            {this.state.houses.map((house, key) => (
                                <View
                                    key={key}
                                    style={[newReservationScreenStyle.houseContainer]}>
                                    <HouseExpandableRow house_id={house.id}
                                                        house_name={house.name}
                                                        house={house}
                                                        onPress={() => this.updateSelectedHouse(house)}
                                                        accommodationsSelectionBeans={house.accommodationsSelectionBeans}
                                                        onSelect={(accommodationIndex) => this.updateSelectedAccommodation(key, accommodationIndex)}
                                                        selected={house.id == this.state.selectedHouse.id}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.houses} show={this.state.showNoData}/>
                    </View>
                </View>
                <ConfirmButtonBottom active={dataComplete(this)}
                                     onPress={() => openNextPage(this)}
                                     title={i18n.t('buttons.new_reservation_accommodations')}/>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let bean = context.state.reservationBean;
    let houses = HouseCompleteFactory.factoryArray(3);
    let housesWithSelectionBeans = HouseForAccommodationsSelection.fromHouseList(houses.filter(house => house.accommodationsCount > 0));
    housesWithSelectionBeans.forEach(house => {
        house.accommodationsSelectionBeans = AccommodationSelection.fromAccommodationList(house.accommodations);
    });
    bean.houses = housesWithSelectionBeans;
    context.setState({reservationBean: bean, houses: housesWithSelectionBeans});
}

/**
 * Checks if at least one accommodation for every house has been selected
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    let selectedAccommodations = context.state.houses.filter(bean => bean.accommodationsSelectionBeans.filter(acc => acc.selected).length > 0);
    return selectedAccommodations != null && context.state.houses.length > 0 && selectedAccommodations.length == context.state.houses.length;
}

/**
 * Open the next page for reservation creation
 * @param {React.Component<any, State>} context
 */
function openNextPage(context: Component<any, State>) {
    if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.reservation_accommodations_data_incomplete'));
        return;
    }

    let reservationBean = context.state.reservationBean;
    reservationBean.houses = context.state.houses;
    openNewReservationDoneRoute(context, reservationBean);
}