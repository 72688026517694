/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FilterContainerBlock.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React from 'react';
import {GestureResponderEvent, ScrollView, StyleProp, TextInput, View, ViewStyle} from 'react-native';
import filterContainerBlockStyle from "@styles/host/FilterContainerBlockStyle";
import {AvenirBookPrimaryText, AvenirHeavyPrimaryText} from "./StyledText";
import {FilterImageButton} from "./FilterImageButton";
import {i18n} from "@i18n/i18n";
import {FilterRoundedTextButton} from "./FilterRoundedTextButton";
import {CheckTextButton, ReservationStateCheckTextButton, CollaborationStateCheckTextButton} from "./CheckTextButton";
import {ReservationStateEnum, CollaborationStateEnum, ResourceType} from "@services/src/models";
import {FilterHouseImageButton} from "./FilterHouseImageButton";
import {HouseSelectionBean} from "../models/beans/HouseSelection";
import {DateInputBlock} from "./DateInputBlock";

interface FilterContainerBlockProps {
    title: string,
    children?: any,
    style?: StyleProp<ViewStyle>,
}

export function FilterContainerBlock(props: FilterContainerBlockProps) {
    return (
        <View style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.container, props.style]}>
            <View
                style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.flexWrap, filterContainerBlockStyle.background]}>
                <AvenirHeavyPrimaryText>
                    {props.title}
                </AvenirHeavyPrimaryText>
            </View>
            <View style={[filterContainerBlockStyle.flexBig, filterContainerBlockStyle.background]}>
                {props.children}
            </View>
        </View>
    );
}

interface DoorHousesFilterBlockProps {
    onPress: (number) => void,
    style?: StyleProp<ViewStyle>,
    houseSelectionBeans: Array<HouseSelectionBean>,
}

export function DoorHousesFilterBlock(props: DoorHousesFilterBlockProps) {
    if (props.houseSelectionBeans == null || props.houseSelectionBeans.length <= 0)
        return null;

    return (
        <FilterContainerBlock title={i18n.t('common.houses')} style={[filterContainerBlockStyle.flex, props.style]}>
            <ScrollView horizontal={true} style={[{width: '100%', height: '100%', alignContent: 'center',}]}>
                {props.houseSelectionBeans.map((houseBean, key) => (
                    <View key={key}
                          style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.centerContent, {marginRight: 20,}]}>
                        <FilterHouseImageButton style={[filterContainerBlockStyle.centerContent, {flex: 1,}]}
                                                selectedImageUri={houseBean != null && houseBean.house != null ? houseBean.house.photoPath : null}
                                                selected={houseBean.selected}
                                                onPress={() => props.onPress(key)}/>
                    </View>
                ))}
            </ScrollView>
        </FilterContainerBlock>
    );
}

interface DoorTypeFilterBlockProps {
    selectedType: ResourceType,
    onPressResidential: (event: GestureResponderEvent) => void,
    onPressPublic: (event: GestureResponderEvent) => void,
    style?: StyleProp<ViewStyle>,
}

export function DoorTypeFilterBlock(props: DoorTypeFilterBlockProps) {
    return (
        <FilterContainerBlock title={i18n.t('common.type')} style={props.style}>
            <View style={[filterContainerBlockStyle.flex]}>
                <FilterImageButton style={{flex: 1,}} selectedImageUri={require('../assets/images/residential.png')}
                                   notSelectedImageUri={require('../assets/images/residential_color.png')}
                                   selected={props.selectedType == ResourceType.Residential}
                                   onPress={props.onPressResidential}/>
            </View>
            <View style={filterContainerBlockStyle.flex}>
                <FilterImageButton style={{flex: 1,}} selectedImageUri={require('../assets/images/public.png')}
                                   notSelectedImageUri={require('../assets/images/public_color.png')}
                                   selected={props.selectedType == ResourceType.Public} onPress={props.onPressPublic}/>
            </View>
        </FilterContainerBlock>
    );
}

interface DoorConnectionFilterBlockProps {
    online: boolean,
    onPressOnline: (event: GestureResponderEvent) => void,
    onPressOffline: (event: GestureResponderEvent) => void,
    style?: StyleProp<ViewStyle>,
}

export function DoorConnectionFilterBlock(props: DoorConnectionFilterBlockProps) {
    return (
        <FilterContainerBlock title={i18n.t('common.connection_status')} style={props.style}>
            <View style={[filterContainerBlockStyle.flex]}>
                <FilterRoundedTextButton style={{flex: 1,}}
                                         selected={props.online == true} text={i18n.t('common.online')}
                                         onPress={props.onPressOnline}/>
            </View>
            <View style={filterContainerBlockStyle.flex}>
                <FilterRoundedTextButton style={{flex: 1,}}
                                         selected={props.online == false} text={i18n.t('common.offline')}
                                         onPress={props.onPressOffline}/>
            </View>
        </FilterContainerBlock>
    );
}

interface DoorStatusFilterBlockProps {
    active: boolean,
    expiring: boolean,
    expired: boolean,
    onPressActive: (event: GestureResponderEvent) => void,
    onPressExpiring: (event: GestureResponderEvent) => void,
    onPressExpired: (event: GestureResponderEvent) => void,
}

export function DoorStatusFilterBlock(props: DoorStatusFilterBlockProps) {
    return (
        <FilterContainerBlock title={i18n.t('common.service_status')}>
            <View style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.checkTextContainer]}>
                <CheckTextButton style={{flex: 1,}}
                                 selected={props.active} title={i18n.t('common.active')} onPress={props.onPressActive}/>
            </View>
            <View style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.checkTextContainer]}>
                <CheckTextButton style={{flex: 1,}}
                                 selected={props.expiring} title={i18n.t('common.expiring')}
                                 onPress={props.onPressExpiring}/>
            </View>
            <View style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.checkTextContainer]}>
                <CheckTextButton style={{flex: 1,}}
                                 selected={props.expired} title={i18n.t('common.expired')}
                                 onPress={props.onPressExpired}/>
            </View>
        </FilterContainerBlock>
    );
}

interface ReservationStatusFilterBlockProps {
    checkedin: boolean,
    pending: boolean,
    confirmed: boolean,
    canceled: boolean,
    checkedout: boolean,
    onPressCheckedin: (event: GestureResponderEvent) => void,
    onPressPending: (event: GestureResponderEvent) => void,
    onPressConfirmed: (event: GestureResponderEvent) => void,
    onPressCanceled: (event: GestureResponderEvent) => void,
    onPressCheckedout: (event: GestureResponderEvent) => void,
}


interface CollaborationStatusFilterBlockProps {
    checkedin: boolean,
    pending: boolean,
    confirmed: boolean,
    canceled: boolean,
    checkedout: boolean,
    onPressCheckedin: (event: GestureResponderEvent) => void,
    onPressPending: (event: GestureResponderEvent) => void,
    onPressConfirmed: (event: GestureResponderEvent) => void,
    onPressCanceled: (event: GestureResponderEvent) => void,
    onPressCheckedout: (event: GestureResponderEvent) => void,
}

export function ReservationStatusFilterBlock(props: ReservationStatusFilterBlockProps) {
    return (
        <FilterContainerBlock title={i18n.t('common.status')} style={[filterContainerBlockStyle.flex]}>
            <View style={[filterContainerBlockStyle.flex, {flexDirection: 'column'}]}>
                <View style={[filterContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.reservationStatusContainer]}>
                        <ReservationStateCheckTextButton style={{flex: 1,}}
                                                         selected={props.checkedin} title={i18n.t('common.CHECKEDIN')}
                                                         onPress={props.onPressCheckedin}
                                                         state={ReservationStateEnum.Checkedin}/>
                    </View>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.reservationStatusContainer]}>
                        <ReservationStateCheckTextButton style={{flex: 1,}}
                                                         selected={props.pending} title={i18n.t('common.PENDING')}
                                                         onPress={props.onPressPending}
                                                         state={ReservationStateEnum.Pending}/>
                    </View>
                </View>
                <View style={[filterContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.reservationStatusContainer]}>
                        <ReservationStateCheckTextButton style={{flex: 1,}}
                                                         selected={props.confirmed} title={i18n.t('common.CONFIRMED')}
                                                         onPress={props.onPressConfirmed}
                                                         state={ReservationStateEnum.Confirmed}/>
                    </View>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.reservationStatusContainer]}>
                        <ReservationStateCheckTextButton style={{flex: 1,}}
                                                         selected={props.checkedout} title={i18n.t('common.CHECKEDOUT')}
                                                         onPress={props.onPressCheckedout}
                                                         state={ReservationStateEnum.Checkedout}/>
                    </View>
                </View>
            </View>
        </FilterContainerBlock>
    );
}

export function CollaborationStatusFilterBlock(props: CollaborationStatusFilterBlockProps) {
    return (
        <FilterContainerBlock title={i18n.t('common.status')} style={[filterContainerBlockStyle.flex]}>
            <View style={[filterContainerBlockStyle.flex, {flexDirection: 'column'}]}>
                <View style={[filterContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.collaborationStatusContainer]}>
                        <CollaborationStateCheckTextButton style={{flex: 1,}}
                                                         selected={props.checkedin} title={i18n.t('common.ONGOING')}
                                                         onPress={props.onPressCheckedin}
                                                         state={CollaborationStateEnum.Ongoing}/>
                    </View>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.collaborationStatusContainer]}>
                        <CollaborationStateCheckTextButton style={{flex: 1,}}
                                                         selected={props.pending} title={i18n.t('common.PENDING')}
                                                         onPress={props.onPressPending}
                                                         state={CollaborationStateEnum.Pending}/>
                    </View>
                </View>
                <View style={[filterContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.collaborationStatusContainer]}>
                        <CollaborationStateCheckTextButton style={{flex: 1,}}
                                                         selected={props.confirmed} title={i18n.t('common.CONFIRMED')}
                                                         onPress={props.onPressConfirmed}
                                                         state={CollaborationStateEnum.Confirmed}/>
                    </View>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.collaborationStatusContainer]}>
                        <CollaborationStateCheckTextButton style={{flex: 1,}}
                                                         selected={props.checkedout} title={i18n.t('common.EXPIRED')}
                                                         onPress={props.onPressCheckedout}
                                                         state={CollaborationStateEnum.Expired}/>
                    </View>
                </View>
            </View>
        </FilterContainerBlock>
    );
}

interface ReservationEmailFilterBlockProps {
    value: string,
    onChangeText: (text: string) => void,
}

interface CollaborationEmailFilterBlockProps {
    value: string,
    onChangeText: (text: string) => void,
}

export function ReservationEmailFilterBlock(props: ReservationEmailFilterBlockProps) {
    return (
        <FilterContainerBlock title={i18n.t('common.search_by_email')} style={[filterContainerBlockStyle.flex]}>
            <View style={[filterContainerBlockStyle.flex, {flexDirection: 'column'}]}>
                <View style={[filterContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.reservationStatusContainer]}>
                        <View
                            style={[filterContainerBlockStyle.textInputContainer, {flex: 1,}]}>
                            <TextInput style={filterContainerBlockStyle.textInput} onChangeText={props.onChangeText}
                                       value={props.value}/>
                        </View>
                    </View>
                </View>
            </View>
        </FilterContainerBlock>
    );
}


export function CollaborationEmailFilterBlock(props: CollaborationEmailFilterBlockProps) {
    return (
        <FilterContainerBlock title={i18n.t('common.search_by_email')} style={[filterContainerBlockStyle.flex]}>
            <View style={[filterContainerBlockStyle.flex, {flexDirection: 'column'}]}>
                <View style={[filterContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.collaborationStatusContainer]}>
                        <View
                            style={[filterContainerBlockStyle.textInputContainer, {flex: 1,}]}>
                            <TextInput style={filterContainerBlockStyle.textInput} onChangeText={props.onChangeText}
                                       value={props.value}/>
                        </View>
                    </View>
                </View>
            </View>
        </FilterContainerBlock>
    );
}

interface ReservationDateFilterBlockProps {
    value: string,
    fromDate: Date,
    toDate: Date,
    onChangeDateFrom?: (date: Date) => void,
    onChangeDateTo?: (date: Date) => void,
}

export function ReservationDateFilterBlock(props: ReservationDateFilterBlockProps) {
    return (
        <FilterContainerBlock title={i18n.t('common.sort_by')} style={[filterContainerBlockStyle.flex]}>
            <View style={[filterContainerBlockStyle.flex, {flexDirection: 'column'}]}>
                <View style={[filterContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                    <View
                        style={[filterContainerBlockStyle.flex, filterContainerBlockStyle.reservationStatusContainer, {flexDirection: 'row',}]}>
                        <View
                            style={[filterContainerBlockStyle.flex, {marginRight: 3, flexDirection: 'column',}]}>
                            <View style={[filterContainerBlockStyle.flex]}>
                                <AvenirBookPrimaryText>
                                    {i18n.t('common.from_date')}
                                </AvenirBookPrimaryText>
                            </View>
                            <View
                                style={[filterContainerBlockStyle.textInputContainer, filterContainerBlockStyle.flex]}>
                                <DateInputBlock date={props.fromDate} onChangeDate={props.onChangeDateFrom}/>
                            </View>
                        </View>
                        <View
                            style={[filterContainerBlockStyle.flex, {marginLeft: 3, flexDirection: 'column',}]}>
                            <View style={[filterContainerBlockStyle.flex]}>
                                <AvenirBookPrimaryText>
                                    {i18n.t('common.to_date')}
                                </AvenirBookPrimaryText>
                            </View>
                            <View
                                style={[filterContainerBlockStyle.textInputContainer, filterContainerBlockStyle.flex]}>
                                <DateInputBlock date={props.toDate} onChangeDate={props.onChangeDateTo}/>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </FilterContainerBlock>
    );
}