/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostReservation,
    HostReservationFromJSON,
    HostReservationFromJSONTyped,
    HostReservationToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostReservationList
 */
export interface HostReservationList {
    /**
     * 
     * @type {Array<HostReservation>}
     * @memberof HostReservationList
     */
    data?: Array<HostReservation>;
}

export function HostReservationListFromJSON(json: any): HostReservationList {
    return HostReservationListFromJSONTyped(json, false);
}

export function HostReservationListFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostReservationList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(HostReservationFromJSON)),
    };
}

export function HostReservationListToJSON(value?: HostReservationList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(HostReservationToJSON)),
    };
}

