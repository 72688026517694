/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */


import * as fakerStatic from 'faker';
import {Reservation, ReservationStateEnum} from "@services/src/models";

export class ReservationFactory {
    static factoryArray(size: number, type: ReservationStateEnum = null): Reservation[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(ReservationFactory.factory(type));
        }
        return array;
    }

    static factory(type: ReservationStateEnum = null): Reservation {
        const typeArray = [ReservationStateEnum.Checkedin, ReservationStateEnum.Pending, ReservationStateEnum.Canceled, ReservationStateEnum.Confirmed];
        if (type == null)
            type = typeArray[fakerStatic.random.number(typeArray.length - 1)];
        return {
            id: fakerStatic.random.number(4),
            startAt: fakerStatic.date.future(),
            endAt: fakerStatic.date.future(),
            registryId: fakerStatic.random.number(4),
            hostId: fakerStatic.random.number(4),
            currentState: type,
        };
    }
}

