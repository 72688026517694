/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       Colors.ts
 * @author     Christian Ascone
 * @date       10/21/19 6:25 PM
 */

// Palette from sketch
const deleteCard = '#604C8F';
const primaryColor = '#604C8F';
const titleColor = '#604C8F';
const primaryColorHalfOpacity = 'rgba(96, 76, 143, 0.50)';
const primaryColorLowOpacity = 'rgba(96, 76, 143, 0.13)';
const primaryDark = '#4D387E';
const textColor = '#1D1D26';
const topLabelColor = 'rgba(29, 29, 38, 0.5)';
const numberLabelColor = 'rgba(29, 29, 38, 0.5)';
const labelColor = '#9A9A9D';
const subtitleTextColor = '#A5A5A9';
const sectionColor = '#CBD1D5';
const captionColor = '#9B9B9B';
const background = 'rgba(96, 76, 143, 0.05)';
const filtersBackground = 'rgba(96, 76, 143, 0.03)';
const filtersBorder = 'rgba(96, 76, 143, 0.2)';
const separatorColor = 'rgba(216, 216, 216, 0.3)';
const clickableBlockBackground = '#E6E4EA';
const buttonBorder = 'rgba(96, 76, 143, 0.25)';
const greyBorder = '#E9E9EA';
const addContactBackground = '#A8A8AB';
const drawerSeparator = 'rgba(255, 255, 255, 0.07)';
const listSeparator = '#F7F6F9';
const buttonEnabledBackground = 'rgba(96, 76, 143, 1)';
const buttonDisabledBackground = 'rgba(96, 76, 143, 0.13)';
const borderPrimaryColor = '#604C8F';
const recapLine = '#CBD1D5';
const reservationRowLine = '#CBD1D5';
const collaborationRowLine = '#CBD1D5';
const eventRowLine = '#CBD1D5';
const reservationNameColor = '#717183';
const collaborationNameColor = '#717183';
const eventNameColor = '#717183';
const reservationGuestColor = '#ABABC0';
const collaborationCollaboratorColor = '#ABABC0';
const eventResourceColor = '#ABABC0';
// Status
const statusOnline = '#7ED321';
const statusOffline = '#9B9B9B';
// Service status
const serviceStatusActive = '#7ED321';
const serviceStatusExpiring = '#F5A623';
const serviceStatusExpired = '#EA4242';
const serviceStatusCheckedout = '#EA4242';
// Reservations
const reservationCheckedin = '#604C8F';
const reservationConfirmed = '#7ED321';
const reservationPending = '#F5A623';
const reservationCanceled = '#F7403A';
const reservationCheckedout = '#F7403A';
const reservationTime = '#4A4A4A';
const collaborationTime = '#4A4A4A';
//Collaborations 
const collaborationCheckedin = '#604C8F';
const collaborationConfirmed = '#7ED321';
const collaborationPending = '#F5A623';
const collaborationCanceled = '#F7403A';
const collaborationCheckedout = '#F7403A';
// Event Priority
const eventPriorityLow = '#604C8F';
const eventPriorityMedium = '#F5A623';
const eventPriorityHigh = '#F7403A';
// Availability
const available = '#7ED321';
const notAvailable = '#F7403A';

export default {
    primaryColor,
    titleColor,
    primaryColorHalfOpacity,
    primaryColorLowOpacity,
    primaryDark,
    textColor,
    topLabelColor,
    numberLabelColor,
    labelColor,
    subtitleTextColor,
    sectionColor,
    captionColor,
    background,
    filtersBackground,
    filtersBorder,
    separatorColor,
    clickableBlockBackground,
    buttonBorder,
    greyBorder,
    addContactBackground,
    drawerSeparator,
    listSeparator,
    buttonEnabledBackground,
    buttonDisabledBackground,
    borderPrimaryColor,
    recapLine,
    reservationRowLine,
    collaborationRowLine,
    eventRowLine,
    reservationNameColor,
    collaborationNameColor,
    eventNameColor,
    reservationGuestColor,
    collaborationCollaboratorColor,
    eventResourceColor,
    statusOnline,
    statusOffline,
    serviceStatusActive,
    serviceStatusExpiring,
    serviceStatusExpired,
    serviceStatusCheckedout,
    reservationCheckedin,
    reservationConfirmed,
    reservationPending,
    reservationCanceled,
    reservationCheckedout,
    collaborationCheckedin,
    collaborationConfirmed,
    collaborationPending,
    collaborationCanceled,
    collaborationCheckedout,
    eventPriorityLow,
    eventPriorityMedium,
    eventPriorityHigh,
    reservationTime,
    collaborationTime,
    deleteCard,
    available,
    notAvailable,
    tintColor: '#2f95dc',
    tabIconDefault: '#ccc',
    tabIconSelected: primaryColor,
    tabBar: '#fefefe',
    errorBackground: 'red',
    errorText: '#fff',
    warningBackground: '#EAEB5E',
    warningText: '#666804',
    noticeBackground: primaryColor,
    noticeText: '#fff',
};
