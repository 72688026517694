/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DashboardSegment.tsx
 * @author     Christian Ascone
 * @date       10/31/19 1:04 PM
 */

import React from 'react';
import {GestureResponderEvent, Text, TouchableOpacity, View} from 'react-native';
import {i18n} from "@i18n/i18n";
import segmentStyle from "@styles/SegmentStyle";
import {
    CREDITS_DASHBOARD_SEGMENT_TAB,
    EVENTS_DASHBOARD_SEGMENT_TAB,
    HOUSES_DASHBOARD_SEGMENT_TAB,
    RESERVATIONS_DASHBOARD_SEGMENT_TAB
} from "@helpers/SegmentHelper";

interface DashboardSegmentProps {
    activeTab: number,
    onPressFirst: (event: GestureResponderEvent) => void,
    onPressSecond: (event: GestureResponderEvent) => void,
    onPressThird: (event: GestureResponderEvent) => void,
}

export function DashboardSegment(props: DashboardSegmentProps) {
    let activeTab = props.activeTab;
    let first = false;
    let second = false;
    let third = false;

    switch (activeTab) {
        case EVENTS_DASHBOARD_SEGMENT_TAB:
            first = true;
            break;
        case HOUSES_DASHBOARD_SEGMENT_TAB:
            second = true;
            break;
        case CREDITS_DASHBOARD_SEGMENT_TAB:
        case RESERVATIONS_DASHBOARD_SEGMENT_TAB:
            third = true;
            break;
    }

    return (
        <View style={{flexDirection: 'row', marginTop: 10,}}>
            <TouchableOpacity onPress={props.onPressFirst} {...props}
                              style={(first ? segmentStyle.activeTab : segmentStyle.inactiveTab)}>
                <Text
                    style={[segmentStyle.text, (first ? segmentStyle.activeText : segmentStyle.inactiveText)]}>{i18n.t('screens.dashboard.tabs.events')}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={props.onPressSecond} {...props}
                              style={(third ? segmentStyle.activeTab : segmentStyle.inactiveTab)}>
                <Text
                    style={[segmentStyle.text, (third ? segmentStyle.activeText : segmentStyle.inactiveText)]}>{i18n.t('screens.dashboard.tabs.reservations')}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={props.onPressThird} {...props}
                              style={(second ? segmentStyle.activeTab : segmentStyle.inactiveTab)}>
                <Text
                    style={[segmentStyle.text, (second ? segmentStyle.activeText : segmentStyle.inactiveText)]}>{i18n.t('screens.dashboard.tabs.houses')}</Text>
            </TouchableOpacity>
        </View>
    );
}