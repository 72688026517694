/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewReservationCollaboratorScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import newCollaborationCollaboratorScreenStyle from "@styles/host/collaborations/NewCollaborationCollaboratorScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {TextInputBlock} from "@components/TextInputBlock";
import {NavigationProps, openNewCollaborationPeriodRoute} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {getCollaboratorFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import {Collaborator} from "@services/src/models/index";
import {DebugButton} from "@components/DebugButton";
import {CollaboratorFactory} from "../../../models/factory/CollaboratorFactory";
import {HostCollaboratorsApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";

interface State extends LoaderState {
    id: number,
    first_name: string,
    last_name: string,
    mail: string,
    mobile: string,
    originalMobile: string,
    originalFirst_name: string,
    originalLast_name: string,
}



export default class NewCollaborationCollaboratorScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.new_collaboration_collaborator.title');
        let collaborator = getCollaboratorFromNavigationProps(this) || {};
        this.state = {
            id: collaborator.id,
            loading: false,
            first_name: collaborator.firstName || '',
            last_name: collaborator.lastName || '',
            mail: collaborator.email || '',
            mobile: collaborator.mobile || '',
            originalMobile: collaborator.mobile || '',
            originalLast_name: collaborator.lastName || '',
            originalFirst_name: collaborator.firstName || '',
        };

    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={newCollaborationCollaboratorScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <View>
                    {/* TODO: Wizard */}
                </View>
                <Loader state={this.state}/>
                <ScrollView
                    style={[newCollaborationCollaboratorScreenStyle.scrollContainer, globalScreenStyle.globalMargins]}
                    contentContainerStyle={newCollaborationCollaboratorScreenStyle.contentContainer}>
                    <View
                        style={[newCollaborationCollaboratorScreenStyle.getStartedContainer, newCollaborationCollaboratorScreenStyle.scrollContainer]}>
                        <View
                            style={newCollaborationCollaboratorScreenStyle.inputContainer}>

                            {/* HIDDEN Selector*/}
                            {/*<View style={newDoorScreenStyle.serviceSelectionContainer}>*/}
                            {/*<CollaboratorClientSelectionBlock*/}
                            {/*onPress={() => this.setState({selectedType: CollaboratorType.Client})}*/}
                            {/*style={[newDoorScreenStyle.serviceBlock, newDoorScreenStyle.clientBlock]}*/}
                            {/*active={clientSelected(this)}/>*/}
                            {/*<CollaboratorCollaboratorSelectionBlock*/}
                            {/*onPress={() => this.setState({selectedType: CollaboratorType.Collaborator})}*/}
                            {/*style={[newDoorScreenStyle.serviceBlock, newDoorScreenStyle.collaboratorBlock]}*/}
                            {/*active={collaboratorSelected(this)}/>*/}
                            {/*</View>*/}

                            <TextInputBlock label={i18n.t('screens.new_collaboration_collaborator.first_name')}
                                            onChangeText={(text) => this.setState({first_name: text})}
                                            text={this.state.first_name}/>
                            <TextInputBlock label={i18n.t('screens.new_collaboration_collaborator.last_name')}
                                            onChangeText={(text) => this.setState({last_name: text})}
                                            text={this.state.last_name}/>
                            <TextInputBlock label={i18n.t('screens.new_collaboration_collaborator.mail')}
                                            keyboardType='email-address'
                                            onChangeText={(text) => this.setState({mail: text})}
                                            text={this.state.mail} editable={!this.state.id || this.state.id == 0}/>
                            <TextInputBlock label={i18n.t('screens.new_collaboration_collaborator.mobile')}
                                            keyboardType={'phone-pad'}
                                            onChangeText={(text) => this.setState({mobile: text})}
                                            text={this.state.mobile} editable={/*!this.state.id || this.state.id == 0*/true}/>
                        </View>
                    </View>
                </ScrollView>
                <ConfirmButtonBottom active={dataComplete(this)} onPress={() => openNextPage(this)}
                                     title={this.dataDidChange() ? i18n.t('buttons.new_collaboration_collaborator_to_save'): i18n.t('buttons.new_collaboration_collaborator')}
                                     style={{}}/>
            </KeyboardAvoidingView>
        );
    }

    dataDidChange(){
        let mobileChanged = this.state.mobile != this.state.originalMobile;
        let nameChanged = this.state.first_name != this.state.originalFirst_name ;
        let surnameChanged = this.state.last_name != this.state.originalLast_name;
        console.log('OriginalFN: ', this.state.originalFirst_name);
        console.log('NewFN: ', this.state.originalFirst_name);
        console.log('OriginalLN: ', this.state.originalLast_name);
        console.log('NewLN: ', this.state.originalLast_name);
        return (mobileChanged || nameChanged || surnameChanged);
    }
}


/**
 * Checks if data is valid
 * @param {string} data
 * @returns {boolean}
 */
function dataIsValid(data: string) {
    return data != null && data != '';
}

/**
 * Checks if data is complete
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataComplete(context: Component<any, State>) {
    return dataIsValid(context.state.first_name) && dataIsValid(context.state.last_name) && dataIsValid(context.state.mail) && dataIsValid(context.state.mobile);
}

function debugUpdateData(context: Component<any, State>) {
    let fakeCollaborator = CollaboratorFactory.factory();
    context.setState({
        first_name: fakeCollaborator.firstName,
        last_name: fakeCollaborator.lastName,

        mail: fakeCollaborator.email,
        mobile: fakeCollaborator.mobile,
    });

}

/**
 * Open the next page if password is complete
 * @param context
 */
function openNextPage(context: Component<any, State>) {
    if (!dataComplete(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.collaboration_data_incomplete'));
        return;
    }
    let collaborator: Collaborator = {
        id: context.state.id,
        firstName: context.state.first_name,
        lastName: context.state.last_name,
        email: context.state.mail,
        mobile: context.state.mobile,
    };
    if (context.state.id && context.state.id != 0) {
        updateCollaborator(context, collaborator);
    } else {
        createCollaborator(context, collaborator);
    }
}

/**
 * Updates the existing Collaborator
 * @param {React.Component<any, State>} context
 * @param {Collaborator} collaborator
 */
function updateCollaborator(context: React.Component<any, State>, collaborator: Collaborator) {
    new HostCollaboratorsApi(context).apiHostCollaboratorsIdPut({
        id: context.state.id,
        collaborator: collaborator
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        collaborator.id = context.state.id;
        openNewCollaborationPeriodRoute(context, collaborator);
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    })
}

/**
 * Creates a new Collaborator
 * @param {React.Component<any, State>} context
 * @param {Collaborator} collaborator
 */
function createCollaborator(context: React.Component<any, State>, collaborator: Collaborator) {
    new HostCollaboratorsApi(context).apiHostCollaboratorsPost({
        collaborator: collaborator
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        Logger.log(context.constructor.name, resp);
        openNewCollaborationPeriodRoute(context, resp);
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    })
}