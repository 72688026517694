/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CheckEmptyImage.tsx
 * @author     Christian Ascone
 * @date       9/26/19 10:20 AM
 */

import React from 'react';
import {Image} from "react-native";

export function CheckEmptyImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                require('../../assets/images/check_empty.png')
            }
        />
    );
}