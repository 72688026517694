/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       BfreeRecapImage.tsx
 * @author     Christian Ascone
 * @date       10/22/19 12:19 PM
 */

import React from 'react';
import {Image} from "react-native";

export function BfreeRecapImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                require('../../assets/images/bfree_recap.png')
            }
        />
    );
}