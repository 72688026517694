
export const environment = {
    debugRoom: false,
    production: false,
    apiUrl: 'https://api.bfree.host',
    webGetCreditsUrl: 'https://app.bfree.host/#/Dashboard/GetCreditsScreen/Home',
    oauthClientId: '2',
    oauthClientSecret: 'UNfFGVNnLzHaTNLIo2RrWjUmiYJASrVg4MvuuH0p',
    sentryDsn: 'https://9feecf4a8cac43908556f277637ad678@sentry.io/1793715',
    sentryEnableDevelopment: true,
    googleApiKey: 'AIzaSyBlxo9EQNAc3xlfrwKCt5XqwPpWkv3aIV0',
    stripePublicKey: 'pk_test_dSyhnd8Tn2hzgCvUHyROskXQ',
    stripePrivateKey: '',
    disableLoader: false,
};