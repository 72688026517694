/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResourceJoinType,
    ResourceJoinTypeFromJSON,
    ResourceJoinTypeFromJSONTyped,
    ResourceJoinTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResourceJoin
 */
export interface ResourceJoin {
    /**
     * 
     * @type {number}
     * @memberof ResourceJoin
     */
    id?: number;
    /**
     * 
     * @type {ResourceJoinType}
     * @memberof ResourceJoin
     */
    type?: ResourceJoinType;
    /**
     * 
     * @type {number}
     * @memberof ResourceJoin
     */
    resourcePrimaryId?: number;
    /**
     * 
     * @type {number}
     * @memberof ResourceJoin
     */
    resourceSecondaryId?: number;
}

export function ResourceJoinFromJSON(json: any): ResourceJoin {
    return ResourceJoinFromJSONTyped(json, false);
}

export function ResourceJoinFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceJoin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ResourceJoinTypeFromJSON(json['type']),
        'resourcePrimaryId': !exists(json, 'resource_primary_id') ? undefined : json['resource_primary_id'],
        'resourceSecondaryId': !exists(json, 'resource_secondary_id') ? undefined : json['resource_secondary_id'],
    };
}

export function ResourceJoinToJSON(value?: ResourceJoin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ResourceJoinTypeToJSON(value.type),
        'resource_primary_id': value.resourcePrimaryId,
        'resource_secondary_id': value.resourceSecondaryId,
    };
}

