/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       LoginScreenStyle.ts
 * @author     Christian Ascone
 * @date       2/25/20 1:50 PM
 */

import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    forgotPassword: {
        textAlign: 'right',
    },
    contentContainer: {
        justifyContent: 'space-between',
        paddingTop: 30,
    },
    welcomeContainer: {
        flex: 1,
        marginTop: 10,
        marginBottom: 50,
    },
    inputContainer: {
        flex: 2,
        marginTop: 10,
        marginBottom: 20,
    },
    buttonContainer: {
        flex: 2,
        marginTop: 10,
        marginBottom: 20,
    },
    getStartedContainer: {
        height: '100%',
    },
});

export default styles;