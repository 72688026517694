/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    House,
    HouseFromJSON,
    HouseToJSON,
    HouseDetail,
    HouseDetailFromJSON,
    HouseDetailToJSON,
} from '../models';

export interface ApiHousesIdDeleteRequest {
    id: number;
}

export interface ApiHousesIdGetRequest {
    id: string;
}

export interface ApiHousesIdPutRequest {
    id: number;
    house: House;
}

export interface ApiHousesPostRequest {
    house: House;
}

/**
 * 
 */
export class HousesApi extends runtime.BaseAPI {

    /**
     */
    async apiHousesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<House>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/houses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HouseFromJSON));
    }

    /**
     */
    async apiHousesGet(initOverrides?: RequestInit): Promise<Array<House>> {
        const response = await this.apiHousesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHousesIdDeleteRaw(requestParameters: ApiHousesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHousesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/houses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHousesIdDelete(requestParameters: ApiHousesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHousesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHousesIdGetRaw(requestParameters: ApiHousesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HouseDetail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHousesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/houses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HouseDetailFromJSON(jsonValue));
    }

    /**
     */
    async apiHousesIdGet(requestParameters: ApiHousesIdGetRequest, initOverrides?: RequestInit): Promise<HouseDetail> {
        const response = await this.apiHousesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHousesIdPutRaw(requestParameters: ApiHousesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHousesIdPut.');
        }

        if (requestParameters.house === null || requestParameters.house === undefined) {
            throw new runtime.RequiredError('house','Required parameter requestParameters.house was null or undefined when calling apiHousesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/houses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HouseToJSON(requestParameters.house),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHousesIdPut(requestParameters: ApiHousesIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHousesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHousesPostRaw(requestParameters: ApiHousesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<House>> {
        if (requestParameters.house === null || requestParameters.house === undefined) {
            throw new runtime.RequiredError('house','Required parameter requestParameters.house was null or undefined when calling apiHousesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/houses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HouseToJSON(requestParameters.house),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HouseFromJSON(jsonValue));
    }

    /**
     */
    async apiHousesPost(requestParameters: ApiHousesPostRequest, initOverrides?: RequestInit): Promise<House> {
        const response = await this.apiHousesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
