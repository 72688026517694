/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       Fonts.ts
 * @author     Christian Ascone
 * @date       9/11/19 5:43 PM
 */

const spaceMono = 'space-mono';
const avenirBook = 'avenir-book';
const avenirHeavy = 'avenir-heavy';
const avenirLight = 'avenir-light';
const avenirMedium = 'avenir-medium';

export const names = {
    spaceMono,
    avenirBook,
    avenirHeavy,
    avenirLight,
    avenirMedium,
};

const spaceMonoFile = 'SpaceMono-Regular.ttf';
const avenirLTStdBook = 'AvenirLTStd-Book.otf';
const avenirLTStdHeavy = 'AvenirLTStd-Heavy.otf';
const avenirLTStdLight = 'AvenirLTStd-Light.otf';
const avenirLTStdMedium = 'AvenirLTStd-Medium.otf';

export const files = {
    spaceMonoFile,
    avenirLTStdBook,
    avenirLTStdHeavy,
    avenirLTStdLight,
    avenirLTStdMedium,
};