/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationStateText.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import React, {Component} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {AvenirHeavyPrimaryText, AvenirHeavyText} from "./StyledText";
import {CollaborationStateEnum, ReservationStateEnum} from "@services/src/models";
import Colors from "../config/Colors";
import {i18n} from "@i18n/i18n";

interface ReservationStateTextProps {
    state: ReservationStateEnum | CollaborationStateEnum,
    style?: StyleProp<ViewStyle>,
}

export class ReservationStateText extends Component<ReservationStateTextProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        let textBlock = null;
        switch (this.props.state) {
            case ReservationStateEnum.Pending || CollaborationStateEnum.Pending:
                textBlock = (<AvenirHeavyText
                    style={[{
                        fontSize: 12,
                        color: Colors.reservationPending
                    },]}>{i18n.t('common.PENDING')}</AvenirHeavyText>);
                break;
            case ReservationStateEnum.Checkedin || CollaborationStateEnum.Ongoing:
                textBlock = (<AvenirHeavyPrimaryText
                    style={[{fontSize: 12,}]}>{i18n.t('common.CHECKEDIN')}</AvenirHeavyPrimaryText>);
                break;
            case ReservationStateEnum.Confirmed || CollaborationStateEnum.Confirmed:
                textBlock = (<AvenirHeavyText
                    style={[{
                        fontSize: 12,
                        color: Colors.reservationConfirmed
                    },]}>{i18n.t('common.CONFIRMED')}</AvenirHeavyText>);
                break;
            case ReservationStateEnum.Canceled || CollaborationStateEnum.Canceled:
                textBlock = (<AvenirHeavyText
                    style={[{
                        fontSize: 12,
                        color: Colors.reservationCanceled
                    },]}>{i18n.t('common.CANCELED')}</AvenirHeavyText>);
                break;
            case ReservationStateEnum.Checkedout || CollaborationStateEnum.Expired:
                textBlock = (<AvenirHeavyText
                    style={[{
                        fontSize: 12,
                        color: Colors.reservationCheckedout
                    },]}>{i18n.t('common.CHECKEDOUT')}</AvenirHeavyText>);
                break;
        }

        return (
            <View>
                {textBlock}
            </View>
        );
    }
}