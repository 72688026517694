/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HostCollaborationFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */



import {HouseFactory} from "./HouseFactory";
import {CollaborationFactory} from "./CollaborationFactory";
import {RegistryFactory} from "./RegistryFactory";
import {CollaborationStateEnum, HostCollaboration, HostReservation, ReservationStateEnum} from "@services/src";
import {ReservationFactory} from "./ReservationFactory";
import {CollaboratorFactory} from "./CollaboratorFactory";

export class HostCollaborationFactory {
    static factoryArray(size: number, type: CollaborationStateEnum = null): HostCollaboration[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(HostCollaborationFactory.factory());
        }
        return array;
    }

    static factory(type: CollaborationStateEnum = null): HostCollaboration {
        return {
            id: CollaborationFactory.factory().id,
            startAt: CollaborationFactory.factory().startAt,
            endAt: CollaborationFactory.factory().endAt,
            collaborator: CollaboratorFactory.factory(),
            house: HouseFactory.factory(),
            state: CollaborationFactory.factory().state,
        };
    }
}

