/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostReservationHouseInput,
    HostReservationHouseInputFromJSON,
    HostReservationHouseInputFromJSONTyped,
    HostReservationHouseInputToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostReservationInput
 */
export interface HostReservationInput {
    /**
     * 
     * @type {Date}
     * @memberof HostReservationInput
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostReservationInput
     */
    fromTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostReservationInput
     */
    toDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostReservationInput
     */
    toTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof HostReservationInput
     */
    guestId?: number;
    /**
     * List of house data with accommodations
     * @type {Array<HostReservationHouseInput>}
     * @memberof HostReservationInput
     */
    houses?: Array<HostReservationHouseInput>;
}

export function HostReservationInputFromJSON(json: any): HostReservationInput {
    return HostReservationInputFromJSONTyped(json, false);
}

export function HostReservationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostReservationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromDate': !exists(json, 'from_date') ? undefined : (new Date(json['from_date'])),
        'fromTime': !exists(json, 'from_time') ? undefined : (new Date(json['from_time'])),
        'toDate': !exists(json, 'to_date') ? undefined : (new Date(json['to_date'])),
        'toTime': !exists(json, 'to_time') ? undefined : (new Date(json['to_time'])),
        'guestId': !exists(json, 'guest_id') ? undefined : json['guest_id'],
        'houses': !exists(json, 'houses') ? undefined : ((json['houses'] as Array<any>).map(HostReservationHouseInputFromJSON)),
    };
}

export function HostReservationInputToJSON(value?: HostReservationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from_date': value.fromDate === undefined ? undefined : (value.fromDate.toISOString().substr(0,10)),
        'from_time': value.fromTime === undefined ? undefined : (value.fromTime.toISOString()),
        'to_date': value.toDate === undefined ? undefined : (value.toDate.toISOString().substr(0,10)),
        'to_time': value.toTime === undefined ? undefined : (value.toTime.toISOString()),
        'guest_id': value.guestId,
        'houses': value.houses === undefined ? undefined : ((value.houses as Array<any>).map(HostReservationHouseInputToJSON)),
    };
}

