/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       LogoBFreeCreditImage.tsx
 * @author     Christian Ascone
 * @date       12/27/19 12:52 PM
 */

import React from 'react';
import {Image} from "react-native";
import {imageUri} from "../../models/ImageUri";

export function LogoBFreeCreditImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                imageUri.logo_bfree_credit.uri
            }
        />
    );
}