/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SplashLogo.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {View} from 'react-native';
import {Header} from "react-navigation";
import Layout from "../config/Layout";
import mainScreenStyle from "@styles/MainScreenStyle";
import {LogoBFreeImage} from "@components/Images/LogoBFreeImage";

export function SplashLogo(props) {
    return (
        <View style={{
            alignSelf: 'center',
            alignItems: 'center',
            alignContent: 'center',
            height: Layout.window.height - Header.HEIGHT,
            width: Layout.window.width,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            flexDirection: 'column',
            flex: 1,
        }}>

            <LogoBFreeImage style={[mainScreenStyle.welcomeImage, {
                width: 150,
                height: 50,
                resizeMode: 'contain',
                marginTop: 3, flex: 1, alignSelf: 'center',
            }]}/>

        </View>
    );
}