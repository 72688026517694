/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       BarcodeScannerScreen.tsx
 * @author     Christian Ascone
 * @date       2/26/20 6:07 PM
 */

import React, {Component} from 'react';
import {Button, StyleSheet, Text, View} from 'react-native';
import {BarCodeScanner} from 'expo-barcode-scanner';
import {getDoorBarcodeDismissCallbackFromNavigationProps} from "@helpers/NavigationDataHelper";
import {goBack, NavigationProps} from "@helpers/NavigationHelper";
import {LoaderState} from "@helpers/LoaderHelper";
import {Logger} from "@helpers/Logger";
import {PermissionManager} from "../modules/PermissionManager";
import {DeviceScanHelper} from "@helpers/DeviceScanHelper";
import {BfreeX1} from "@helpers/BfreeX1";

interface State extends LoaderState {
    granted: boolean,
    scanned: boolean,
    onDismiss: (data, type, enc_code) => void,
    type: string,
    data: string,
    enc_code: string,
}

export default class BarcodeScannerScreen extends Component<NavigationProps, State> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            granted: null,
            scanned: false,
            onDismiss: getDoorBarcodeDismissCallbackFromNavigationProps(this) || null,
            type: null,
            data: null,
            enc_code: null,
        };
    }

    componentDidMount() {
        this.checkPermissions();
    }

    componentWillUnmount() {
        this.state.onDismiss(this.state.type, this.state.data, this.state.enc_code);
    }

    handleBarCodeScanned(data) {
        const scanned = DeviceScanHelper.extractImeiFromCode(data.data);
        if (scanned[1] == "IDX:")
            var imei = BfreeX1.decode(scanned[0].toString());
        else
            var imei = scanned[0];
        this.setState({scanned: true, type: data.type, data: imei, enc_code: scanned[0]});
        Logger.log(this.constructor.name, data);
        goBack(this);
    };

    render() {
        if (this.state.granted === false) {
            return <Text>No access to camera</Text>;
        }

        return (
            <View
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                }}>
                <BarCodeScanner
                    onBarCodeScanned={(data) => this.state.scanned ? undefined : this.handleBarCodeScanned(data)}
                    style={StyleSheet.absoluteFillObject}
                />

                {this.state.scanned &&
                <Button title={'Tap to Scan Again'} onPress={() => this.setState({scanned: false})}/>}
            </View>
        );
    }

    private async checkPermissions() {
        const status = await PermissionManager.checkCameraPermission();
        this.setState({granted: status});
    }
}