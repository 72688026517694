/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       StatusDotView.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import statusDotStyles from "@styles/StatusDotViewStyle";
import {i18n} from "@i18n/i18n";
import {AvenirLightGreyText} from "./StyledText";
import {ResourceStateEnum} from "@services/src/models";

interface StatusDotProps {
    enabled: boolean,
    style: StyleProp<ViewStyle>,
}

interface ActivationProps {
    status: boolean,
    style: StyleProp<ViewStyle>,
}

/**
 * A custom view with a dot for online/offline status and text
 * @param {StatusDotProps} props
 * @returns {any}
 * @constructor
 */
export function StatusConnectionDotView(props: StatusDotProps) {
    if (!props.enabled)
        return (
            <View style={[statusDotStyles.container, props.style]}>
                <View style={[statusDotStyles.dot, statusDotStyles.online]}/>
                <AvenirLightGreyText numberOfLines={1}
                                     style={statusDotStyles.statusText}>{i18n.t('common.free')}</AvenirLightGreyText>
            </View>
        );
    else
        return (
            <View style={[statusDotStyles.container, props.style]}>
                <View style={[statusDotStyles.dot, statusDotStyles.offline]}/>
                <AvenirLightGreyText numberOfLines={1}
                                     style={statusDotStyles.statusText}>{i18n.t('common.inuse')}</AvenirLightGreyText>
            </View>
        );
}
