/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CollaborationDetailScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 11:00 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import collaborationDetailScreenStyle from "@styles/host/collaborations/CollaborationDetailScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {goBack, NavigationProps} from "@helpers/NavigationHelper";
import {ConfirmButton, ConfirmGreenButton} from "@components/ConfirmButton";
import {
    getCollaborationFromNavigationProps,
    getGenericDismissCallbackFromNavigationProps,
    getUserFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {DebugButton} from "@components/DebugButton";
import {HouseCompleteFactory} from "../../../models/factory/HouseCompleteFactory";
import {HostCollaborationsApi, HostCollaboratorsApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {
    CollaborationStateEnum,
    HostCollaborationAccommodationJoin,
    HostCollaborationDetail,
    House,
    ReservationStateEnum,
    User,
    VerificationCodeType
} from "@services/src/models/index";
import {
    CollaborationAccommodationBlock,
    CollaborationUserDataBlock
} from "@components/details/CollaborationDetailsContainerBlock";
import {CollaborationFactory} from "../../../models/factory/CollaborationFactory";
import {AccommodationFactory} from "../../../models/factory/AccommodationFactory";
import {UserFactory} from "../../../models/factory/UserFactory";
import {ReservationStateText as CollaborationStateText} from "@components/ReservationStateText";
import {AvenirBookUpperInlineText, AvenirHeavyTitleText} from "@components/StyledText";
import {AlertHelper} from "@helpers/AlertHelper";
import {DeleteButton} from "@components/DeleteButton";
import {AccommodationSelectionBean} from "../../../models/beans/AccommodationSelection";
import {ReservationPeriodBlock} from "@components/details/ReservationDetailsContainerBlock";
import {CardRow} from "@components/rows/CardRow";

interface State extends LoaderState {
    collaboration: HostCollaborationDetail,
    originalCollaboration: HostCollaborationDetail,
    onDismiss: () => void,
    enableResendCodes: boolean,
    user: User,
}

export default class CollaborationDetailScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.collaboration_detail.title');

        this.state = {
            loading: false,
            collaboration: {},
            originalCollaboration: {},
            onDismiss: getGenericDismissCallbackFromNavigationProps(this),
            enableResendCodes: true,
            user: getUserFromNavigationProps(this),
        };

    }

    getCollaboration(){
        new HostCollaborationsApi(this).apiHostCollaborationsIdGet({id: getCollaborationFromNavigationProps(this).id + ''}).then(resp => {
            Logger.log(self.constructor.name, 'Ok');
            console.log(resp);
            this.setState({
                collaboration: {...resp},
                originalCollaboration: {...resp}
            });

        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + JSON.stringify(error));
        });
    }

    componentDidMount() {

        this.getCollaboration();
        console.log('Collaboration: ' + this.state.collaboration);
        console.log('User: ' + this.state.user);
        console.log('Collaboration User: ' + this.state.collaboration.user);
    }

    /**
     * Checks if guest user is verified
     * @returns {boolean}
     */
    guestIsVerified() {
        let user = this.state.collaboration.user;
        if (user == null) return false;
        return user.emailVerifiedAt != null && user.phoneVerifiedAt != null;
    }

    resendEmailCode() {
        let type = VerificationCodeType.Email;
        if (this.state.collaboration.user.emailVerifiedAt != null) return;
        let self = this;

        AlertHelper.showYesNoAlert(i18n.t('alerts.attention'), i18n.t('screens.collaboration_detail.alert_resend_message'), function (confirmed) {
            if (confirmed) {
                self.sendVerificationCode(type);
            }
        });
    }

    resendPhoneCode() {
        let type = VerificationCodeType.Phone;
        if (this.state.collaboration.user.phoneVerifiedAt != null) return;
        let self = this;

        AlertHelper.showYesNoAlert(i18n.t('alerts.attention'), i18n.t('screens.collaboration_detail.alert_resend_message'), function (confirmed) {
            if (confirmed) {
                self.sendVerificationCode(type);
            }
        });
    }

    /**
     * Update selected
     * @param {House} house
     * @param {Array<AccommodationSelectionBean>} accommodations
     */
    updateSelectedAccommodations(house: House, accommodations: Array<AccommodationSelectionBean>) {
        // TODO
        console.log(house);
        console.log(accommodations);
    }

    /**
     * Builds the bottom button
     * @returns {any}
     */
    buildBottomButton() {

        if (this.dataDidChange()  && this.state.collaboration.currentState != CollaborationStateEnum.Expired && this.state.collaboration.currentState != CollaborationStateEnum.Canceled ){
            console.log("DATA DID CHANGE")
            return (<ConfirmButton active={this.state.enableResendCodes} onPress={() => this.updateCollaboration(this)}
                                   title={i18n.t('buttons.signup')}
                                   style={{marginBottom: 20}}/>);
        }

        if (!this.guestIsVerified())
            return (<ConfirmButton active={false} onPress={null}
                                   title={i18n.t('screens.collaboration_detail.wait_button')}
                                   style={{marginBottom: 20}}/>);
        else if (this.guestIsVerified() && this.state.collaboration.currentState == CollaborationStateEnum.Pending)
            return (<ConfirmGreenButton active={true} onPress={() => updateState(this, CollaborationStateEnum.Confirmed)}
                                        title={i18n.t('screens.collaboration_detail.confirm_button')}
                                        style={{marginBottom: 20}}/>);
        else if (this.guestIsVerified() && this.state.collaboration.currentState == CollaborationStateEnum.Confirmed)
            return (<ConfirmButton active={true} onPress={() => updateState(this, CollaborationStateEnum.Ongoing)}
                                   title={i18n.t('screens.collaboration_detail.checkin_button')}
                                   style={{marginBottom: 20}}/>);
        else if (this.state.collaboration.currentState == CollaborationStateEnum.Ongoing)
            return (<ConfirmButton active={this.state.enableResendCodes} onPress={() => resendCodes(this)}
                                   title={i18n.t('screens.collaboration_detail.resend_codes_button')}
                                   style={{marginBottom: 20}}/>);
    }

    /////////////////////////////// New Section ////////////////////////////////////////////////

    onDateChange(date, type){
        let collaboration = this.state.collaboration;
        if (type == 'from') {
            let time = collaboration.startAt.toTimeString();
            date = date.toDateString();
            let datetime = date+ ' ' + time;

            collaboration.startAt = new Date(datetime);
        }
        if (type == 'to'){
            let time = collaboration.endAt.toTimeString();
            date = date.toDateString();
            let datetime = date+ ' ' + time;

            collaboration.endAt = new Date(datetime);
        }
        console.log(collaboration.endAt, collaboration.startAt);
        this.setState({collaboration: collaboration});
    }

    dataDidChange(){

        let collaborationChanged = this.state.collaboration.startAt === this.state.originalCollaboration.startAt && this.state.collaboration.endAt === this.state.originalCollaboration.endAt;
        console.log('DataChanged: ',collaborationChanged);
        return !(collaborationChanged);
    }

    onTimeChange(time, type){
        let collaboration = this.state.collaboration;
        if (type == 'from') {
            let date = collaboration.startAt.toDateString();
            time = time.toTimeString();
            let datetime = date+ ' ' + time;

            collaboration.startAt = new Date(datetime);
        }
        if (type == 'to'){
            let date = collaboration.endAt.toDateString();
            time = time.toTimeString();
            let datetime = date+ ' ' + time;

            collaboration.endAt = new Date(datetime);
        }
        console.log(collaboration.endAt, collaboration.startAt);
        this.setState({collaboration: collaboration});
    }

    updateCollaboration(context){
        let collaboration = context.state.collaboration;
        collaboration.user = {};
        new HostCollaborationsApi(context).apiHostCollaborationsUpdatePost({hostCollaborationDetail: collaboration}).then((resp)=>{
            console.log('save pressed');
            context.getCollaboration();
        }).catch((error)=>{
            console.log('error in save');
            console.log(JSON.stringify(error));
        })
    }


    ////////////////////////////////////////////////////////////////////////////////////////////


    render() {
        //if (this.state.collaboration == null || this.state.user == null)
          //  return null;
        return (
            <KeyboardAvoidingView behavior="padding" style={collaborationDetailScreenStyle.container}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View
                    style={[collaborationDetailScreenStyle.container, globalScreenStyle.globalMargins]}
                >
                    <View style={{
                        flexDirection: 'row',
                        marginTop: 5,
                        alignItems: 'flex-end',
                        alignContent: 'flex-end',
                        justifyContent: 'flex-end'
                    }}>
                        <View style={{flex: 0.1, alignSelf: 'flex-end'}}>

                            <DeleteButton onPress={() => deleteCollaboration(this)}/>

                        </View>
                    </View>
                    <View
                        style={[collaborationDetailScreenStyle.getStartedContainer, collaborationDetailScreenStyle.container]}>
                        <View style={collaborationDetailScreenStyle.installationContainer}>
                            <ScrollView
                                style={[collaborationDetailScreenStyle.container, collaborationDetailScreenStyle.scroll]}
                                contentContainerStyle={collaborationDetailScreenStyle.contentContainer}
                                refreshControl={<RefreshControl onRefresh={() => this.getCollaboration()} refreshing={false}/>}>
                                <View>
                                    {/* TODO: Enable editing only for pending state */}
                                    <ReservationPeriodBlock from_datetime={this.state.collaboration.startAt}
                                                            to_datetime={this.state.collaboration.endAt}
                                                            style={{marginVertical: 5,}}
                                                            onDateChange={(date, type) => this.onDateChange(date, type)}
                                                            onTimeChange={(date, type)=> this.onTimeChange(date, type)}/>
                                    <CollaborationAccommodationBlock
                                        hostCollaborationAccommodationJoins={this.state.collaboration.collaborationAccommodationJoins}
                                        style={{marginVertical: 5,}} context={this}
                                        onPressEnabled={false}
                                        collaboration={this.state.collaboration}
                                        unsent={this.state.collaboration.unsent}
                                        resendCodes={ () => resendCodesToDevices(this)}
                                        onChange={(house, accommodations) => this.updateSelectedAccommodations(house, accommodations)}/>
                                    <CollaborationUserDataBlock collaborator={this.state.collaboration.collaborator}
                                                              user={this.state.user || this.state.collaboration.user}
                                                              style={{marginVertical: 5,}}
                                                              emailStatePress={() => this.resendEmailCode()}
                                                              phoneStatePress={() => this.resendPhoneCode()}/>
                                </View>
                                {this.state.collaboration.cards != null && this.state.collaboration.cards.length > 0 &&
                                    <View style={{marginVertical: '5%'}}>
                                        <AvenirHeavyTitleText
                                        style={{
                                        textAlign: 'center',
                                    }}>{i18n.t('rows.card.cards')}</AvenirHeavyTitleText>
                                        {this.state.collaboration.cards.map((card) => (
                                            <View style={{marginVertical: '2%'}}>
                                                <CardRow inuse={true} context={this} card={card} isDetail={true}/>
                                            </View>
                                        ))}
                                    </View>
                                }
                            </ScrollView>
                        </View>
                    </View>
                    <View style={{marginVertical: 5, flexDirection: 'row',}}>
                        <AvenirBookUpperInlineText>
                            {i18n.t('screens.collaboration_detail.collaboration_state')}
                        </AvenirBookUpperInlineText>
                        <CollaborationStateText state={this.state.collaboration.currentState}/>
                    </View>
                    {this.buildBottomButton()}
                </View>
            </KeyboardAvoidingView>
        );
    }

    /**
     * Resend verification code to user with given type
     * @param type
     */
    private sendVerificationCode(type) {
        let self = this;
        new HostCollaboratorsApi(this).apiHostCollaboratorsSendVerificationTypePost({
            type: type,
            collaborator: self.state.collaboration.collaborator,
        }).then(resp => {
            Logger.log(self.constructor.name, 'Ok');

        }).catch(error => {
            Logger.warn(self.constructor.name, 'Error: ' + JSON.stringify(error));
        });
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let collaboration = context.state.collaboration;
    let fakecollaboration = CollaborationFactory.factory();
    collaboration.user = UserFactory.factory(true, true);
    collaboration.startAt = fakecollaboration.startAt;
    collaboration.endAt = fakecollaboration.endAt;
    collaboration.currentState = fakecollaboration.currentState;

    let joins = [];
    let accommodations = AccommodationFactory.factoryArray(10);
    let houses = HouseCompleteFactory.factoryArray(accommodations.length / 2);
    accommodations.forEach((accommodation, index) => {
        let houseIndex = Math.trunc(index / 2);
        let join: HostCollaborationAccommodationJoin = {
            house: houses[houseIndex],
            accommodations: accommodation,
        };
        joins.push(join);
    });
    collaboration.collaborationAccommodationJoins = joins;
    context.setState({collaboration: collaboration});
}

/**
 * Update component state with collaboration data
 * @param {React.Component<NavigationProps, State>} context
 * @param {HostCollaborationDetail} collaborationDetail
 */
function updateCollaborationState(context: Component<NavigationProps, State>, collaborationDetail: HostCollaborationDetail) {
    let collaboration = collaborationDetail;
    console.log(collaboration);
    console.log(collaboration.unsent.length > 0 ? '=> Non Pronta: ' + collaboration.unsent : '=> Pronta');
    context.setState({
        collaboration: collaboration,
    });
}

function resendCodesToDevices(context: Component<NavigationProps, State>){

            new HostCollaborationsApi(context).apiHostCollaborationsIdDevicecodesResendPost({id: context.state.collaboration.id}).then(resp => {
                context.getCollaboration();
            }).catch(error => {
                Logger.warn(context.constructor.name, JSON.stringify(error));
                AlertHelper.showSimpleErrorAlert();});
}

/**h
 * Delete a collaboration
 * @param {React.Component<NavigationProps, State>} context
 */
function deleteCollaboration(context: Component<NavigationProps, State>) {
    AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed) {
            new HostCollaborationsApi(context).apiHostCollaborationsIdDelete({id: context.state.collaboration.id}).then(resp => {
                Logger.log(context.constructor.name, "Ok");
                if (context.state.onDismiss != null)
                    context.state.onDismiss();
                goBack(context);
            }).catch(error => {
                Logger.warn(context.constructor.name, error);
                AlertHelper.showSimpleErrorAlert();
            });
        }
    });
}

/**
 * Resend access codes to users
 * @param {React.Component<any, State>} context
 */
function resendCodes(context: Component<any, State>) {
    new HostCollaborationsApi(context).apiHostCollaborationsIdCodesResendPost({id: context.state.collaboration.id}).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        context.setState({enableResendCodes: false});
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + error);
        AlertHelper.showSimpleErrorAlert();
    });
}

/**
 * Update collaboration state
 * @param context
 * @param newState
 */
function updateState(context: Component<any, State>, newState: CollaborationStateEnum) {
    let collaboration = context.state.collaboration;

    new HostCollaborationsApi(context).apiHostCollaborationsIdStatePut({
        id: collaboration.id,
        state: newState
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        collaboration.currentState = newState;
        context.setState({collaboration: collaboration});
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error " + JSON.stringify(error));
        AlertHelper.showSimpleErrorAlert();
    });
}