/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AddressProviderType {
    GoogleMaps = 'GOOGLE_MAPS',
    OpenMaps = 'OPEN_MAPS'
}

export function AddressProviderTypeFromJSON(json: any): AddressProviderType {
    return AddressProviderTypeFromJSONTyped(json, false);
}

export function AddressProviderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressProviderType {
    return json as AddressProviderType;
}

export function AddressProviderTypeToJSON(value?: AddressProviderType | null): any {
    return value as any;
}

