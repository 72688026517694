/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ResourcePlanJoin,
    ResourcePlanJoinFromJSON,
    ResourcePlanJoinToJSON,
    ResourcePlanJoinFilterInput,
    ResourcePlanJoinFilterInputFromJSON,
    ResourcePlanJoinFilterInputToJSON,
} from '../models';

export interface ApiHostResourcePlanJoinsByResourceIdPostRequest {
    id: string;
    resourcePlanJoinFilterInput: ResourcePlanJoinFilterInput;
}

export interface ApiHostResourcePlanJoinsIdDeleteRequest {
    id: number;
}

export interface ApiHostResourcePlanJoinsIdGetRequest {
    id: string;
}

export interface ApiHostResourcePlanJoinsPostRequest {
    resourcePlanJoin: ResourcePlanJoin;
}

export interface ApiHostResourcePlanJoinsSuspendByResourceIdPostRequest {
    id: string;
}

/**
 * 
 */
export class HostResourcePlanJoinsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostResourcePlanJoinsByResourceIdPostRaw(requestParameters: ApiHostResourcePlanJoinsByResourceIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResourcePlanJoin>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcePlanJoinsByResourceIdPost.');
        }

        if (requestParameters.resourcePlanJoinFilterInput === null || requestParameters.resourcePlanJoinFilterInput === undefined) {
            throw new runtime.RequiredError('resourcePlanJoinFilterInput','Required parameter requestParameters.resourcePlanJoinFilterInput was null or undefined when calling apiHostResourcePlanJoinsByResourceIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resource_plan_joins/byResource/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourcePlanJoinFilterInputToJSON(requestParameters.resourcePlanJoinFilterInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourcePlanJoinFromJSON));
    }

    /**
     */
    async apiHostResourcePlanJoinsByResourceIdPost(requestParameters: ApiHostResourcePlanJoinsByResourceIdPostRequest, initOverrides?: RequestInit): Promise<Array<ResourcePlanJoin>> {
        const response = await this.apiHostResourcePlanJoinsByResourceIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostResourcePlanJoinsIdDeleteRaw(requestParameters: ApiHostResourcePlanJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcePlanJoinsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resource_plan_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostResourcePlanJoinsIdDelete(requestParameters: ApiHostResourcePlanJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostResourcePlanJoinsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostResourcePlanJoinsIdGetRaw(requestParameters: ApiHostResourcePlanJoinsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResourcePlanJoin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcePlanJoinsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resource_plan_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourcePlanJoinFromJSON(jsonValue));
    }

    /**
     */
    async apiHostResourcePlanJoinsIdGet(requestParameters: ApiHostResourcePlanJoinsIdGetRequest, initOverrides?: RequestInit): Promise<ResourcePlanJoin> {
        const response = await this.apiHostResourcePlanJoinsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostResourcePlanJoinsPostRaw(requestParameters: ApiHostResourcePlanJoinsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourcePlanJoin === null || requestParameters.resourcePlanJoin === undefined) {
            throw new runtime.RequiredError('resourcePlanJoin','Required parameter requestParameters.resourcePlanJoin was null or undefined when calling apiHostResourcePlanJoinsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resource_plan_joins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourcePlanJoinToJSON(requestParameters.resourcePlanJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostResourcePlanJoinsPost(requestParameters: ApiHostResourcePlanJoinsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostResourcePlanJoinsPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostResourcePlanJoinsSuspendByResourceIdPostRaw(requestParameters: ApiHostResourcePlanJoinsSuspendByResourceIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResourcePlanJoin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostResourcePlanJoinsSuspendByResourceIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/resource_plan_joins/suspend/byResource/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourcePlanJoinFromJSON(jsonValue));
    }

    /**
     */
    async apiHostResourcePlanJoinsSuspendByResourceIdPost(requestParameters: ApiHostResourcePlanJoinsSuspendByResourceIdPostRequest, initOverrides?: RequestInit): Promise<ResourcePlanJoin> {
        const response = await this.apiHostResourcePlanJoinsSuspendByResourceIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
