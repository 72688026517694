/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostResource,
    HostResourceFromJSON,
    HostResourceFromJSONTyped,
    HostResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostResourceList
 */
export interface HostResourceList {
    /**
     * 
     * @type {Array<HostResource>}
     * @memberof HostResourceList
     */
    data?: Array<HostResource>;
}

export function HostResourceListFromJSON(json: any): HostResourceList {
    return HostResourceListFromJSONTyped(json, false);
}

export function HostResourceListFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostResourceList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(HostResourceFromJSON)),
    };
}

export function HostResourceListToJSON(value?: HostResourceList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(HostResourceToJSON)),
    };
}

