/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SegmentStyle.ts
 * @author     Christian Ascone
 * @date       9/17/19 5:36 PM
 */

import {StyleSheet} from "react-native";
import Colors from "../config/Colors";
import * as Fonts from "../config/Fonts";

const styles = StyleSheet.create({
    activeTab: {
        flex: 1,
        borderBottomColor: Colors.primaryColor,
        borderBottomWidth: 1,
        paddingBottom: 5,
    },
    inactiveTab: {
        flex: 1,
        paddingBottom: 5,
    },
    text: {
        textAlign: 'center',
        color: Colors.titleColor,
    },
    activeText: {
        fontFamily: Fonts.names.avenirHeavy,
    },
    inactiveText: {
        fontFamily: Fonts.names.avenirLight,
    },
});

export default styles;