/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ResourceSelection.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import {Resource} from "@services/src/models";

export interface ResourceSelectionBean {
    resource: Resource,
    selected: boolean,
}

export class ResourceSelection {
    /**
     * Builds a bean from Resource
     * @param {Resource} resource
     * @returns {ResourceSelectionBean}
     */
    static fromResource(resource: Resource): ResourceSelectionBean {
        return {resource: resource, selected: false};
    }

    /**
     * Builds a bean list from Resource List
     * @param {Resource[]} resources
     * @returns {ResourceSelectionBean[]}
     */
    static fromResourceList(resources: Resource[]): ResourceSelectionBean[] {
        return resources.map(resource => this.fromResource(resource));
    }
}