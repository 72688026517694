/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Card,
    CardFromJSON,
    CardToJSON,
} from '../models';

export interface ApiHostCardsExistsCodePostRequest {
    code: string;
}

export interface ApiHostCardsIdDeleteRequest {
    id: number;
}

export interface ApiHostCardsPostRequest {
    card: Card;
}

/**
 * 
 */
export class HostCardsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostCardsExistsCodePostRaw(requestParameters: ApiHostCardsExistsCodePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Card>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling apiHostCardsExistsCodePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/cards/exists/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCardsExistsCodePost(requestParameters: ApiHostCardsExistsCodePostRequest, initOverrides?: RequestInit): Promise<Card> {
        const response = await this.apiHostCardsExistsCodePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCardsIdDeleteRaw(requestParameters: ApiHostCardsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostCardsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/cards/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiHostCardsIdDelete(requestParameters: ApiHostCardsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiHostCardsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiHostCardsPostRaw(requestParameters: ApiHostCardsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Card>> {
        if (requestParameters.card === null || requestParameters.card === undefined) {
            throw new runtime.RequiredError('card','Required parameter requestParameters.card was null or undefined when calling apiHostCardsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/cards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CardToJSON(requestParameters.card),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCardsPost(requestParameters: ApiHostCardsPostRequest, initOverrides?: RequestInit): Promise<Card> {
        const response = await this.apiHostCardsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
