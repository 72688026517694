/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Accommodation,
    AccommodationFromJSON,
    AccommodationFromJSONTyped,
    AccommodationToJSON,
    Resource,
    ResourceFromJSON,
    ResourceFromJSONTyped,
    ResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccommodationResources
 */
export interface AccommodationResources {
    /**
     * 
     * @type {Accommodation}
     * @memberof AccommodationResources
     */
    accommodation?: Accommodation;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof AccommodationResources
     */
    resources?: Array<Resource>;
}

export function AccommodationResourcesFromJSON(json: any): AccommodationResources {
    return AccommodationResourcesFromJSONTyped(json, false);
}

export function AccommodationResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccommodationResources {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accommodation': !exists(json, 'accommodation') ? undefined : AccommodationFromJSON(json['accommodation']),
        'resources': !exists(json, 'resources') ? undefined : ((json['resources'] as Array<any>).map(ResourceFromJSON)),
    };
}

export function AccommodationResourcesToJSON(value?: AccommodationResources | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accommodation': AccommodationToJSON(value.accommodation),
        'resources': value.resources === undefined ? undefined : ((value.resources as Array<any>).map(ResourceToJSON)),
    };
}

