/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HostAccommodation,
    HostAccommodationFromJSON,
    HostAccommodationFromJSONTyped,
    HostAccommodationToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostAccommodationDetail
 */
export interface HostAccommodationDetail {
    /**
     * 
     * @type {HostAccommodation}
     * @memberof HostAccommodationDetail
     */
    data?: HostAccommodation;
}

export function HostAccommodationDetailFromJSON(json: any): HostAccommodationDetail {
    return HostAccommodationDetailFromJSONTyped(json, false);
}

export function HostAccommodationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostAccommodationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : HostAccommodationFromJSON(json['data']),
    };
}

export function HostAccommodationDetailToJSON(value?: HostAccommodationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': HostAccommodationToJSON(value.data),
    };
}

