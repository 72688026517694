/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       MainTabNavigator.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */

import React, {Component} from 'react';
import {createStackNavigator, StackNavigatorConfig} from 'react-navigation';
import {Platform, TouchableOpacity, View,} from 'react-native';
import TabBarIcon from '@components/TabBarIcon';
import MainScreen from "@screens/MainScreen";
import LoginScreen from "@screens/LoginScreen";
import SignupScreen from "@screens/host/SignupScreen";
import {LeftArrowImage} from "@components/Images/LeftArrowImage";
import globalScreenStyle from "@styles/GlobalStyle";
import headerStyle from "@styles/HeaderStyle";
import {LogoBFreeSmallImage} from "@components/Images/LogoBFreeSmallImage";
import {AvenirHeavyTitleText} from "@components/StyledText";
import {getHeaderTitle} from "@helpers/NavigationDataHelper";
import DrawerTabNavigator from "../drawer/DrawerTabNavigator";
import {NavigationProps} from "@helpers/NavigationHelper";
import DoorFiltersScreen from "@screens/host/doors/DoorFiltersScreen";
import EmailVerificationScreen from "@screens/host/user/EmailVerificationScreen";
import PhoneVerificationScreen from "@screens/host/user/PhoneVerificationScreen";
import ReservationFiltersScreen from "@screens/host/reservations/ReservationFiltersScreen";
import StripeDebugScreen from "@screens/host/StripeDebugScreen";
import CollaborationFiltersScreen from "@screens/host/collaborations/CollaborationFiltersScreen";
import ProfilePassepartoutScreen from "@screens/host/user/ProfilePassepartoutScreen";
import ProfileScreen from "@screens/host/user/ProfileScreen";
import CardsScreen from "@screens/host/cards/CardsScreen";
import UpdateAppScreen from "@screens/UpdateAppScreen";

const config: StackNavigatorConfig = Platform.select({
    ios: {
        headerMode: 'float',
        initialRouteName: 'Home',
        headerLayoutPreset: 'center',
    },
    android: {
        headerMode: 'screen',
        initialRouteName: 'Home',
        headerLayoutPreset: 'center',
    },
    default: {
        headerMode: 'screen',
        initialRouteName: 'Home',
        headerLayoutPreset: 'center',
    },
});

//Navigation Drawer Structure for all screen
class NavigationHeaderLeftBack extends Component<NavigationProps, any> {
    /**
     * Go to previous page
     */
    goBack = () => {
        //Props to open/close the drawer
        this.props.navigation.pop();
    };

    render() {
        return (
            <View style={globalScreenStyle.globalMargins}>
                <TouchableOpacity onPress={this.goBack.bind(this)}>
                    <LeftArrowImage style={headerStyle.headerLeftBackImage}/>
                </TouchableOpacity>
            </View>
        );
    }
}

//Navigation Drawer Structure for all screen
class NavigationHeaderTitle extends Component<NavigationProps, any> {
    render() {
        let title = getHeaderTitle(this.props);
        let visible = title != null && title.trim() !== '';
        if (visible)
            return (
                <View style={headerStyle.headerTitleContainer}>
                    <LogoBFreeSmallImage style={headerStyle.headerTitleImage}/>
                    <AvenirHeavyTitleText>{title}</AvenirHeavyTitleText>
                </View>
            );
        else
            return null;
    }
}

/**
 * Gets options for Navigation header
 * @param navigation
 * @returns object
 */
function headerNavigationOptions(navigation) {
    return {
        headerLeft: <NavigationHeaderLeftBack navigation={navigation}/>,
        headerTitle: <NavigationHeaderTitle navigation={navigation}/>,
        headerStyle: headerStyle.header
    }
}


const UpdateStack = createStackNavigator(
    {
        Home: {
            screen: UpdateAppScreen,
            navigationOptions: ({navigation}) => headerNavigationOptions(navigation),
        },
    },
    {
        headerMode: "none",
    }
);

const NeedToUpdateNavigator = createStackNavigator(
    {
        UpdateStack: {
            screen: UpdateStack,
        },
    },
    {
        headerMode: 'none',
    }
);




export default NeedToUpdateNavigator;
