/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       StyledText.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import React, {Component} from 'react';
import {StyleProp, Text, ViewStyle} from 'react-native';
import * as Fonts from "../config/Fonts";
import TextStyle from "@styles/TextStyle";
import StyledTextProps from "./properties/StyledTextProps";
import {CollaborationStateEnum, EventPriority, ReservationStateEnum} from "@services/src/models";
import Colors from "../config/Colors";

export function AvenirBookText(props) {
    return (
        <Text {...props} style={[props.style, {fontFamily: Fonts.names.avenirBook}]}/>
    );
}

export function AvenirHeavyText(props) {
    return (
        <Text {...props} style={[props.style, {fontFamily: Fonts.names.avenirHeavy}]}/>
    );
}

export function AvenirLightText(props) {
    return (
        <Text {...props} style={[props.style, {fontFamily: Fonts.names.avenirLight}]}/>
    );
}

export function AvenirMediumText(props) {
    return (
        <Text {...props} style={[props.style, {fontFamily: Fonts.names.avenirMedium}]}/>
    );
}

/**
 * Text with Avenir Light font and grey color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirLightGreyText(props) {
    return (
        <AvenirLightText {...props} style={[props.style, TextStyle.greyText]}/>
    );
}

/**
 * Text with Avenir Book font and primary color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookPrimaryText(props) {
    return (
        <AvenirBookText {...props} style={[props.style, TextStyle.primaryText]}/>
    );
}

/**
 * Text with Avenir Book font and primary color with half opacity
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookPrimaryHalfOpacityText(props) {
    return (
        <AvenirBookText {...props} style={[props.style, TextStyle.primaryColorHalfOpacityText]}/>
    );
}

/**
 * Text with Avenir Book font and grey color for caption
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookServiceGreyText(props) {
    return (
        <AvenirBookText {...props} style={[props.style, TextStyle.captionText]}/>
    );
}

/**
 * Text with Avenir Book font and red color for errors
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookServiceRedText(props) {
    return (
        <AvenirBookText {...props} style={[props.style, TextStyle.notAvailableText]}/>
    );
}

/**
 * Text with Avenir Book font, uppercase for upper label
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookUpperLabelText(props) {
    return (
        <AvenirBookText {...props} style={[TextStyle.uppercase, TextStyle.upperLabel, props.style,]}/>
    );
}

/**
 * Text with Avenir Book font, uppercase for inline label
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookUpperInlineText(props) {
    return (
        <AvenirBookText {...props} style={[TextStyle.uppercase, TextStyle.inlineLabel, props.style,]}/>
    );
}

/**
 * Text with Avenir Book font, grey for number label
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookGreyLabelText(props) {
    return (
        <AvenirBookText {...props} style={[props.style, TextStyle.greyLabel]}/>
    );
}

/**
 * Text with Avenir Book font, underlined
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookUnderlinedText(props) {
    return (
        <AvenirBookText {...props} style={[props.style, TextStyle.underlined, TextStyle.topLabelText]}/>
    );
}

/**
 * Text with Avenir Book font for switch
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirBookSwitchText(props) {
    return (
        <AvenirBookText {...props} style={[props.style, TextStyle.upperLabel]}/>
    );
}

/**
 * Text with Avenir Medium font and primary color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirMediumPrimaryText(props) {
    return (
        <AvenirMediumText {...props} style={[props.style, TextStyle.primaryText]}/>
    );
}

/**
 * Text with Avenir Medium font and grey color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirMediumGreyText(props) {
    return (
        <AvenirMediumText {...props} style={[props.style, TextStyle.greyText]}/>
    );
}

/**
 * Text with Avenir Medium font and primary color with half opacity
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirMediumPrimaryHalfOpacityText(props) {
    return (
        <AvenirMediumText {...props} style={[props.style, TextStyle.primaryColorHalfOpacityText]}/>
    );
}

/**
 * Text with Avenir Light font and primary color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirLightPrimaryText(props) {
    return (
        <AvenirLightText {...props} style={[props.style, TextStyle.primaryText]}/>
    );
}

/**
 * Text with Avenir Heavy font and primary color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirHeavyPrimaryText(props) {
    return (
        <AvenirHeavyText {...props} style={[props.style, TextStyle.primaryText]}/>
    );
}

/**
 * Text with Avenir Heavy font and green available color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirHeavyAvailableText(props) {
    return (
        <AvenirHeavyText {...props} style={[props.style, TextStyle.availableText]}/>
    );
}

/**
 * Text with Avenir Heavy font and red not available color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirHeavyNotAvailableText(props) {
    return (
        <AvenirHeavyText {...props} style={[props.style, TextStyle.notAvailableText]}/>
    );
}

/**
 * Text with Avenir Heavy font and title color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirHeavyTitleText(props) {
    return (
        <AvenirHeavyText {...props} style={[props.style, TextStyle.titleText]}/>
    );
}

/**
 * Text with Avenir Heavy font and label color
 * @param props
 * @returns {any}
 * @constructor
 */
export function AvenirHeavyLabelText(props) {
    return (
        <AvenirHeavyText {...props} style={[props.style, TextStyle.labelColorText]}/>
    );
}

/**
 * Text with Avenir book or heavy. It depends on bold property
 * @param props
 * @returns {any}
 * @constructor
 */
export class AvenirDrawerText extends Component<StyledTextProps, any> {
    constructor(props: StyledTextProps) {
        super(props);
    }

    render() {
        let newProps = {...this.props, bold: undefined};

        if (this.props.bold)
            return (
                <AvenirHeavyText {...newProps} style={[this.props.style]}/>
            );
        else
            return (
                <AvenirBookText {...newProps} style={[this.props.style]}/>
            );
    }
}

interface ReservationTextProps {
    state: ReservationStateEnum,
    style?: StyleProp<ViewStyle>,
}

/**
 * Text with Avenir book or heavy. It depends on bold property
 * @param props
 * @returns {any}
 * @constructor
 */
export class AvenirReservationStateText extends Component<ReservationTextProps, any> {
    constructor(props: ReservationTextProps) {
        super(props);
    }

    render() {
        let newProps = {...this.props};

        switch (this.props.state) {
            case ReservationStateEnum.Pending:
                return (
                    <AvenirLightText {...newProps} style={[{color: Colors.reservationPending}, this.props.style]}/>
                );
            case ReservationStateEnum.Checkedin:
                return (
                    <AvenirHeavyPrimaryText {...newProps} style={[this.props.style]}/>
                );
            case ReservationStateEnum.Confirmed:
                return (
                    <AvenirLightText {...newProps} style={[{color: Colors.reservationConfirmed}, this.props.style]}/>
                );
            case ReservationStateEnum.Canceled:
                return (
                    <AvenirLightText {...newProps} style={[{color: Colors.reservationCanceled}, this.props.style]}/>
                );
            case ReservationStateEnum.Checkedout:
                return (
                    <AvenirLightText {...newProps} style={[{color: Colors.reservationCheckedout}, this.props.style]}/>
                );
            default:
                return (
                    <AvenirLightText {...newProps}/>
                );
        }
    }
}

interface CollaborationTextProps {
    state: CollaborationStateEnum,
    style?: StyleProp<ViewStyle>,
}

/**
 * Text with Avenir book or heavy. It depends on bold property
 * @param props
 * @returns {any}
 * @constructor
 */
export class AvenirCollaborationStateText extends Component<CollaborationTextProps, any> {
    constructor(props: CollaborationTextProps) {
        super(props);
    }

    render() {
        let newProps = {...this.props};

        switch (this.props.state) {
            case CollaborationStateEnum.Pending:
                return (
                    <AvenirLightText {...newProps} style={[{color: Colors.collaborationPending}, this.props.style]}/>
                );
            case CollaborationStateEnum.Ongoing:
                return (
                    <AvenirHeavyPrimaryText {...newProps} style={[this.props.style]}/>
                );
            case CollaborationStateEnum.Confirmed:
                return (
                    <AvenirLightText {...newProps} style={[{color: Colors.collaborationConfirmed}, this.props.style]}/>
                );
            case CollaborationStateEnum.Canceled:
                return (
                    <AvenirLightText {...newProps} style={[{color: Colors.collaborationCanceled}, this.props.style]}/>
                );
            case CollaborationStateEnum.Expired:
                return (
                    <AvenirLightText {...newProps} style={[{color: Colors.collaborationCheckedout}, this.props.style]}/>
                );
            default:
                return (
                    <AvenirLightText {...newProps}/>
                );
        }
    }
}


interface EventTextProps {
    priority: EventPriority,
    read: boolean,
    style?: StyleProp<ViewStyle>,
}

/**
 * Text with Avenir book or heavy. It depends on bold property
 * @param props
 * @returns {any}
 * @constructor
 */
export class AvenirEventPriorityText extends Component<EventTextProps, any> {
    constructor(props: EventTextProps) {
        super(props);
    }

    render() {
        let newProps = {...this.props};

        if (this.props.read) {
            switch (this.props.priority) {
                case EventPriority.High:
                    return (
                        <AvenirLightText {...newProps} style={[{color: Colors.eventPriorityHigh}, this.props.style]}/>
                    );
                case EventPriority.Medium:
                    return (
                        <AvenirLightText {...newProps} style={[{color: Colors.eventPriorityMedium}, this.props.style]}/>
                    );
                case EventPriority.Low:
                    return (
                        <AvenirLightText {...newProps} style={[{color: Colors.eventPriorityLow}, this.props.style]}/>
                    );
            }
        } else {
            switch (this.props.priority) {
                case EventPriority.High:
                    return (
                        <AvenirHeavyText {...newProps} style={[{color: Colors.eventPriorityHigh}, this.props.style]}/>
                    );
                case EventPriority.Medium:
                    return (
                        <AvenirHeavyText {...newProps} style={[{color: Colors.eventPriorityMedium}, this.props.style]}/>
                    );
                case EventPriority.Low:
                    return (
                        <AvenirHeavyText {...newProps} style={[{color: Colors.eventPriorityLow}, this.props.style]}/>
                    );
            }
        }
        return (
            <AvenirLightText {...newProps}/>
        );
    }
}