/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       UserStateText.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import React, {Component} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {AvenirLightText} from "./StyledText";
import {User, VerificationCodeType} from "@services/src/models";
import Colors from "../config/Colors";
import {i18n} from "@i18n/i18n";

interface UserStateTextProps {
    codeType?: VerificationCodeType,
    user: User,
    style?: StyleProp<ViewStyle>,
}

export class UserStateText extends Component<UserStateTextProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.user == null) return null;
        let title = '';
        let textBlock = null;
        let confirmed = null;
        switch (this.props.codeType) {
            case VerificationCodeType.Email:
                confirmed = this.props.user.emailVerifiedAt != null;
                break;
            case VerificationCodeType.Phone:
                confirmed = this.props.user.phoneVerifiedAt != null;
                break;
        }

        if (confirmed == true) {
            title = i18n.t('common.VERIFIEDo');
            textBlock = (<AvenirLightText
                style={[{color: Colors.reservationConfirmed}, this.props.style]}>{title}</AvenirLightText>
                );
        } else if (confirmed == false) {
            title = i18n.t('common.PENDING');
            textBlock = (<AvenirLightText
                style={[{color: Colors.reservationPending}, this.props.style]}>{title}</AvenirLightText>);
        }

        return (
            <View>
                {textBlock}
            </View>
        );
    }
}


interface UserStateTextAbsoluteProps {
    user: User,
    style?: StyleProp<ViewStyle>,
}

export class UserStateTextAbsolute extends Component<UserStateTextAbsoluteProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.user == null) return null;
        let title = '';
        let textBlock = null;
        let confirmed = null;
        confirmed = this.props.user.emailVerifiedAt != null && this.props.user.phoneVerifiedAt != null;

        if (confirmed == true) {
            title = i18n.t('common.VERIFIEDo');
            textBlock = (<AvenirLightText
                    style={[{color: Colors.reservationConfirmed}, this.props.style]}>{title}</AvenirLightText>
            );
        } else if (confirmed == false) {
            title = i18n.t('common.PENDING');
            textBlock = (<AvenirLightText
                style={[{color: Colors.reservationPending}, this.props.style]}>{title}</AvenirLightText>);
        }

        return (
            <View>
                {textBlock}
            </View>
        );
    }
}