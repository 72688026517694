/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ProfileManager.ts
 * @author     Christian Ascone
 * @date       1/22/20 12:28 PM
 */

import {Component} from 'react';
import {Logger} from "@helpers/Logger";
import {UsersApi} from "@services/src/apis";
import {LoaderState} from "@helpers/LoaderHelper";
import {openMainScreen} from "@helpers/NavigationHelper";
import * as StorageHelper from "@helpers/StorageHelper";
import {environment} from "../environments/environment";
import {UserProfile} from "@services/src/models";

export class ProfileManager {
    /**
     * Checks if user has granted camera roll permission
     * @returns {Promise<void>}
     */
    static async getProfile(context: Component<any, LoaderState>): Promise<UserProfile> {
        try {
            let resp = await new UsersApi(context).apiUsersProfileMeGet();
            Logger.log(context.constructor.name, `Found profile data.`);
            return resp;
        } catch (e) {
            Logger.warn(context.constructor.name, "Cannot get profile");
            if (!environment.debugRoom) {
                await StorageHelper.removeAccessToken();
                openMainScreen(context);
            } else {
                throw e;
            }
        }
    }
}