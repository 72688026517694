

/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorDetailBlock.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React from 'react';
import {GestureResponderEvent, View} from 'react-native';
import {AvenirMediumPrimaryText} from "../StyledText";
import {Resource, ResourceStateEnum, ResourceType} from "@services/src/models";
import {StatusActivationDotView, StatusConnectionDotView} from "../StatusDotView";
import doorDetailStyle from "@styles/host/details/DoorDetailBlockStyle";
import {PublicImage} from "@components/Images/services/PublicImage";
import {ResidentialImage} from "@components/Images/services/ResidentialImage";
import {DeleteButton} from "../DeleteButton";

export interface DoorDetailBlockProps {
    online: boolean,
    activationStatus: ResourceStateEnum,
    door: Resource,
    onDelete: (event: GestureResponderEvent) => void,
}

export function DoorDetailBlock(props: DoorDetailBlockProps) {
    return (
        <View style={doorDetailStyle.serviceSelectionContainer}>
            <View
                style={[doorDetailStyle.bigContainer, doorDetailStyle.textContainer, {flexDirection: 'row',}]}>
                <View style={{
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                    marginRight: 5,
                }}>
                    {props.door.type == ResourceType.Public &&
                    <PublicImage style={[doorDetailStyle.doorImage,]}/>
                    }
                    {props.door.type == ResourceType.Residential &&
                    <ResidentialImage style={[doorDetailStyle.doorImage,]}/>
                    }
                </View>
                <View style={{flex: 7,}}>
                    <View style={{
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center'
                    }}>
                        <AvenirMediumPrimaryText numberOfLines={1}
                                                 style={{
                                                     flex: 1,
                                                     textAlign: "left",
                                                     marginHorizontal: 10,
                                                 }}>
                            {props.door.name}
                        </AvenirMediumPrimaryText>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'center',
                        marginTop: 5,
                    }}>
                        <StatusConnectionDotView enabled={props.online} style={doorDetailStyle.flex}/>
                        <StatusActivationDotView status={props.activationStatus}
                                                 style={doorDetailStyle.flex}/>
                    </View>
                </View>
                <View style={{flex: 1}}>
                    <DeleteButton onPress={props.onDelete} style={{}}/>
                </View>
            </View>
        </View>
    );
}