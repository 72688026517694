/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Card,
    CardFromJSON,
    CardToJSON,
    CardsData,
    CardsDataFromJSON,
    CardsDataToJSON,
} from '../models';

export interface ApiHostCardsIdCandeleteGetRequest {
    id: number;
}

/**
 * 
 */
export class CardsApi extends runtime.BaseAPI {

    /**
     */
    async apiHostCardsFreeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Card>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/cards/free`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CardFromJSON));
    }

    /**
     */
    async apiHostCardsFreeGet(initOverrides?: RequestInit): Promise<Array<Card>> {
        const response = await this.apiHostCardsFreeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCardsIdCandeleteGetRaw(requestParameters: ApiHostCardsIdCandeleteGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiHostCardsIdCandeleteGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/cards/{id}/candelete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async apiHostCardsIdCandeleteGet(requestParameters: ApiHostCardsIdCandeleteGetRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.apiHostCardsIdCandeleteGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiHostCardsStatusGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CardsData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/host/cards/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardsDataFromJSON(jsonValue));
    }

    /**
     */
    async apiHostCardsStatusGet(initOverrides?: RequestInit): Promise<CardsData> {
        const response = await this.apiHostCardsStatusGetRaw(initOverrides);
        return await response.value();
    }

}
