/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReservationGuestBean
 */
export interface ReservationGuestBean {
    /**
     * 
     * @type {any}
     * @memberof ReservationGuestBean
     */
    reservation?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ReservationGuestBean
     */
    guest?: any | null;
}

export function ReservationGuestBeanFromJSON(json: any): ReservationGuestBean {
    return ReservationGuestBeanFromJSONTyped(json, false);
}

export function ReservationGuestBeanFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationGuestBean {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reservation': !exists(json, 'reservation') ? undefined : json['reservation'],
        'guest': !exists(json, 'guest') ? undefined : json['guest'],
    };
}

export function ReservationGuestBeanToJSON(value?: ReservationGuestBean | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reservation': value.reservation,
        'guest': value.guest,
    };
}

