/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorFiltersScreen.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, TouchableOpacity, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import doorDetailScreenStyle from "@styles/host/doors/DoorFiltersScreenStyle";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {dismissModal, NavigationProps} from "@helpers/NavigationHelper";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {
    getDoorFiltersDismissCallbackFromNavigationProps,
    getDoorFiltersFromNavigationProps,
    getFiltersHousesFromNavigationProps
} from "@helpers/NavigationDataHelper";
import {ResourceFilterInput, ResourceType} from "@services/src/models/index";
import {
    DoorConnectionFilterBlock,
    DoorHousesFilterBlock,
    DoorStatusFilterBlock,
    DoorTypeFilterBlock
} from "@components/FilterContainerBlock";
import {AvenirHeavyPrimaryText} from "@components/StyledText";
import {CloseColorImage} from "@components/Images/CloseColorImage";
import {Logger} from "@helpers/Logger";
import {HouseSelectionBean} from "../../../models/beans/HouseSelection";

interface State extends LoaderState {
    housesSelectionBeans: Array<HouseSelectionBean>,
    selectedType: ResourceType,
    door_filters: ResourceFilterInput,
    onDismiss: (result: ResourceFilterInput) => void,
    applied: boolean,
}

export default class DoorFiltersScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            housesSelectionBeans: getFiltersHousesFromNavigationProps(this) || [],
            selectedType: null,
            door_filters: getDoorFiltersFromNavigationProps(this) || {
                type: null,
                online: null,
                resourceStateActive: false,
                resourceStateExpiring: false,
                resourceStateExpired: false
            },
            onDismiss: getDoorFiltersDismissCallbackFromNavigationProps(this) || null,
            applied: false,
        };

    }

    componentWillUnmount() {
        if (this.state.applied)
            this.state.onDismiss(this.state.door_filters);
    }

    updateResourceType(type: ResourceType) {
        let filters = this.state.door_filters;
        filters.type = type;
        this.setState({door_filters: filters});
    }

    updateConnectionStatus(online: boolean) {
        let filters = this.state.door_filters;
        if (filters.online != online)
            filters.online = online;
        else
            filters.online = null;
        this.setState({door_filters: filters});
    }

    updateActive(active: boolean) {
        let filters = this.state.door_filters;
        filters.resourceStateActive = active;
        this.setState({door_filters: filters});
    }

    updateExpiring(expiring: boolean) {
        let filters = this.state.door_filters;
        filters.resourceStateExpiring = expiring;
        this.setState({door_filters: filters});
    }

    updateExpired(expired: boolean) {
        let filters = this.state.door_filters;
        filters.resourceStateExpired = expired;
        this.setState({door_filters: filters});
    }

    updateSelectedHouse(key) {
        let houses = this.state.housesSelectionBeans;
        houses[key].selected = !houses[key].selected;
        let filters = this.state.door_filters;
        filters.houseIds = houses.filter(bean => bean.selected).map(bean => bean.house.id);
        console.log(filters.houseIds);
        this.setState({housesSelectionBeans: houses, door_filters: filters});
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={doorDetailScreenStyle.container}>
                <Loader state={this.state}/>
                <View
                    style={[doorDetailScreenStyle.scrollContainer, doorDetailScreenStyle.scrollOuter]}>
                    <View style={{flexDirection: 'row', marginBottom: 10,}}>
                        <View style={[doorDetailScreenStyle.flex,]}>
                            <AvenirHeavyPrimaryText>
                                {i18n.t('screens.door_filters.title')}
                            </AvenirHeavyPrimaryText>
                        </View>
                        <View style={[doorDetailScreenStyle.flex, {alignContent: 'flex-end', alignItems: 'flex-end',}]}>
                            <TouchableOpacity onPress={() => dismissModal(this)}>
                                <CloseColorImage style={{width: 20, height: 20,}}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View
                        style={[doorDetailScreenStyle.getStartedContainer, doorDetailScreenStyle.scrollContainer]}>
                        {this.state.housesSelectionBeans != null && this.state.housesSelectionBeans.length > 0 &&
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <DoorHousesFilterBlock onPress={(key) => this.updateSelectedHouse(key)}
                                                   houseSelectionBeans={this.state.housesSelectionBeans} style={{flex: 1, flexDirection: 'column'}}/>
                        </View>
                        }
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <DoorTypeFilterBlock onPressPublic={() => this.updateResourceType(ResourceType.Public)}
                                                 onPressResidential={() => this.updateResourceType(ResourceType.Residential)}
                                                 selectedType={this.state.door_filters.type} style={{marginRight: 5,}}/>
                            <DoorConnectionFilterBlock online={this.state.door_filters.online}
                                                       onPressOffline={() => this.updateConnectionStatus(false)}
                                                       onPressOnline={() => this.updateConnectionStatus(true)}
                                                       style={{marginLeft: 5,}}/>
                        </View>
                        <View style={doorDetailScreenStyle.flex}>
                            {/*Dummy*/}
                        </View>
                        <View style={doorDetailScreenStyle.flex}>
                            <DoorStatusFilterBlock active={this.state.door_filters.resourceStateActive}
                                                   expired={this.state.door_filters.resourceStateExpired}
                                                   expiring={this.state.door_filters.resourceStateExpiring}
                                                   onPressActive={() => this.updateActive(!this.state.door_filters.resourceStateActive)}
                                                   onPressExpired={() => this.updateExpired(!this.state.door_filters.resourceStateExpired)}
                                                   onPressExpiring={() => this.updateExpiring(!this.state.door_filters.resourceStateExpiring)}/>
                        </View>
                        <View style={doorDetailScreenStyle.flex}>
                            {/*Dummy*/}
                        </View>
                    </View>
                    <ConfirmButtonBottom active={true} onPress={() => applyFilters(this)}
                                         title={i18n.t('buttons.door_filters')}
                                         style={{marginHorizontal: 0, marginBottom: 0,}}/>
                </View>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Apply selected filters
 * @param {React.Component<any, State>} context
 */
function applyFilters(context: Component<NavigationProps, State>) {
    Logger.log(context.constructor.name, "Applying door filters");
    context.setState({applied: true});
    dismissModal(context);
}