/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HostFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */


import * as fakerStatic from 'faker';
import {Host, HostType} from "@services/src/models";

export class HostFactory {
    static factoryArray(size: number): Host[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(HostFactory.factory());
        }
        return array;
    }

    static factory(): Host {
        return {
            id: fakerStatic.random.number(4),
            firstName: fakerStatic.name.firstName(),
            lastName: fakerStatic.name.lastName(),
            email: fakerStatic.internet.email(),
            mobile: fakerStatic.phone.phoneNumber(),
            fiscalCode: fakerStatic.random.alphaNumeric(16),
            type: HostType.Private,
            usageThreshold: 0,
        };
    }
}

