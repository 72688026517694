/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationDetailsContainerBlockStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import Colors from "../../../config/Colors";

const marginBottomTopRow = 5;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignSelf: 'stretch',
    },
    flex: {
        flex: 1,
    },
    flexWrap: {
        flexWrap: 'wrap',
    },
    flexBig: {
        flex: 6,
        flexDirection: 'column',
        marginHorizontal: 5,
    },
    background: {
        backgroundColor: 'white',
    },
    header: {
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    leftBorderView: {
        backgroundColor: Colors.recapLine,
        width: 1,
        flex: 1,
    },
    textContainer: {
        flexDirection: 'row',
        marginBottom: marginBottomTopRow,
    },
    titleText: {
        textTransform: 'uppercase',
        letterSpacing: 2,
        fontSize: 12,
    },
    headImage: {
        width: 15,
        height: 15,
        resizeMode: 'contain',
        marginBottom: marginBottomTopRow,
    },
    periodRowContainer: {
        paddingBottom: 10,
        borderBottomColor: Colors.separatorColor,
        borderBottomWidth: 1,
    },
    accommodationsRowContainer: {
        backgroundColor: Colors.background,
        padding: 10,
        marginVertical: 3,
        flexDirection: 'row'
    },
    detailArrowContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    detailArrow: {
        alignSelf: 'flex-end',
        width: 8,
        height: 8,
    },
});

export default styles;