/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ButtonStyle.ts
 * @author     Christian Ascone
 * @date       3/2/20 3:49 PM
 */

import {Platform, StyleSheet} from "react-native";
import Colors from "../config/Colors";

const styles = StyleSheet.create({
    enabledButton: {
        paddingTop: 10,
        paddingBottom: 10,
        ...Platform.select({
            web: {
                width: '50%',
                alignSelf: 'center',
            }
        })
    },
    backgroundEnabled: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        backgroundColor: Colors.buttonEnabledBackground,
    },
    backgroundDisabled: {
        backgroundColor: Colors.buttonDisabledBackground,
    },
    doubleButtonBackgroundEnabled: {
        backgroundColor: Colors.buttonEnabledBackground,
    },
    doubleButtonBackgroundDisabled: {
        backgroundColor: Colors.buttonDisabledBackground,
    },
    doubleButtonSecondaryBackgroundEnabled: {
        backgroundColor: Colors.background,
    },
    doubleButtonSecondaryTextEnabled: {
        color: Colors.primaryColor,
    },
    doubleButtonSecondaryBackgroundDisabled: {
        backgroundColor: Colors.background,
    },
    doubleButtonSecondaryTextDisabled: {
        color: Colors.primaryColorLowOpacity,
    },
    text: {
        textAlign: 'center',
        color: 'white',
        padding: 10,
    },
    doubleButtonText: {
        textAlign: 'center',
        color: 'white',
        padding: 10,
        fontSize: 12,
    },
    avatarPlaceholderAdd: {
        width: 30,
        height: 30,
        position: 'absolute',
        left: 60,
    },
    filterCheck: {
        width: 15,
        height: 15,
        position: 'absolute',
        left: 35,
    },
    filterImageContainer: {
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 25,
        width: 50,
        height: 50,
        borderColor: Colors.primaryColor,
        borderWidth: 1,
    },
    filterHouseImageContainer: {
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 25,
        width: 50,
        height: 50,

    },
    filterHouseImageSelectedContainer: {
        borderColor: Colors.primaryColor,
        borderWidth: 2,
    },
    filterRoundedTextContainer: {
        alignContent: 'center',
        alignItems: 'center',
        borderRadius: 25,
        width: 50,
        height: 50,
        borderColor: Colors.primaryColor,
        borderWidth: 1,
    },
    filterImageContainerBackround: {
        backgroundColor: Colors.primaryColor,
    },
    filterImage: {
        flex: 1,
        width: 15,
        height: 15,
        resizeMode: 'contain',
        alignSelf: 'center',
    },
    filterHouseImage: {
        flex: 1,
        width: 48,
        height: 48,
        // resizeMode: 'contain',
        alignSelf: 'center',
        borderRadius: 24,
    },
    filterText: {
        flex: 1,
        alignSelf: 'center',
        textAlignVertical: 'center',
    },
    filterTextContainer: {
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    addButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    addButtonImage: {
        width: 40,
        height: 40,
    },
    addButtonText: {
        marginLeft: 20,
    },
    inputBlockButtonContainer: {
        flexDirection: 'row',
        display: 'flex',
    },
    inputBlockButtonInputContainer: {
        flex: 4,
        flexDirection: 'column',
    },
    inputBlockButtonButtonContainer: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    deleteButtonTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        borderWidth: 0.5,
        borderColor: Colors.buttonBorder,
        borderRadius: 3,
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    deleteButtonImage: {
        width: 15,
        height: 15,
        alignSelf: 'center',
        resizeMode: 'contain',
    },
    enabledRoundedButton: {
        paddingTop: 5,
        paddingBottom: 5,
        borderWidth: 0.5,
        borderRadius: 3,
        borderColor: Colors.primaryColor,
    },
    roundedButtonText: {
        color: Colors.primaryColor,
        textAlign: 'center',
        padding: 1,
    },
});

export default styles;