/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ConfirmButton.tsx
 * @author     Christian Ascone
 * @date       11/4/19 6:05 PM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import ButtonStyle from "@styles/ButtonStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {AvenirMediumText} from "./StyledText";
import Colors from "../config/Colors";

interface ConfirmButtonProps {
    active: boolean,
    style?: StyleProp<ViewStyle>,
    onPress: (event: GestureResponderEvent) => void,
    title: string,
}

export class ConfirmButton extends Component<ConfirmButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <View>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[ButtonStyle.enabledButton, (this.props.active ? ButtonStyle.backgroundEnabled : ButtonStyle.backgroundDisabled), this.props.style]}>
                    <AvenirMediumText style={ButtonStyle.text}>{this.props.title}</AvenirMediumText>
                </TouchableOpacity>
            </View>
        );
    }
}

export class ConfirmButtonBottom extends Component<ConfirmButtonProps, any> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ConfirmButton active={this.props.active} onPress={this.props.onPress}
                           title={this.props.title}
                           style={[globalScreenStyle.globalMargins, {marginBottom: 20}, this.props.style,]}/>
        );
    }
}

export class ConfirmGreenButton extends Component<ConfirmButtonProps, any> {
    render() {
        return (
            <ConfirmButton {...this.props}
                           style={[{backgroundColor: Colors.reservationConfirmed}, this.props.style,]}/>
        );
    }
}