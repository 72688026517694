/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccommodationCreateInput
 */
export interface AccommodationCreateInput {
    /**
     * 
     * @type {string}
     * @memberof AccommodationCreateInput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccommodationCreateInput
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof AccommodationCreateInput
     */
    availability?: number;
    /**
     * Id of house
     * @type {number}
     * @memberof AccommodationCreateInput
     */
    houseId?: number;
    /**
     * 
     * @type {number}
     * @memberof AccommodationCreateInput
     */
    hostId?: number;
    /**
     * Id list of resources
     * @type {Array<number>}
     * @memberof AccommodationCreateInput
     */
    resourceIds?: Array<number>;
}

export function AccommodationCreateInputFromJSON(json: any): AccommodationCreateInput {
    return AccommodationCreateInputFromJSONTyped(json, false);
}

export function AccommodationCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccommodationCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'availability': !exists(json, 'availability') ? undefined : json['availability'],
        'houseId': !exists(json, 'house_id') ? undefined : json['house_id'],
        'hostId': !exists(json, 'host_id') ? undefined : json['host_id'],
        'resourceIds': !exists(json, 'resource_ids') ? undefined : json['resource_ids'],
    };
}

export function AccommodationCreateInputToJSON(value?: AccommodationCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'availability': value.availability,
        'house_id': value.houseId,
        'host_id': value.hostId,
        'resource_ids': value.resourceIds,
    };
}

