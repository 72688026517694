/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccommodationResourceJoin,
    AccommodationResourceJoinFromJSON,
    AccommodationResourceJoinFromJSONTyped,
    AccommodationResourceJoinToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostAccommodation
 */
export interface HostAccommodation {
    /**
     * 
     * @type {number}
     * @memberof HostAccommodation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HostAccommodation
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof HostAccommodation
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof HostAccommodation
     */
    publicResourcesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof HostAccommodation
     */
    residentialResourcesCount?: number;
    /**
     * Id of house
     * @type {number}
     * @memberof HostAccommodation
     */
    houseId?: number;
    /**
     * 
     * @type {number}
     * @memberof HostAccommodation
     */
    hostId?: number;
    /**
     * 
     * @type {Array<AccommodationResourceJoin>}
     * @memberof HostAccommodation
     */
    accommodationResourceJoins?: Array<AccommodationResourceJoin>;
}

export function HostAccommodationFromJSON(json: any): HostAccommodation {
    return HostAccommodationFromJSONTyped(json, false);
}

export function HostAccommodationFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostAccommodation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'publicResourcesCount': !exists(json, 'public_resources_count') ? undefined : json['public_resources_count'],
        'residentialResourcesCount': !exists(json, 'residential_resources_count') ? undefined : json['residential_resources_count'],
        'houseId': !exists(json, 'house_id') ? undefined : json['house_id'],
        'hostId': !exists(json, 'host_id') ? undefined : json['host_id'],
        'accommodationResourceJoins': !exists(json, 'accommodationResourceJoins') ? undefined : ((json['accommodationResourceJoins'] as Array<any>).map(AccommodationResourceJoinFromJSON)),
    };
}

export function HostAccommodationToJSON(value?: HostAccommodation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'public_resources_count': value.publicResourcesCount,
        'residential_resources_count': value.residentialResourcesCount,
        'house_id': value.houseId,
        'host_id': value.hostId,
        'accommodationResourceJoins': value.accommodationResourceJoins === undefined ? undefined : ((value.accommodationResourceJoins as Array<any>).map(AccommodationResourceJoinToJSON)),
    };
}

