/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ResourceSelectionBlock.tsx
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import React, {Component} from 'react';
import {GestureResponderEvent, StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import {AvenirBookPrimaryText, AvenirBookServiceGreyText} from "./StyledText";
import blockStyle from "@styles/host/ResourceSelectionBlockStyle";
import {TriangleSelectedImage} from "@components/Images/TriangleSelectedImage";
import {PublicColorImage} from "@components/Images/services/PublicColorImage";
import {i18n} from "@i18n/i18n";
import {ResidentialColorImage} from "@components/Images/services/ResidentialColorImage";
import {ResidentialImage} from "@components/Images/services/ResidentialImage";
import {PublicImage} from "@components/Images/services/PublicImage";
import {ResourceType} from "@services/src/models";


interface ServiceSelectionBlockProps {
    onPress: (event: GestureResponderEvent) => void,
    style: StyleProp<ViewStyle>,
}

interface ServiceSelectionMutexBlockProps extends ServiceSelectionBlockProps {
    active: boolean,
}

interface ServiceTypeBlockProps extends ServiceSelectionBlockProps {
    type: ResourceType
}

/**
 * A view component with Public selection.
 * Based on 'active' property it returns the active or inactive view
 * @param {ServiceSelectionMutexBlockProps} props
 * @returns {any}
 * @constructor
 */
export function PublicSelectionBlock(props: ServiceSelectionMutexBlockProps) {
    return (
        <View style={props.style}>
            {props.active &&
            <SelectionSelectedBlock onPress={props.onPress} type={ResourceType.Public} style={props.style}/>}
            {!props.active &&
            <SelectionNotSelectedBlock onPress={props.onPress} type={ResourceType.Public} style={props.style}/>}
        </View>
    );
}

/**
 * A view component with Residential selection.
 * Based on 'active' property it returns the active or inactive view
 * @param {ServiceSelectionMutexBlockProps} props
 * @returns {any}
 * @constructor
 */
export function ResidentialSelectionBlock(props: ServiceSelectionMutexBlockProps) {
    return (
        <View style={props.style}>
            {props.active &&
            <SelectionSelectedBlock onPress={props.onPress} type={ResourceType.Residential} style={props.style}/>}
            {!props.active &&
            <SelectionNotSelectedBlock onPress={props.onPress} type={ResourceType.Residential} style={props.style}/>}
        </View>
    );
}


/**
 * Component for selected service
 */
class SelectionSelectedBlock extends Component<ServiceTypeBlockProps, any> {
    render() {
        let labelKey = '';
        switch (this.props.type) {
            case ResourceType.Public:
                labelKey = 'services.public';
                break;
            case ResourceType.Residential:
                labelKey = 'services.residential';
                break;
        }
        return (
            <View style={this.props.style}>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[blockStyle.serviceButtonTouchable]}>
                    <View style={blockStyle.serviceTextContainer}>
                        <View style={[blockStyle.serviceButtonContainer]}>
                            {this.props.type == ResourceType.Residential &&
                            <ResidentialColorImage style={[blockStyle.serviceButtonImage]}/>}
                            {this.props.type == ResourceType.Public &&
                            <PublicColorImage style={[blockStyle.serviceButtonImage]}/>}
                        </View>
                        <AvenirBookPrimaryText
                            style={blockStyle.serviceButtonText}>{i18n.t(labelKey)}</AvenirBookPrimaryText>
                    </View>
                    <View style={{flex: 1}}>
                        <TriangleSelectedImage style={[blockStyle.serviceButtonImage]}/>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}

/**
 * Component for NOT selected service
 */
class SelectionNotSelectedBlock extends Component<ServiceTypeBlockProps, any> {
    render() {
        let labelKey = '';
        switch (this.props.type) {
            case ResourceType.Public:
                labelKey = 'services.public';
                break;
            case ResourceType.Residential:
                labelKey = 'services.residential';
                break;
        }
        return (
            <View style={this.props.style}>
                <TouchableOpacity onPress={this.props.onPress} {...this.props}
                                  style={[blockStyle.serviceNotSelectedButtonTouchable]}>
                    <View style={blockStyle.serviceTextContainer}>
                        <View style={[blockStyle.serviceButtonContainer]}>
                            {this.props.type == ResourceType.Residential &&
                            <ResidentialImage style={[blockStyle.serviceButtonImage]}/>}
                            {this.props.type == ResourceType.Public &&
                            <PublicImage style={[blockStyle.serviceButtonImage]}/>}
                        </View>
                        <AvenirBookServiceGreyText
                            style={blockStyle.serviceButtonText}>{i18n.t(labelKey)}</AvenirBookServiceGreyText>
                    </View>
                    <View style={{flex: 1}}>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}