/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressComponent
 */
export interface AddressComponent {
    /**
     * 
     * @type {string}
     * @memberof AddressComponent
     */
    longName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressComponent
     */
    shortName?: string;
}

export function AddressComponentFromJSON(json: any): AddressComponent {
    return AddressComponentFromJSONTyped(json, false);
}

export function AddressComponentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressComponent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'longName': !exists(json, 'long_name') ? undefined : json['long_name'],
        'shortName': !exists(json, 'short_name') ? undefined : json['short_name'],
    };
}

export function AddressComponentToJSON(value?: AddressComponent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'long_name': value.longName,
        'short_name': value.shortName,
    };
}

