/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ReservationDetailsContainerBlock.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {StyleProp, TouchableOpacity, View, ViewStyle} from 'react-native';
import style from "@styles/host/reservations/ReservationDetailsContainerBlockStyle";
import {
    AvenirBookGreyLabelText, AvenirBookServiceGreyText,
    AvenirBookText,
    AvenirBookUpperLabelText,
    AvenirLightGreyText,
    AvenirLightPrimaryText, AvenirLightText,
    AvenirMediumPrimaryText,
    AvenirHeavyText
} from "../StyledText";
import {i18n} from "@i18n/i18n";
import {GuestIconImage} from "@components/Images/services/GuestIconImage";
import {
    Device,
    HostReservationAccommodationJoin,
    HostReservationDetail,
    House,
    Registry,
    User,
    VerificationCodeType
} from "@services/src/models";
import {TextInputBlock} from "../TextInputBlock";
import {DateHelper} from "@helpers/DateHelper";
import {BfreeRecapImage} from "@components/Images/BfreeRecapImage";
import {PeriodImage} from "@components/Images/PeriodImage";
import {UserStateText} from "@components/UserStateText";
import {NavigationProps, openReservationEditAccommodations} from "@helpers/NavigationHelper";
import {AccommodationSelectionBean} from "../../models/beans/AccommodationSelection";
import Colors from "../../config/Colors";
import {RoundedButton} from "@components/RoundedButton";
import {DateTimeInputBlock} from "@components/DateTimeInputBlock";
import Moment from "moment";

interface ReservationDetailsContainerBlockProps {
    title: string,
    image: any,
    children?: any,
    style?: StyleProp<ViewStyle>,
}

export function ReservationDetailsContainerBlock(props: ReservationDetailsContainerBlockProps) {
    return (
        <View style={[style.flex, style.container, props.style]}>
            <View
                style={[style.flex, style.flexWrap, style.background, style.header]}>
                {props.image}
                <View style={style.leftBorderView}/>
            </View>
            <View style={[style.flexBig, style.background]}>
                <View style={style.textContainer}>
                    <AvenirBookGreyLabelText style={[style.titleText]}>
                        {props.title}
                    </AvenirBookGreyLabelText>
                </View>
                {props.children}
            </View>
        </View>
    );
}

interface ReservationUserDataBlockProps {
    registry: Registry,
    user: User,
    style?: StyleProp<ViewStyle>,
    emailStatePress: () => void,
    phoneStatePress: () => void,
}

export function ReservationUserDataBlock(props: ReservationUserDataBlockProps) {
    let hasName = props.registry != null && props.registry.firstName != null && props.registry.lastName != null;
    let name = hasName ? `${props.registry.firstName} ${props.registry.lastName}` : '';
    let registry = props.registry || {};
    let email = props.registry != null ? `${registry.email}` : '';
    let mobile = props.registry != null ? `${registry.mobile}` : '';
    let user = props.user || null;

    return (
        <ReservationDetailsContainerBlock title={i18n.t('common.user_data')} style={props.style}
                                          image={<GuestIconImage style={[style.headImage]}/>}>
            <View style={[style.flex, {flexDirection: 'column'}]}>
                <TextInputBlock label={i18n.t('components.reservation_details.name')}
                                onChangeText={null}
                                placeholder=' '
                                text={name} autoCapitalize={'none'}/>
                <TextInputBlock label={i18n.t('components.reservation_details.mail_address')}
                                onChangeText={null}
                                placeholder=' '
                                text={email} autoCapitalize={'none'}
                                style={{marginBottom: 0,}}/>


                        <UserStateText user={user}
                                   style={{marginTop: 5, marginBottom: 10,}} codeType={VerificationCodeType.Email}/>

                {user && !user.emailVerifiedAt && <RoundedButton isQr={false} style={{marginBottom: 10}} onPress={props.emailStatePress} title={i18n.t("components.reservation_details.resend_email_verification")}/>}


                <TextInputBlock label={i18n.t('components.reservation_details.mobile')}
                                onChangeText={null}
                                placeholder=' '
                                text={mobile} autoCapitalize={'none'}
                                style={{marginBottom: 0}}/>
                <TouchableOpacity onPress={props.phoneStatePress}>
                    <UserStateText user={user}
                                   style={{marginTop: 5, marginBottom: 10,}} codeType={VerificationCodeType.Phone}/>
                    {user && !user.phoneVerifiedAt && <RoundedButton isQr={false} style={{marginBottom: 10}} onPress={props.phoneStatePress} title={i18n.t("components.reservation_details.resend_phone_verification")}/>}
                </TouchableOpacity>
            </View>
        </ReservationDetailsContainerBlock>
    );
}

interface ReservationAccommodationBlockProps {
    hostReservationAccommodationJoins: Array<HostReservationAccommodationJoin>,
    style?: StyleProp<ViewStyle>,
    context: Component<NavigationProps, any>,
    reservation: HostReservationDetail,
    onChange: (house: House, beans: Array<AccommodationSelectionBean>) => void,
    onPressEnabled: boolean,
    unsent: Array<Device>,
    resendCodes: () => void,
}

export function ReservationAccommodationBlock(props: ReservationAccommodationBlockProps) {
    let housesData = [];
    if (props.hostReservationAccommodationJoins) {
        // Group data by house
        props.hostReservationAccommodationJoins.forEach(join => {
            let houseId = join.house.id;
            let house = housesData[houseId];
            if (house == null) {
                let newHouse = join.house;
                housesData[houseId] = {house: newHouse, accommodations: []};
            }
            housesData[houseId].accommodations.push(join.accommodation);
        });
    }
    console.log("HouseData: ", JSON.stringify(housesData));
    return (
        <ReservationDetailsContainerBlock title={i18n.t('common.accommodation')} style={props.style}
                                          image={<BfreeRecapImage style={[style.headImage]}/>}>
            <View style={[style.flex, {flexDirection: 'column'}]}>
                {housesData.map((houseData, key) => (
                    <TouchableOpacity key={key} style={[style.accommodationsRowContainer, {marginBottom: 0}]}
                                      onPress={() => props.onPressEnabled ? openReservationEditAccommodations(props.context, props.reservation, houseData.house, houseData.accommodations, (accommodations) => props.onChange(houseData, accommodations)) : null}>
                        <View flex-box style={{flexDirection: 'column', marginBottom: 0}}>
                            <AvenirLightPrimaryText>
                                {houseData.house.name}
                            </AvenirLightPrimaryText>
                            <AvenirMediumPrimaryText>
                                {getAccommodationsText(houseData)}
                            </AvenirMediumPrimaryText>
                        </View>
                        <View style={[style.detailArrowContainer]}>
                            {/* TODO: Enable and fix detail for reservation*/}
                            {/*<RightArrowColorImage style={[style.detailArrow]}/>*/}
                        </View>
                    </TouchableOpacity>
                ))}
            </View>
            {props.unsent && props.unsent.length == 0 ?
                <AvenirLightText style={{
                    color: props.unsent.length == 0 ? Colors.reservationConfirmed : Colors.reservationPending,
                    margin: 10,
                    marginLeft: 0,
                }}> {props.unsent && i18n.t('components.reservation_details.updated')}</AvenirLightText>
            : props.unsent != null && props.unsent.length != 0 && <RoundedButton isQr={false} title={i18n.t('components.reservation_details.update_now')} style={{marginTop: 5}} onPress={props.resendCodes}/>}
        </ReservationDetailsContainerBlock>
    );
}

function getAccommodationsText(houseData){
    console.log("HOUSEDATA::::: ",houseData);
    if (houseData.house.accommodationsCount <= houseData.accommodations.length)
        return i18n.t('components.reservation_details.all_accommodations');
    if (houseData.accommodations.length == 1)
        return houseData.accommodations[0].name 
    return  i18n.t('components.reservation_details.selected_accommodations', {accommodations: houseData.accommodations.length});
}

interface ReservationPeriodBlockProps {
    from_datetime: Date,
    to_datetime: Date,
    style?: StyleProp<ViewStyle>,
    onDateChange: (date: any, type: string)=>void,
    onTimeChange: (time: any, type: string)=>void,
}

export function ReservationPeriodBlock(props: ReservationPeriodBlockProps) {

    let date = new Date(Moment(DateHelper.formatDatetime(props.to_datetime).split(' ')[0]).format('YYYY/MM/DD'))


    return (
        <ReservationDetailsContainerBlock title={i18n.t('common.period')} style={props.style}
                                          image={<PeriodImage style={[style.headImage]}/>}>

                <View style={[style.flex, {flexDirection: 'column', marginTop: 5,}]}>
                        <DateTimeInputBlock label={i18n.t('rows.recap.from_date')} date={Moment(props.from_datetime).toDate()} time={Moment(props.from_datetime).toDate()}
                                            onChangeDate={(date)=> {
                                                if (!isNaN(date.getDate()))
                                                    props.onDateChange(date, 'from')
                                            }}
                                            onChangeTime={(date)=> {
                                                if (!isNaN(date.getTime()))
                                                    props.onTimeChange(date, 'from')
                                            }}/>
                </View>



                <View style={[style.flex, {flexDirection: 'column', marginTop: 5,}]}>

                    <DateTimeInputBlock label={i18n.t('rows.recap.to_date')} date={Moment(props.to_datetime).toDate()} time={Moment(props.to_datetime).toDate()}
                                        onChangeDate={(date)=> {
                                            if (!isNaN(date.getDate()))
                                                props.onDateChange(date, 'to')
                                        }}
                                        onChangeTime={(date)=> {
                                            if (!isNaN(date.getDate()))
                                                props.onTimeChange(date, 'to')
                                        }}/>
                </View>

        </ReservationDetailsContainerBlock>
    );
}