/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       YourHousesScreen.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../i18n/i18n";
import yourHousesScreenStyle from "@styles/host/YourHousesScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openNewHouse} from "@helpers/NavigationHelper";
import {AddButton} from "@components/AddButton";
import {getUserFromNavigationProps, setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {HostHouse, User} from "@services/src/models/index";
import {HouseRow} from "@components/rows/HouseRow";
import {HouseFactory} from "../../models/factory/HouseFactory";
import {DebugButton} from "@components/DebugButton";
import {HostHousesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {NoDataLabel} from "@components/NoDataLabel";

interface State extends LoaderState {
    user: User,
    houses: Array<HostHouse>,
}

export default class YourHousesScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.your_houses.title');

        this.state = {
            loading: false,
            refreshing: false,
            user: getUserFromNavigationProps(this),
            houses: [],
        };
    }

    componentDidMount() {
        this.downloadHouses();

        this.didFocusListener = this.props.navigation.addListener('didFocus', payload => {
            this.onRefresh();
        });
    }

    componentWillUnmount(): void {
        this.didFocusListener.remove();
    }

    render() {
        return (
            <View style={[yourHousesScreenStyle.container]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[yourHousesScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <View style={yourHousesScreenStyle.scrollContainerWithBottomButton}>
                        <ScrollView
                            style={yourHousesScreenStyle.container}
                            contentContainerStyle={yourHousesScreenStyle.contentContainer}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()}/>
                            }>
                            {this.state.houses.map((house, key) => (
                                // @ts-ignore
                                <View
                                    key={key}
                                    style={[yourHousesScreenStyle.houseContainer]}>
                                    <HouseRow accommodations={house.accommodationsCount || 0} context={this}
                                              free={house.accommodationsCount || 0}
                                              house_id={house.id}
                                              house_name={house.name} public_count={house.publicResourcesCount}
                                              residential_count={house.residentialResourcesCount}
                                              house={house}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.houses} show={this.state.showNoData}/>
                    </View>
                    <View style={[{position: 'absolute', bottom: 20}]}>
                        <AddButton title={i18n.t('screens.dashboard.blocks.houses.add_button')}
                                   onPress={() => openNewHouse(this)}/>
                    </View>
                </View>
            </View>
        );
    }

    private onRefresh() {
        this.downloadHouses();
    }

    private downloadHouses() {
        let self = this;

        new HostHousesApi(this).apiHostHousesGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} houses for host.`);
            self.setState({houses: resp.data, showNoData: true,});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get houses list");
            self.setState({showNoData: true,});
        });
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({houses: HouseFactory.factoryArray(3)});
}