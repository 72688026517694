/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       IntroReservationScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    emailInput: {
    },
    developmentModeText: {
        marginBottom: 20,
        color: 'rgba(0,0,0,0.4)',
        fontSize: 14,
        lineHeight: 19,
        textAlign: 'center',
    },
    contentContainer: {
        justifyContent: 'space-between',
        paddingTop: 30,
    },
    welcomeContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 30,
    },
    inputContainer: {
        flex: 2,
        marginTop: 60,
        marginBottom: 20,
    },
    buttonContainer: {
        flex: 2,
        marginTop: 10,
        marginBottom: 20,
    },
    welcomeImage: {
        width: 325,
        height: 63,
        resizeMode: 'contain',
        alignSelf: 'center',
    },
    welcomeText: {
        flex: 1,
    },
    flex: {
        flex: 1,
    },
    getStartedContainer: {
        height: '100%',
    },
    titleTextContainer: {
        flex: 3,
    },
});

export default styles;