/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ResourceJoin,
    ResourceJoinFromJSON,
    ResourceJoinToJSON,
} from '../models';

export interface ApiResourceJoinsIdDeleteRequest {
    id: number;
}

export interface ApiResourceJoinsIdGetRequest {
    id: string;
}

export interface ApiResourceJoinsIdPutRequest {
    id: number;
    resourceJoin: ResourceJoin;
}

export interface ApiResourceJoinsPostRequest {
    resourceJoin: ResourceJoin;
}

/**
 * 
 */
export class ResourceJoinsApi extends runtime.BaseAPI {

    /**
     */
    async apiResourceJoinsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResourceJoin>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_joins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceJoinFromJSON));
    }

    /**
     */
    async apiResourceJoinsGet(initOverrides?: RequestInit): Promise<Array<ResourceJoin>> {
        const response = await this.apiResourceJoinsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResourceJoinsIdDeleteRaw(requestParameters: ApiResourceJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourceJoinsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourceJoinsIdDelete(requestParameters: ApiResourceJoinsIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourceJoinsIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResourceJoinsIdGetRaw(requestParameters: ApiResourceJoinsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ResourceJoin>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourceJoinsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceJoinFromJSON(jsonValue));
    }

    /**
     */
    async apiResourceJoinsIdGet(requestParameters: ApiResourceJoinsIdGetRequest, initOverrides?: RequestInit): Promise<ResourceJoin> {
        const response = await this.apiResourceJoinsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiResourceJoinsIdPutRaw(requestParameters: ApiResourceJoinsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiResourceJoinsIdPut.');
        }

        if (requestParameters.resourceJoin === null || requestParameters.resourceJoin === undefined) {
            throw new runtime.RequiredError('resourceJoin','Required parameter requestParameters.resourceJoin was null or undefined when calling apiResourceJoinsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_joins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceJoinToJSON(requestParameters.resourceJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourceJoinsIdPut(requestParameters: ApiResourceJoinsIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourceJoinsIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiResourceJoinsPostRaw(requestParameters: ApiResourceJoinsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.resourceJoin === null || requestParameters.resourceJoin === undefined) {
            throw new runtime.RequiredError('resourceJoin','Required parameter requestParameters.resourceJoin was null or undefined when calling apiResourceJoinsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/resource_joins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceJoinToJSON(requestParameters.resourceJoin),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiResourceJoinsPost(requestParameters: ApiResourceJoinsPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiResourceJoinsPostRaw(requestParameters, initOverrides);
    }

}
