/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    Guest,
    GuestFromJSON,
    GuestFromJSONTyped,
    GuestToJSON,
    HostReservationAccommodationJoin,
    HostReservationAccommodationJoinFromJSON,
    HostReservationAccommodationJoinFromJSONTyped,
    HostReservationAccommodationJoinToJSON,
    Registry,
    RegistryFromJSON,
    RegistryFromJSONTyped,
    RegistryToJSON,
    ReservationStateEnum,
    ReservationStateEnumFromJSON,
    ReservationStateEnumFromJSONTyped,
    ReservationStateEnumToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface HostReservationDetail
 */
export interface HostReservationDetail {
    /**
     * 
     * @type {number}
     * @memberof HostReservationDetail
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof HostReservationDetail
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HostReservationDetail
     */
    endAt?: Date;
    /**
     * 
     * @type {ReservationStateEnum}
     * @memberof HostReservationDetail
     */
    currentState?: ReservationStateEnum;
    /**
     * 
     * @type {Registry}
     * @memberof HostReservationDetail
     */
    registry?: Registry;
    /**
     * 
     * @type {Guest}
     * @memberof HostReservationDetail
     */
    guest?: Guest;
    /**
     * 
     * @type {User}
     * @memberof HostReservationDetail
     */
    user?: User;
    /**
     * 
     * @type {Array<Device>}
     * @memberof HostReservationDetail
     */
    unsent?: Array<Device>;
    /**
     * 
     * @type {Array<HostReservationAccommodationJoin>}
     * @memberof HostReservationDetail
     */
    reservationAccommodationJoins?: Array<HostReservationAccommodationJoin>;
}

export function HostReservationDetailFromJSON(json: any): HostReservationDetail {
    return HostReservationDetailFromJSONTyped(json, false);
}

export function HostReservationDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostReservationDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startAt': !exists(json, 'start_at') ? undefined : (new Date(json['start_at'])),
        'endAt': !exists(json, 'end_at') ? undefined : (new Date(json['end_at'])),
        'currentState': !exists(json, 'current_state') ? undefined : ReservationStateEnumFromJSON(json['current_state']),
        'registry': !exists(json, 'registry') ? undefined : RegistryFromJSON(json['registry']),
        'guest': !exists(json, 'guest') ? undefined : GuestFromJSON(json['guest']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'unsent': !exists(json, 'unsent') ? undefined : ((json['unsent'] as Array<any>).map(DeviceFromJSON)),
        'reservationAccommodationJoins': !exists(json, 'reservation_accommodation_joins') ? undefined : ((json['reservation_accommodation_joins'] as Array<any>).map(HostReservationAccommodationJoinFromJSON)),
    };
}

export function HostReservationDetailToJSON(value?: HostReservationDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start_at': value.startAt === undefined ? undefined : (value.startAt.toISOString()),
        'end_at': value.endAt === undefined ? undefined : (value.endAt.toISOString()),
        'current_state': ReservationStateEnumToJSON(value.currentState),
        'registry': RegistryToJSON(value.registry),
        'guest': GuestToJSON(value.guest),
        'user': UserToJSON(value.user),
        'unsent': value.unsent === undefined ? undefined : ((value.unsent as Array<any>).map(DeviceToJSON)),
        'reservation_accommodation_joins': value.reservationAccommodationJoins === undefined ? undefined : ((value.reservationAccommodationJoins as Array<any>).map(HostReservationAccommodationJoinToJSON)),
    };
}

