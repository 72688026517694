/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CloseColorImage.tsx
 * @author     Christian Ascone
 * @date       10/11/19 12:52 PM
 */

import React from 'react';
import {Image} from "react-native";

export function CloseColorImage(props) {
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                require('../../assets/images/close_color.png')
            }
        />
    );
}