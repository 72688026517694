/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       PlansTableImageHelper.web.tsx
 * @author     Christian Ascone
 * @date       1/9/20 10:31 AM
 */

import React from 'react';
import {Image} from "react-native";
import {i18n} from "@i18n/i18n";
import {Logger} from "@helpers/Logger";

/**
 * Gets size of plans-table image
 * @param {(width: any, height: any) => void} success
 * @param {(error: any) => void} failure
 */
export function plansTableImageSize(success: (width: any, height: any) => void, failure: (error: any) => void) {
    let uri = i18n.locale == 'it-IT' ? require('../../assets/images/plans-table-ita.png') : require('../../assets/images/plans-table-eng.png');
    Logger.warn('', uri);
    Image.getSize(uri, (width, height) => success(width, height), (error) => failure(error));
}