/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FilterRoundedTextButton.tsx
 * @author     Christian Ascone
 * @date       10/31/19 12:54 PM
 */

import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import ButtonStyle from "@styles/ButtonStyle";
import {CheckImage} from "@components/Images/CheckImage";
import FilterRoundedTextButtonProps from "./properties/FilterRoundedTextButtonProps";
import {AvenirHeavyText, AvenirLightPrimaryText} from "./StyledText";

export function FilterRoundedTextButton(props: FilterRoundedTextButtonProps) {
    return (
        <View style={props.style}>
            <TouchableOpacity onPress={props.onPress} {...props}
                              style={[ButtonStyle.filterRoundedTextContainer, props.selected ? ButtonStyle.filterImageContainerBackround : null]}>
                {props.selected &&
                <View style={ButtonStyle.filterTextContainer}>
                    <AvenirHeavyText style={{color: 'white',}}>{props.text}</AvenirHeavyText>
                </View>
                }
                {!props.selected &&
                <View style={ButtonStyle.filterTextContainer}>
                    <AvenirLightPrimaryText style={[]}>{props.text}</AvenirLightPrimaryText>
                </View>
                }
                {props.selected &&
                <CheckImage style={ButtonStyle.filterCheck}/>
                }
            </TouchableOpacity>
        </View>
    );
}