/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressProviderType,
    AddressProviderTypeFromJSON,
    AddressProviderTypeFromJSONTyped,
    AddressProviderTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    fullAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    placeId?: string;
    /**
     * 
     * @type {AddressProviderType}
     * @memberof Address
     */
    placeProvider?: AddressProviderType;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zip?: string;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'countryId': !exists(json, 'country_id') ? undefined : json['country_id'],
        'fullAddress': !exists(json, 'full_address') ? undefined : json['full_address'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'placeId': !exists(json, 'place_id') ? undefined : json['place_id'],
        'placeProvider': !exists(json, 'place_provider') ? undefined : AddressProviderTypeFromJSON(json['place_provider']),
        'street': !exists(json, 'street') ? undefined : json['street'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'locality': !exists(json, 'locality') ? undefined : json['locality'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'country_id': value.countryId,
        'full_address': value.fullAddress,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'place_id': value.placeId,
        'place_provider': AddressProviderTypeToJSON(value.placeProvider),
        'street': value.street,
        'number': value.number,
        'city': value.city,
        'locality': value.locality,
        'zip': value.zip,
    };
}

