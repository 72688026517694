/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseFactory.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:02 AM
 */


import * as fakerStatic from 'faker';
import {House} from "@services/src/models";
import {AddressFactory} from "./AddressFactory";

export class HouseFactory {
    static factoryArray(size: number): House[] {
        let array = [];
        for (let i = 0; i < size; i++) {
            array.push(HouseFactory.factory());
        }
        return array;
    }

    static factory(): House {
        return {
            description: fakerStatic.lorem.paragraph(1).substr(0, 255),
            id: fakerStatic.random.number(4),
            name: fakerStatic.commerce.department(),
            address: AddressFactory.factory(),
            photoPath: 'https://spikingnet.com/wp-content/uploads/2019/06/led-surface-lights-square-10.jpg',
        };
    }
}

