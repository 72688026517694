/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HostType {
    Private = 'PRIVATE',
    Company = 'COMPANY'
}

export function HostTypeFromJSON(json: any): HostType {
    return HostTypeFromJSONTyped(json, false);
}

export function HostTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostType {
    return json as HostType;
}

export function HostTypeToJSON(value?: HostType | null): any {
    return value as any;
}

