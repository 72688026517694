/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressComponent,
    AddressComponentFromJSON,
    AddressComponentFromJSONTyped,
    AddressComponentToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddressToCoordinates
 */
export interface AddressToCoordinates {
    /**
     * 
     * @type {string}
     * @memberof AddressToCoordinates
     */
    lat?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressToCoordinates
     */
    lng?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressToCoordinates
     */
    accuracy?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressToCoordinates
     */
    formattedAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressToCoordinates
     */
    placeId?: string;
    /**
     * 
     * @type {Array<AddressComponent>}
     * @memberof AddressToCoordinates
     */
    addressComponents?: Array<AddressComponent>;
}

export function AddressToCoordinatesFromJSON(json: any): AddressToCoordinates {
    return AddressToCoordinatesFromJSONTyped(json, false);
}

export function AddressToCoordinatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressToCoordinates {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lng': !exists(json, 'lng') ? undefined : json['lng'],
        'accuracy': !exists(json, 'accuracy') ? undefined : json['accuracy'],
        'formattedAddress': !exists(json, 'formatted_address') ? undefined : json['formatted_address'],
        'placeId': !exists(json, 'place_id') ? undefined : json['place_id'],
        'addressComponents': !exists(json, 'address_components') ? undefined : ((json['address_components'] as Array<any>).map(AddressComponentFromJSON)),
    };
}

export function AddressToCoordinatesToJSON(value?: AddressToCoordinates | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'lng': value.lng,
        'accuracy': value.accuracy,
        'formatted_address': value.formattedAddress,
        'place_id': value.placeId,
        'address_components': value.addressComponents === undefined ? undefined : ((value.addressComponents as Array<any>).map(AddressComponentToJSON)),
    };
}

