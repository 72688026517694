/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Device,
    DeviceFromJSON,
    DeviceToJSON,
} from '../models';

export interface ApiDevicesByImeiImeiGetRequest {
    imei: string;
}

export interface ApiDevicesCypherUpdatePostRequest {
    device: Device;
}

export interface ApiDevicesFilterbyResourceIdGetRequest {
    id: number;
}

export interface ApiDevicesIdDeleteRequest {
    id: number;
}

export interface ApiDevicesIdGetRequest {
    id: string;
}

export interface ApiDevicesIdPutRequest {
    id: number;
    device: Device;
}

export interface ApiDevicesPostRequest {
    device: Device;
}

/**
 * 
 */
export class DevicesApi extends runtime.BaseAPI {

    /**
     */
    async apiDevicesByImeiImeiGetRaw(requestParameters: ApiDevicesByImeiImeiGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Device>> {
        if (requestParameters.imei === null || requestParameters.imei === undefined) {
            throw new runtime.RequiredError('imei','Required parameter requestParameters.imei was null or undefined when calling apiDevicesByImeiImeiGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/devices/byImei/{imei}`.replace(`{${"imei"}}`, encodeURIComponent(String(requestParameters.imei))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceFromJSON(jsonValue));
    }

    /**
     */
    async apiDevicesByImeiImeiGet(requestParameters: ApiDevicesByImeiImeiGetRequest, initOverrides?: RequestInit): Promise<Device> {
        const response = await this.apiDevicesByImeiImeiGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDevicesCypherUpdatePostRaw(requestParameters: ApiDevicesCypherUpdatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError('device','Required parameter requestParameters.device was null or undefined when calling apiDevicesCypherUpdatePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/devices/cypher/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceToJSON(requestParameters.device),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDevicesCypherUpdatePost(requestParameters: ApiDevicesCypherUpdatePostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDevicesCypherUpdatePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDevicesFilterbyResourceIdGetRaw(requestParameters: ApiDevicesFilterbyResourceIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Device>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDevicesFilterbyResourceIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/devices/filterbyResource/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceFromJSON));
    }

    /**
     */
    async apiDevicesFilterbyResourceIdGet(requestParameters: ApiDevicesFilterbyResourceIdGetRequest, initOverrides?: RequestInit): Promise<Array<Device>> {
        const response = await this.apiDevicesFilterbyResourceIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDevicesGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Device>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceFromJSON));
    }

    /**
     */
    async apiDevicesGet(initOverrides?: RequestInit): Promise<Array<Device>> {
        const response = await this.apiDevicesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDevicesIdDeleteRaw(requestParameters: ApiDevicesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDevicesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/devices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDevicesIdDelete(requestParameters: ApiDevicesIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDevicesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDevicesIdGetRaw(requestParameters: ApiDevicesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Device>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDevicesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/devices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceFromJSON(jsonValue));
    }

    /**
     */
    async apiDevicesIdGet(requestParameters: ApiDevicesIdGetRequest, initOverrides?: RequestInit): Promise<Device> {
        const response = await this.apiDevicesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiDevicesIdPutRaw(requestParameters: ApiDevicesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDevicesIdPut.');
        }

        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError('device','Required parameter requestParameters.device was null or undefined when calling apiDevicesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/devices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceToJSON(requestParameters.device),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDevicesIdPut(requestParameters: ApiDevicesIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDevicesIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiDevicesPostRaw(requestParameters: ApiDevicesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError('device','Required parameter requestParameters.device was null or undefined when calling apiDevicesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/devices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceToJSON(requestParameters.device),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiDevicesPost(requestParameters: ApiDevicesPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiDevicesPostRaw(requestParameters, initOverrides);
    }

}
