import React, {Component} from "react";
import {NavigationProps} from "@helpers/NavigationHelper";
import {Linking, Platform, ScrollView, View} from "react-native";
import {AppApi} from "@services/src";
import screenStyles from "@styles/host/user/ProfilePassepartoutScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader from "@helpers/LoaderHelper";
import cardsScreenStyle from "@styles/host/cards/CardScreenStyle";
import {AvenirBookPrimaryText, AvenirHeavyTitleText, AvenirLightText, AvenirMediumText} from "@components/StyledText";
import {ConfirmButtonBottom} from "@components/ConfirmButton";
import {LogoBFreeImage} from "@components/Images/LogoBFreeImage";
import {StatusConnectionDotView} from "@components/StatusDotView";
import statusDotStyles from "@styles/StatusDotViewStyle";
import {VersionHelper} from "@helpers/VersionHelper";
import {i18n} from "@i18n/i18n";

interface State {
    loading: boolean,
    version: string,
}

export default class UpdateAppScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            version: '',
        }
    }

    componentDidMount(): void {
        new AppApi(this).apiAppVersionGet().then(resp => {
            let version = resp['front'];
            this.setState({version: version});
        }).catch(e => {
            console.log(e);
        });
    }

    openAppStore(){
        if (Platform.OS === "android")
            Linking.openURL('https://play.google.com/store/apps/details?id=com.plexa.bfree');//"https://play.google.com/store/apps/details?id=com.plexa.bfree");
        else if (Platform.OS === "ios")
            Linking.openURL('https://apps.apple.com/it/app/b-free-host/id1498457005');
    }

    render() {
        return (
            <View style={[screenStyles.container, {width: "100%", paddingHorizontal: 30}]}>
                <Loader state={this.state}/>
                <ScrollView style={[cardsScreenStyle.container, {marginTop: '10%', alignContent: "center"}]} contentContainerStyle={[{alignItems: 'center'}]} >
                    <LogoBFreeImage style={{height: '20%', width: '60%'}} resizeMode={'contain'}/>
                    <AvenirHeavyTitleText style={{textAlign: "center", marginTop: "20%"}}> {i18n.t("to_update.warning")} </AvenirHeavyTitleText>
                    <AvenirMediumText style={{textAlign: "center", marginBottom: "20%"}}> {process.env.ENVIRONMENT != 'production' ? i18n.t("to_update.need_to_update_test") : i18n.t("to_update.need_to_update")} </AvenirMediumText>
                    <View style = {{marginBottom: "20%", marginTop: "10%", flexDirection: "column", alignItems:"center"}}>
                        <View style={{flexDirection: "row", margin: "10%"}}>
                            <View style={[statusDotStyles.dot, statusDotStyles.online, statusDotStyles.container,]}/>
                            <AvenirBookPrimaryText>{i18n.t("to_update.required")}</AvenirBookPrimaryText>
                            <AvenirLightText>{this.state.version}</AvenirLightText>
                        </View>
                        <View style={{flexDirection: "row", margin: "10%"}}>
                            <View style={[statusDotStyles.dot, statusDotStyles.expired, statusDotStyles.container,]}/>
                            <AvenirBookPrimaryText>{i18n.t("to_update.current")}</AvenirBookPrimaryText>
                            <AvenirLightText>{VersionHelper.getAppVersion()}</AvenirLightText>
                        </View>
                    </View>
                </ScrollView>

                    <ConfirmButtonBottom active={process.env.ENVIRONMENT == 'production'} onPress={()=> {if (process.env.ENVIRONMENT == 'production') this.openAppStore()}} title={i18n.t("to_update.open_store")} style={{}}/>

            </View>

        );
    }
}