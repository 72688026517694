/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       Validator.ts
 * @author     Christian Ascone
 * @date       10/12/19 5:31 PM
 */

/**
 * Validates the given email and returns true or false
 * @param text
 * @returns {boolean}
 */
export function validateEmail(text) {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w*)+$/;
    return reg.test(text) !== false;
}

/**
 * Validates a phone number and returns true or false
 * @param text
 * @returns {boolean}
 */
export function validatePhoneNumber(text) {
    let reg = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return reg.test(text) !== false || text == '+';
}

/**
 * Normalize a given email address removing spaces and setting lower case
 * @param email
 * @returns {string}
 */
export function normalizeEmail(email: string) {
    if (email == null)
        return '';
    return toLowerCase(email.trim().replace(/\s/g, ''));
}

function toLowerCase(string: string){
    let array = string.split('');
    array = array.map(char => {
        let code = char.charCodeAt(0);
        if (code >=65 && code <= 90)
            code += 32;
        return char = String.fromCharCode(code);
    });
    return array.join('');
}