/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DeviceScanHelper.ts
 * @author     Christian Ascone
 * @date       2/11/21 9:37 PM
 */

import {BfreeX1} from "@helpers/BfreeX1";

export class DeviceScanHelper {
    static IMEI_LENGTH = 15;

    /**
     * Extracts the imei code from a complete device code
     * @param {string} code
     * @returns {any}
     */
    public static extractImeiFromCode(code: string) {
        if (code == null || DeviceScanHelper.trim(code).length == 0) {
            return '';
        }
        code = code.toUpperCase();
        code = code.replace(/\s/g, '');
        var toCheck = "IDX:";
        var indexOfPattern = code.lastIndexOf(toCheck);
        console.log("CODE: " + code);
        if (indexOfPattern == -1){
            toCheck = "IMEI:";
            indexOfPattern = code.lastIndexOf(toCheck);
            if (indexOfPattern == -1)
                return DeviceScanHelper.returnDefaultImei(code);
        }
        console.log("Code: " + code);
        console.log("IndexOfPattern: " + indexOfPattern);
        console.log("ToCheck.length: " + toCheck.length);
        const startIndex = indexOfPattern + toCheck.length;
        const endIndex = indexOfPattern + toCheck.length + DeviceScanHelper.IMEI_LENGTH;
        console.log("StartIndex: " + startIndex);
        console.log("EndIndex: " + endIndex);
        /*if (endIndex > code.length)
            return DeviceScanHelper.returnDefaultImei(code);*/
        return [code.substring(startIndex, endIndex), toCheck];
    }

    /**
     * Extracts the imei code from a complete device code
     * @param {string} code
     * @returns {any}
     */
    public static extractCodeFromCard(code: string) {
        if (code == null || DeviceScanHelper.trim(code).length == 0) {
            return '';
        }

        code = code.toUpperCase();
        code = code.replace(/\s/g, '');
        var toCheck = "TAGN:";
        var indexOfPattern = code.lastIndexOf(toCheck);
        if (indexOfPattern == -1){
            toCheck = "TAGX:";
            indexOfPattern = code.lastIndexOf(toCheck);
            if (indexOfPattern == -1){
                toCheck = "RFID:";
                indexOfPattern = code.lastIndexOf(toCheck);
                if (indexOfPattern == -1)
                    return DeviceScanHelper.returnDefaultImei(code);
            }

        }
        const startIndex = indexOfPattern + toCheck.length;
        return [code.substring(startIndex, code.length), toCheck];
    }

    public static trim(value : string){
        let result = [];
        for (var i = 0; i < value.length; i++) {
            var char = value.charAt(i)
            if (char != ' ')
                result.push(char);
        }
        return  result.toString();
    }

    /**
     * Returns the default imei from given string
     * @param code
     */
    private static returnDefaultImei(code: string) {
        if (code.trim().length == this.IMEI_LENGTH) {
            return [code.trim(), ''];
        }
        return '';
    }
}