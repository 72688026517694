/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CardSelection.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:01 AM
 */

import {Card, HostCardStatus} from "@services/src/models";

export interface CardSelectionBean {
    card: HostCardStatus,
    selected: boolean,
}

export class CardSelection {

    /**
     * Builds a bean from Card
     * @param {Card} card
     * @param selected
     * @returns {CardSelectionBean}
     */
    static fromCard(card: Card, selected = false, ids: number[]): CardSelectionBean {
        console.log(ids);
        return {card: card, selected: ids.includes(card.id) ? true : selected};
    }

    /**
     * Builds a bean list from Card List
     * @param {Card[]} cards
     * @param {CardSelectionBean[]} stateCards
     * @param selected
     * @returns {CardSelectionBean[]}
     */
    static fromCardList(cards: Card[], selected = false, stateCards: CardSelectionBean[]): CardSelectionBean[] {
        let ids = [];
        stateCards.forEach((card)=> {
            if (card.selected == true)
                ids.push(card.card.id);
        })
        return cards.map(card => this.fromCard(card, selected, ids));
    }
}