/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PeriodicReading
 */
export interface PeriodicReading {
    /**
     * 
     * @type {number}
     * @memberof PeriodicReading
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PeriodicReading
     */
    keyword?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodicReading
     */
    keywordValue?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodicReading
     */
    unitOfKeyword?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodicReading
     */
    topic?: string;
    /**
     * 
     * @type {number}
     * @memberof PeriodicReading
     */
    resourceId?: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodicReading
     */
    deviceId?: number;
}

export function PeriodicReadingFromJSON(json: any): PeriodicReading {
    return PeriodicReadingFromJSONTyped(json, false);
}

export function PeriodicReadingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodicReading {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'keyword': !exists(json, 'keyword') ? undefined : json['keyword'],
        'keywordValue': !exists(json, 'keyword_value') ? undefined : json['keyword_value'],
        'unitOfKeyword': !exists(json, 'unit_of_keyword') ? undefined : json['unit_of_keyword'],
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'resourceId': !exists(json, 'resource_id') ? undefined : json['resource_id'],
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
    };
}

export function PeriodicReadingToJSON(value?: PeriodicReading | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'keyword': value.keyword,
        'keyword_value': value.keywordValue,
        'unit_of_keyword': value.unitOfKeyword,
        'topic': value.topic,
        'resource_id': value.resourceId,
        'device_id': value.deviceId,
    };
}

