/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DateInputBlock.web.tsx
 * @author     Christian Ascone
 * @date       10/31/19 10:26 AM
 */

import React, {Component} from 'react';
import {TouchableOpacity, View} from 'react-native';
import TextStyle from '@styles/TextStyle';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import DateInputBlockProps from "./properties/DateInputBlockProps";


export class DateInputBlock extends Component<DateInputBlockProps, any> {
    changeDate(date: Date) {
        this.props.onChangeDate(date);
    }

    render() {
        return (
            <View style={{flexDirection: 'column', zIndex: -99,}}>
                <View style={{flexDirection: 'row', flex: 1,}}>
                    <TouchableOpacity style={[{flex: 1, marginRight: 5,}]}>
                        <DatePicker
                            style={[this.props.style, TextStyle.separator,]}
                            selected={this.props.date}
                            onChange={(date) => this.changeDate(date)}
                            dateFormat="dd/MM/yyyy"
                        />
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
}