/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       AvatarPlaceholderImage.tsx
 * @author     Christian Ascone
 * @date       9/30/19 6:09 PM
 */

import React from 'react';
import {Image} from "react-native";

export function AvatarPlaceholderImage(props) {
    let source = props.imageUri ? {uri: props.imageUri} : require('../../assets/images/avatar_placeholder.png');
    return (
        <Image
            {...props}
            style={[props.style]}
            source={
                source
            }
        />
    );
}