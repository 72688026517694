/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DeviceProtocolVersion {
    Default = 'DEFAULT',
    Encryption1 = 'ENCRYPTION1'
}

export function DeviceProtocolVersionFromJSON(json: any): DeviceProtocolVersion {
    return DeviceProtocolVersionFromJSONTyped(json, false);
}

export function DeviceProtocolVersionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceProtocolVersion {
    return json as DeviceProtocolVersion;
}

export function DeviceProtocolVersionToJSON(value?: DeviceProtocolVersion | null): any {
    return value as any;
}

