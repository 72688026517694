/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       PermissionManager.ts
 * @author     Christian Ascone
 * @date       2/26/20 6:07 PM
 */

import * as Permissions from 'expo-permissions';

export class PermissionManager {
    /**
     * Checks if user has granted camera roll permission
     * @returns {Promise<void>}
     */
    static async checkCameraRollPermission() {
        const permission = await Permissions.getAsync(Permissions.CAMERA_ROLL);
        if (permission.status !== 'granted') {
            const newPermission = await Permissions.askAsync(Permissions.CAMERA_ROLL);
            return newPermission.status === 'granted';
        }
        return true;
    }

    /**
     * Checks if user has granted camera permission
     * @returns {Promise<boolean>}
     */
    static async checkCameraPermission() {
        const permission = await Permissions.getAsync(Permissions.CAMERA);
        if (permission.status !== 'granted') {
            const newPermission = await Permissions.askAsync(Permissions.CAMERA);
            return newPermission.status === 'granted';
        }
        return true;
    }

    /**
     * Checks if user has granted location permission
     */
    static async checkLocationPermission() {
        const permission = await Permissions.getAsync(Permissions.LOCATION);
        if (permission.status !== 'granted') {
            const newPermission = await Permissions.askAsync(Permissions.LOCATION);
            return newPermission.status === 'granted';
        }
        return true;
    }
}