/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CreditsRow.tsx
 * @author     Christian Ascone
 * @date       1/9/20 12:07 PM
 */

import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {i18n} from "@i18n/i18n";
import {AvenirHeavyPrimaryText, AvenirLightPrimaryText} from "../StyledText";
import {RightArrowColorImage} from "@components/Images/RightArrowColorImage";
import creditsRowStyle from "@styles/host/rows/CreditsRowStyle";
import {LogoBFreeCreditImage} from "@components/Images/LogoBFreeCreditImage";
import {Credit} from "@services/src";
import {PriceHelper} from "@helpers/PriceHelper";

interface CreditsRowProps {
    credit: Credit,
    onPress: () => void,
}

export function CreditsRow(props: CreditsRowProps) {
    let prefix = 'rows.credits.';
    return (
        <TouchableOpacity style={[creditsRowStyle.flex, creditsRowStyle.container]} onPress={props.onPress}>
            <View style={[creditsRowStyle.textContainer]}>
                <LogoBFreeCreditImage style={[creditsRowStyle.icon]}/>
                <AvenirHeavyPrimaryText
                    style={[creditsRowStyle.smallText, {
                        fontSize: 20,
                        textAlign: 'right',
                    }]}>{props.credit.amount}</AvenirHeavyPrimaryText>
                <AvenirLightPrimaryText
                    style={[creditsRowStyle.text]}>{i18n.t(prefix + 'credits')}</AvenirLightPrimaryText>
                <AvenirLightPrimaryText
                    style={[creditsRowStyle.smallText, {flex: 0.1}]}>|</AvenirLightPrimaryText>
                <AvenirHeavyPrimaryText
                    style={[creditsRowStyle.text]}>{"- "/*PriceHelper.priceToDouble(props.credit.price)*/}€</AvenirHeavyPrimaryText>
                <View style={[creditsRowStyle.detailArrowContainer]}>
                    <RightArrowColorImage style={[creditsRowStyle.detailArrow]}/>
                </View>
            </View>
        </TouchableOpacity>
    );
}